import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { SexWrapper } from '../SearchBar/styles';
import * as api from '../../api';

const genders = [
  {
    value: 'MALE',
    label: 'Male'
  },
  {
    value: 'FEMALE',
    label: 'Female'
  }
];

const Gender = ({ updateProfileInfo, profile }) => {
  const [gender, setGender] = useState(profile.gender);
  
  useEffect(() => {
    if (!gender && profile.gender) {
      setGender(profile.gender);
    }
  }, [profile]);
  
  const onChange = e => {
    const gender = e.target.value;
    
    setGender(gender);
    
    api.updateProfile({ body: { gender } })
      .then(() => updateProfileInfo({ gender }));
  };
  
  return (
    <GenderWrapper>
      <span className="title">Gender</span>
      <div className='radio_group'>
        {genders.map(({ value, label }) => (
          <label key={value}>
            <input
              name="gender"
              type="radio"
              value={value}
              checked={value === gender}
              onChange={onChange}
            />
            {label}
          </label>
        ))}
      </div>
    </GenderWrapper>
  );
};

export default Gender;

const GenderWrapper = styled(SexWrapper)`
  flex-direction: column;
  align-items: baseline;
  padding: 1.75rem 0;
  
  .title {
    font-size: 18px;
    margin-bottom: 15px;
  }
`;