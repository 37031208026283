import styled from 'styled-components';

export const ButtonContainer = styled.div`
	img {
	  width: 1rem;
    height: 1rem;
	}
	
	button {
		background-color: #dd4057;
    border: 0;
    color: #fff;
    outline: none;
    font-weight: 600;
    margin-top: 15px;
    font-size: 11px;
    letter-spacing: 2px;
    padding: 9px;
    cursor: pointer;
    height: 1.85rem;
    max-width: 11.875rem;
    width: 100%;

    @media (max-width: 480px) {
      font-weight: 400;
    }
	}
`;

export const Header = styled.div`
	display: flex;
	position: relative;
`;

export const Close = styled.span`
  position: absolute; 
  right: .8rem;
  top: .8rem;
  background: 0;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  color: #b8bfd3;
  cursor: pointer;
  
  :hover {
    color: #4b4c59;
  }
`;