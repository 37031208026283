import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { NavLink } from '../Link';
import { btn } from '../styles';
import * as Icons from '../../assets';
import SwitchButton from '../Switch';
import SwitchAccounts from './SwitchAccounts';
import ModalInfo from './infoModal';
import { isMobile } from '../../utils/mobile';
import { useCookies } from 'react-cookie';
import { basicUsersFields } from '../../constants';

const options = [
  {
    icon: 'fal fa-pen',
    label: 'Edit profile',
    link: '/me'
  },
  {
    icon: 'fal fa-cog',
    label: 'Settings',
    link: '/settings'
  },
  {
    icon: 'far fa-power-off',
    label: 'Logout',
    link: '/logout'
  },
];

const UserBox = ({ upgradeAccount, refillCredits, toggleOnlineStatus, profile, initOnlineList, addUserToOnlineList, removeUserFromOnlineList }) => {
  const cookieName = 'profileFilled';
  const isProfileCompleted = basicUsersFields.every(field => {
    return Array.isArray(profile[field]) ? profile[field].length : profile[field];
  });

  const [cookies, setCookie] = useCookies([cookieName]);
  const [showProfileMessage, setShowProfileMessage] = useState(false);

  useEffect(() => {
    if (
      profile.isLoaded &&
      !profile.is_agency &&
      !isMobile() &&
      !isProfileCompleted &&
      !cookies[cookieName]
    ) {
      setShowProfileMessage(true);
    }
  }, [profile]);

  const onHover = () => {
    if (!cookies[cookieName] && !isMobile() && showProfileMessage) {
      const today = new Date();
      const tomorrow = new Date(today);

      setCookie(cookieName, 'true', {
        path: '/',
        expires: new Date(tomorrow.setDate(tomorrow.getDate() + 1))
      });
    }

    if (setShowProfileMessage) {
      setShowProfileMessage(false);
    }
  };

  const { photo, name, last_subscription, is_agency, is_online } = profile;

  return (
    <UserBoxWrapper is_agency={is_agency}>
      {!Boolean(profile.is_agency) && (
        <NavLink to='/payments' style={{ textDecoration: 'none' }}>
          {profile.credits !== undefined && (<CreditsNumber>{profile.credits + ' credits'}</CreditsNumber>)}
        </NavLink>
      )}

      {profile.id && (
        !is_agency ? (
          !(last_subscription && last_subscription.is_active)
            ? (!profile.credits ? <Upgrade onClick={upgradeAccount}>upgrade account</Upgrade> : null)
            : (<RefillCredits onClick={refillCredits}><i className='fas fa-coins'/>refill credits</RefillCredits>)
        ) : profile.switch_profiles && (
          <SwitchAccounts
            profile={profile}
            switch_profiles={profile.switch_profiles}
            initOnlineList={initOnlineList}
            addUserToOnlineList={addUserToOnlineList}
            removeUserFromOnlineList={removeUserFromOnlineList}
          />
        )
      )

      }
      <Wrap>
        <NavLink to='/me'>
          <PhotoWrap onMouseEnter={onHover}>
            <Photo src={photo ? photo.src : Icons.unknownUser}/>
            <UserMenu>
              <UserName>
                <SubscriptionIcon>
                  <i className="fas fa-gem"/>
                </SubscriptionIcon>
                {name}
              </UserName>
              {
                options.map(option => (
                  <NavLink
                    to={option.link}
                    style={{ textDecoration: 'none' }}
                    key={option.label}
                  >
                    <Option>
                      <Icon>
                        <i className={option.icon}/>
                      </Icon>
                      <span>{option.label}</span>
                    </Option>
                  </NavLink>
                ))
              }
            </UserMenu>
          </PhotoWrap>
        </NavLink>
        <ModalInfo show={showProfileMessage}/>
        <PopperProfile show={showProfileMessage}>
          <div className="popperProfile-wrapper">
            <div className="popperProfile-icon">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.9998 21.9995C17.0748 21.9995 21.9995 17.0748 21.9995 10.9998C21.9995 4.92476 17.0748 0 10.9998 0C4.92476 0 0 4.92476 0 10.9998C0 17.0748 4.92476 21.9995 10.9998 21.9995Z"
                  fill="#DE60AD"
                />
                <path
                  d="M12.5165 13.8984H9.45569L8.88179 2.19995H13.0631L12.5165 13.8984ZM12.5438 19.1756C12.1066 19.5916 11.5873 19.7996 10.9861 19.7996C10.3849 19.7996 9.86562 19.5916 9.42836 19.1756C9.00932 18.7597 8.7998 18.2744 8.7998 17.7198C8.7998 17.1479 9.00932 16.654 9.42836 16.238C9.86562 15.8221 10.3849 15.6141 10.9861 15.6141C11.5873 15.6141 12.1066 15.8221 12.5438 16.238C12.9811 16.654 13.1997 17.1479 13.1997 17.7198C13.1997 18.2744 12.9811 18.7597 12.5438 19.1756Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="popperProfile-description">
              Get 10 bonus credits by filling out your profile: download photos, tell about your hobbies, etc.
            </div>
          </div>
        </PopperProfile>
      </Wrap>
      {
        is_agency && (
          <HideOnlineStatus>
            <span>Online</span>
            <SwitchButton onChange={toggleOnlineStatus} value={is_online}/>
          </HideOnlineStatus>
        )
      }
    </UserBoxWrapper>
  );
};

export default UserBox;

const CreditsNumber = styled.span`
  color: #555;
  font-weight: 600;
  font-size: 1rem;
  margin-right: 15px;
  text-decoration: underline;
  white-space: nowrap;
`;

const HideOnlineStatus = styled.div`
  display: flex;
  align-items: center;
  
  span {
    color: #515151;
    margin-right: .5rem;
    font-size: .875rem;
    font-weight: 600;
    flex-shrink: 0;
  }
`;

const Option = styled.div`
  display: flex;
  margin-bottom: .625rem;
  white-space: nowrap;
  text-decoration: none;
  color: #4b4c59;
  background-color: transparent;
  
  span {
    margin-left: .375rem;
    color: #515151;
  }
`;

const UserMenu = styled.div`
  position: absolute;
  top: 2.75rem;
  right: -.9375rem;
  display: flex;
  flex-direction: column;
  padding: 1.125rem .9375rem;
  padding-bottom: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(184, 191, 211, .5);
  background-color: #fff;
  opacity: 0;
  transition: opacity .5s;
  visibility: hidden;
  
  &::before {
    position: absolute;
    top: -.8125rem;
    right: 1.8125rem;
    content: "";
    border: .5rem solid transparent;
    border-bottom: .3125rem solid #fff;
	}
`;

const SubscriptionIcon = styled.div`
  margin-right: 6px;
  font-size: .875rem;
  color: #4a90e2
`;

const PopperProfile = styled.div`
  position: absolute;
  width: 210px;
  right: 11px;
  top: 55px;
  display: none;
  z-index: 101;
  
  ${({ show }) => show && `
    display: block;
  `}
`;

const UserName = styled.span`
  font-size: 1.125rem;
  font-weight: 600;
  color: #4a90e2;
  margin-bottom: .625rem;
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  margin-right: 6px;
  color: #b8bfd3;
  font-size: 1.125rem;
`;


const Photo = styled.img`
  border-radius: 50%;
  box-sizing: content-box;
  max-width: 100%;
  height: auto;
`;

const PhotoWrap = styled.div`
  position: relative;
  width: 2.625rem;
  height: 2.625rem;
  margin-right: .9375rem;
  margin-left: .9375rem;
  z-index: 101;
  
  :hover ${UserMenu} {
    opacity: 1;
    z-index: 102;
    visibility: visible;
    width: auto;
  }
`;

const Wrap = styled.div`
  position: relative;
`;

const UserBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  position: relative;
`;

const Upgrade = styled(btn)`
  display: flex;
  min-width: 11.25rem;
  margin-left: auto;
  letter-spacing: .046875rem;
  box-shadow: 0 0 0.375rem 0 rgb(59, 90, 154, .5);
  align-items: center;
  justify-content: center;
    
  &::before {
	  content: '';
		width: .625rem;
    height: 1rem;
    margin-right: .3125rem;
		background: url(${require('../../assets/icons/settings-btn-icon.svg')}) center/cover no-repeat;
  }
`;

const RefillCredits = styled(btn)`
  display: flex;
  min-width: 11.25rem;
  margin-left: auto;
  letter-spacing: .046875rem;
  box-shadow: 0 0 0.375rem 0 rgb(59, 90, 154, .5);
  align-items: center;
  justify-content: center;
  
  i {
		font-size: 12px;
	  margin-right: 6px;
	}
`;
