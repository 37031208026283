export const GetCardType = number => {
  // visa
  let re = new RegExp('^4');
  if (number.match(re) != null) {
    return {name: 'Visa', icon: require('../assets/icons/visa.jpg')};
  }

  // Mastercard
  re = new RegExp('^5[1-5]');
  if (number.match(re) != null) {
    return {name: 'Mastercard', icon: require('../assets/icons/Mastercard.svg')};
  }

  // AMEX
  re = new RegExp('^3[47]');
  if (number.match(re) != null) {
    return {name: 'AMEX', icon: require('../assets/icons/AMEX.svg')};
  }

  // Discover
  re = new RegExp('^6011');
  if (number.match(re) != null) {
    return {name: 'DISCOVER', icon: require('../assets/icons/DISCOVER.svg')};
  }

  // Dinners
  re = new RegExp('^3[0-5]|[68][0-9]');
  if (number.match(re) != null) {
    return {name: 'Dinners', icon: require('../assets/icons/dinners.png')};
  }

  // JCB
  re = new RegExp('^3[3-8][0-9]');
  if (number.match(re) != null) {
    return {name: 'JCB', icon: require('../assets/icons/jcb.jpg')};
  }

  return {name: 'card', icon: require('../assets/icons/creditcard-solid.png')};
};