import {withSuccessStatus, withFailureStatus} from '../utils/withData';
import {toLocalDate} from '../utils/toLocalDate';
import * as TYPES from '../constants/types';
import {
  getErrorChatWithId,
  removeErrorMessageFromLocalStorage,
  setErrorMessageToLocalStorage
} from '../components/Chat/LocalStorage';

const initialState = {
  data: [],
  loading: false,
  error: null,
  typing: false,
  sent: false,
  errorMessages: null,
  profile: null,
};

const convertMessagesDateToLocal = messages => messages.map(message => Object.assign({}, message, {
  created_at: toLocalDate(message.created_at.replace(/-/gi, '/'))
}));

const sortDate = array => array.sort((a, b) => a.id > b.id ? 1 : (a.id < b.id ? -1 : 0));

export default (state = initialState, action) => {
  switch (action.type) {
  case TYPES.INIT_CHAT_USER_PROFILE:
    return {
      ...state,
      profile: action.profile
    };
    
  case TYPES.FETCH_CHAT_HISTORY_REQUEST:
    return {
      ...state,
      loading: true,
      error: null
    };
  case TYPES.FETCH_CHAT_HISTORY_SUCCESS:
    return withSuccessStatus(state, {
      ...action.messages,
      data: sortDate(convertMessagesDateToLocal(action.messages.data)),
    });

  case TYPES.FETCH_CHAT_HISTORY_FAILURE:
    return withFailureStatus(state, [], action.error);

  case TYPES.FETCH_MORE_MESSAGES_REQUEST:
    return {
      ...state,
      fetching: true,
      error: null
    };

  case TYPES.FETCH_MORE_MESSAGES_SUCCESS:
    return {
      ...state,
      ...action.messages,
      page: action.page,
      data: sortDate([...state.data, ...convertMessagesDateToLocal(action.messages.data)]),
      fetching: false
    };

  case TYPES.FETCH_MORE_MESSAGES_FAILURE:
    return {
      ...state,
      fetching: false,
      error: action.error
    };

  case TYPES.FETCH_MESSAGE: {
    return {
      ...state,
      error: null,
      sent: false
    };
  }

  case TYPES.ADD_MESSAGE: {
    const message = {
      ...action.message,
      created_at: toLocalDate(action.message.created_at.replace(/-/gi, '/')),
      id: state.data[0] ? state.data[state.data.length - 1].id + 1 : 1,
    };

    return {
      ...state,
      data: state.data.concat(message),
      error: null,
      sent: true
    };
  }

  case TYPES.ADD_MESSAGE_FAILURE:
    return {...state, error: action.response, sent: false};

  case TYPES.RECEIVE_MESSAGE:
    const message = {
      ...action.message,
      created_at: toLocalDate(action.message.created_at.replace(/-/gi, '/')),
      id: state.data[0] ? state.data[state.data.length - 1].id + 1 : 1
    };
    
    return {
      ...state,
      data: state.data.concat(message)
    };

  case TYPES.TYPING_START: {
    return {
      ...state,
      typing: true
    };
  }

  case TYPES.TYPING_END: {
    return {
      ...state,
      typing: false
    };
  }
  
  case TYPES.INIT_ERROR_MESSAGES: {
    return {
      ...state,
      errorMessages: getErrorChatWithId(action.id)
    };
  }
  
  case TYPES.ADD_ERROR_MESSAGE: {
    return {
      ...state,
      errorMessages: setErrorMessageToLocalStorage({ text: action.message.text, profileId: action.message.profileId })
    };
  }
  
  case TYPES.REMOVE_ERROR_MESSAGE: {
    return {
      ...state,
      errorMessages: removeErrorMessageFromLocalStorage(action)
    };
  }

  default:
    return state;
  }
};