import React, {Component,} from 'react';
import styled from 'styled-components';

import SendEmail from '../SendEmail';
import AddPhoto from './AddPhoto';
import {loader} from '../../assets';
import * as Styles from './styles';
import {deleteAlbum} from '../../api';
import DropDownMenu from '../DropDownMenu';
import PhotoPreview from './PhotoPreview';
import PhotoView from './PhotoView';
import Subscription from '../../containers/Subscription';
import BuyAlbum from '../../containers/BuyAlbum';
import {btn} from '../styles';
import {isMobile} from '../../utils/mobile';
import Switch from '../Switch';
import {Close} from '../Mailbox/LettersBody/Header';

// TODO: Handle 404 error when /album/:id is not found

class Album extends Component {
  state = {
    isOpen: false,
    emailFormIsOpen: false,
    photoIsOpen: false,
    editMode: false,
    is_private: null,
    error: null,
    currentSlide: 0,
    name: ''
  };

  componentDidMount() {
    const {match, fetchAlbum, album, profile, fetchUserProfile, location} = this.props;
    const {id, profile_id} = match.params;

    if (profile.id && +atob(profile_id) !== profile.id) {
      fetchUserProfile(/profiles/ + atob(profile_id));
    }

    fetchAlbum(id, profile_id !== profile.id && `/profiles/${atob(profile_id)}/albums/`);

    if (album.data.name) {
      this.setState({
        name: album.data.name,
        is_private: album.data.is_private,
        editMode: location.state ? location.state.editMode : false
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.album.data.name !== this.props.album.data.name) {
      this.setState({name: nextProps.album.data.name, is_private: nextProps.album.data.is_private});
    }
  }

  getDropDownOptions = () => {
    const {is_private} = this.state;

    return [
      {
        icon: 'fal fa-pen',
        name: 'Edit',
        callback: () => this.setState({editMode: true})
      },
      {
        icon: 'fal fa-lock-alt',
        name: is_private ? 'Make open' : 'Make private',
        callback: () => {
          const body = {
            is_private: !is_private
          };

          this.props.updatePhotos(this.props.match.params.id, body);
          this.setState({is_private: !is_private});
        }
      },
      {
        icon: 'fal fa-trash-alt',
        name: 'Delete',
        callback: () => {
          deleteAlbum(this.props.match.params.id)
            .then(() => this.goBack());
        }
      },
    ];
  };

  addPhotos = values => {
    let body = {
      photos: [...this.props.album.data.photos.map(photo => photo.id), ...values.photos]
    };

    this.props.updatePhotos(this.props.match.params.id, body);
    this.toggleAlbumEditor();
  };

  onClose = () => this.setState({editMode: false});

  toggleAlbumEditor = () => this.setState(prevState => ({isOpen: !prevState.isOpen}));

  togglePhotoView = currentSlide => this.setState(prevState => ({photoIsOpen: !prevState.photoIsOpen, currentSlide}));

  changeAlbumName = e => {
    this.setState({name: e.target.value, error: e.target.value.length < 3 && 'Must be at least 3 symbols'});
  };

  removePhoto = e => {
    this.props.removePhoto(e.target.id);
  };

  saveChanges = e => {
    e.preventDefault();

    const body = {
      photos: this.props.album.data.photos.map(photo => photo.id),
      name: this.state.name
    };

    if (body.name.length >= 3) {
      this.setState({editMode: false});
      this.props.updatePhotos(this.props.match.params.id, body);
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  sendEmail = () => this.setState(prevState => ({isOpen: false, emailFormIsOpen: !prevState.emailFormIsOpen}));

  render() {
    const {album, profile, user, setLikeToPhoto, form} = this.props;
    const {name, editMode, isOpen, is_private, error, photoIsOpen, currentSlide, emailFormIsOpen} = this.state;
    const albumId = album.data.id;
    const profileId = album.data.profile_id;
    const role = album.data.profile_id === profile.id ? 'me' : 'another';

    if (album.loading) {
      return (
        <Styles.AlbumContainer>
          <Styles.Loader src={loader}/>
        </Styles.AlbumContainer>
      );
    }

    if (is_private && !profile.is_agency && !profile.last_subscription && profile.last_subscription.is_active && role !== 'me') {
      return <Subscription/>;
    } else if (is_private && !album.data.is_purchased && role !== 'me' && !profile.is_agency) {
      return <BuyAlbum history={this.props.history}/>;
    }

    return (
      <Styles.AlbumContainer>
        {
          role === 'me' && (
            <ControlsBar>
              <DropDownMenu options={this.getDropDownOptions()}/>
              <Close onClick={this.goBack}>
                <i className='fal fa-times'/>
              </Close>
            </ControlsBar>
          )
        }
        {
          editMode ? (
            isMobile() ? (
              <div style={{marginBottom: '2rem'}}>
                <Styles.Editor>
                  <label htmlFor='album-name'>Album name</label>
                  <input
                    id='album-name'
                    type="text"
                    value={name}
                    onChange={this.changeAlbumName}
                  />
                </Styles.Editor>
                <Styles.HideAlbum>
                  <div>
                    <span>Hidden album</span>
                    <span>Visible only to your contacts</span>
                  </div>
                  <Switch/>
                </Styles.HideAlbum>
              </div>
            ) : (
              <Styles.Editor>
                <label htmlFor='album-name'>Album name</label>
                <input
                  id='album-name'
                  type="text"
                  value={name}
                  onChange={this.changeAlbumName}
                />
                <SaveChanges onClick={this.saveChanges}>save</SaveChanges>
                {error && (<Styles.Error>{error}</Styles.Error>)}
              </Styles.Editor>
            )
          ) : (
            <Styles.Name>
              {is_private && (<i className='fas fa-lock'/>)}
              <h1>{name}</h1>
            </Styles.Name>
          )
        }
        <AddPhoto
          onSubmit={this.addPhotos}
          isOpen={isOpen}
          onClose={this.toggleAlbumEditor}
          formState={form}
        />
        {
          (album.data.photos && !album.data.photos.length) || !album.data.photos ? (
            role === 'me' && (
              <Styles.AddPhoto onClick={this.toggleAlbumEditor}>
                <div>
                  <div>
                    <input type="text"/>
                    <span>
                      <Icon>
                        <i className="fas fa-camera"/>
                      </Icon>
                      ADD PHOTOS
                    </span>
                  </div>
                </div>
              </Styles.AddPhoto>
            )
          ) : (
            <>
              <PhotoList>
                {
                  role === 'me' && (
                    <Styles.AddPhoto onClick={this.toggleAlbumEditor}>
                      <div>
                        <div>
                          <input type="text"/>
                          <span>
                            <Icon>
                              <i className="fas fa-camera"/>
                            </Icon>
                            Add photos
                          </span>
                        </div>
                      </div>
                    </Styles.AddPhoto>
                  )
                }
                <PhotoPreview
                  removePhoto={this.removePhoto}
                  togglePhotoView={this.togglePhotoView}
                  setLikeToPhoto={setLikeToPhoto}
                  photos={album.data.photos}
                  editMode={editMode}
                  profile_id={profileId}
                  album_id={albumId}
                  role={role}
                  profile={profile}
                />
              </PhotoList>
              <PhotoView
                profile={profile}
                album_id={albumId}
                setLikeToPhoto={setLikeToPhoto}
                role={role}
                currentSlide={currentSlide}
                sendEmail={this.sendEmail}
                togglePhotoView={this.togglePhotoView}
                photoIsOpen={photoIsOpen}
                profile_id={profileId}
                images={album.data.photos}
              />
            </>
          )
        }
        {
          editMode && isMobile() && (
            <ButtonContainer>
              <Styles.Button as='button' close={this.onClose}>cancel</Styles.Button>
              <Styles.Button as='button' onClick={this.saveChanges}>save changes</Styles.Button>
            </ButtonContainer>
          )
        }
        {
          emailFormIsOpen && (
            <SendEmail
              profile={user}
              onClose={this.sendEmail}
              isOpen={emailFormIsOpen}
            />
          )
        }
      </Styles.AlbumContainer>
    );
  };
}

export default Album;

const ControlsBar = styled.div`
  right: 11px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  
  div {
    cursor: pointer;
  }
`;

const ButtonContainer = styled.div`
  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    margin-top: 1.575rem;
  }
`;

const PhotoList = styled.div`
	display: flex;
	flex-wrap: wrap;
	
	@media (max-width: 480px) {
    margin: -.1875rem;
  }
`;

const Icon = styled.div`
	margin-bottom: 4px;
  font-size: 36px;
  
  @media (max-width: 767px) {
    font-size: 1.75rem;
  }
`;

const SaveChanges = styled(btn)`
	width: 128px
`;

