import styled from 'styled-components';

import { btn } from '../styles';
import { ModalContent } from '../Modal';

export const AgeWrapper = styled.div`
  display: flex;
  align-items: center;
  
  @media (max-width: 767px) {
    width: 100%;
    border-bottom: .0625rem solid #dadee8;
    div {
      border: none;
    }
  }
`;

export const TitleWrap = styled.div`
  @media (max-width: 767px) {
    display: flex;
    align-items: baseline;
    width: 100%;
    margin-bottom: .75rem;
    
    span {
      position: inherit;
      font-size: 1.2rem;
      margin-right: .35rem;
      margin-left: -.25rem;
    }
  }
`;

export const Button = styled(btn)`
  line-height: 1.875rem;
  min-width: 8rem;
  height: 1.875rem;
  
  @media (max-width: 480px) {
    width: calc(48%);
    letter-spacing: .05rem;
  }
  
  margin-left: auto;
  margin-right: 20px;
  background-color: #fff;    
  color: #b8bfd3;
  border-color: #b8bfd3;
  
  :focus {
	  background-color: rgba(221, 44, 87, .1);
  }
  
  :hover {
    background-color: rgba(221, 44, 87, .1);
  }
  
  @media (max-width: 480px) {
    margin: 0;
    min-width: 0;
  }
  
  @media (max-width: 319px) {
    min-width: 0;
  }
`;

export const Container = styled(ModalContent)`
  max-width: 488px;
  margin-top: 60px;
  margin: 55px auto auto;
  padding: 20px;
  height: auto;
  
  select {
    background-color: #fff;
    outline: none;
  }
  
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    overflow-y: auto;
    
    &::before {
      display: none;
    } 
  } 
  
  @media (max-width: 480px) {
    padding: .9375rem;
  }
  
  @media (max-width: 767px) {
    max-width: 100%;
    height: auto !important;
    margin: 4.0625rem .9375rem 6.875rem;
    padding: .9375rem;
    align-self: flex-start;
  }
  
  @media (max-width: 480px) {
    height: auto;
    margin: .9375rem;
    align-self: flex-start;
  }
  
  @media (max-width: 767px) {
    margin-bottom: 2.5rem !important;
  }
`;

export const Form = styled.form`
  select {
    color: #515151;
  }
  
  &::before {
    content: '';
    position: absolute;
    left: 50px;
    top: -20px;
    border: 10px solid transparent;
    border-bottom: 10px solid #fff
  }
`;

export const SelectsContainer = styled.div`  
  display: flex;
  flex-wrap: wrap;
  margin: -10px -30px;
  margin-bottom: .5rem;
  
  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
    justify-content: flex-start;
    flex-flow: column;
  }
`;

export const OptionsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 194px;
  margin: 10px 30px;
  
  span {
    width: 6rem;
    font-weight: 600;
  }
  
  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
    border-bottom: .0625rem solid #dadee8;
      
    div {
      border: none;
    }
  }
`;

export const Interests = styled.div`
	display: flex;
  margin-top: 30px;
  
  @media (max-width: 767px) {
    border-bottom: .0625rem solid #dadee8;
  }
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 1.25rem;
  align-items: center;
  margin-top: .625rem;

  @media (max-width: 767px) {
    flex-flow: wrap;
    
    label {
      @media (max-width: 767px) {
        margin-top: .3125rem;
        margin-right: 1.5625rem;
        margin-left: 0;
      }
      
      div {
        width: 14px;
        height: 14px;
        
        &::before {
          top: 2px !important;
        }
      }
    }
    
    p {
      @media (max-width: 480px) {
        font-size: .75rem;
      }
      
      font-size: 1.35rem;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 767px) {
    margin-top: 1.5rem;
  }
`;

export const Close = styled.span`
  font-size: 1.5rem;
  position: absolute; 
  right: 10px;
  top: 10px;
  cursor: pointer;
  color: #b8bfd3;
  
  :hover {
    color: #4b4c59;
  }
`;

export const Search = styled(btn)`
	width: 70%;
  
  @media (max-width: 767px) {
    display: block;
    width: 9.375rem;
  }
`;

export const StyledSearchField = styled.div`
  .title {
    font-size: 1rem;
    padding-bottom: 10px;
  }
  input {
    width: 100%;
    height: 32px;
    padding: 0 10px;
    outline: none;
    box-shadow: none;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .75rem 0;
  
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: inherit;
  }
`;


export const LocationInputWrap = styled.div`
  width: 100%;
`;

export const LocationLabel = styled.span`
  font-weight: 600;
  min-width: auto;
  margin-right: 0.5rem;  
`;

export const Location = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    border-bottom: .0625rem solid #dadee8;
    
    ${LocationLabel} {
      min-width: 35%;
      margin: 0;
    }
    
    div {
      border: none;
    }
    
    ${LocationInputWrap} {
    }
  }

  width: 50%;
  display: flex;
  align-items: center;
`;

export const Title = styled.h1`
	margin: 0;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
  
  @media (max-width: 767px) {
    width: 100%;
  }
  
  @media (max-width: 767px) {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
  }
`;

export const ExtendedSearchWrap = styled(Title)`
  padding-bottom: 1.25rem;
  padding-top: .5rem;
 
  @media (max-width: 767px) {
    span {
      font-size: 1rem;
    }
    
    ${({isOpen}) => isOpen && `
      padding-bottom: 0;
    `}
   
    padding-right: 0;
    padding-bottom: 1.25rem;
    border-bottom: .0625rem solid #dadee8;
  }
`;

export const InterestsSearchWrap = styled(ExtendedSearchWrap)`
  @media (max-width: 767px) {
    margin-top: 1.25rem;
    span {
      font-size: 1rem;
    }
    ${({isOpen}) => isOpen && `
      border-bottom: none;
    `}
  }
`;

export const Error = styled.span`
  color: red;
	text-align: left;
	font-size: 11px;
	margin-top: 3px;
	padding-left: 20px;
`;

export const SearchIcon = styled.div`
	margin-left: 4px;
  font-size: 18px;
  color: #3b5a9a;
  cursor: pointer;
`;

export const SexWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: .75rem 0;
  
  .title {
    margin-right: 1rem;
    font-weight: 600;
    color: #515151;
  }
  .radio_group {
    input {
      margin-right: 5px;
    }
    label {
      margin-right: 1rem;
    
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;


