import {connect} from 'react-redux';

import AlbumsComponent from '../components/Profile/Albums';
import {fetchAlbumsList, addAlbum, fetchAlbum, loadMoreAlbums} from '../actions';

const mapStateToProps = state => ({
  form: state.form,
  albums: state.albums,
  profile: state.profile,
  user: state.user
});

const mapDispatchToProps = {
  fetchAlbumsList,
  loadMoreAlbums,
  fetchAlbum,
  addAlbum
};

export default connect(mapStateToProps, mapDispatchToProps)(AlbumsComponent);