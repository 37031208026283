import React, { Component } from 'react';

import { calculateAge } from '../../utils/calculateAge';
import { encodeBase64, isBase64 } from '../../utils/encodeBase64';
import CurrentProfile from './CurrentProfile';
import StatusCode from '../StatusCode';
import NoMatchFound from './NoMatch';
import { Event } from '../../Tracking';
import * as api from '../../api';
import { getUrlParams } from '../App';

let getValueByRole = (role, arg1, arg2) => role === 'me' ? arg1 : arg2;

function getCookie(name) {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

class Profile extends Component {
  state = {
    role: '',
    modalIsOpen: false,
    emailFormIsOpen: false,
    refillCreditsPanelIsOpen: false,
    subscriptionPanelIsOpen: false,
    isShowMessage: false,
    error: null,
    component: 'PersonalDetails',
    initialValues: {}
  };

  openEditComponent = componentName => {
    this.setState({ modalIsOpen: true, component: componentName });
  };

  onFetchUserProfile = id => {
    const { match, fetchUserProfile } = this.props;

    if (sessionStorage.getItem('token') || localStorage.getItem('token') || getCookie('token')) {
      if (match.params.id) {
        if (isBase64(match.params.id)) {
          fetchUserProfile(`/profiles/${encodeBase64(id || match.params.id)}`);
          this.setState({ role: 'another' });
        } else {
          this.setState({ error: 404 });
        }
      } else {
        this.setState({ role: 'me' });
      }
    }
  }

  componentDidMount() {
    const { location, history } = this.props;

    const statesToClear = ['editMode', 'editInterests'];

    if (history.location.state && history.location.state.editMode) {
      this.setState({ modalIsOpen: true, component: 'UserDetails' });
    }

    if (history.location.state && history.location.state.editInterests) {
      this.setState({ modalIsOpen: true, component: 'UserInterests' });
    }

    if (history.location.state && Object.keys(history.location.state).some(key => statesToClear.includes(key))) {
      let state = { ...history.location.state };

      delete state.editMode;
      delete state.editInterests;

      history.replace({ ...history.location, state });
    }

    this.setState({error: null});

    if (location.pathname.includes('me') && location.search && location.search.includes('email_token')) {
      let token = getUrlParams(window.location.href).email_token;

      api.verifyEmail(token)
        .then(response => {
          if (response && response.message && !response.exception) {
            this.onToggleMessage();
            history.push(history.location.pathname);
          } else if (response && response.exception) {

            alert('Too many attempts! Please try again in few minutes.');
          }
        });
    }

    this.onFetchUserProfile();
  }

  componentWillUpdate(nextProps, nextState) {
    const history = nextProps.history;

    if (!this.state.modalIsOpen && history.location.state && history.location.state) {
      const statesToClear = ['editMode', 'editInterests'];

      if (history.location.state.editMode) {
        this.setState({ modalIsOpen: true, component: 'UserDetails' });
      }

      if (history.location.state.editInterests) {
        this.setState({ modalIsOpen: true, component: 'UserInterests' });
      }

      if (history.location.state && Object.keys(history.location.state).some(key => statesToClear.includes(key))) {
        let state = { ...history.location.state };

        delete state.editMode;
        delete state.editInterests;

        history.replace({ ...history.location, state });
      }
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      if (nextProps.match.params.id !== 'me' && +encodeBase64(nextProps.match.params.id) !== +nextProps.user.id) {
        this.onFetchUserProfile(nextProps.match.params.id);
      }
    }
  }

  toggleEmailForm = () => this.setState(prevState => ({ emailFormIsOpen: !prevState.emailFormIsOpen }));

  toggleRefillCreditsPanel = () => this.setState(prevState => ({ refillCreditsPanelIsOpen: !prevState.refillCreditsPanelIsOpen }));

  toggleSubscriptionPanel = () => {
    Event('userclicks', 'button', 'upgrade');
    this.setState(prevState => ({ subscriptionPanelIsOpen: !prevState.subscriptionPanelIsOpen }));
  };

  onToggleMessage = () => this.setState(prevState => ({ isShowMessage: !prevState.isShowMessage }));

  openModalWindow = (component, initialValues = {}) => this.setState({modalIsOpen: true, initialValues, component});

  onClose = () => this.setState({modalIsOpen: false});

  onSubmit = values => {
    const {updateProfileInfo, myProfile} = this.props;
    delete values.email;
    delete values.LaravelEcho;

    if (values.is_online === null) {
      delete values.is_online;
    }

    if (values.birth_date === '1970-01-01') {
      values.birth_date = myProfile.birth_date;
    }

    if (values.birth_date) {
      values.age = calculateAge(new Date(values.birth_date));
    }

    updateProfileInfo(values);
    api.updateProfile({body: {...values}});
    this.setState({modalIsOpen: false});
  };

  render() {
    const {role, subscriptionPanelIsOpen, initialValues, error, isShowMessage} = this.state;
    const {myProfile, selects, match, user, onlineUsers, history} = this.props;
    const loading = getValueByRole(role, myProfile.loading, user.loading);
    const profile = getValueByRole(role, myProfile, user);
    const isOnline = ~onlineUsers.indexOf((match.params.id && match.params.id !== 'me') ? +encodeBase64(match.params.id) : 'me');

    if ((user.error && user.error.status === 404) || error) {
      return (<NoMatchFound/>);
    }

    return (
      <>
        <CurrentProfile
          {...this.state}
          {...this.props}
          isOnline={isOnline}
          selects={selects.data}
          initialValues={initialValues}
          subscriptionPanelIsOpen={subscriptionPanelIsOpen}
          toggleSubscriptionPanel={this.toggleSubscriptionPanel}
          onSubmit={this.onSubmit}
          profileData={profile}
          loading={loading}
          toggleRefillCreditsPanel={this.toggleRefillCreditsPanel}
          toggleEmailForm={this.toggleEmailForm}
          openModal={this.openModalWindow}
          onClose={this.onClose}
          openEditComponent={this.openEditComponent}
          isUserProfile={history.location.pathname.includes('/profile')}
        />
        {isShowMessage && (
          <StatusCode onClose={this.onToggleMessage} image={require('../../assets/img/success-ico.svg')}>
            <p>Your email has successfully been reset!</p>
          </StatusCode>
        )}
      </>
    );
  }
}

export default Profile;
