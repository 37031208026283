import React from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isMobile } from '../utils/mobile';

const LIMIT = isMobile() ? 2 : 4;

const Notification = () => (
  <div>
    <ToastContainer
      limit={LIMIT}
      position={toast.POSITION.BOTTOM_RIGHT}
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      rtl={false}
      closeButton={false}
      pauseOnVisibilityChange={false}
      draggable
      closeOnClick
      pauseOnHover
    />
  </div>
);

export default Notification;