import React, {Component} from 'react';
import styled from 'styled-components';

class Input extends Component {

  state = {
    value: ''
  };

  onEnterPress = e => {
    if (e.keyCode === 13) {
      this.props.autoFocus(this.props.id);
    }
  };

  updateState = e => {
    const {maxLength, onChange, input, autoFocus, id} = this.props;

    const value = e.target.value;

    if (maxLength ? e.target.value.length <= maxLength : true) {
      if (e.target.value.length === maxLength) {
        autoFocus(id);
      }

      onChange && onChange(e);
      input && input.onChange(value);
      this.setState({value});
    }
  };

  onChange = e => {
    const {type} = this.props;

    if (type === 'number') {
      if (!isNaN(e.target.value)) {
        this.updateState(e);
      }
    } else {
      this.updateState(e);
    }
  };

  render() {
    const {meta} = this.props;
    return (
      <StyledInput
        onKeyUp={this.onEnterPress}
        type="text"
        style={{border: (meta && meta.touched && meta.error) ? '.0625rem solid red' : '.0625rem solid #dadee8'}}
        value={this.state.value}
        onChange={this.onChange}
        placeholder={this.props.placeholder}
      />
    );
  }
}

export default Input;

const StyledInput = styled.input`
  height: 1.875rem;
  margin-bottom: .625rem;
  width: 100%;
  border: .0625rem solid #dadee8;
  padding: 0 .625rem;
  outline: 0;
    
  :focus {
    border: .0625rem solid #b8bfd3
  }
    
  ::-webkit-input-placeholder {
    color: #b8bfd3
  }
    
  ::-moz-placeholder {
    color: #b8bfd3;
    opacity: 1
  }
`;