import {withSuccessStatus, withFailureStatus} from '../utils/withData';
import * as TYPES from '../constants/types';

const initialState = {
  data: [],
  error: null,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
  case TYPES.FETCH_CATALOG_REQUEST:
    return {
      ...state,
      loading: true,
      error: null
    };

  case TYPES.FETCH_CATALOG_SUCCESS:
    return withSuccessStatus(state, {
      ...action.catalog
    });

  case TYPES.FETCH_CATALOG_FAILURE:
    return withFailureStatus(state, [], action.error);

  case TYPES.FETCH_MORE_PROFILES_REQUEST:
    return {
      ...state,
      fetching: true,
      error: null
    };

  case TYPES.FETCH_MORE_PROFILES_SUCCESS:
    return {
      ...state,
      ...action.catalog,
      page: action.page,
      data: [...state.data, ...action.catalog.data],
      fetching: false
    };

  case TYPES.FETCH_MORE_PROFILES_FAILURE:
    return {
      ...state,
      fetching: false,
      error: action.error
    };

  default:
    return state;
  }
};

