export function getItemFromLocalStorage({item, parse = true}) {
  if (localStorage.getItem(item)) {
    if (parse) {
      return JSON.parse(localStorage.getItem(item));
    } else {
      return localStorage.getItem(item);
    }
  } else {
    return false;
  }
}

export function setLocalStorageItem(item, value) {
  localStorage.setItem(item, JSON.stringify(value));
}