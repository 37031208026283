import React from 'react';
import styled from 'styled-components';

import * as Styles from '../styles';

const LookingFor = ({ text, role, openModal, history, match }) =>
  <Styles.LookingFor>
    <Styles.Title>
      I'm Looking For
      {role === 'me' && text && (
        <Edit style={{ marginLeft: 3 }} onClick={() => openModal('UserSearch')}>
          <i className='far fa-pen'/>
        </Edit>
      )}
    </Styles.Title>
    {
      !text ? (role === 'me' ? (
        <Placeholder>
          <b>Tell people what are <br/>you looking for </b>
          <span>Full info increases <br/>the conversion </span>
          <div style={{paddingTop: 13, fontWeight: 600}}>
            <Edit onClick={() => openModal('UserSearch')}>ADD INFO</Edit>
          </div>
        </Placeholder>
      ) : (
        <Placeholder>
          <b>User did not provide <br/>this information ask him</b>
          <div style={{paddingTop: 13, fontWeight: 600}}>
            <Edit onClick={() => {
              history.push('/messages/' + match.params.id);
            }}
            >
              START CHAT
            </Edit>
          </div>
        </Placeholder>
      )) : (text && <Text>{text}</Text>)
    }
  </Styles.LookingFor>;

export default LookingFor;

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;

  b {
    color: #515151;
    font-weight: 600;
    font-size: .75rem;
  }
  
  span {
    color: #b8bfd3;
    font-size: .75rem;
    padding-top: 7px;
  }

  @media (max-width: 767px) {
    font-size: .875rem;
  }
`;

const Edit = styled.div`
	font-size: 12px;
  color: #3b5a9a;
  cursor: pointer;
  text-decoration: underline;
  
  :hover {
    text-decoration: none;
  }
`;

const Text = styled.div`
  word-break: break-word;
  @media (max-width: 767px) {
    font-size: .875rem;
    color: #4b4c59;
  }
`;