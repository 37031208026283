import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {TabsItem} from '../../styles';

const Checkboxes = ['All', 'Match', 'Favorites'];

const FilterBar = ({onChange}) => {
  const [listType, setListType] = useState('All');

  const handleClick = e => {
    onChange('list', e.target.id);
    setListType(e.target.id);
  };

  return (
    <TabsContainer>
      {
        Checkboxes.map(value => (
          <TabsItem
            onClick={handleClick}
            key={value}
            id={value}
            active={value === listType}
          >
            {value}
          </TabsItem>
        ))
      }
    </TabsContainer>
  );
};

FilterBar.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default FilterBar;

const TabsContainer = styled.div`
  display: flex;
  border-bottom: .0625rem solid #edeff4;
  padding-right: .9375rem;
  padding-left: .9375rem;
  
  @media (max-width: 767px) {
    width: 100%;
    background-color: #fff;
    padding: 0;
    list-style: none;
    max-width: 70rem;
    margin-bottom: .625rem;
    
    div {
      font-size: .875rem;
    }
  }
`;



