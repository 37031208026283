export const packRow = [
  'Credits',
  'First purchase price',
  'Second purchase price'
];

export const planRow = [
  'Plan',
  'Credits after activation',
  'Daily credits',
  'Total credits per period',
  'Price'
];