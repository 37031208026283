import React, {Component} from 'react';
import styled from 'styled-components';

import {buyAlbum} from '../../../api';
import * as Styles from './styles';
import RefilCredits from '../../Credits';
import {whiteLoader, unknownUser} from '../../../assets';
import {Modal, ModalContent} from '../../Modal';

class BuyAlbum extends Component {
  state = {
    isOpen: false,
    loading: false,
    error: null
  };

  componentDidMount() {
    this.setState({isOpen: true});
  }

  onClick = () => {
    this.setState({loading: true});

    buyAlbum({profile_id: this.props.profile.id, album_id: this.props.album.data.id})
      .then(response => {
        if (response.status === 402 || response.status === 423) {
          this.setState({error: response});

        } else {
          this.props.updateAlbum(response.data);
        }

        this.setState({loading: false});
      });
  };

  onClose = () => {
    this.setState({isOpen: false});
    this.props.history.goBack();
  };

  clearErrorState = () => this.setState({error: null});

  render() {
    const {cover, is_private, photos_count} = this.props.album.data;

    if (this.state.error && this.state.error.status === 402) {
      return <RefilCredits clearErrorState={this.clearErrorState}/>;
    }

    return (
      <Modal isOpen={this.state.isOpen}>
        <PopupContent>
          <Styles.Header>
            <Styles.Close onClick={this.onClose}><i className='fal fa-times'/></Styles.Close>
          </Styles.Header>
          <Wrapper>
            <AlbumItem>
              <Image src={cover ? cover.src : unknownUser}/>
              <PhotosNumber>
                <AlbumItemIcon>
                  <i className={is_private ? 'fas fa-lock' : 'fas fa-camera'}/>
                  <span>{photos_count}</span>
                </AlbumItemIcon>
              </PhotosNumber>
            </AlbumItem>
            <RightContent>
              <h3>Buy private album</h3>
              <p>30 Credits</p>
              <Styles.ButtonContainer>
                <button onClick={this.onClick}>{this.state.loading ? (<img src={whiteLoader} alt=""/>) : 'BUY'}</button>
              </Styles.ButtonContainer>
            </RightContent>
            <div className="album-price">
              30 <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.044 15.556c-2.283 0-4.198-.722-5.746-2.165C.766 11.933 0 10.062 0 7.778 0 5.493.766 3.63 2.298 2.187 3.846.729 5.761 0 8.044 0c2.5 0 4.479.992 5.932 2.976l-1.641.88c-.453-.647-1.063-1.173-1.83-1.579a5.183 5.183 0 00-2.461-.609c-1.72 0-3.158.579-4.315 1.736-1.142 1.142-1.712 2.6-1.712 4.374 0 1.773.57 3.239 1.712 4.396 1.157 1.142 2.595 1.713 4.315 1.713.875 0 1.696-.195 2.462-.586.766-.406 1.376-.94 1.829-1.6L14 12.58c-1.517 1.984-3.502 2.976-5.956 2.976z" fill="#DE60AD"/><path d="M10.889 10.889H9.245l-1.32-2.21H6.876v2.21H5.444V4.667H8.59c.692 0 1.24.186 1.644.56.41.373.615.855.615 1.445 0 .51-.145.924-.434 1.241-.282.317-.628.52-1.038.606l1.512 2.37zm-2.51-3.377c.295 0 .537-.075.725-.224a.76.76 0 00.283-.616.733.733 0 00-.283-.606c-.188-.155-.43-.233-.726-.233H6.876v1.679h1.502z" fill="#DE60AD"/></svg>
            </div>
          </Wrapper>
        </PopupContent>
      </Modal>
    );
  }
}

export default BuyAlbum;

const Wrapper = styled.div`
  display: flex;
  margin: 1.875rem 0

  @media (max-width: 767px) {
    margin: 1.0625rem 0;
  }

`;

const RightContent = styled.div`
  padding-left: 1.5625rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  
  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #4b4c59;
    max-width: 100%;
    background: 0;
    text-align: left;
    line-height: inherit;
    margin: 0;
    margin-bottom: .625rem;
    padding: 0;
    
    @media (max-width: 767px) {
      font-size: 1.125rem;
      margin-bottom: 0;
    }
  }
  
  p {
    font-size: 1.125rem;
    font-weight: 600;
    
    @media (max-width: 767px) {
      font-size: .75rem;
    }
  }
  
  @media (max-width: 480px) {
     padding-left: .3125rem;
  }
`;

const PopupContent = styled(ModalContent)`
  position: relative;
  max-width: 20rem;
  width: 100%;
  margin: 90px auto auto;
  background-color: #fff;
  cursor: default;
  border-radius: 2px;
  max-width: 34.375rem;
  padding: 1.875rem;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  
  @media (max-width: 767px) {
    padding: 0;
  }
`;

const AlbumItemIcon = styled.div`
	display: flex;
	align-items: center;
	i {
		font-size: 18px;
		margin-right: 6px;
	}
`;

const PhotosNumber = styled.span`
	position: absolute;
  bottom: 5px;
  left: 7px;
  color: #fff;
  user-select: none;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, .5)
`;

const Image = styled.img`
  height: 100%;
  display: block;
  width: 100%;
  height: 100%;
  font-family: "object-fit: cover;", serif;
  object-fit: cover;
`;

const AlbumItem = styled.div`
  width: 7.75rem;
  height: 7.75rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  @media (max-width: 767px) {
    width: 6.25rem;
    height: 6.25rem;
  }
`;