import {connect} from 'react-redux';

import AlbumComponent from '../components/Album';
import {fetchAlbum, updatePhotos, removePhoto, fetchUserProfile, setLikeToPhoto} from '../actions';

const mapStateToProps = ({form, album, profile, user}) => ({form, album, profile, user});

const mapDispatchToProps = {
  fetchAlbum,
  fetchUserProfile,
  updatePhotos,
  setLikeToPhoto,
  removePhoto
};

export default connect(mapStateToProps, mapDispatchToProps)(AlbumComponent);