import React from 'react';
import styled from 'styled-components';

import Notification from './Notification';
import * as Styles from '../styles';

const notificationTypes = [
  {
    label: 'On mail',
    description: 'Notifications will be sent to your E-mail. You can enable or disable this feature for each type of notification.',
  },
  {
    label: 'On website',
    description: 'Notifications will be displayed at the bottom of the site page. You can enable or disable this feature for each type of notification.',
  },
  {
    label: 'Audio',
    description: 'Notifications will be will be played with sound.',
  },
];

const Notifications = ({selects, myProfile: {disabled_notifications}, updateProfileInfo}) => {
  const notifications = selects.data.notifications;

  return (
    <>
      <Styles.Notifications>Notifications</Styles.Notifications>
      <Styles.NotificationsMethods>
        {notificationTypes.map(({ label, description }) => (
          <span className='checkbox_row_title' key={label}>
            {label}
            <Hint>
              <Icon><i className="far fa-question-circle"/></Icon>
              <HintText>{description}</HintText>
            </Hint>
          </span>
        ))}

      </Styles.NotificationsMethods>
      <NotificationsList>
        {notifications && Object.keys(notifications).map(item => (
          <Notification
            {...notifications[item]}
            key={item}
            notification={item}
            updateProfileInfo={updateProfileInfo}
            disabled_notifications={disabled_notifications}
          />
        ))}
      </NotificationsList>
    </>
  );
};

export default Notifications;

const NotificationsList = styled.ul`
	margin: 0 0 20px;
  padding: 0;
  list-style: none;
  
  @media (max-width: 767px) {
    margin-right: -.9375rem;
    margin-left: -.9375rem;
  }
`;

const Icon = styled.div`
	font-size: 12px;
	margin-left: 5px;
  color: #dadee8;
  
  @media (max-width: 767px) {
    position: relative;
    top: .125rem;
  }
`;

const HintText = styled.span`
  position: absolute;
  opacity: 0;
  visibility: hidden;
  left: 50%;
  top: -34px;
  transform: translateX(-50%);
  padding: 3px 13px;
  white-space: nowrap;
  color: #fff;
  font-size: 14px;
  background-color: rgba(75, 76, 89, .5);
  transition: .2s;
  user-select: none;

	&::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 8px solid transparent;
    border-top: 5px solid rgba(75, 76, 89, .5)
	}
	
	@media (max-width: 360px) {
    transform: translate(-86%, -100%);

		&::before {
			right: 0;
			left: auto;
			transform: translateX(-50%);
		}
  }
`;

const Hint = styled.span`
  display: inline-flex;
  position: relative;

	:hover ${HintText} {
    opacity: 1;
    visibility: visible
	}
`;
