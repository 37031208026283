import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

const CookiePopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem('cookie_popup')) {
      setIsOpen(true);
    }
  }, []);
  
  const handleCloseCookiePopup = () => {
    setIsOpen(false);
    localStorage.setItem('cookie_popup', 'show');
  };
  
  if (!isOpen) {
    return <div />;
  }

  return (
    <StyledWrapper location={history.location.pathname}>
      <Close onClick={handleCloseCookiePopup}><i className='fal fa-times'/></Close>
      <div>
        By continuing to use this website, you consent to the use of cookies in accordance with our <a href={process.env.REACT_APP_REDIRECT_URL_PROMO}>Cookie Policy</a>
      </div>
    </StyledWrapper>
  );
};


const Close = styled.span`
  position: absolute;
  right: 10px;
  background: 0;
  top: 3px;
  padding: 0;
  border: 0;
  font-size: 1.15rem;
  color: #b8bfd3;
  cursor: pointer;
  transition: opacity .33s cubic-bezier(.25, .46, .45, .94);
  z-index: 1;
  
  :hover {
    color: #4b4c59;
  }
`;

const StyledWrapper = styled.div`
  position: fixed;
  bottom: 0;
  padding: 5px 20px;
  font-size: 10px;
  width: 100%;
  background: #fff;
  z-index: 10000;
  
  @media (max-width: 767px) {
    display: none;
    ${({ location }) => location === '/catalog' && `
      bottom: 48px;
      display: block;
    `}
  }
  
  a {
    color: #0077ce;
  }
  
  line-height: 18px;
  text-align: center;
`;

export default CookiePopup;