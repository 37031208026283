import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

import Contact from './Contact';
import { loader } from '../../../assets';

const List = ({contacts, initialContacts, myId, markMessagesAsRead, onlineUsers, loading, isLoaded, history}) => {
  const isPlaceholder = !Object.keys(contacts).length;
  
  if (loading && !isLoaded) {
    return (
      <ContactsBox>
        <LoaderWrap>
          <img src={loader} alt='loader'/>
        </LoaderWrap>
      </ContactsBox>
    );
  }

  return (
    <>
      <ContactsBox>
        {
          !loading ? !contacts.length && (
            <Placeholder>
              {!initialContacts.length ? (<span style={{textAlign: 'center'}}>You have no messages yet, <br/> start chating :)</span>) : !contacts.length && 'No match found.'}
            </Placeholder>
          ) : (
            <div/>
          )
        }
        {loading}
        {
          isPlaceholder ? (
            <div/>
          ) : (
            Object.keys(contacts).map(user => {
              const is_read = contacts[user].last_message ? (contacts[user].last_message.sender_id !== myId ? contacts[user].last_message.read_at : true) : true;
              return (
                <Contact
                  key={contacts[user].id}
                  is_online={~onlineUsers.indexOf(contacts[user].id)}
                  is_read={is_read}
                  markMessagesAsRead={markMessagesAsRead}
                  myId={myId}
                  uid={contacts[user].id}
                  user={contacts[user]}
                  history={history}
                />
              );
            })
          )
        }
      </ContactsBox>
    </>
  );
};

List.propTypes = {
  markMessagesAsRead: PropTypes.func.isRequired,
  contacts: PropTypes.array.isRequired,
  initialContacts: PropTypes.array.isRequired
};

export default withRouter(List);

const Placeholder = styled.span`
  color: #b8bfd3;
  font-size: .875rem;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const ContactsBox = styled.div`
	overflow-x: hidden;
	overflow-y: auto;
	
	::-webkit-scrollbar { 
    display: none; 
  }
  
  height: 25.75rem;
  
  @media (max-width: 767px) {
  	max-height: 100%;
  	position: relative;
    z-index: 1;
    height: auto;
    overflow: hidden;
  }
`;

const LoaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
  
  img {
    width: 40px;
  }
  
  @media (max-width: 767px) {
    margin-top: 4.5rem;
  }
`;