import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import { unknownUser } from '../../assets';
import DropDownMenu from '../DropDownMenu';
import { removeLetter } from '../../api';
import * as Styles from './styles';
import ErrorMessageActions from '../Chat/MessagesList/error';
import { isMobile } from '../../utils/mobile';
import SendEmail from '../SendEmail';

const Mail = ({ history, deleteLetter, letter, type, removeErrorsLetter, isError, resendLetter }) => {
  const [isOpenMailForm, setIsOpenMailForm] = useState(false);
  
  const closeMailForm = () => setIsOpenMailForm(false);
  
  const getDropDownOptions = () => [
    {
      name: 'Delete',
      icon: 'fal fa-trash-alt',
      callback: () => {
        if (isError) {
          removeErrorsLetter(letter);
        } else {
          removeLetter(letter.id);
          deleteLetter(letter);
        }
      }
    },
  ];
  
  const openLetter = () => {
    if (!isError || !isMobile()) {
      history.push('/mailbox/' + letter.id);
    }
  
    if (isError) {
      localStorage.setItem('letter', JSON.stringify(letter));
      setIsOpenMailForm(true);
    }
  };
  
  const { read_at, sender, receiver, sent_at } = letter;
  const author = type === 'sent' ? receiver : sender;
  
  const isActive = history.location.pathname.includes(letter.id);
  
  return (
    <DialogItem is_read={read_at} isActive={isActive} className="mailbox-item">
      <Styles.DropDownMenu>
        <DropDownMenu options={getDropDownOptions()}/>
      </Styles.DropDownMenu>
      <ErrorMessageActions
        isError={isError}
        message={letter}
        removeMessage={removeErrorsLetter}
        sendLetter={resendLetter}
      />
      <div className="mail-body-wrapper" onClick={openLetter}>
        <Styles.PhotoContainer>
          <Styles.Circle is_read={read_at || isError}/>
          <Styles.Photo src={author.photo ? author.photo.src : unknownUser} alt=""/>
        </Styles.PhotoContainer>
        <Styles.MailBody is_read={read_at}>
          <Styles.MailHeader>
            <span>{author.name}</span>
            <span className="sent_at">
              {
                moment().diff(moment(sent_at), 'days') < 7 ? (
                  moment(sent_at).format('dddd') + ' ' + moment(sent_at).format('hh:mm:ss')
                ) : (sent_at)
              }
            </span>
          </Styles.MailHeader>
          <Styles.Letter>{letter.text}</Styles.Letter>
        </Styles.MailBody>
      </div>
      {isOpenMailForm && (
        <SendEmail
          isReply={false}
          isOpen={isOpenMailForm}
          onClose={closeMailForm}
        />
      )}
    </DialogItem>
  );
};

export default withRouter(Mail);

const DialogItem = styled.li`
  position: relative;
  display: flex;
  padding: .625rem .9375rem .625rem 1.25rem;
  border-bottom: .0625rem solid #edeff4;
  align-items: flex-start;
  .mail-body-wrapper {
    width: 100%;
    display: flex;
  }
  
  ${({ isActive }) => isActive && `
     background-color: rgba(237, 239, 244, 0.5);
  `}
	
	&:hover {
		cursor: pointer;
		background-color: rgba(237, 239, 244, 0.5);
	}
	
	@media (max-width: 767px) {
    padding: .9375rem .9375rem .9375rem 1.75rem;
  }  
  
  :first-of-type {
    padding-top: 1.25rem;
  }
  
  ${({ is_read }) => !is_read && `
    @media (max-width: 767px) {
      background-color: rgba(237, 239, 244, .5);
    }
  `}    
`;
