import React from 'react';
import styled from 'styled-components';

import { loader } from '../../../../assets';

const Loader = ({ loading }) => {
  if (!loading) {
    return null;
  }
  
  return (
    <LoaderWrapper>
      <img src={loader} alt=''/>
    </LoaderWrapper>
  );
};

export default Loader;

const LoaderWrapper = styled.div`
  width: 100%;
  margin: 25px;

  img {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;