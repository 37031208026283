import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import * as Styles from '../styles';
import { likePhoto } from '../../../api';
import { Heart } from '../../../assets/svg/Heart';
import { btn } from '../../styles';

class Photo extends PureComponent {
  picture = createRef();
  buttons = createRef();
  
  state = {
    liked: this.props.is_liked,
    subscriptionPanelIsOpen: false,
    loaded: false
  };

  componentDidMount() {
    this.picture.current.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    this.picture.current.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (
      !event.target.className.includes('photo') &&
      !event.target.className.includes('arrow') &&
      !document.getElementById('actions').contains(event.target) &&
      !this.buttons.current.contains(event.target)
    ) {
      this.props.onClose();
    }
  };

  startChat = () => {
    this.props.history.push('/messages/' + btoa(this.props.profile_id));
  };

  likePhoto = () => {
    if (!this.state.liked) {
      const { profile_id, album_id, setLikeToPhoto, images, index, id } = this.props;
      
      likePhoto({ album_id, profile_id, photo_id: id });
  
      setLikeToPhoto(images[index]);
  
      this.setState({ liked: true });
    }
  };

  onPhotoLoad = () => this.setState(prevState => ({loaded: !prevState.loaded}));

  closeSubscriptionPanel = () => this.setState({subscriptionPanelIsOpen: false});

  render() {
    const {src, id, role, sendEmail} = this.props;
    const {loaded} = this.state;
    return (
      <PhotoContainer ref={this.picture}>
        <Styles.AlbumPhoto
          src={src}
          style={{display: this.state.loaded ? 'block' : 'none'}}
          className='photo'
          onLoad={this.onPhotoLoad}
        />
        <Styles.ActionsContainer ref={this.buttons} role={role}>
          {
            loaded && (
              !this.state.liked ? (
                <Like onClick={this.likePhoto}>
                  <Heart id={id}/>
                </Like>
              ) : (
                <Like liked={this.state.liked}>
                  <i className='fas fa-heart'/>
                </Like>
              )
            )
          }
          <ButtonContainer>
            <Button as="button" onClick={this.startChat}>Start chat</Button>
            <Button as="button" onClick={sendEmail}>Send mail</Button>
          </ButtonContainer>
        </Styles.ActionsContainer>
      </PhotoContainer>
    );
  }
}

Photo.propTypes = {
  index: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired
};

const ButtonContainer = styled(Styles.ButtonContainer)`
  @media (max-width: 767px) {
    margin-top: 11%;
  }
  
  margin: 0;
  button {
    padding: 0 .3125rem;
  }
`;

const Button = styled(btn)`
  min-width: 100px;
  height: 30px;
  line-height: 30px;
  
  :first-of-type {
    :focus {
      background-color: #c61e47;
    }
    :hover {
      background-color: #c61e47;
    }
  }
  
	:last-of-type {
    margin-left: 16px;
    background-color: #3b5a9a;

		:focus {
    	background-color: #4c6dae;
  	}
 	
 	 	:hover {
  	  background-color: #4c6dae;
		}
	}
`;

const PhotoContainer = styled.div`
	margin: 0;
	position: relative;
	display: flex;
	justify-content: center;
  border-radius: 2px;
  cursor: pointer;
  
  span {
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: red;
    font-weight: 600;
  }
`;

const Like = styled.div`
	right: auto;
  left: 50%;
  bottom: auto;
  top: 10px;
  font-size: 37px;
  width: 3.25rem;
  height: 3.25rem;
  justify-content: center;
  align-items: center;
  transform: translateX(-50%);
  transition: .2s;
  position: absolute;
  display: flex;
  background: rgba(255, 255, 255, .5);
  border: 1px solid #fff;
  padding: 11px 9px 8px;
  color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 #4b4c59;

	:hover {
    cursor: pointer;
    opacity: .7
	}

	:focus {
    outline: 0;
    border-color: #dd2c57
	}
  
  svg {
    width: 34px;
    height: 34px;
    fill: ${props => props.liked ? '#dd2c57' : '#fff'};
  }
  
  i {
    font-size: 1.8rem;
    color: #dd2c57;
  }
  
  border-color: ${props => props.liked ? '#dd2c57' : '#fff'};

	:hover {
    cursor: pointer;
    opacity: .7
	}
	
	:focus {
    outline: 0;
    border-color: #dd2c57;
	}
	
	@media (max-width: 480px) {
	  width: 3.25rem;
	  height: 3.25rem;
	}
`;

export default withRouter(Photo);

