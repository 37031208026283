import React, {useEffect} from 'react';
import renderHTML from 'react-render-html';

import {Modal, ModalContent} from '../Modal';

const SecureForm = ({form: {form3d_html}, isOpen}) => {
  useEffect(() => {
    setTimeout(() => {
      document.getElementById('form3d').submit();
    }, 0);
  }, []);
  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        {renderHTML(form3d_html)}
      </ModalContent>
    </Modal>
  );
};

// 2222400060000007

export default SecureForm;