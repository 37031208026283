import ru from './default.ru.json';
import en from './default.en.json';

import profileEn from './profile/en.json';
import sidebarEn from './sidebar/en.json';
import headerEn from './header/en.json';
import wizardEn from './wizard/en.json';
import settingsEn from './settings/en.json';
import mailboxEn from './mailbox/en.json';
import albumEn from './album/en.json';
import paymentEn from './payment/en.json';
import chatEn from './chat/en.json';

import profileRu from './profile/ru.json';
import sidebarRu from './sidebar/ru.json';
import headerRu from './header/ru.json';
import wizardRu from './wizard/ru.json';
import settingsRu from './settings/ru.json';
import mailboxRu from './mailbox/ru.json';
import albumRu from './album/ru.json';
import paymentRu from './payment/ru.json';
import chatRu from './chat/ru.json';

ru['ru'].profile = profileRu;
ru['ru'].sidebar = sidebarRu;
ru['ru'].header = headerRu;
ru['ru'].wizard = wizardRu;
ru['ru'].settings = settingsRu;
ru['ru'].mailbox = mailboxRu;
ru['ru'].album = albumRu;
ru['ru'].payment = paymentRu;
ru['ru'].chat = chatRu;

en['en'].profile = profileEn;
en['en'].sidebar = sidebarEn;
en['en'].header = headerEn;
en['en'].wizard = wizardEn;
en['en'].settings = settingsEn;
en['en'].mailbox = mailboxEn;
en['en'].album = albumEn;
en['en'].payment = paymentEn;
en['en'].chat = chatEn;

export {
  ru,
  en
};
