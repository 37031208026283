import styled from 'styled-components';

import {btn} from '../styles';

export const SendButton = styled(btn)`
  background-color: #3b5a9a;
  width: 50%;
`;

export const Form = styled.form`
	height: 100%;
	position: relative;
	padding: 12px;
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  ${({ error }) => error && `
    border: 1px solid red;
  `}
`;

export const Require = styled.span`
  position: relative;
  padding-left: .25rem !important;
  top: 0;
  right: 0;
  color: red;
  margin-right: 1rem;
`;

export const Image = styled.img`
  display: block;
  border-radius: .125rem;
  font-family: "object-fit: cover;", serif;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const SubjectWrap = styled.div`
  width: 100%;
  
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  span:first-child {
    color: #515151;
    font-size: .875rem;
    font-weight: 600;
  }
  
  span:last-child {
    padding-left: .875rem;
    font-size: .875rem;
    font-weight: 600;
    color: red;
    @media (min-width: 1200px) {
      font-size: .75rem;
    }
  }

  input {
    width: 40%;
    padding: 5px;
    outline: none;
    border: 1px solid #dadee8;
    transition: .3s;
    color: #4b4c59;
    
    @media (max-width: 767px) {
      margin-left: .5rem;
      width: -webkit-fill-available;
    }
  }
`;

export const TextWrap = styled.div`
  overflow-y: auto;
  max-height: 13.875rem;
  margin-bottom: .8125rem;
  padding-right: .3125rem;
  
  @media (max-width: 767px) {
    max-height: 100%;
    height: auto;
    margin-bottom: 9rem;
    padding-right: 0
  }
`;

export const Section = styled.p`
  color: #515151;
  text-align: justify;
`;

export const Receiver = styled.span`
  display: block;
  font-size: 18px;
  line-height: 21px;
  color: #4B4C59;
  font-weight: 600;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const ProfileImage = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  border: 1px solid #EDEFF4;
  border-radius: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
`;

export const ProfileImageActive = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 9px;
  height: 9px;
  background-color: #7ED321;
  border: 1px solid #FFFFFF;
  border-radius: 100%;
`;

export const ImageWrap = styled.div`
  width: 8.33125rem;
  height: ${props => props.photo ? '6.25rem' : 0}; 
  margin-bottom: .625rem;
  
  @media (max-width: 767px) {
    height: ${props => props.photo && 0};    
  }
`;

export const MessageWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  
  @media (max-width: 767px) {
    display: none;
  }
`;

export const MobileMessageWrap = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: flex;
  }
  
  z-index: 1048;
  bottom: 0;
  left: 0;
  display: none;
  box-sizing: border-box;
  width: 100%;
  min-height: 2.9375rem;
  border-top: .0625rem solid #dadee8;
  background-color: #fff;
  position: fixed;
  
  input {
    @media (max-width: 767px) {
      font-size: .875rem;
      height: 1.875rem;
      min-height: 0;
      margin: 0;
      padding: 0;
      border: none;
    }
   
    width: 100%;
    margin-bottom: .625rem;
    padding: .3125rem;
    resize: none;
    transition: .3s;
    color: #4b4c59;
    border: .0625rem solid #dadee8;
    max-height: 11.875rem;
    min-height: 5rem;
    height: calc(100vh - 28.125rem);
  }
`;

export const Cover = styled.div`
  display: flex;
  height: 2.9375rem;
  padding: 0 .9375rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TextArea = styled.textarea`
  width: 100%;
  margin-bottom: 10px;
  height: 190px;
  resize: none;
  padding: 5px;
  border: 1px solid #dadee8;
  transition: .3s;
  color: #4b4c59;

  :focus {
    outline: 0;
  }
`;

export const Number = styled.span`
  margin-right: .9375rem;
  margin-left: auto;
  color: #b8bfd3;
  
  @media (max-width: 767px) {
    font-size: .875rem;
    margin-right: 0;
    margin-left: .9375rem;
  }
`;

export const Close = styled.span`
  position: absolute; 
  right: 2%;
  top: 1.9%;
  background: 0;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  color: #b8bfd3;
  cursor: pointer;
  
  :hover {
    color: #4b4c59;
  }
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	span {
		color: #777;
		margin-right: 10px;
		font-size: 17px;
	}
`;

export const Button = styled(btn)`
  min-width: 10.75rem;
  
  @media (max-width: 767px) {
    width: 1.625rem;
    min-width: 1.625rem;
    height: 1.5rem;
    margin-left: .9375rem;
    padding: 0;
    border: none;
    background: url(${require('../../assets/img/send-btn.svg')}) 50%/contain no-repeat;
    flex-shrink: 0;
  }
`;

export const Title = styled.h1`
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3px;
  margin-top: 0;
`;