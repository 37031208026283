import React from 'react';
import {withRouter} from 'react-router';
import styled from 'styled-components';

import { getLastMessageByType } from '../../../utils/getLastMessageByType';
import { unknownUser } from '../../../assets';

const Contact = props => {
  const {
    user: { photo, id, name, last_message },
    myId,
    is_online,
    markMessagesAsRead,
    is_read,
    history
  } = props;
  
  const handleClick = () => {
    props.history.push('/messages/' + btoa(id));
    
    if (!is_read) {
      markMessagesAsRead(id);
    }
  };
  
  const isActive = history.location.pathname.includes(btoa(id));
  const author = last_message ? (last_message.sender_id === myId ? 'me' : 'another') : null;
  
  return (
    <ContactsTabs
      onClick={handleClick}
      isActive={isActive}
    >
      <Avatar>
        <Circle is_read={is_read}/>
        <Photo src={photo ? photo.src : unknownUser}/>
        <OnlineStatus online={is_online}/>
      </Avatar>
      <UserNameWrap>
        <UserName>{name}</UserName>
        <LastMessage>
          {author === 'me' && (<i>You: </i>)}
          {last_message && (getLastMessageByType(last_message, author))}
        </LastMessage>
      </UserNameWrap>
    </ContactsTabs>
  );
};

export default withRouter(Contact);

const LastMessage = styled.div`
	display: flex;
  font-size: .75rem;
  overflow: hidden;
  max-width: 10.625rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  i {
    color: #3b5a9a;
    margin-right: 5px;
  }
`;

const UserName = styled.div`
	font-size: 1rem;
  font-weight: 600;
  margin-bottom: .1875rem;
  color: #4b4c59;
`;

const UserNameWrap = styled.div`
  display: flex;
  flex-direction: column
`;

const Avatar = styled.div`
	position: relative;
  margin-right: 8px;
  flex-shrink: 0;
`;

const ContactsTabs = styled.div`
  cursor: pointer;
  display: flex;
  padding: .8125rem .9375rem .8125rem 1.25rem;
  border-bottom: .0625rem solid #edeff4;
  align-items: center;
  
  &:hover {
     background-color: rgba(237, 239, 244, 0.5);
  }
  
  ${({ isActive }) => isActive && `
     background-color: rgba(237, 239, 244, 0.5);
  `}
  
	@media (max-width: 767px) {
		padding-right: .9375rem;
    padding-left: 1.875rem;
	}
`;

const Photo = styled.img`
	display: block;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  position: relative;
`;

const Circle = styled.span`
	${({ is_read }) => !is_read && `
		position: absolute;
    left: -.9rem;
    top: 50%;
    transform: translateY(-50%);
    width: .625rem;
    height: .625rem;
    background-color: #3b5a9a;
    border-radius: 50%;
	`}
`;

const OnlineStatus = styled.span`
	${({ online }) => online && `
		background-color: #7ed321;
	  width: .65rem;
	  margin-right: 5px;
	  border-radius: 50%;
	  height: .65rem;
	  position: absolute;
	  border: 2px solid #fff;
	  bottom: 0px;
	  right: -5px;
	`}
`;
