import React, { Component } from 'react';
import { DebounceInput } from 'react-debounce-input';
import styled from 'styled-components';
import { Route } from 'react-router-dom';


import Mails from './Mails';
import Letter from './Letter';
import * as Styles from './styles';
import { getMailDetails, markEmailAsRead } from '../../api';
import { getQueryString } from '../../utils/getQueryString';
import Filters from './Filters';
import NewEmail from './NewEmail';

class Mailbox extends Component {
  state = {
    type: 'inbox',
    search: '',
  };
  
  componentDidMount() {
    const query = {
      search: this.state.search,
      type: this.state.type
    };

    this.props.fetchMailsList(getQueryString(query));
  }

  getMailDetails = mailId => {
    const {profile, readLetter, decrementMailCount} = this.props;

    return getMailDetails(mailId)
      .then(mail => {
        if (mail) {
          if (profile.id && (profile.id === mail.receiver.id) && !mail.read_at) {
            markEmailAsRead(mailId)
              .then(mail => {
                readLetter(mail);
                decrementMailCount();
              });

          }
        }
        return mail;
      });
  };

  onFilter = e => {
    const query = {
      search: this.state.search,
      type: e.target.id
    };

    this.props.fetchMailsList(getQueryString(query));

    this.setState({type: e.target.id});
  };

  onSearch = e => {
    const query = {
      search: e.target.value,
      type: this.state.type
    };

    this.props.fetchMailsList(getQueryString(query));

    this.setState({search: e.target.value});
  };

  render() {
    const {mailbox, loadMoreMails, profile, deleteLetter, contacts, onlineUsers, fetchMailsList, removeErrorMail, initErrorMails, addErrorMail, onSendMail} = this.props;
    const {search, type} = this.state;
    const query = {search, type};
    
    return (
      <>
        <Styles.Mailbox>
          <Container>
            <Title>
              <span>Mailbox</span>
              <NewEmail contacts={contacts} onlineUsers={onlineUsers}/>
            </Title>
            <InputContainer>
              <i className="far fa-search"/>
              <DebounceInput
                id='search'
                placeholder='Search'
                onChange={this.onSearch}
                debounceTimeout={500}
              />
            </InputContainer>
          </Container>
          <MailboxTabs>
            <Filters
              filter={type}
              onClick={this.onFilter}
            />
            <Mails
              myProfile={profile}
              onSendMail={onSendMail}
              addErrorMail={addErrorMail}
              removeErrorMail={removeErrorMail}
              initErrorMails={initErrorMails}
              type={type}
              mailbox={mailbox}
              query={query}
              loadMoreMails={loadMoreMails}
              deleteLetter={deleteLetter}
            />
          </MailboxTabs>
        </Styles.Mailbox>
        <Route path={'/mailbox/:id'} render={props => (
          <Letter
            {...props}
            errorEmails={mailbox.errorEmails}
            toggleEmailForm={this.toggleEmailForm}
            getMailDetails={this.getMailDetails}
            fetchMailsList={fetchMailsList}
            type={type}
            profile={profile}
          />
        )}
        />
      </>
    );
  }
}

export default Mailbox;

const MailboxTabs = styled.div`
	@media (max-width: 767px) {
	  position: relative;
    z-index: 1;
  }
`;

const Container = styled.div`
	padding: 20px 20px 0;
	@media (max-width: 767px) {
    top: 0;
    left: 0;
    padding: 1.25rem .9375rem 0;
    background: #fff;    
  }
`;

const InputContainer = styled.div`
  margin-bottom: 1.25rem;
  border: .0625rem solid transparent;
  display: flex;
  padding-left: .5625rem;
  border-radius: .9375rem;
  background-color: #edeff4;
  align-items: center;
  
  i {
    font-size: 1rem;
  }	
  
	input {
	  width: 100%;
    height: 1.875rem;
    padding: 0 .625rem;
    border: 0;
    background-color: transparent;
    color: #515151;
  
    ::-webkit-input-placeholder {
      font-size: .875rem;
      color: #b8bfd3
    }
  
    ::-ms-input-placeholder {
      font-size: .875rem;
      color: #b8bfd3
    }
  
    ::placeholder {
      font-size: .875rem;
      color: #b8bfd3
    }
  
    :focus {
      outline: 0
    }
  }
`;

const Title = styled.h1`
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;