import React from 'react';
import styled from 'styled-components';

const CheckBox = ({input, label}) => {
  const handleChange = () => input.onChange(input.value ? 0 : 1);

  return (
    <Label>
      <Input
        type="checkbox"
        checked={!!input.value}
        onChange={handleChange}
      />
      <CheckboxCustom/>
      <Text>{label}</Text>
    </Label>
  );
};

export default CheckBox;

const Text = styled.p`
	font-size: 16px;
  white-space: initial;
  color: #4b4c59;
  line-height: 1;
`;

const Label = styled.label`
	margin-left: 1.25rem;
	position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  
  @media (max-width: 767px) {
   	margin-right: 1.5625rem;
    margin-left: 0;
  }
`;

const CheckboxCustom = styled.div`
  position: relative;
  flex-shrink: 0;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  border: 1px solid #b8bfd3;
  background-color: #fff;
  border-radius: 2px;
`;

const Input = styled.input`
	position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  
  :focus ${CheckboxCustom} {
    border-color: #84c44b;
  }
  
  :checked ~ ${CheckboxCustom}::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 3px;
    transform: rotate(-45deg);
    width: 7px;
    height: 5px;
    border: 2px solid #fff;
    border-top: none;
    border-right: none
  }
  
  :checked ~ ${CheckboxCustom} {
    border-color: #84c44b;
    background-color: #84c44b;
  }
`;