import styled from 'styled-components';

import {btn} from '../styles';

export const Button = styled(btn)`
  min-width: 128px;
  line-height: 30px;
  height: 30px;
  
  @media (max-width: 480px) {
     width: calc(42%);
     margin: 0;
     min-width: 0;
  }
  
  ${({close}) => close && `
    margin-left: auto;
    margin-right: 20px;
    background-color: #fff;    
    color: #b8bfd3;
    border-color: #b8bfd3;

		:focus {
	    background-color: rgba(221, 44, 87, .1);
    }
    
    :hover {
      background-color: rgba(221, 44, 87, .1);
    }
    
    @media (max-width: 480px) {
      margin: 0;
      min-width: 0;
    }
    
    @media (max-width: 319px) {
      min-width: 0;
    }
  `}
`;

export const HideAlbum = styled.div`
  display: flex;
  align-items: center;
  
  div {
    span:first-child {
      font-weight: 600;
      display: block;
    }
    
    span:last-child {
	    font-size: 10px;
	    color: #9b9b9b;
    }
  }
`;

export const AlbumContainer = styled.form`
  position: relative;
  max-width: 52.1875rem;
  width: 100%;
  padding: 1.25rem;
  border-radius: 2px;
  color: #515151;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(184, 191, 211, .2);
  
  @media (max-width: 767px) {
    box-shadow: none;
  }
`;

export const Error = styled.p`
	color: red;
	font-size: 12px;
`;

export const FieldLabel = styled.div`
  white-space: nowrap;
  span {
    position: relative;
    top: 0;
    right: 0;
    color: red;
    padding-left: 0.25rem !important;
  }
`;

export const Loader = styled.div`
	width: 50px;
  display: inline-block;
  border-radius: 15px;
  right: 0;
  left: 0;
  margin: auto;
  top: 35%;
  position: absolute;
`;

export const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
`;

export const AlbumPhoto = styled.img`
	max-width: -webkit-fill-available;
  object-fit: contain;
  max-height: 100vh;
  padding: 15px 0;
  
  @media (max-width: 767px) {
    padding: 0;
  }
`;

export const PhotoWrapper = styled.div`
  .slick-next {
    right: 0;
  }
  
  .slick-prev {
    left: 0;
  }
`;

export const PhotoContainer = styled.div`
  position: relative;
  width: calc(100% / 4 - 1.25rem);
  height: auto;
  margin: 10px;
  border-radius: 2px;
  background-color: #edeff4;
  cursor: pointer;
  
  span {
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: red;
    font-weight: 600;
  }
  
  @media (max-width: 480px) {
    width: calc(100% / 3 - .375rem);
    margin: .1875rem;
  }
`;

export const Title = styled.div`
`;

export const Name = styled.div`
	display: flex;
	color: #555;
	align-items: center;
	
	i {
	  font-size: 1.125rem;
	  color: #515151;
	}
	
	h1 {
		margin: 0;
		font-size: 1.5rem;
		margin-left: 5px;
		font-weight: 600;
		overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
		
		@media (max-width: 767px) {
      font-size: 1.125rem;
    }
	}
	
	margin-bottom: 30px;
	
	@media (max-width: 767px) {
	  margin-bottom: 15px;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	margin-top: 11%;
	justify-content: flex-end;
	button {
		cursor: pointer;
		padding: 15px;
    background-color: #dd4057;
    outline: none;
    border: 0;
    margin: 0 10px;
    border-radius: 0px;
    color: #fff;
    font-weight: 600;
	}
`;

export const AddPhoto = styled.div`
	position: relative;
  width: calc(100% / 4 - 20px);
  margin: 10px;
  border-radius: 2px;
  background-color: #edeff4;
  cursor: pointer;
  z-index: 1;
  height: 11.5rem;
  
  @media (max-width: 767px) {
    height: auto;
  }
  
  @media (max-width: 480px) {
    width: calc(100% / 3 - .375rem);
    margin: .1875rem;
    background-color: #fff;
  }
  
  div {
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    cursor: pointer;
  }
  
	input {
		position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    cursor: pointer;
	}
	
	span {
    @media (max-width: 767px) {
      font-size: .75rem;
    }
    
    font-size: 1.125rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    color: #3b5a9a;
    align-items: center;
	}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const ControlsBar = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  
  ${({role}) => role !== 'me' && `
    opacity: 1;
    visibility: visible;
  `}
`;

export const Close = styled.span`
  cursor: pointer;
  background: 0;
  padding: 0;
  border: 0;
  font-size: 2.5rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .4s;
  
  i {
    opacity: .8;
    transition: opacity .4s;
    
    :hover {
      opacity: 1;
    }
  }  
  
  @media (max-width: 767px) {
    font-size: 1.75rem;
  }
`;

export const Editor = styled.div`
	display: flex;
  align-items: center;
  margin-bottom: 30px;
 
  label {
    font-weight: 600;
    flex-shrink: 0;
  }
  
  input {
	  width: 100%;
	  height: 30px;
	  padding: 0 10px;
	  background-color: transparent;
	  color: #515151;
	
		::-webkit-input-placeholder {
		  font-size: 14px;
		  color: #b8bfd3
		}
	
		::-ms-input-placeholder {
		  font-size: 14px;
		  color: #b8bfd3
		}
			
		::placeholder {
		  font-size: 14px;
		  color: #b8bfd3
		}
	
		:focus {
	    outline: 0
		}
		
	  max-width: 245px;
	  border: 1px solid #dadee8;
	  margin: 0 20px
  }
  
  @media (max-width: 767px) {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
`;

export const ActionsContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  padding-top: 5rem;
  opacity: 0;
  visibility: hidden;
  
  ${({role}) => role !== 'me' && `
    opacity: 1;
    visibility: visible;
  `}
  
  @media (max-width: 480px) {
    padding-top: 4rem;
    bottom: .5rem;
  }
  
  @media (max-width: 767px) {
     padding-bottom: 4rem;
  }
`;
