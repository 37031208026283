import {connect} from 'react-redux';

import subscriptionComponent from '../components/Subscription';
import {updateProfileInfo} from '../actions';

const mapStateToProps = ({profile}) => ({profile});

const mapDispatchToProps = {
  updateProfileInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(subscriptionComponent);