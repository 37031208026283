export const endpoints = {
  LOGIN: '/login/',
  REGISTER: '/register/',
  PASSWORD_RESET: '/password/reset/',
  LOGOUT: '/logout/',
  SEND_EMAIL: '/password/email/',
  CATALOG: '/catalogs/profile/',
  MY_PROFILE: '/me/profile/',
  PROFILES: '/profiles/',
  CONTACTS: '/me/contacts/',
  UPDATE_PROFILE_PHOTO: '/me/photos/',
  MY_ALBUMS: '/me/albums/',
  MY_MAILBOX: '/me/mail/',
  TYPING: '/typing/',
  CARD: '/me/cards/',
  BLACKLIST: '/me/blacklist/'
};

export const detailInfo = {
  education: null,
  marital_status: null,
  has_children: null,
  smoking_attitude: null,
  alcohol_attitude: null,
  height: null,
  body_type: null,
  eye_color: null,
  hair_color: null,
  weight: null
};

export const currencySymbols = {
  'USD': '$', // US Dollar
  'EUR': '€', // Euro
  'CRC': '₡', // Costa Rican Colón
  'GBP': '£', // British Pound Sterling
  'ILS': '₪', // Israeli New Sheqel
  'INR': '₹', // Indian Rupee
  'JPY': '¥', // Japanese Yen
  'KRW': '₩', // South Korean Won
  'NGN': '₦', // Nigerian Naira
  'PHP': '₱', // Philippine Peso
  'PLN': 'zł', // Polish Zloty
  'PYG': '₲', // Paraguayan Guarani
  'THB': '฿', // Thai Baht
  'UAH': '₴', // Ukrainian Hryvnia
  'VND': '₫', // Vietnamese Dong
};

export const basicUsersFields = ['age', 'name', 'birth_date', 'about', 'country', 'city', 'photo', 'interests'];

export const mainInfoFields = ['age', 'name', 'birth_date', 'about', 'country', 'city', 'photo'];