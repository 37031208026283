import React, {Component, PureComponent} from 'react';
import styled from 'styled-components';

import {hexToRgba} from '../../utils/hexToRgba';
import '../../assets/fontawesome/css/all.css';

class Interest extends PureComponent {
  state = {
    choose: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.choose !== nextProps.choose) {
      this.setState({choose: nextProps.choose});
    }
  };

  handleClick = e => {
    const {toggleInterests, maxLength, interestsLength, showMessage} = this.props;

    if (maxLength && (!this.state.choose && maxLength < interestsLength + 1)) {
      showMessage();
    } else {
      toggleInterests(e.target.id);
      this.setState({choose: !this.state.choose});
    }
  };

  render() {
    const {value, icon, color} = this.props;

    return (
      <Label
        id={value}
        choose={this.state.choose}
        onClick={this.handleClick}
        color={color}
        backgroundColor={hexToRgba(color, .2)}
      >
        <Icon id={value} className={icon}/>
        {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase().replace(/_/g, ' ')}
      </Label>
    );
  }
}


class RenderInterests extends Component {
  state = {
    interests: []
  };

  initInterestsValue = () => {
    const {input, meta, values} = this.props;

    let value;

    if (values) {
      value = values.interests || [];
    } else {
      value = (!input.value && !meta.initial) ? [...input.value] : (meta.initial && !input.value) ? meta.initial : input.value;
    }

    this.setState({interests: [...value]});
  };

  componentDidMount() {
    this.initInterestsValue();
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.input.value === '') {
      this.setState({interests: []});
    }
  }

  showMessage = () => this.setState({message: 'You can choose 2-6 interests'});

  toggleInterests = id => {
    const interests = JSON.parse(JSON.stringify(this.state.interests));

    if (interests.includes(id)) {
      interests.splice(interests.indexOf(id), 1);
    } else {
      interests.push(id);
    }

    this.props.input.onChange(interests);

    this.setState({interests, message: ''});
  };

  render() {
    return (
      <Container>
        <Message>{this.state.message}</Message>
        {
          this.props.interests && Object.keys(this.props.interests).map(value => (
            <Interest
              key={value}
              choose={this.state.interests.includes(value)}
              value={value}
              maxLength={this.props.maxLength}
              interestsLength={this.state.interests.length}
              message={this.state.message}
              showMessage={this.showMessage}
              icon={`fas ${this.props.interests[value].icon}`}
              color={this.props.interests[value].color}
              toggleInterests={this.toggleInterests}
            />
          ))
        }
      </Container>
    );
  }
}

export default RenderInterests;

const Message = styled.span`
  width: 100%;
  color: red;
  font-weight: 600;
`;

const Icon = styled.i`
  font-size: .875rem;
  margin-right: .25rem;
`;

const Label = styled.label`
	background-color: ${props => props.choose ? props.backgroundColor : '#edeff4'};
  color: ${props => props.choose ? props.color : '#b7c0d5'};
	opacity: 1;
  margin: .3125rem;
  
  @media (max-width: 767px) {
    margin: 0 .3125rem .625rem 0;
  }
 
  font-size: .75rem;
  display: inline-flex;
  margin: .1875rem;
  padding: .5rem .9375rem .375rem;
  user-select: none;
  border-radius: .90625rem;
  cursor: pointer;
`;

const Container = styled.div`
  margin: -.3125rem -.3125rem 1.5625rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
