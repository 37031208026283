import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { addErrorMail, onSendMail } from '../../actions';

import Form from './Form';
import { sendEmailToUser } from '../../api';
import RefillCredits from '../Credits';
import Subscription from '../../containers/Subscription';
import { Modal, ModalContent } from '../Modal';
import StatusCode from '../StatusCode';

import { Event } from '../../Tracking';
import { removeLetterFromLocalStorage } from '../Chat/LocalStorage';

const SendEmail = ({ profile, letter, onClose, myProfile, isReply, isOpen, onlineUsers, addErrorMail, onSendMail }) => {
  const [error, setError] = useState(null);
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);

  useEffect(() => {
    localStorage.setItem('modal', 'letter');

    if (!localStorage.getItem('letter')) {
      localStorage.setItem('letter', JSON.stringify({profile, isReply, letter}));
    }
  }, []);

  const handleClose = () => {
    onClose();

    localStorage.removeItem('modal');
    localStorage.removeItem('letter');
  };

  const onSubmit = values => {
    const {profile, letter, isReply} = JSON.parse(localStorage.getItem('letter'));

    Event('send', 'form', 'sendmail');

    let urlParams = {
      receiver_id: profile.id,
      sent_at: new Date().toJSON(),
      subject: letter ? letter.subject : values.subject,
      ...values,
    };
    
    if (isReply) {
      urlParams.reply_to_id = letter.id;
    }

    setSending(true);
    setValidationErrors(false);
    
    if (values.error) {
      removeLetterFromLocalStorage(values);
    }

    if (!sending) {
      sendEmailToUser({body: urlParams}).then(response => {
        if (response.status >= 400) {
          addErrorMail({
            ...urlParams,
            receiver: profile,
            sender: { id: myProfile.id }
          });
        }
        
        if (response.status === 402 || response.status === 423) {
          setError(response);
          setSuccess(false);
          
        } else if (response.status === 422) {
          setSending(false);
  
          return response.json();
    
        } else {
          onSendMail(response.data);
          onSuccess();
          clearErrorState();
          handleClose();
        }
  
        setSending(false);
  
        return response;
      })
        .then(response => {
          if (response && response.errors) {
            setValidationErrors(
              Object.keys(response.errors).reduce((errors, errorKey) => {
                errors[errorKey] = response.errors[errorKey][0];
                return errors;
              }, {})
            );
          }
        });
    }
  };

  const clearErrorState = () => {
    setError(null);
    setValidationErrors(null);
  };

  const onSuccess = () => setSuccess(success => !success);

  const values = JSON.parse(localStorage.getItem('letter')) || { ...(letter || {}), isReply, profile };
  
  if (values && Object.keys(values).length === 0) {
    localStorage.removeItem('letter');
    if (localStorage.getItem('modal') === 'letter') {
      localStorage.removeItem('modal');
    }
  }
  
  const isHasSubscription = profile && profile.last_subscription && profile.last_subscription.is_active;
  const isCreditsError = error && error.status === 402;
  const isSubscriptionError = error && error.status === 423;
  
  const reply = values && values.isReply ? values.isReply : isReply;
  
  return (
    <>
      <Modal isOpen={error ? false : isOpen} styles={customStyles}>
        <Content>
          <Form
            onlineUsers={onlineUsers}
            myProfile={myProfile}
            initialValues={reply ? { photo: '' } : Object.assign({}, values, { photo: '' })}
            letter={values ? values.letter : letter}
            error={error}
            isReply={reply}
            onSubmit={onSubmit}
            onClose={handleClose}
            validationErrors={validationErrors}
            profile={values ? values.profile : profile}
          />
        </Content>
      </Modal>
      {
        success && (
          <StatusCode onClose={onSuccess} image={require('../../assets/img/success-ico.svg')}>
            <p>Your email has been sent successfully!</p>
          </StatusCode>
        )
      }
      {isCreditsError && isHasSubscription && (<RefillCredits clearErrorState={clearErrorState}/>)}
      {(isSubscriptionError || (isCreditsError && !isHasSubscription)) && (<Subscription onClose={clearErrorState}/>)}
    </>
  );
};

const mapStateToProps = ({ onlineUsers, profile }) => ({ onlineUsers, myProfile: profile });

const mapDispatchToProps = { addErrorMail, onSendMail };

export default connect(mapStateToProps, mapDispatchToProps)(SendEmail);

const customStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    maxHeight: '100vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '100%',
    border: 0,
    width: '100%',
    padding: 0,
    marginRight: '-50%',
    overflow: 'inherit',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
  },
  overlay: {
    zIndex: 1222,
    backgroundColor: 'rgba(75, 76, 89, 0.5)'
  }
};

const Content = styled(ModalContent)`
  max-width: 644px;
  
  @media (max-width: 480px) {
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    
    @media (max-width: 767px) {
      max-width: 100%;
      min-height: 100%;
      margin: 0;
      align-self: stretch;
  }
`;

