import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  margin-top: 1.25rem;
  border-collapse: collapse;
  text-align: left;
  color: #4b4c59;
  
  th {
    font-size: .75rem;
    font-weight: 600;
    padding: .3125rem 1.25rem;
    text-transform: uppercase;
    color: #b8bfd3;
    border-bottom: .0625rem solid #dadee8;
    width: 25%; 
       
    @media (max-width: 480px) {
      padding: .625rem .3125rem;
      font-size: .5rem;
    }
  }
  
  tbody {
      td {
        padding: .9375rem 1.25rem .9375rem;
        border-bottom: .0625rem solid #dadee8;
        
        small {
          color: #aaa;
        }
      }
      
      @media (max-width: 480px) {
        td {
          padding: .625rem .3125rem;
          width: 33%;
        }
      }
  }
`;