import { LOCATION_CHANGE } from 'react-router-redux';
import * as TYPES from '../constants/types';

const initialState = {
  data: [],
  loading: false,
  loaded: false,
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
  case TYPES.FETCH_USER_PROFILE_REQUEST:
    return initialState;
    
  case TYPES.GET_SIMILAR_PROFILES_REQUEST:
    return {
      ...state,
      loading: true,
      loaded: false,
    };
    
  case TYPES.GET_SIMILAR_PROFILES_SUCCESS:
    return {
      ...state,
      data: action.payload,
      loading: false,
      loaded: true,
    };
    
  case TYPES.GET_SIMILAR_PROFILES_FAILURE:
    return {
      ...state,
      loading: false,
      loaded: true,
      error: action.payload,
    };
    
  case LOCATION_CHANGE:
    return initialState;
    
  default:
    return state;
  }
}
