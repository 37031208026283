import styled from 'styled-components';

import {isMobile} from '../../utils/mobile';

export const LettersBody = styled.div`
  box-shadow: rgb(199,195,195) 0px 1px 2px -1px;
  border-radius: 5px;
  background-color: #fff;
  display: inline-block;
  color: #515151;
  max-width: 28.4375rem;
  margin-left: 1.25rem;
  width: 100%;
  
  ${isMobile() && `
    position: relative;
    width: 100%;
    max-width: fit-content;
    margin: 90px auto auto;
    background-color: #fff;
    cursor: default;
    border-radius: 2px;
    color: #515151;
    
    &&& {
      max-width: 100%;
      margin: 0;
      height: 100%;
      top: 0;
    }
  `} 
 
  ${({isModalPhoto}) => !isModalPhoto && `
     position: absolute;
     left: 50%;
     top: 90px;
     transform: translateX(-50%);
  `}
`;

export const DropDownMenu = styled.div`
	position: absolute;
  right: .5rem;
  top: .75rem;
  
  b {
    padding: 5px;
  }
  
  label {
    width: 4px;
    height: 4px;
    margin-right: 3px;
  }
  
  span {
    top: 17px;
    right: -4px;
  }
`;

export const Circle = styled.label`
	display: ${props => props.is_read ? 'none' : 'block'}
	position: absolute;
  left: -0.625rem;
  top: 50%;
  transform: translate(-50%, -50%);
	width: 0.625rem;
  background-color: #dd4057;
  margin-right: 5px;
  border-radius: 50%;
  height: 0.625rem;
`;

export const Mail = styled.div`
	position: relative;
`;

export const Letter = styled.p`
  width: 100%;
  font-size: .75rem;
  line-height: normal;
  overflow: hidden;
  max-height: 3.5125rem;
  word-break: break-word;
`;

export const MailBody = styled.div`
  display: flex;
  flex-direction: column;
  ${Letter} {
    font-weight: ${props => props.is_read ? 400 : 600};
  }
`;

export const Mailbox = styled.div`
  position: relative;
  width: 100%;
  max-width: 22.5rem;
  border-radius: 2px;
  color: #515151;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(184, 191, 211, .2);
	
  ${Mail}:last-child {
    border-bottom: 1px solid #e8e8e8;
  }
  
  @media (max-width: 767px) {
    max-width: 100%;
    box-shadow: none;
  }
`;

export const Loader = styled.img`
  width: 100px;
  display: inline-block;
  border-radius: 15px;
  right: 0;
  left: 0;
  margin: auto;
  top: 38%;
  position: absolute;
`;

export const Filters = styled.div`
  display: flex;
  border-bottom: .0625rem solid #edeff4;
  padding: 0 1.25rem;

  @media (max-width: 480px) {
    padding: 0 .9375rem;
  }
  
  @media (max-width: 767px) {
    z-index: 5;
    width: 100%;
    background-color: #fff;
  }
`;

export const Close = styled.span`
  font-size: 28px;
  position: absolute; 
  right: 2%;
  color: #ccc7c7;
  top: 1.9%;
  cursor: pointer;
`;

export const PhotoContainer = styled.div`
	position: relative;
  flex-shrink: 0;
  margin-right: 6px;
`;

export const Photo = styled.img`
  display: block;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
`;

export const MailHeader = styled.div`
	display: flex;
	
	.sent_at {
	  font-size: 9px;
    margin-left: 6px;
    color: #000;
    position: absolute;
    bottom: 3px;
    right: 5px;
    opacity: .4;
	}
	
	span {
		font-size: 1rem;
    font-weight: 600;
	}
`;

export const Mails = styled.div`
	position: relative;
	overflow-y: auto;
  overflow-x: hidden;
  min-height: 50vh;
  max-height: 50vh;
  
  ::-webkit-scrollbar { 
    display: none; 
  } 
  
  @media (max-width: 767px) {
    min-height: auto;
    max-height: inherit;
  }
`;

export const ButtonContainer = styled.div`
	position: relative;
	display: flex;
  justify-content: flex-end;
  padding: 0 1.25rem 1.25rem 0;
`;

