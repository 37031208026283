import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import renderTextArea from './renderTextArea';
import renderInput from './renderInput';
import uploadPicture from './uploadPhoto';
import { isMobile } from '../../utils/mobile';
import validate from './validate';
import { setLocalStorage } from './setLocalStorage';
import * as Styles from './styles';
import UnknownUserJpeg from '../../assets/unknown_user.jpeg';

const renderField = ({ input, meta: { touched, error }, validationError, required }) => {
  const handleChange = e => {
    input.onChange(e.target.value);
    setLocalStorage('subject', e.target.value);
  };
  

  return (
    <Styles.SubjectWrap>
      <div style={{display: 'flex'}}>
        <span>Subject</span>
        <Styles.Require>*</Styles.Require>
      </div>
      <Styles.Input autoComplete="off" type="text" required={required} error={(touched && error) || validationError} {...input} onChange={handleChange}/>
      {validationError && <span className="error">{validationError}</span>}
    </Styles.SubjectWrap>
  );
};

const Form = ({handleSubmit, profile, onClose, myProfile, onlineUsers, letter, isReply, validationErrors}) => {
  const [maxLength] = useState(8000);
  const [currentLength, setCurrentLength] = useState(8000);

  const onChange = e => setCurrentLength(maxLength - e.target.value.length);

  const isOnlineUser = profile ? ~onlineUsers.indexOf(profile.id) : false;
  
  if (!profile && !letter) {
    return <div/>;
  }
  
  return (
    <Styles.Form onSubmit={handleSubmit}>
      <Styles.Close onClick={onClose}><i className='fal fa-times'/></Styles.Close>
      <Styles.Title>{isReply ? 'Reply' : 'New mail'}</Styles.Title>
      <Styles.ProfileWrapper as={NavLink} to={`/profile/${btoa(profile.id)}`} onClick={onClose}>
        <Styles.ProfileImage style={{ backgroundImage: `url(${profile.photo ? profile.photo.src : UnknownUserJpeg})` }}>
          {Boolean(isOnlineUser) && (<Styles.ProfileImageActive/>)}
        </Styles.ProfileImage>
        <Styles.Receiver>
          {profile.name} {profile.age ?  <span>, {profile.age}</span> : ''}
        </Styles.Receiver>
      </Styles.ProfileWrapper>
      {!Boolean(myProfile.is_agency) && myProfile.credits === 0 && (
        <InfoWrapper>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14 7A7 7 0 110 7a7 7 0 0114 0zM7.875 3.5a.875.875 0 11-1.75 0 .875.875 0 011.75 0zm-1.75 2.625a.875.875 0 000 1.75V10.5a.875.875 0 00.875.875h.875a.875.875 0 000-1.75V7A.875.875 0 007 6.125h-.875z"
              fill="#3B5A9A"
            />
          </svg>
          <InfoDescription>
            To send email, please check if you have enough credits for it. Price: 10 Credits per 1 email (8000 symbols)
          </InfoDescription>
        </InfoWrapper>
      )}
    
      {
        (isReply || !isMobile()) ? (
          <>
            <Styles.ImageWrap photo={letter && letter.photo}>
              {isReply && letter && letter.photo && (<Styles.Image src={letter.photo.thumbnail_src} alt=""/>)}
            </Styles.ImageWrap>
            <Styles.TextWrap>
              <Styles.Section> {isReply && letter && letter.text}</Styles.Section>
            </Styles.TextWrap>
          </>
        ) : (
          <div>
            <Field
              name='subject'
              required
              component={renderField}
              type='text'
              {...{
                validationError: validationErrors && validationErrors.subject
              }}
            />
            <Field
              name="text"
              required
              handleChange={onChange}
              maxLength={maxLength}
              component={renderTextArea}
            />
            <Field
              name='photo_id'
              component={uploadPicture}
            />
            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
              <Styles.SendButton as='button' type='submit'>SEND</Styles.SendButton>
            </div>
          </div>
        )
      }
      {
        isMobile() ? (isReply && (
          <Styles.MobileMessageWrap>
            <Styles.Cover>
              <Field
                name='photo_id'
                component={uploadPicture}
              />
              <Field
                name="text"
                required
                onChange={onChange}
                maxLength={maxLength}
                component={renderInput}
              />
              <Styles.Number>{currentLength}</Styles.Number>
              <Styles.Button as='button' type='submit'/>
            </Styles.Cover>
          </Styles.MobileMessageWrap>
        )) : (
          <Styles.MessageWrap>
            {
              !isReply && (
                <Field
                  name='subject'
                  component={renderField}
                  type='text'
                  required
                  {...{
                    validationError: validationErrors && validationErrors.subject
                  }}
                />
              )
            }
            <Field
              name='text'
              required
              maxLength={maxLength}
              handleChange={onChange}
              component={renderTextArea}
            />
            <Field
              name='photo_id'
              component={uploadPicture}
            />
            <Styles.Number style={{ opacity: 0, visibility: 'hidden' }}/>
            <Styles.Button as='button' type="submit">SEND</Styles.Button>
          </Styles.MessageWrap>
        )
      }
    </Styles.Form>
  );
};

export default reduxForm({
  form: 'sendEmail',
  validate
})(Form);

const InfoWrapper = styled.div`
  display: flex;
  margin-left: 2.875rem;
  margin-bottom: 0.875rem;
  svg {
    flex: 0 0 auto;
  }
`;

const InfoDescription = styled.div`
  font-size: 0.825rem;
  line-height: 0.75rem;
  margin-left: 0.5rem;
  color: #515151;
`;