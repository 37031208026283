export function load(params) {
  return new Promise(function (resolve, reject) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;

    Object.keys(params).forEach(parameter => {
      script[parameter] = params[parameter];
    });

    document.head.appendChild(script);
  });
}