import React from 'react';
import styled from 'styled-components';

import * as Styles from './styles';
import { Modal, ModalContent } from '../Modal';

const TrialPopup = ({ isOpen, onClose, trial_info }) => {
  const { trial_days, trial_credits } = trial_info || {};
  
  return (
    <Modal isOpen={isOpen}>
      <Content>
        <Styles.Close onClick={onClose}><i className='fal fa-times'/></Styles.Close>
        <div className="modal_body">
          <span className='text'>
            You won’t be charged now.
            Just add your credit card and receive {trial_credits} credits for {trial_days} days for free.
          </span>
          <span className='notice'>*We will charge your card after {trial_days} days of using your subscription</span>
          <div className="btn_wrapper">
            <button onClick={onClose}>Got it!</button>
          </div>
        </div>
      </Content>
    </Modal>
  );
};

export default TrialPopup;

export const Content = styled(ModalContent)`
  width: 100%;
  max-width: 35rem;
  
  .modal_body {
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    
    .text {
      font-size: 1.125rem;
      line-height: 25px;
    }
    
    .notice {
      font-size: .75rem;
      letter-spacing: .5px;
      color: #777;
      margin-top: 20px;
    }
    
    .btn_wrapper {
      margin-top: 30px;
      display: flex;
      width: 100%;
      justify-content: center;
      
      button {
        cursor: pointer;
        background-color: #5072de;
        border-radius: 3px;
        width: 35%;
        outline: none;
        padding: .6125rem;
        font-size: 1rem;
        color: #fff;
      }
    }
  }
`;