export const minValue = min => value => value && value < min ? `Must be at least ${min}` : undefined;

export const required = message => value => value ? undefined : message || 'Required';

export const date = value => value && isNaN(new Date(value).getTime()) ? 'Date in invalid' : undefined;

export const isAgeValid = value => {
  const age = new Date().getFullYear() - new Date(value).getFullYear();

  return value && (age > 70 || age < 18) ? 'Your age doesn\'t valid' : undefined;
};