import React from 'react';
import styled from 'styled-components';
import { currencySymbols } from '../../constants';

const CreditPlan = ({ onChoosePlan, id, credits, price, chosenPlan, is_purchased, first_price, currency }) => {
  const handleClick = () => onChoosePlan(id);
  
  const isActive = chosenPlan && chosenPlan.id === id;
  
  if (!is_purchased && first_price && first_price < price) {
    return (
      <Plan
        className="payment-card-wrapper"
        onClick={handleClick}
        active={isActive}
        isFirst
      >
        <div className="payment-card-discount">
          <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M48.419 17.484l-1.174 5.539 3.143 4.71c.364.547.313 1.27-.126 1.758l-3.781 4.214.358 5.652a1.438 1.438 0 01-.987 1.457l-5.382 1.761-2.513 5.075a1.438 1.438 0 01-1.586.769l-5.539-1.174c-4.555 3.04-4.824 3.265-5.13 3.337-.033.008-.067.014-.106.022a1.437 1.437 0 01-1.232-.342l-4.215-3.781-5.651.358a1.438 1.438 0 01-1.457-.987l-1.762-5.382-5.074-2.513a1.438 1.438 0 01-.769-1.586l1.173-5.539-3.143-4.71a1.438 1.438 0 01.126-1.758l3.782-4.215-.359-5.651a1.438 1.438 0 01.987-1.457l5.382-1.762 2.514-5.074a1.438 1.438 0 011.586-.769l5.539 1.173 4.71-3.143a1.439 1.439 0 011.758.126l4.214 3.782 5.652-.359a1.438 1.438 0 011.457.987l1.761 5.382 5.075 2.514c.589.291.904.944.769 1.586z"
              fill="#DE60AD"/>
          </svg>
          <div className="payment-card-discount__number">SAVE {100 - (first_price / (price / 100)).toFixed(0)}%</div>
        </div>
        <div className="payment-card-content">
          <div className="payment-card-title">
            {credits} Credits
          </div>
          <div className="price-wrapper">
            <span>{currencySymbols[currency] || currency}{price}</span> <strong>{currencySymbols[currency] || currency}{first_price}</strong>
          </div>
        </div>
      </Plan>
    );
  }
  
  return (
    <Plan
      className="payment-card-wrapper"
      onClick={handleClick}
      active={isActive}
    >
      <div className="payment-card-content">
        <div className="payment-card-title">
          {credits} Credits
        </div>
        <div className="price-wrapper">
          <span>{currencySymbols[currency] || currency}{price}</span> <strong>{currencySymbols[currency] || currency}{first_price}</strong>
        </div>
      </div>
    </Plan>
  );
};

export default CreditPlan;

const Plan = styled.div`
  margin: 1rem 0;
  
  :hover {
    cursor: pointer;
    border: ${props => props.active ? '1px solid #3b5a9a' : '1px solid #b8bfd3'};
  }

  border: ${props => props.active ? '1px solid #3b5a9a' : '1px solid transparent'};
  
  span:last-child {
    font-size: .875rem;
    letter-spacing: .05625rem;
  }
  
  ${({ isFirst }) => isFirst && `
    ::before {
      position: absolute;
      top: 0;
      left: 50%;
      display: inline-block;
      padding: 0 .625rem;
      content: "First time offer";
      transform: translate(-50%, -50%);
      white-space: nowrap;
      background-color: #fff;
      font-size: 0.875rem;
      line-height: 1rem;
      letter-spacing: 0.055rem;
      color: #3B5A9A;
    }
  `}
`;