import * as TYPES from '../constants/types';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
  case TYPES.INIT_ONLINE_LIST: {
    return action.users.reduce((res, user) => res.concat(user.id), []);
  }

  case TYPES.REMOVE_USER_FROM_ONLINE_LIST: {
    let atIndex = state.indexOf(action.user);

    if (~atIndex) {
      return [
        ...state.slice(0, atIndex),
        ...state.slice(atIndex + 1)
      ];

    } else {
      return state;
    }
  }

  case TYPES.ADD_USER_TO_ONLINE_LIST: {
    let atIndex = state.indexOf(action.user);

    if (!~atIndex) {
      return [...state, action.user];
    } else {
      return state;
    }
  }

  default:
    return state;
  }
};
