const validate = value => {
  let error = '';

  if (value.hasOwnProperty('email')) {
    if (!value.email) {
      error = 'Please enter an email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.email)) {
      error = 'Invalid email address (format:xxx@xxx.xxx)';
    }
  }

  if (value.hasOwnProperty('password')) {
    if (!value.password) {
      error = 'Please enter a password';
    } else if (value.password.length < 8) {
      error = 'Password must have at least 8 symbols';
    }
  }

  return error;
};

export default validate;


