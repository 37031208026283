import React from 'react';

export default function Icon() {
  return (
    <div className="check-validate-icon">
      
      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.9617 2.3436C11.0776 2.3436 8.86811 1.61233 6.99974 0C5.13156 1.61233 2.92215 2.3436 0.0383067 2.3436C0.0383067 6.54244 -0.940689 12.5573 6.99969 15C14.9406 12.5573 13.9617 6.54249 13.9617 2.3436Z"
          fill="#DE60AD"
        />
        <path
          d="M5 6.90661L6.84795 8.75456L9.60251 6"
          stroke="white"
          stroke-width="2"
          troke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}