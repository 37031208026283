import React from 'react';
import styled from 'styled-components';

import {unknownUser} from '../../../../assets';

const Contact = ({user: {photo, id, name}, myId, is_online, toggleEmailForm}) => {

  const sendEmail = () => {
    toggleEmailForm(id);
  };

  return (
    <ContactsTabs onClick={sendEmail}>
      <Avatar>
        <Photo src={photo ? photo.src : unknownUser}/>
        <OnlineStatus online={is_online}/>
      </Avatar>
      <UserName>{name}</UserName>
    </ContactsTabs>
  );
};

export default Contact;

const UserName = styled.div`
	font-size: 1rem;
  font-weight: 600;
  margin-bottom: .1875rem;
  color: #4b4c59;
`;

const Avatar = styled.div`
	position: relative;
  margin-right: 8px;
  flex-shrink: 0;
`;

const ContactsTabs = styled.div`
  display: flex;
  padding: .8125rem .9375rem .8125rem 1.25rem;
  border-bottom: .0625rem solid #edeff4;
  align-items: center;
  cursor: pointer;
  
  :hover {
    background-color: #e8e8e8;
  }
	
	@media (max-width: 767px) {
		padding-right: .9375rem;
    padding-left: 1.875rem;
	}
`;

const Photo = styled.img`
	display: block;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  position: relative;
`;

const OnlineStatus = styled.span`
	${({online}) => online && `
		background-color: #7ed321;
	  width: .65rem;
	  margin-right: 5px;
	  border-radius: 50%;
	  height: .65rem;
	  position: absolute;
	  border: 2px solid #fff;
	  bottom: 0px;
	  right: -5px;
	`}
`;
