const validate = values => {
  const errors = {};

  if (!values.subject) {
    errors.subject = 'Required';
  }

  if (!values.text) {
    errors.text = 'Required';
  }

  return errors;
};

export default validate;



