import React from 'react';
import {Redirect} from 'react-router-dom';

import {isMobile} from '../utils/mobile';
import Contacts from '../containers/Contacts';

const ContactsPage = () => {
  if (isMobile()) {
    return <Contacts/>;
  }

  return (<Redirect to={{pathname: '/me'}}/>);
};

export default ContactsPage;