import React, {useState, useRef} from 'react';
import Calendar from 'react-datepicker';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

import {formatDate} from '../../utils/formatDate';

const Datepicker = ({meta, input, label, meta: {error}}) => {
  const [date, setDate] = useState((meta && meta.initial) ? new Date(meta.initial) : null);
  const datepicker = useRef(null);

  const handleChange = date => {
    setDate(date);
    input && input.onChange(formatDate(date));
  };

  const handleChangeRaw = () => {
    let input = datepicker.current.input;

    input.value = input.value.slice(0, 10);
  };

  return (
    <Container style={{position: 'relative'}}>
      <Wrapper>
        <Wrapper style={{width: '100%'}}>
          <Span>{label}</Span>
          <DateWrapper error={error}>
            <Calendar
              ref={datepicker}
              onChangeRaw={(e) => handleChangeRaw(e)}
              selected={date}
              onChange={handleChange}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </DateWrapper>
        </Wrapper>
      </Wrapper>
    </Container>
  );
};

export default Datepicker;

export const Require = styled.span`
  position: relative;
  padding-left: .25rem !important;
  top: 0;
  right: 0;
  color: red;
`;

export const Span = styled.span`
  font-weight: 600;
  display: flex;
  flex-shrink: 0;
  
  @media (max-width: 480px) {
    margin-bottom: .1875rem;
  }
     
  ::selection {
   background: transparent;
  }
  ::-moz-selection {
    background: transparent;
  }
`;

const DateWrapper = styled.div`
  width: 65%;

  input {
    max-width: 256px;
    border: 1px solid #dadee8;
    background-color: #fff;
    border-radius: 2px;
    padding-left: 14px;
    height: 30px;
    padding: 0 10px;
    color: #515151;
    width: -webkit-fill-available;
    cursor: text;
	
    ${({error}) => error && `
      border: 1px solid red;
    `}
    
    ::-webkit-input-placeholder {
      font-size: 14px;
      color: #b8bfd3
    }
    
    ::-ms-input-placeholder {
      font-size: 14px;
      color: #b8bfd3
    }
    
    ::placeholder {
      font-size: 14px;
      color: #b8bfd3
    }
    
    :focus {
      outline: 0
    }
    
    @media (max-width: 480px) {
      max-width: 90%;
    }
  }
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  
  @media (max-width: 480px) {
    margin-bottom: .9375rem;
    flex-flow: column;
    align-items: flex-start;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 30px;
  width: 100%;

	:nth-of-type(3) {
    margin-bottom: 26px;
	}
	
	@media (max-width: 480px) {
	  margin-bottom: 5px;
	
	  div {
      margin-bottom: .9375rem;
      flex-flow: column;
      align-items: flex-start;
    }

    :nth-of-type(3) {
      margin-bottom: 0;
	  }
  }
`;