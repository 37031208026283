import React, {Component} from 'react';
import styled from 'styled-components';

const normalize = val => typeof val === 'string' ? val.charAt(0).toUpperCase() + val.slice(1).replace(/_/, ' ').toLowerCase() : val;

class Select extends Component {
  state = {
    isOpen: false,
    option: null
  };

  componentDidMount() {
    this.initValue();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const {input, options} = nextProps;
    const {option} = this.state;

    if (this.props.input) {
      if (input.value !== this.props.input.value) {
        this.setState({option: {label: normalize(input.value), value: input.value}});
      }
    } else if (options.length === 0 && option) {
      this.setState({option: null});
    } else if (options.length && option && !options.find(option => option.value === this.state.option.value)) {
      this.setState({option: null});
    } else if (nextProps.defaultOption) {
      this.setState({option: nextProps.defaultOption});
    }
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({isOpen: false});
    }
  };

  initValue = () => {
    const {input, meta, defaultOption} = this.props;

    if (input && (input.value || meta.initial)) {
      let initialValue = (input.value && meta.initial) ? input.value : (meta.initial && !input.value) ? meta.initial : input.value;

      this.setState({
        option: {
          label: normalize(initialValue),
          value: initialValue
        }
      });
    } else if (defaultOption) {
      input && input.onChange(defaultOption.value);
      this.setState({option: defaultOption});
    }
  };

  handleClick = e => {
    const {options, input, onChange} = this.props;
    const option = options.find(optionItem => {
      if (typeof optionItem.value !== 'string') {
        return JSON.stringify(optionItem.value) === e.target.id;
      } else {
        return optionItem.value === e.target.id;
      }
    });

    input && input.onChange(option ? option.value : '');
    onChange && onChange(option ? option : '');

    this.setState({option: option || {value: '', label: ''}});
  };

  setWrapperRef = node => this.wrapperRef = node;

  toggleOptionsList = () => this.setState({isOpen: !this.state.isOpen});

  render() {
    const {isOpen} = this.state;
    const {options, width, emptyOptionAvailable = true, input} = this.props;
    const placeholder = this.props.placeholder ? (' - ' + this.props.placeholder) : '-';

    const option = input ? ((input.value && this.state.option) ? this.state.option : {
      label: normalize(input.value),
      value: input.value
    }) : this.state.option;

    const initialOption = options.find(({ value }) => option && option.value === value);

    return (
      <Wrap
        isOpen={isOpen}
        onClick={this.toggleOptionsList}
        ref={this.setWrapperRef}
        width={width}
      >
        <DefaultOption>
          {option && option.icon && (<img src={option.icon} alt=""/>)}
          {initialOption ? initialOption.label : option ? option.label : ''}
        </DefaultOption>
        {
          isOpen && (
            <OptionsList isOpen={isOpen}>
              {
                emptyOptionAvailable && (
                  <Option id='' onClick={this.handleClick}>
                    {placeholder}
                  </Option>
                )
              }
              {
                options.map(({value, label, icon}) => (
                  <Option
                    {...this.props}
                    key={value}
                    id={typeof value !== 'string' ? JSON.stringify(value) : value}
                    onClick={this.handleClick}
                  >
                    {icon && (<img src={icon} alt=""/>)}
                    {label}
                  </Option>
                ))
              }
            </OptionsList>
          )
        }
        <SelectIco>
          <i className='fal fa-angle-down'/>
        </SelectIco>
      </Wrap>
    );
  }
}

export default Select;

const OptionsList = styled.ul`
  padding: 0;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: -.0625rem;
  box-sizing: border-box;
  width: calc(100% + .125rem);
  min-width: 2.5rem;
  margin: 0;
  list-style: none;
  transform: translate(0, 100%);
  pointer-events: none;
  opacity: 0;
  border: .0625rem solid #b8bfd3;
  background-color: #fff;
  overflow-y: auto;
  max-height: 50vh;

  ${({isOpen}) => isOpen && `
    pointer-events: auto;
    opacity: 1
  `}
`;

const Option = styled.li`
  font-size: .875rem;
  display: flex;
  padding: .3125rem 1.5625rem .3125rem .6125rem;
  white-space: nowrap;
  color: #515151;
  align-items: center;
  height: 30px;
  
  img {
    width: 100%;
    max-width: 1.5rem;
    margin-right: 10px;
  }
  
  :hover {
    background-color: #e8e8e8;
  }
  
  ${({disabled}) => disabled && `
     cursor: not-allowed;
  `}

  @media (max-width: 480px) {
    font-size: .75rem;
  }
`;

const DefaultOption = styled.span`
  font-size: .875rem;
  position: relative;
  z-index: 0;
  display: flex;
  width: 100%;
  padding-right: 1.5625rem;
  padding-left: .3125rem;
  cursor: pointer;
  white-space: nowrap;
  color: #515151;
  border: none;
  outline: 0;
  align-items: center;
  font-weight: 400 !important;
  height: 25px;
  
  img {
    width: 100%;
    max-width: 1.5rem;
    margin-right: 10px;
  }

  @media (max-width: 480px) {
    font-size: .75rem;
  }
`;

const Wrap = styled.div`
  color: #515151;
  position: relative;
  display: inline-block;
  padding: .3125rem;
  border: .0625rem solid #dadee8;
  border-radius: .125rem;
  background-color: #fff;
  cursor: pointer;
  width: ${props => props.width ? props.width : '10rem'};
  min-height: 1.8125rem;

  ${({isOpen}) => isOpen && `
     border: .0625rem solid #b8bfd3;
  `}

  @media (max-width: 767px) {
    width: 70%;
    height: 100%;
  }

  @media (max-width: 480px) {
    width: 65%;
  }
`;

const SelectIco = styled.div`
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  right: .625rem;
  display: block;
  content: "";
  transform: translate(0, -40%);
  color: #b8bfd3;
`;