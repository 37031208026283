import React, { Component } from 'react';
import { Field } from 'redux-form';

import { loader } from '../../assets';
import Input from './Input';

import {
  AddCardCol,
  AddCardRow,
  CardBtn,
  CardNumInputs,
  CardsBtnWrap,
  CreateCardWrapper,
  CvvWrap,
  Loader,
  SelectContainer,
  StyledWrapper
} from '../Settings/styles';

import CardField from './CardField';

class NewCard extends Component {
  autoFocus = id => {
    const inputs = this.cardForm.getElementsByTagName('input');

    if (inputs.length >= id + 1) {
      inputs[id].focus();
    } else if (!inputs[id]) {
      inputs[0].focus();
    }
  };

  handleClose = () => !this.props.loading && this.props.onClose();

  render() {
    const {handleSubmit, loading, isFirstSubscribe} = this.props;

    return (
      <StyledWrapper>
        <CreateCardWrapper onSubmit={handleSubmit} ref={c => this.cardForm = c} loading={loading}>
          <h5>New card</h5>
          <CardNumInputs>
            <Field
              maxLength={19}
              name='pan'
              component={CardField}
              autoFocus={this.autoFocus}
              id={1}
            />
          </CardNumInputs>
          <Field
            name="holder"
            placeholder="Cardholder name"
            component={Input}
            autoFocus={this.autoFocus}
            id={2}
          />
          <AddCardRow>
            <AddCardCol>
              <SelectContainer>
                <Field
                  type='number'
                  component={Input}
                  maxLength={2}
                  placeholder='01'
                  name='expiration_month'
                  autoFocus={this.autoFocus}
                  id={3}
                />
              </SelectContainer>
              <SelectContainer>
                <Field
                  type='number'
                  component={Input}
                  maxLength={2}
                  placeholder='01'
                  name='expiration_year'
                  autoFocus={this.autoFocus}
                  id={4}
                />
              </SelectContainer>
            </AddCardCol>
            <CvvWrap>
              <Field
                type="number"
                maxLength={3}
                placeholder="cvv"
                name='cvv'
                component={Input}
                autoFocus={this.autoFocus}
                id={5}
              />
              <i className="fas fa-question-circle"/>
            </CvvWrap>
          </AddCardRow>
          {!isFirstSubscribe && (
            <CardsBtnWrap loading={loading}>
              <CardBtn type="button" onClick={this.handleClose}>Cancel</CardBtn>
              <CardBtn type="submit">Save</CardBtn>
            </CardsBtnWrap>
          )}
  
          {isFirstSubscribe && this.props.renderPurchaseButton && this.props.renderPurchaseButton({
            type: isFirstSubscribe ? 'submit' : 'button',
            disabled: false,
            callback: isFirstSubscribe ? () => {} : null,
          })}

        </CreateCardWrapper>
        {loading && (<Loader src={loader} alt=""/>)}
      </StyledWrapper>
    );
  };
}

export default NewCard;