import React, {useState} from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

import {Modal, ModalContent} from './Modal';
import {btn} from './styles';

const StatusCode = ({redirect, history, onClose, children, status = 'success', image, ...props}) => {
  const [isOpen, toggleModal] = useState(true);

  const onCloseModal = () => toggleModal(false);

  const handleClick = () => {
    onClose && onClose();
    redirect ? history.push(redirect) : onCloseModal();
  };

  const title = props.title || (status.charAt(0).toUpperCase() + status.slice(1).toLowerCase());

  return (
    <Modal isOpen={isOpen}>
      <Wrapper>
        <Content>
          {image && (<Icon><img src={image} alt=""/></Icon>)}
          <h4>{title}</h4>
          {children}
        </Content>
        <BtnWrap>
          <Button onClick={handleClick}>
            OK
          </Button>
        </BtnWrap>
      </Wrapper>
    </Modal>
  );
};

export default withRouter(StatusCode);

const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  
  @media (max-width: 767px) {
    justify-content: center;
    button {
      min-width: 8rem;
    }
  }
`;

const Button = styled(btn)`
  letter-spacing: 2px;
  min-width: 120px;
`;

const Icon = styled.div`
  @media (max-width: 480px) {
    width: 36px;
    height: 36px;
  }
  
  img {
    box-sizing: content-box;
    max-width: 100%;
    height: auto;
    border-style: none;
  }
  
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-25%);
`;

const Content = styled.div`
  @media (max-width: 480px) {
    padding-left: 50px;
    margin-top: 20px;
  }
  
  h4 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #4b4c59;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }
  
  margin-top: 50px;
  position: relative;
  padding-left: 70px;
`;

const Wrapper = styled(ModalContent)`
  max-width: 460px;
  padding: 20px;
  
  @media (max-width: 480px) {
    height: auto !important;
    margin: .9375rem;
    align-self: flex-start;
  }

  @media (max-width: 767px) {
    border: 1px solid #e8e8e8;
    margin-top: 50% !important;
    transform: translateY(-50%);
  }
`;