import React from 'react';
import styled from 'styled-components';
import {Field} from 'redux-form';

import RenderSelect from './renderSelect';
import RenderInput from './renderInput';
import * as Styles from './styles';
import {Event} from '../../Tracking';
import { detailInfo } from '../../constants';

const normalize = val => typeof val === 'string' ? val.charAt(0).toUpperCase() + val.slice(1).replace(/_/, ' ').toLowerCase() : val;

const additionalFields = [
  {
    name: 'has_children',
    component: RenderSelect,
    label: 'Has children',
    props: {
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ]
    }
  },
  {
    name: 'height',
    label: 'Height',
    component: RenderInput,
    props: { type: 'number' }
  },
  {
    name: 'weight',
    label: 'Weight',
    component: RenderInput,
    props: { type: 'number' }
  }
];

const PersonalDetails = ({selects, handleSubmit, buttonText, onClose, step, lastStep}) => {
  const handleClick = () => Event('send', 'form', 'step3');
  return (
    <Form as="form" onSubmit={handleSubmit}>
      <Styles.Title>About me {step && (step + '/' + lastStep)}</Styles.Title>
      {onClose && (<Styles.Skip onClick={onClose}><i className='fal fa-times'/></Styles.Skip>)}
      <Styles.MessageWrap>
        <Styles.Options>
          {
            Object.keys(selects)
              .filter(key => Object.keys(detailInfo).includes(key))
              .map(key => (
                <Field
                  label={key}
                  name={key}
                  key={key}
                  placeholder="No answer"
                  component={RenderSelect}
                  options={
                    Object.keys(selects[key]).map(selectItem => ({
                      label: normalize(selectItem),
                      value: selectItem
                    }))
                  }
                />
              ))
          }
          {additionalFields.map(({ name, label, component, props }) => (
            <Field
              name={name}
              key={name}
              placeholder="No answer"
              component={component}
              label={label}
              {...props}
            />
          ))}
        </Styles.Options>
        {onClose && (<Styles.Button as="button" close={onClose} onClick={onClose}>CANCEL</Styles.Button>)}
        <Styles.Button as="button" type="submit" onClick={handleClick}>
          {buttonText || 'NEXT'}
        </Styles.Button>
      </Styles.MessageWrap>
    </Form>
  );
};

export default PersonalDetails;

const Form = styled(Styles.Form)`
  max-width: 37.375rem;
  padding-right: 4.6875rem;
  padding: 1.875rem;
  width: 100%;

  @media (max-width: 767px) {
    margin: 0;
    align-self: flex-start;
    max-width: 100%;
    padding: 1.25rem 1.375rem;
    width: auto;
  }

  @media (max-width: 767px) {
    align-self: flex-start;
    max-width: 100%;
    padding: 1.25rem .9375rem;
  }
  
  @media (max-width: 480px) {
    height: auto;
  }
  
   @media (max-width: 480px) {
    margin: .9375rem;
  }
`;
