import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { withCookies } from 'react-cookie';

import { initGA } from '../../Tracking';

import { NavLink } from '../Link';
import ErrorBoundary from '../../ErrorBoundary';
import Routes from '../../routes';

import { unknownUser } from '../../assets';
import * as api from '../../api';
import { login } from '../../api';
import * as Styles from '../Sidebar/styles';

import Echo from '../../utils/echo';
import { getLastMessageByType } from '../../utils/getLastMessageByType';
import { Button } from '../Catalog/styles';
import { isMobile } from '../../utils/mobile';
import AudioMessageM4a from '../../assets/audio/new_message.m4a';

export const getUrlParams = url => {
  const urlParams = {};

  for (let value of new URL(url).searchParams.entries()) {
    urlParams[value[0]] = value[1];
  }

  return urlParams;
};

function getContactById(contacts, id) {
  return contacts.find(contact => contact.id === id);
}

function showMessage({ contact, message, onClose, type, entity }) {
  return (
    <div className="notification-item">
      <div className="notification-close-button" onClick={onClose}/>
      <div className="notification-dot"/>
      <NavLink
        to={'/profile/' + btoa(contact.id)}
        style={{ textDecoration: 'none', color: '#aaa' }}
      >
        <div
          className="notification-image"
          style={{ backgroundImage: `url(${contact.photo ? contact.photo.src : unknownUser})` }}
        >
          <div className="notification-image__online-dot"/>
        </div>
      </NavLink>
      <div className="notification-about">
        <div className="notification-about__title">
          {contact.name}
        </div>
        <div className="notification-about__description">
          {message}
        </div>
      </div>
      {type === 'LETTER' ? (
        <NavLink
          to={'/mailbox/' + entity.id}
          style={{ textDecoration: 'none', color: '#aaa', marginLeft: 'auto' }}
        >
          <Button className="notification-button">
            OPEN EMAIL
          </Button>
        </NavLink>
      ) : (
        <NavLink
          to={'/messages/' + btoa(contact.id)}
          style={{ textDecoration: 'none', color: '#aaa', marginLeft: 'auto' }}
        >
          <Button className="notification-button">
            OPEN CHAT
          </Button>
        </NavLink>
      )}
    </div>
  );
}

const toastList = new Set();

const LaravelEcho = new Echo();

class App extends Component {
  showNotificationsButton = false;
  audio = new Audio(AudioMessageM4a);

  componentDidMount() {
    const {
      receiveMessage,
      incrementMailCount,
      incrementMessagesCount,
      updateContact,
      createNewContact,
      updateContactsInfo,
      updateProfileInfo,
      fetchWizardOptions,
      fetchProfile,
      getContactsList,
      getInitialMailCount,
      getInitialMessagesCount,
      cookies,
    } = this.props;

    window.addEventListener('beforeunload', this.handleAppClose);

    // init Google Analytics Tracking

    initGA('UA-135461727-1');

    // init language from excel file

    // const language = localStorage.getItem('language') || 'en';
    // i18next.addResourceBupandle(language, 'common', transformer(data, language));

    let token = getUrlParams(window.location.href).token;

    if (token && window.location.pathname === '/catalog') {
      localStorage.setItem('token', token);
      localStorage.setItem('saveSession', 'on');
      cookies.remove('profileFilled');
      cookies.remove('bonusesModal');
      cookies.remove('token');

      window.history.pushState({}, document.title, window.location.pathname);

      sessionStorage.setItem('token', token);
    }

    if (localStorage.getItem('token')) {
      if (!sessionStorage.getItem('token')) {
        sessionStorage.setItem('token', localStorage.getItem('token'));
      }

    } else {
      sessionStorage.clear();
    }

    if (!sessionStorage.getItem('token') && !localStorage.getItem('token') && process.env.REACT_APP_ENV === 'local') {
      const email = prompt('email', 'testuser@test.com');
      const password = prompt('password', 'test12345');

      if (email && password) {
        login({ body: { email, password } })
          .then(response => {
            if (response && response.data) {
              localStorage.setItem('token', response.data.token);
              localStorage.setItem('saveSession', 'on');
              sessionStorage.setItem('token', response.data.token);

              window.location.reload();
            }
          });
      }
    }

    if (sessionStorage.getItem('token') || localStorage.getItem('token')) {
      fetchWizardOptions();
      fetchProfile();
      getContactsList();

      api.getFullProfile({})
        .then(res => {
          const profile = res.data;

          if (profile.unread_mail_count) {
            getInitialMailCount(profile.unread_mail_count);
          }

          if (profile.unread_messages_count) {
            getInitialMessagesCount(profile.unread_messages_count);
          }

          LaravelEcho.init()
            .then(() => {
              updateProfileInfo({ LaravelEcho });
              return (
                LaravelEcho
                  .getEcho()
                  .private('messages.' + profile.id)
                  .listen('.NewMessage', data => {
                    const { sender_id, type, receiver_id, entity } = data.message;
                    const userId = ((sender_id !== profile.id && sender_id) || (receiver_id !== profile.id && receiver_id));
                    const contact = this.props.contacts.data.find(contact => contact.id === userId);

                    if (contact) {
                      updateContact(userId, data.message);
                      if (!contact.is_match) {
                        api.getContactsInfo(userId)
                          .then(contact => {
                            updateContactsInfo(userId, { is_match: contact.is_match });
                          });
                      }
                    } else {
                      api.getContactsInfo(userId)
                        .then(contact => createNewContact(contact));
                    }

                    if (sender_id !== profile.id) {
                      const contact = getContactById(this.props.contacts.data, sender_id);

                      if (this.props.messages.profile && this.props.messages.profile.id === sender_id) {
                        receiveMessage(data.message);
                      }

                      incrementMessagesCount();

                      if (contact && contact.is_favorite && !this.props.profile.disabled_notifications.push.includes('FAVORITE_MESSAGES')) {
                        this.notify({ contact, message: getLastMessageByType(data.message), type, entity });
                      }

                      if (contact && contact.is_favorite && !this.props.profile.disabled_notifications.sound.includes('FAVORITE_MESSAGES')) {
                        this.audio.play();
                      }

                      if (type === 'PHOTO' && !this.props.profile.disabled_notifications.sound.includes('LIKES_COUNT')) {
                        this.audio.play();
                      }

                      if (type === 'MESSAGE' && !this.props.profile.disabled_notifications.sound.includes('MESSAGES_COUNT')) {
                        this.audio.play();
                      }

                      if (type === 'LETTER' && !this.props.profile.disabled_notifications.sound.includes('LETTERS_COUNT')) {
                        this.audio.play();
                      }

                      if (contact && !contact.is_favorite) {
                        if (contact && type === 'PHOTO' && !this.props.profile.disabled_notifications.push.includes('LIKES_COUNT')) {
                          this.notify({ contact, message: getLastMessageByType(data.message) });
                        }

                        if (contact && type === 'MESSAGE' && !this.props.profile.disabled_notifications.push.includes('MESSAGES_COUNT')) {
                          this.notify({ contact, message: getLastMessageByType(data.message) });
                        }

                        if (contact && type === 'LETTER' && !this.props.profile.disabled_notifications.push.includes('LETTERS_COUNT')) {
                          this.notify({ contact, message: getLastMessageByType(data.message), entity, type });
                        }
                      }

                      if (type === 'LETTER') {
                        incrementMailCount();
                      }
                    } else if (type === 'LETTER') {
                      receiveMessage(data.message);
                    }
                  })
                  .listen('.CreditBalanceChanged', data => updateProfileInfo({ credits: data.credits }))
                  .listen('.Blocked', data => updateContactsInfo(data.profile_id, { is_blocked: true }))
                  .listen('.Unblocked', data => updateContactsInfo(data.profile_id, { is_blocked: false }))
              );
            });
          this.subscribeOnlineChannel();
        });
    }
  };


  dismissAll = () => toast.dismiss();

  getHideNotificationsButton = () => {
    return toast(<Styles.HideNotifications onClick={this.dismissAll}>Hide all</Styles.HideNotifications>, {
      closeButton: false,
      autoClose: 5000,
      onClose: () => {
        toastList.delete(this.showNotificationsButton);
        this.showNotificationsButton = false;
      }
    });
  };

  notify = ({ contact, message, entity, type }) => {
    if (!isMobile()) {
      if (!this.showNotificationsButton) {
        this.showNotificationsButton = this.getHideNotificationsButton();
        toastList.add(this.showNotificationsButton);

      } else if (!toastList.has(this.showNotificationsButton)) {
        this.showNotificationsButton = this.getHideNotificationsButton();
        toastList.add(this.showNotificationsButton);

      } else {
        toast.update(this.showNotificationsButton, {
          autoClose: 5000,
        });
      }
    }

    const onClose = () => toastList.delete(id);

    const id = toast(showMessage({ contact, message, onClose, entity, type }), {
      onClose,
    });

    toastList.add(id);
  };

  subscribeOnlineChannel = () => {
    const {
      initOnlineList,
      addUserToOnlineList,
      removeUserFromOnlineList
    } = this.props;

    LaravelEcho
      .getEcho()
      .join('online')
      .here(users => {
        initOnlineList(users);
      })
      .joining(user => {
        addUserToOnlineList(user.id);
      })
      .leaving(user => {
        removeUserFromOnlineList(user.id);
      });
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleAppClose);
  }

  handleAppClose = () => {
    if (localStorage.getItem('saveSession') !== 'on') {
      this.clearStorage();
    }
  };

  clearStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  render() {
    if (!sessionStorage.getItem('token') && !localStorage.getItem('token')) {
      api.getContacts();
    }

    return (
      <ErrorBoundary>
        <div className="App">
          <Routes/>
        </div>
      </ErrorBoundary>
    );
  }
}


export default withCookies(App);
