import styled from 'styled-components';
import React from 'react';

import * as Styles from '../styles';
import {isMobile} from '../../../utils/mobile';
import AddToFavorite from '../../../containers/AddToFavorite';

const MainInfoWrap = ({role, profileData, is_favorite, age, name, city, country, is_online, last_seen}) =>
  <MobileWrap role={role}>
    <MainInfo role={role}>
      {
        !isMobile() && (
          <AddToFavorite
            role={role}
            profile={profileData}
            isFavorite={is_favorite}
          />
        )
      }
      {
        role === 'me' && (
          <Styles.Icon>
            <i className="fas fa-gem"/>
          </Styles.Icon>
        )
      }
      <Styles.UserName title={(name && name.length > 23) ? name : ''}>
        {name && name.length > 23 ? name.slice(0, 23) + '...' : name}
      </Styles.UserName>
      <Styles.UserName>{age ? ', ' + age : ''}</Styles.UserName>
    </MainInfo>
    <Styles.Status online={!!is_online}>
      {(city && country) && (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: role === 'me' ? 'center' : 'initial'}}>
          <Styles.LocationIcon role={role}>
            <i className="fas fa-map-marker-alt"/>
          </Styles.LocationIcon>
          {city + ', ' + country}
        </div>
      )}
      {!isMobile() && role !== 'me' && !!is_online && (
        <Styles.Online online={!!is_online}>Online</Styles.Online>
      )}
      {role !== 'me' && !is_online && last_seen && (
        <LastSeen>Last seen {last_seen}</LastSeen>
      )}
    </Styles.Status>
  </MobileWrap>;

export default MainInfoWrap;

const LastSeen = styled.span`
  margin-left: 10px;
  color: #b8bfd3;
  
  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const MobileWrap = styled.div`
  ${({role}) => role !== 'me' && isMobile() && `
      display: flex;
      flex-direction: column;
      align-items: baseline;
  `}
`;

const MainInfo = styled.div`
  display: flex;
  align-items: baseline;
  
  @media (max-width: 767px) {
   	justify-content: ${props => props.role === 'me' ? 'center' : 'inherit'};
  }
`;