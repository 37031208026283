import React, {Component} from 'react';
import Switch from 'react-switch';

class SwitchButton extends Component {
  state = {checked: false};

  input = this.props.input;

  componentDidMount() {
    if (this.input) {
      this.input.onChange(0);
      
    } else if (this.props.value) {
      this.setState({checked: this.props.value});
    }
  }
  
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.value !== this.props.value) {
      this.setState({ checked: nextProps.value });
    }
  }
  
  handleChange = () => {
    if (this.input) {
      this.input.onChange(+!this.state.checked);
      
    } else if (this.props.onChange) {
      this.props.onChange(!this.state.checked);
    }

    this.setState({checked: !this.state.checked});
  };

  render() {
    return (
      <Switch
        checked={this.state.checked}
        onChange={this.handleChange}
        onHandleColor="#7ed321"
        handleDiameter={30}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={36}
        className="react-switch"
        id="material-switch"
      />
    );
  }
}

export default SwitchButton;
