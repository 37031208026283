import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { NavLink } from '../Link';
import * as Styles from './styles';
import * as Icons from '../../assets';

const Profile = ({ name, age, id, photo, is_new, is_online, is_verified }) => {
  const history = useHistory();
  const username = name.split(' ')[0];

  const handleClickChat = () => {
    history.push(/messages/ + btoa(id));
  };

  return (
    <Styles.Profile>
      <div>
        {is_new && (<Styles.New>NEW</Styles.New>)}
        <div className="photo-wrapper">
          <NavLink to={'/profile/' + btoa(id)}>
            <Styles.Photo src={photo ? photo.src : Icons.unknownUser}/>
          </NavLink>
          <button className="blue-button blue-button--start" onClick={handleClickChat}>Start chat</button>
        </div>
        <NavLink to={'/profile/' + btoa(id)}>
          <Styles.ProfilesName show={is_verified}>
            <div className="check-validate">
              <div className="check-validate-icon">
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.9617 2.3436C11.0776 2.3436 8.86811 1.61233 6.99974 0C5.13156 1.61233 2.92215 2.3436 0.0383067 2.3436C0.0383067 6.54244 -0.940689 12.5573 6.99969 15C14.9406 12.5573 13.9617 6.54249 13.9617 2.3436Z"
                    fill="#DE60AD"/>
                  <path d="M5 6.90661L6.84795 8.75456L9.60251 6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
              <div className="check-validate-popper">
                This profile is successfully validated by the lady’s documents verification
              </div>
            </div>
            {(username.length > 12 ? (username.slice(0, 12) + '...') : username.slice(0, 12)) + (age ? ', ' + age : '')}
          </Styles.ProfilesName>
          <Styles.Status online={is_online}/>
        </NavLink>
      </div>
    </Styles.Profile>
  );
};

Profile.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default Profile;
