import React, { useState } from 'react';
import styled from 'styled-components';

import withData from './withData';
import { Table } from './styles';
import { planRow } from './constants';
import Subscription from '../../containers/Subscription';
import { currencySymbols } from '../../constants';

const lastCellStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: '140px',
  paddingRight: '0'
};

const TableRow = ({ handleBuy, credits, name, isDisabled, isYour, credits_per_day, days, price, plan, currency, isHidePurchase }) => {
  const handleClick = () => {
    window.dataLayer.push({
      'event': 'date',
      'eventCategory': 'userclicks',
      'eventAction': 'button',
      'eventLabel': 'buy'
    });
    
    handleBuy(plan);
  };
  
  const lowerPriorityText = 'You have better plan now';
  const yourPlanText = 'Your Plan';
  
  return (
    <tr>
      <td>{name}</td>
      <td>{credits}</td>
      <td>{credits_per_day}</td>
      <td>{credits + (credits_per_day * days)}</td>
      <td>
        <div style={lastCellStyle}>
          {currencySymbols[currency] || currency}{price}
          <StyledButton
            title={isDisabled ? lowerPriorityText : isYour && yourPlanText}
            className="blue-button"
            onClick={handleClick}
            disabled={isDisabled || isYour}
            isHidden={isHidePurchase}
          >
            Buy
          </StyledButton>
        </div>
      </td>
    </tr>
  );
};

const SubscriptionPlansTable = ({ data, lastSubscription, isHidePurchase }) => {
  const [subscriptionPanelIsOpen, setSubscriptionPanelIsOpen] = useState(false);
  const [activeSubscriptionPlan, setActiveSubscriptionPlan] = useState(null);
  
  const toggleSubscriptionForm = plan => {
    setActiveSubscriptionPlan(subscriptionPanelIsOpen ? null : plan);
    setSubscriptionPanelIsOpen(prevState => !prevState);
  };
  
  const addToPlansDisableKeyIfNeed = plans => {
    const activeSubscription = (lastSubscription && lastSubscription.is_active) && lastSubscription;
    
    return plans.map(plan => ({
      ...plan,
      isDisabled: !(activeSubscription ? (activeSubscription.priority <= plan.priority) : true),
      isYour: plan.id === (lastSubscription && lastSubscription.plan_id)
    }));
  };
  
  const subscriptionPlans = addToPlansDisableKeyIfNeed(data);
  
  return (
    <div>
      {subscriptionPanelIsOpen && (
        <Subscription
          onClose={toggleSubscriptionForm}
          initialSubscriptionPlan={activeSubscriptionPlan}
        />
      )}
      <Table>
        <thead>
          <tr>
            {planRow.map(cell => (
              <th key={cell}>{cell}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {subscriptionPlans.map(plan => (
            <TableRow
              key={plan.id}
              plan={plan}
              isHidePurchase={isHidePurchase}
              handleBuy={toggleSubscriptionForm}
              {...plan}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const StyledButton = styled.button`
  ${({ isHidden }) => isHidden && `
    display: none;
  `}
  
  ${({ disabled }) => disabled && `
    background-color: #d4d3d3;
    cursor: default;
    box-shadow: none;
  `}
`;

export default withData(SubscriptionPlansTable);