const validate = values => {
  const errors = {};

  if (values.age_from && values.age_to && values.age_from > values.age_to) {
    errors.age_to = 'Error in age range';
  }

  return errors;
};

export default validate;