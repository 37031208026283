import React from 'react';

import {setLocalStorage} from './setLocalStorage';

const RenderInput = ({input, maxLength, onChange, required}) => {
  const handleChange = e => {
    onChange(e);
    input.onChange(e);

    setLocalStorage('text', e.target.value);
  };

  return (
    <input
      autoComplete='off'
      onChange={handleChange}
      maxLength={maxLength}
      type="text"
      {...input}
      required={required}
    />
  );
};

export default RenderInput;