import {connect} from 'react-redux';

import ContactsComponent from '../components/Sidebar/Contacts';
import {getContactsList, markMessagesAsRead} from '../actions';

const mapDispatchToProps = {
  getContactsList,
  markMessagesAsRead
};

const mapStateToProps = state => ({
  contacts: state.contacts,
  onlineUsers: state.onlineUsers,
  myId: state.profile.id,
  unreadMessages: state.unreadMessages,
  is_agency: state.profile.is_agency,
  last_subscription: state.profile.last_subscription,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactsComponent);