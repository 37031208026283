import React from 'react';
import styled from 'styled-components';

import {hexToRgba} from '../../../utils/hexToRgba';
import * as Styles from '../styles';

const normalize = str => str.charAt(0).toUpperCase() + str.slice(1).replace(/_/g, ' ').toLowerCase();

const Interests = ({interests, role, name, openModal, selects}) =>
  <Styles.Interests>
    <Styles.Title>
      My Interests
      {role === 'me' && (
        <Edit onClick={() => openModal('UserInterests')}>
          <i className='far fa-pen'/>
        </Edit>
      )}
    </Styles.Title>
    <Wrap>
      {
        (interests && selects.interests) ? (
          interests.slice(0, 6).map(interest => (
            <Interest
              key={interest}
              color={selects.interests[interest].color}
              backgroundColor={hexToRgba(selects.interests[interest].color, .2)}
            >
              <Icon>
                <i className={'fas ' + selects.interests[interest].icon} />
              </Icon>
              {normalize(interest)}
            </Interest>
          ))
        ) : role !== 'me' && (
          <span>Ask {name} about his interests</span>
        )
      }
    </Wrap>
  </Styles.Interests>;

export default Interests;

const Icon = styled.div`
  font-size: .875rem;
  margin-right: .25rem;
`;

const Edit = styled.div`
	font-size: 12px;
  color: #3b5a9a;
  margin-left: 3px;
  cursor: pointer;
`;

const Wrap = styled.div`
  @media (max-width: 767px) {
    font-size: .875rem;
    color: #4b4c59;
  }

  display: flex;
  margin: -.1875rem;
  flex-wrap: wrap;
`;

const Interest = styled.span`
  @media (max-width: 767px) {
    margin: 0 .3125rem .625rem 0;
  }
  
  font-size: .75rem;
  display: inline-flex;
  flex-shrink: 0;
  margin: .1875rem;
  padding: .5rem .9375rem .375rem;
  user-select: none;
  color: ${props => props.color};
  border-radius: .90625rem;
  background-color: ${props => props.backgroundColor};
  cursor: pointer;
`;

