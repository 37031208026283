
export function eventTransaction({ price, id, name, category }) {
  window.dataLayer.push({
    transactionId: new Date().getTime(),
    transactionTotal: price,
    transactionProducts: [
      {
        sku: id,
        quantity: 1,
        name,
        category,
        price,
      }
    ],
    event: 'orderCompleted'
  });
}