export function encodeBase64(base64) {
  if (isBase64(base64)) {
    return atob(base64);
  }

  return isBase64(base64);
}

export function isBase64(str) {
  return /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/.test(str);
}