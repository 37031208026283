import React, { Component } from 'react';
import styled from 'styled-components';
import { reduxForm } from 'redux-form';

import { Event } from '../../Tracking';

import NewCard from './newCard';
import SecureForm from './SecureForm';
import StatusCode from '../StatusCode';
import Select from '../Select';

import { GetCardType } from '../../utils/GetCardType';
import { btn } from '../styles';
import validate from './validate';

import * as api from '../../api';

class CardsPayment extends Component {
  state = {
    loading: false,
    cardFormIsOpen: false,
    secureFormIsOpen: false,
    form: null,
    error: '',
  };
  
  componentDidMount() {
    api.getCardsList()
      .then(cards => {
        this.props.getCardsList(cards);
        
        if (cards.length === 0) {
          this.setState({ cardFormIsOpen: true });
        }
      });
  }
  
  UNSAFE_componentWillMount() {
    this.AddCard = reduxForm({
      form: this.props.formName,
      validate
    })(NewCard);
  }
  
  onSubmit = values => {
    Event('userclicks', 'button', 'save');
  
    if (!this.state.loading) {
      this.setState({ loading: true });
  
      api.addCard({
        additionalHeaders: {
          'color-depth': window.screen.colorDepth,
          'screen-height': window.screen.availHeight,
          'screen-width': window.screen.availWidth,
          'window-height': window.outerHeight,
          'window-width': window.outerWidth,
          'timezone': new Date().getTimezoneOffset(),
          'java-enabled': true
        },
        body: Object.assign({}, values, {
          expiration_year: '20' + values.expiration_year,
          expiration_month: +values.expiration_month,
          redirect_url: window.location.href,
          pan: values.pan.slice(0, 19)
        }),
      })
        .then(response => {
          this.setState({ loading: false });
      
          if (response.status === 422) {
            return response.json();
        
          } else if (response.form3d) {
            this.setState({ form: response });
            this.toggleSecureForm();
            
          } else {
            const data = response.data;
            let newCard = {};
  
            window.dataLayer.push({
              'event': 'date',
              'eventCategory': 'send',
              'eventAction': 'form',
              'eventLabel': 'add_card'
            });
            
            if (data) {
              newCard = {
                label: data.pan,
                value: data.id.toString(),
                icon: GetCardType(data.pan).icon,
                id: data.id
              };
            }
            
            this.toggleCardForm();
            
            if (!this.props.cards.data.find(card => card.id === newCard.id)) {
              this.props.addNewCard(newCard);
              this.setState({ error: '' });
            }
          }
        })
        .then(body => {
          if (body && body.errors) {
            const errorMessage = Object.keys(body.errors).map(error => body.errors[error][0])[0];
            
            this.props.setError ? this.props.setError(errorMessage) : this.setState({ error: errorMessage });
          }
        });
    }
  };
  
  toggleCardForm = () => {
    if (!this.state.cardFormIsOpen) {
      Event('userclicks', 'button', 'Addnewcard');
    }
    this.props.toggleCardForm && this.props.toggleCardForm();
    this.setState(prevState => ({ cardFormIsOpen: !prevState.cardFormIsOpen }));
  };
  
  messageClose = () => this.setState({ error: '' });
  
  onSelectCard = card => {
    this.props.onSelectCard && this.props.onSelectCard(card);
    
    api.updateProfile({ body: { default_card_id: card.id } })
      .then(() => this.props.updateProfileInfo({ default_card: card }));
  };
  
  toggleSecureForm = () => {
    Event('userclicks', 'button', 'save');
    this.setState(prevState => ({ secureFormIsOpen: !prevState.secureFormIsOpen }));
  };
  
  render() {
    const AddCard = this.AddCard;
    const { cardFormIsOpen, error, secureFormIsOpen, form, loading } = this.state;
    const { cardsListAvailable = true, cards, profile: { default_card } } = this.props;
    
    let defaultCard = default_card && (
      Object.assign({}, default_card, {
        label: default_card.pan,
        icon: GetCardType(default_card.pan).icon,
        value: default_card.id.toString(),
        ...default_card
      })
    );
    
    if (!defaultCard && cards.data.length) {
      this.onSelectCard(cards.data[0]);
    }
    
    return (
      <>
        {
          cardsListAvailable && Boolean(cards.data.length) && (
            <SelectWrap>
              <Select
                options={cards.data}
                onChange={this.onSelectCard}
                defaultOption={defaultCard}
                placeholder="Not chosen"
              />
            </SelectWrap>
          )
        }
        {
          cardFormIsOpen ? (
            <AddCard
              renderPurchaseButton={this.props.renderPurchaseButton}
              isFirstSubscribe={this.props.isFirstSubscribe}
              loading={loading}
              message={error}
              onSubmit={this.onSubmit}
              onClose={this.toggleCardForm}
            />
          ) : (<AddNewCard as='button' onClick={this.toggleCardForm}>Add new card</AddNewCard>)
        }
        {
          error && (
            <StatusCode
              status='error'
              onClose={this.messageClose}
              image={require('../../assets/icons/decline-ico.svg')}
            >
              <p>{error}</p>
            </StatusCode>
          )
        }
        {secureFormIsOpen && (<SecureForm isOpen={secureFormIsOpen} onClose={this.toggleSecureForm} form={form}/>)}
      </>
    );
  }
}

export default CardsPayment;

const AddNewCard = styled(btn)`
  max-width: 16.5625rem;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  min-width: 100px;
  color: #3b5a9a;
  border-color: #3b5a9a;
  background-color: #fff;
  margin: .5rem 0;

	:focus {
    background-color: rgba(221, 44, 87, .1);
	}
	
	:hover {
    background-color: rgba(221, 44, 87, .1);		
	}
`;

const SelectWrap = styled.div`
  margin-bottom: 1.125rem;
  position: relative;
  display: inline-block;
  color: #515151;
  border-radius: .125rem;
  background-color: #fff;
  max-width: 16.5625rem;
  width: 100%;
  height: 30px;
  
  li {
    letter-spacing: .5px;
    padding: 1.3125rem 1.5625rem 1.3125rem .6125rem;
  }
  
  div {
    width: 100%;
  
    i {
      right: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

	:hover {
    cursor: pointer;
	}
`;
