import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { closeVerifyEmailModal } from '../../actions';

import { Modal, ModalContent } from '../Modal';

const VerifyEmailMessage = ({ message, closeVerifyEmailModal }) => {
  const [show, setShow] = useState(false);
  
  useEffect(() => {
    if (message) {
      setShow(true);
    }
  }, [message]);
  
  const handleClose = () => {
    setShow(false);
    closeVerifyEmailModal();
  };
  
  return (
    <Modal isOpen={show}>
      <ModalContent>
        <div className="modal-wrapper modal-wrapper--bonus">
          {message}
          <div className="modal-button-wrapper">
            <button className="modal-button modal-button--thank" onClick={handleClose}>Got it!</button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = ({ modals }) => ({ ...modals });

const mapDispatchToProps = { closeVerifyEmailModal };

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailMessage);