import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import UnknownUserImage from '../../../../assets/unknown_user.jpeg';
import Icon from './icon';

export default function Profile({ age, id, name, photo, isOnline }) {
  const history = useHistory();
  
  const handleChatClick = () => {
    history.push(`/messages/${btoa(id)}`);
  };
  
  return (
    <li className="profile-catalog-list__item">
      <div className="profile-catalog-photo-wrapper">
        <NavLink to={`/profile/${btoa(id)}`}>
          <img
            src={photo ? photo.src : UnknownUserImage}
            alt="img"
          />
        </NavLink>
        <button className="blue-button blue-button--start" onClick={handleChatClick}>
          Start chat
        </button>
      </div>
      <NavLink
        to={`/profile/${btoa(id)}`}
        className="profile-catalog-status-wrapper"
      >
        <span className="profile-catalog-status">
          <div className="check-validate">
            <Icon/>
            <div className="check-validate-popper">
              This profile is successfully validated by the lady’s documents verification
            </div>
          </div>
          {name} {age ? `, ${age}` : ''}
        </span>
        {isOnline ? <span className="profile-catalog-status-online"/> : (<div />)}
      </NavLink>
    </li>
  );
}