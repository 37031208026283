import React from 'react';
import styled from 'styled-components';

const NoMatchFound = () => (
  <Container>
    <h1>404</h1>
    <span>No match found </span>
  </Container>
);

export default NoMatchFound;

const Container = styled.div`
  color: #aeaeb0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 5rem;
  
  @media (max-width: 767px) {
    
  }
  
  h1 {
    text-align: center;
    font-size: 9rem;
    margin: 0;
  }
  
  span {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
  }
`;