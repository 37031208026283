import styled from 'styled-components';

export const HeaderContainer = styled.div`
  box-shadow: 0 2px 4px rgba(184, 191, 211, .2);
  background-color: #fff;
  
  @media (max-width: 767px) {
    display: none
  }
`;

export const Logo = styled.div`
	display: block;
	width: 10rem;
	padding-top: 8px;
	
	img {
	  width: 100%;
	  height: 100%;
	}
`;

export const HeaderSearchSettings = styled.div`
  display: flex;
  align-items: center;
  border: 0;
  padding: 0;
  background: 0;
  flex-shrink: 0;
  
  .dot {
    margin-left: 5px;    
    color: #4b4c59;
  }
  
  &:hover {
    cursor: pointer;
  }
  
  &:focus {
    outline: 0;
  }
  
	span { 
	  font-size: 1.15rem;
	  flex-shrink: 0;
	  font-weight: 600;
	  color: ${props => props.color ? props.color : '#4b4c59'};
  }
`;

export const SearchIcon = styled.div`
	i {
		margin-right: 10px;
    color: #3b5a9a;
    font-size: 18px;
	}
`;

export const Wrapper = styled.div`
  display: flex;
  height: 4rem;
  align-items: center;
  max-width: 71.25rem;
  margin: 0 auto;
  padding-right: .625rem;
  padding-left: .625rem;
`;