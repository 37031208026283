import React, { useEffect } from 'react';

import Message from './message';
import * as Styles from '../../styles';

export default function Messages({ contact, fetchMessage, messages, openEmail, profile, profileId, refs, initErrorMessages, removeErrorMessage, removeErrorMail, mails, resendLetter }) {
  useEffect(() => {
    initErrorMessages(profileId);
  }, []);
  
  if (messages.data.length === 0 && Boolean(messages.errorMessages) && messages.errorMessages.length === 0) {
    return (
      <Styles.Placeholder>
        <span>You have no messages yet, send first! :)</span>
      </Styles.Placeholder>
    );
  }
  
  const errorMessages = [
    ...(Boolean(mails && mails.length) ? mails.filter(mail => mail.receiver_id === Number(profileId)) : []),
    ...(Boolean(messages.errorMessages && messages.errorMessages.length) ? messages.errorMessages : [])
  ].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  
  
  
  return (
    <>
      {messages.data.map((message, i) => (
        <Message
          key={message.id}
          refs={refs}
          onClick={openEmail}
          index={i}
          author={message.sender_id === profile.id ? 'me' : contact.name}
          message={message}
        />
      ))}
      
      {Boolean(errorMessages && errorMessages.length) && errorMessages.map(message => (
        <Message
          key={message.created_at}
          refs={refs}
          removeErrorMessage={message.type === 'MESSAGE' ? removeErrorMessage : removeErrorMail}
          fetchMessage={fetchMessage}
          sendLetter={resendLetter}
          onClick={openEmail}
          message={message}
          author='me'
          isError
        />
      ))}
    </>
  );
}