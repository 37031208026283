import React, { createRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Event } from '../../../Tracking';

import { NavLink } from '../../Link';
import AddToFavorite from '../../../containers/AddToFavorite';
import { isMobile } from '../../../utils/mobile';
import MainInfoWrap from './MainInfo';
import DropDownMenu from '../../DropDownMenu';
import Photo from '../Photo';
import getCroppedImg from '../Photo/Crop';
import * as api from '../../../api';
import { updateProfile, uploadPhoto } from '../../../api';
import * as Icons from '../../../assets';
import * as Styles from '../styles';

const Header = ({ role, isOnline, match, updateProfileInfo, myProfile, profileData, ...props }) => {
  const [statusTextIsFull, setStatusText] = useState(false);
  const [photoIsOpen, togglePhoto] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [errorPhoto, setErrorPhoto] = useState(null);
  const [messageText, setMessageText] = useState('');

  const history = useHistory();

  const photoUpload = createRef(null);

  const clearHistoryState = () => {
    if (history.location.state && history.location.state.editPhoto) {
      let state = { ...history.location.state };

      delete state.editPhoto;

      history.replace({ ...history.location, state });
    }
  };

  useEffect(() => {
    if (history.location.state && history.location.state.editPhoto && !isMobile()) {
      togglePhoto(true);
      clearHistoryState();
    }
  });

  const startMessage = () => {
    Event('send', 'form', 'startchat');

    if (messageText) {
      api.sendMessage({ body: { text: messageText }, id: atob(match.params.id) })
        .then(response => {
          if (response.data) {
            history.push('/messages/' + match.params.id);

          } else if (!(myProfile.last_subscription && myProfile.last_subscription.is_active)) {
            props.toggleSubscriptionPanel();

          } else {
            props.toggleRefillCreditsPanel();
          }
        });

    } else {
      history.push('/messages/' + match.params.id);
    }
  };

  const uploadFile = (src, cropped) => {
    const form = new FormData();

    setUploading(true);

    setErrorPhoto(null);

    getCroppedImg(src, cropped).then(response => {
      form.append('file', response.file);
      uploadPhoto({ body: form })
        .then(response => {
          if (response && response.status === 422) {
            return response.json();
          } else {
            updateProfile({ body: { photo_id: response.data.id } })
              .then(response => {
                setUploading(false);
                updateProfileInfo({ photo: response.data.photo });
              });

            onClose();
          }
        })
        .then(response => {
          if (response && response.errors) {
            const errorMessage = Object.keys(response.errors).map(error => response.errors[error][0])[0];

            setErrorPhoto(errorMessage);
          }
        });
    });
  };


  const getDropDownOptions = () => {
    return [
      //   {
      //     name: 'Report',
      //     icon: 'fal fa-flag-alt',
      //   },
      {
        name: 'Block',
        icon: 'fal fa-times',
        callback: () => {
          api.addUserToBlacklist({ body: { profile_id: atob(match.params.id) } });
          history.push('/me');
        }
      },
    ];
  };

  const updatePhoto = event => {
    setCurrentPhoto(event.target.files[0]);
    togglePhoto(true);
  };

  const onClose = () => {
    togglePhoto(photoIsOpen => !photoIsOpen);
    setCurrentPhoto(null);
  };

  const toggleStatusText = () => setStatusText(statusTextIsFull => !statusTextIsFull);

  const typeMessage = e => setMessageText(e.target.value);

  const goBack = () => history.goBack();

  const { photo, about, is_favorite, last_subscription, is_agency } = profileData;
  const statusText = about && (about.length >= 150 ? (statusTextIsFull ? about.slice(0, 300) : about.slice(0, 150)) : about);

  return (
    <>
      <Styles.Container>
        <Wrap role={role}>
          <PictureContainer role={role}>
            <Styles.Photo src={photo ? photo.src : Icons.unknownUser} uploading={uploading}/>
            <Styles.Loader src={Icons.loader} style={{ display: uploading ? 'block' : 'none' }}/>
            {
              role === 'me' && (
                isMobile() ? (
                  <UpdateWrap>
                    <input onChange={updatePhoto} type='file' id='profile-photo' ref={photoUpload}/>
                    <Styles.UpdatePhoto as='label' htmlFor='profile-photo'/>
                  </UpdateWrap>
                ) : (
                  <UpdateWrap onClick={() => togglePhoto(true)}>
                    <Styles.UpdatePhoto>Update</Styles.UpdatePhoto>
                  </UpdateWrap>
                )
              )
            }
            {role !== 'me' && isMobile() && (<OnlineStatus online={isOnline}/>)}
          </PictureContainer>
          {isMobile() && role !== 'me' && (
            <MainInfoWrap
              {...profileData}
              is_online={isOnline}
              profileData={profileData}
              role={role}
            />
          )}
        </Wrap>
        <Styles.UserInfo>
          {
            (role === 'me' || !isMobile()) && (
              <MainInfoWrap
                {...profileData}
                is_online={isOnline}
                profileData={profileData}
                role={role}
              />
            )
          }
          <Styles.About>
            {statusText}
            {statusText && (statusText.length >= 150) && (
              <Styles.ShowMore onClick={toggleStatusText}>
                {statusTextIsFull ? ' Show less' : ' ...Continue reading'}
              </Styles.ShowMore>
            )}
          </Styles.About>
          <Styles.ButtonContainer role={role}>
            {
              role === 'me'
                ? (
                  <>
                    <Styles.EditProfile onClick={() => props.openModal('UserDetails')}>
                      <Styles.BtnIcon>
                        <i className='far fa-pen'/>
                        EDIT PROFILE
                      </Styles.BtnIcon>
                    </Styles.EditProfile>
                    {
                      !is_agency && (
                        (last_subscription && last_subscription.is_active) ? (
                          <Styles.RefillCredits onClick={props.toggleRefillCreditsPanel}>
                            <Styles.BtnIcon>
                              <i className='fas fa-coins'/>
                              REFILL CREDITS
                            </Styles.BtnIcon>
                          </Styles.RefillCredits>
                        ) : (
                          !profileData.credits
                            ?  <Styles.Upgrade onClick={props.toggleSubscriptionPanel}>upgrade account</Styles.Upgrade>
                            : null
                        )
                      )
                    }
                  </>
                ) : (
                  <>
                    <Styles.InputWrap>
                      <Styles.ChatInput
                        value={messageText}
                        onChange={typeMessage}
                        type='text'
                        placeholder='Type your message'
                      />
                      <Styles.StartChat onClick={startMessage}>Start chat</Styles.StartChat>
                    </Styles.InputWrap>
                    <Styles.SendEmail onClick={props.toggleEmailForm}>Send mail</Styles.SendEmail>
                  </>
                )
            }
          </Styles.ButtonContainer>
        </Styles.UserInfo>
        {
          isMobile() ? (
            <Styles.ControlsMob role={role}>
              {role === 'me' ? (
                <>
                  <span onClick={props.toggleSubscriptionPanel}/>
                  <NavLink to={'/settings'}>
                    <i className='fas fa-cog'/>
                  </NavLink>
                </>
              ) : (
                <>
                  <AddToFavorite
                    role={role}
                    isFavorite={is_favorite}
                    profile={profileData}
                  />
                  <DropDownMenu options={getDropDownOptions()}/>
                </>
              )}
            </Styles.ControlsMob>
          ) : (
            <ControlsDesktop>
              {role !== 'me' && (<DropDownMenu options={getDropDownOptions()}/>)}
              <Styles.Close onClick={goBack}><i className='fal fa-times'/></Styles.Close>
            </ControlsDesktop>
          )
        }
      </Styles.Container>
      {
        photoIsOpen && (
          <Photo
            error={errorPhoto}
            isOpen={photoIsOpen}
            photo={currentPhoto}
            onClose={onClose}
            uploadFile={uploadFile}
          />
        )
      }
    </>
  );
};

export default Header;

const OnlineStatus = styled.span`
  display: none;
  
  @media (max-width: 767px) {
    display: block;
  }
  
	${({ online }) => online && `
		background-color: #7ed321;
	  width: 1rem;
	  margin-right: 5px;
	  border-radius: 50%;
	  height: 1rem;
	  position: absolute;
	  border: 1px solid #fff;
	  bottom: 0px;
	  right: 0;
	`}
`;

const ControlsDesktop = styled.div`
  right: 11px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  
  div {
    cursor: pointer;
  }
`;

export const UpdateWrap = styled.div`
	position: absolute;
  transform: translate(-50%, 0%);
  background: rgb(56, 56, 56);
  background: rgba(0, 0, 0, 0.5);
	width: 10.4em;
  height: 4.6em;
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
  left: 50%;
  bottom: 0;
  opacity: 0;
  transition: opacity .5s;
  cursor: pointer;
  
  @media (max-width: 767px) {
    opacity: 1 !important;
    width: 5.6em;
    height: 2.6em;
    background: inherit;
    input {
      width: 100%;
      visibility: hidden;
    }
  }
`;

const PictureContainer = styled.div`
	position: relative;
  width: 9.4375rem;
  height: 9.4375rem;
  margin-right: .9375rem;
  flex-shrink: 0;
  
	:hover ${UpdateWrap} {
		opacity: 1;
	}
  
  @media (max-width: 767px) {
  	margin: ${props => props.role === 'me' ? '0 auto' : '0 15px 0 0'};
    position: relative;
    width: 4.5rem;
    height: 4.5rem;
  }
`;

const Wrap = styled.div`
  ${({ role }) => role !== 'me' && isMobile() && `
      display: flex;
      align-items: center;
  `}
`;
