import {connect} from 'react-redux';

import BuyAlbumComponent from '../components/Album/BuyAlbum';
import {updateAlbum} from '../actions';

const mapStateToProps = state => ({
  error: state.album.error,
  album: state.album,
  profile: state.user
});

const mapDispatchToProps = {
  updateAlbum
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyAlbumComponent);