import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { NavLink } from '../../Link';
import * as Styles from '../styles';
import AddToFavorite from '../../../containers/AddToFavorite';
import { unknownUser } from '../../../assets';

const Header = ({ sender: { photo, name, age, id, is_favorite }, sender, onClose, sent_at, role }) => {
  return (
    <HeaderContainer>
      <NavLink style={style} to={'/profile/' + btoa(id)}>
        <Title>
          <Styles.PhotoContainer>
            <Styles.Photo src={photo ? photo.src : unknownUser} alt=""/>
          </Styles.PhotoContainer>
          <Author>
            <div>
              <h2>{name + (age ? (', ' + age) : '')}</h2>
              {
                role !== 'me' && (
                  <Star>
                    <AddToFavorite
                      role={role}
                      isFavorite={is_favorite}
                      profile={sender}
                    />
                  </Star>
                )
              }
            </div>
            <p>
              {
                moment().diff(moment(sent_at), 'days') < 7 ? (
                  moment(sent_at).format('dddd') + ' ' + moment(sent_at).format('hh:mm:ss')
                ) : (sent_at)
              }
            </p>
          </Author>
        </Title>
      </NavLink>
      <ControlsBar>
        {/*{role !== 'me' && (<DropDownMenu options={getDropDownOptions()}/>)}*/}
        <Close onClick={onClose}>
          <i className='fal fa-times'/>
        </Close>
      </ControlsBar>
    </HeaderContainer>
  );
};

export default Header;

const style = {
  textDecoration: 'none',
  width: '100%'
};

export const Close = styled.span`
  background: 0;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  color: #b8bfd3;
  cursor: pointer;
  
  :hover {
    color: #4b4c59;
  }
`;

const ControlsBar = styled.div`
  right: 11px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  
  div {
    cursor: pointer;
  }
`;

const Star = styled.div`
	margin-left: 6px !important ;
`;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 12px;
	border-bottom: .0625rem solid #dadee8;
	align-items: end;
	position: relative;
`;

const Title = styled.div`
	display: flex;
	align-items: center;
`;

const Author = styled.div`
	div { 
		display: flex;
    align-items: end;
    font-weight: bold;
    color: #4d4d4d;
    font-size: .95rem;
    
    h2 {
      font-size: .9rem;
			margin: 0;
		}
	}
	
	p {
		color: #555;
    font-size: .6125rem;
    margin: 5px 0;
	}
	
	button {
	  padding: 0;
	  font-size: .875rem;
	}
`;