import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { getQueryString } from '../../utils/getQueryString';
import Mail from './Mail';
import { loader } from '../../assets';
import * as Styles from './styles';
import { Event } from '../../Tracking';
import { sendEmailToUser } from '../../api';

const Mails = ({ mailbox, loadMoreMails, query, deleteLetter, type, removeErrorMail, initErrorMails, addErrorMail, onSendMail, myProfile }) => {
  const inbox = useRef();
  
  useEffect(() => {
    initErrorMails();
  }, []);
  
  const onScroll = () => {
    const { fetching, links } = mailbox;
    const { scrollTop, clientHeight, scrollHeight } = inbox.current;
    const page = links.next ? links.next.split('?page=')[1] : null;
    const Height = scrollHeight - clientHeight;
    
    if ((Height - (Height / 10) <= scrollTop) && page && !fetching) {
      loadMoreMails(page, getQueryString(query));
    }
  };
  
  const resendLetter = letter => {
    Event('send', 'form', 'sendmail');
    
    let urlParams = {
      receiver_id: letter.receiver_id,
      sent_at: new Date().toJSON(),
      subject: letter.subject,
      ...letter,
    };
    
    sendEmailToUser({ body: urlParams }).then(response => {
      if (response.status >= 400) {
        addErrorMail(letter);
      }
  
      if (response.data) {
        onSendMail(response.data);
      }
      
      return response;
    });
  };
  
  return (
    <Styles.Mails
      onScroll={onScroll}
      ref={inbox}
    >
      <DialogList>
        {mailbox.loading && (
          <LoaderWrap>
            <img src={loader} alt='loader'/>
          </LoaderWrap>
        )}
        {type === 'sent' && Boolean(mailbox.errorEmails && mailbox.errorEmails.length) && mailbox.errorEmails.map(letter => (
          <Mail
            type={type}
            key={letter.id}
            resendLetter={resendLetter}
            removeErrorsLetter={removeErrorMail}
            deleteLetter={deleteLetter}
            letter={letter}
            isError
          />
        ))}
        
        {Boolean(mailbox.data.length) && !mailbox.loading && mailbox.data.filter(mail => type !== 'sent' ? mail.receiver.id === myProfile.id : true).map(letter => (
          <Mail
            type={type}
            key={letter.id}
            deleteLetter={deleteLetter}
            letter={letter}
          />
        ))}
        
        {!mailbox.loading && !mailbox.data.length && (
          <Placeholder>
            <span>You have no messages yet, send first! :)</span>
          </Placeholder>
        )}
      </DialogList>
    </Styles.Mails>
  );
};

export default Mails;

const Placeholder = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: .875rem;
  color: #b8bfd3;
  margin-top: 2.5rem;

  @media (max-width: 767px) {
    margin-top: 4.5rem;
  }
`;

const LoaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
  
  img {
    width: 40px;
  }
  
  @media (max-width: 767px) {
    margin-top: 4.5rem;
  }
`;

const DialogList = styled.ul`
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0 0 1.25rem;
	list-style: none;
  
	@media (max-width: 767px) {
    padding-bottom: 7.5rem;
  }
`;

