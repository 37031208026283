import React from 'react';
import styled from 'styled-components';
import withModalClose from './HOC/withModalClose';

import {Modal, ModalContent} from './Modal';
import {btn} from './styles';

const ConfirmWindow = ({onClose, callback, isOpen, children, innerRef}) => {
  const onCloseModal = () => onClose();

  const onConfirm = () => {
    callback();
  };

  return (
    <Modal isOpen={isOpen}>
      <Wrapper ref={innerRef}>
        <Content>
          <h4>Warning</h4>
          {children}
        </Content>
        <BtnWrap>
          <Button Close onClick={onCloseModal}>NO</Button>
          <Button onClick={onConfirm}>YES</Button>
        </BtnWrap>
        <Close onClick={onCloseModal}><i className='fal fa-times'/></Close>
      </Wrapper>
    </Modal>
  );
};

export default withModalClose(ConfirmWindow);

const Close = styled.span`
  position: absolute;
  right: .8rem;
  top: .8rem;
  cursor: pointer;
  background: 0;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  color: #b8bfd3;
  
  :hover {
    color: #4b4c59;
  }
`;

const Content = styled.div`
  @media (max-width: 480px) {
    margin-top: 20px;
  }
  
  h4 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 600;
    color: #4b4c59;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }
  
  margin-top: 25px;
  position: relative;
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  
  @media (max-width: 767px) {
    justify-content: center;
    
    button {
      min-width: 8rem;
    }
  }
  
  button {
    :first-of-type {
      margin-left: 0;
      width: auto;
      margin-right: 20px;
    }
  }
`;

const Button = styled(btn)`
  letter-spacing: 2px;
  min-width: 120px;
  
  ${({Close}) => Close && `
    line-height: 1.875rem;
    min-width: 8rem;
    height: 1.875rem;
    
    @media (max-width: 480px) {
      width: calc(48%);
      letter-spacing: .05rem;
    }
    
    margin-left: auto;
    margin-right: 20px;
    background-color: #fff;    
    color: #b8bfd3;
    border-color: #b8bfd3;
    
    :focus {
      background-color: rgba(221, 44, 87, .1);
    }
    
    :hover {
      background-color: rgba(221, 44, 87, .1);
    }
    
    @media (max-width: 480px) {
      margin: 0;
      min-width: 0;
    }
    
    @media (max-width: 319px) {
      min-width: 0;
    }
  `}
`;

const Wrapper = styled(ModalContent)`
  max-width: 460px;
  padding: 20px;
  
  @media (max-width: 480px) {
    height: auto !important;
    margin: .9375rem;
    align-self: flex-start;
  }
`;