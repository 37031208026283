import React from 'react';

export default Component => {
  return class extends React.Component {
    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = event => {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.props.onClose && this.props.onClose();
      }
    };

    setWrapperRef = node => {
      this.wrapperRef = node;
    };

    render() {
      return (
        <Component {...this.props} innerRef={this.setWrapperRef}/>
      );
    }
  };
};

