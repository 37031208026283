import React, {useState} from 'react';
import styled from 'styled-components';

import * as Styles from '../styles';
import Header from './Header';
import Body from './Body';
import {btn} from '../../styles';
import PhotoView from '../../PhotoView';
import {Modal} from '../../Modal';

const Wrapper = ({isModalContent, isModalPhoto, children}) => {
  if (isModalContent) {
    return (
      <Modal isOpen={isModalContent} styles={customStyles}>
        <Styles.LettersBody isModalPhoto={isModalPhoto}>
          {children}
        </Styles.LettersBody>
      </Modal>
    );
  }

  return (
    <Styles.LettersBody isModalPhoto={isModalPhoto}>
      {children}
    </Styles.LettersBody>
  );
};

const LettersBody = ({onClose, letter, myId, replyMessage, isModalPhoto, isModalContent}) => {
  const [photoIsOpen, togglePhoto] = useState(false);

  const togglePhotoView = () => togglePhoto(photoIsOpen => !photoIsOpen);

  return (
    <>
      {
        photoIsOpen ? (
          <PhotoView
            src={letter.photo && letter.photo.src}
            isOpen={photoIsOpen}
            onClose={togglePhotoView}
            isModal={isModalPhoto}
          />
        ) : (
          <Wrapper isModalPhoto={isModalPhoto} isModalContent={isModalContent}>
            <Header
              role={myId === letter.sender.id ? 'me' : 'another'}
              sent_at={letter.sent_at}
              sender={letter.sender.id === myId ? letter.receiver : letter.sender}
              onClose={onClose}
            />
            <Body {...letter} sender={letter.sender} openPhoto={togglePhotoView}/>
            {
              myId !== letter.sender.id && (
                <Styles.ButtonContainer>
                  <Button onClick={replyMessage}>REPLY</Button>
                </Styles.ButtonContainer>
              )
            }
          </Wrapper>
        )
      }
    </>
  );
};

export default LettersBody;

const customStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    maxHeight: '100vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '100%',
    border: 0,
    width: '100%',
    padding: 0,
    marginRight: '-50%',
    overflow: 'inherit',
    transform: 'translate(-50%, -50%)',
    background: 'transparent'
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, .4)'
  }
};

const Button = styled(btn)`
  margin-top: 1.25rem;
  max-width: 10.75rem;
  display: block;
  margin-left: auto;
  margin-right: 0;
  
  @media (max-width: 480px) {
    min-width: 7rem;
  }
`;