import React from 'react';
import styled from 'styled-components';

import * as Styles from '../styles';
import {detailInfo} from '../../../constants';

//TODO: Change keys for select on their own name, that comes from BE

const normalize = value => {
  if (typeof value === 'string') {
    return value.charAt(0).toUpperCase() + value.slice(1).replace(/_/, ' ').toLowerCase();
  } else if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
};

const PersonalData = ({role, profileData, openModal}) =>
  <Styles.PersonalDetails>
    <Styles.Title>
      About Me {role === 'me' && (
        <Edit onClick={() => openModal('PersonalDetails')}>
          <i className='far fa-pen'/>
        </Edit>
      )}
    </Styles.Title>
    <InfoContent>
      {
        Object.keys(detailInfo).filter(key => !!profileData[key]).map(key => (
          <Styles.Info key={key}>
            <span>{normalize(key)}</span>: <span style={{fontWeight: 600}}>{profileData[key] ? normalize(profileData[key]) : 'No Answer'}</span>
          </Styles.Info>
        ))
      }
    </InfoContent>
  </Styles.PersonalDetails>;

export default PersonalData;

const InfoContent = styled.div`
  @media (max-width: 767px)
    font-size: .875rem;
    color: #4b4c59;
  }
`;

const Edit = styled.div`
	font-size: 12px;
  color: #3b5a9a;
  margin-left: 3px;
  cursor: pointer;
`;
