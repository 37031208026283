import {withSuccessStatus, withFailureStatus} from '../utils/withData';
import * as TYPES from '../constants/types';
import {load} from '../utils/loadFromUrl';

const initialState = {
  error: null,
  loading: false,
  isLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case TYPES.FETCH_PROFILE_REQUEST:
    return {...state, loading: true, error: null};
  case TYPES.FETCH_PROFILE_SUCCESS: {
    const {name, email, id} = action.profile;

    if (process.env.REACT_APP_ZENDESK_KEY) {
      load({
        src: 'https://static.zdassets.com/ekr/snippet.js?key=' + process.env.REACT_APP_ZENDESK_KEY,
        id: 'ze-snippet'
      })
        .then(() => {
          load({
            innerHTML: 'zE(function() { \n' +
                '   zE.identify({ \n' +
                `     name: '${name}', \n` +
                `     email: '${email}', \n` +
                `     id: '${id}' \n` +
                '   }); \n' +
                ' });'
          });
        });
    }

    return withSuccessStatus(state, action.profile);
  }

  case TYPES.FETCH_PROFILE_FAILURE:
    return withFailureStatus(state, {}, action.error);
  case TYPES.UPDATE_PROFILE_INFO: {
    
    return {...state, ...action.values};
  }
  default:
    return state;
  }
};

