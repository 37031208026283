import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ProfileContainer } from '../Profile/styles';
import styled from 'styled-components';

import PlansTable from './subscriptionPlansTable';
import CreditsTable from './creditPacksTable';
import PriceTable from './priceTable';
import FreeCredits from './freeCredits';
import { Loader } from '../styles';
import { loader } from '../../assets';
import * as api from '../../api';

const Pricing = ({ profile }) => {
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [packs, setPacks] = useState([]);
  
  useEffect(() => {
    setLoading(true);
    
    Promise.all([api.getSubscriptionPlans(), api.getCreditPacks()]).then(([plans, packs]) => {
      setPlans(plans.sort((a, b) => a.price > b.price ? 1 : (a.price < b.price ? -1 : 0)));
      setPacks(packs);
      setLoading(false);
    });
  }, []);
  
  if (loading) {
    return (
      <Container>
        <Loader src={loader}/>
      </Container>
    );
  }
  
  return (
    <Container>
      <h1>Pricing</h1>
      
      <h2>Subscription plans <span>for 30 days</span></h2>
      <PlansTable
        data={plans}
        lastSubscription={profile.last_subscription}
        isHidePurchase={profile.is_agency}
      />
  
      <h2>Credit packs</h2>
      <CreditsTable
        data={packs}
        isSubscribe={profile.last_subscription && profile.last_subscription.is_active}
        isHidePurchase={profile.is_agency}
      />
  
      <h2>Price of services</h2>
      <PriceTable isHidePurchase={profile.is_agency}/>
  
      <h2>Free credits</h2>
      <FreeCredits profile={profile} isHidePurchase={profile.is_agency}/>
    </Container>
  );
};

export default withRouter(Pricing);

const Container = styled(ProfileContainer)`
  min-height: 10rem;
  
  h2 {
      margin-top: 2rem;
  }
  
  h2 span {
    font-weight: normal;
  }
`;

