import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Picker } from 'emoji-mart';
import styled from 'styled-components';
import 'emoji-mart/css/emoji-mart.css';

import { btn } from '../styles';
import { getChatWithId, removeMessageFromLocalStorage, setMessageToLocalStorage } from './LocalStorage';

const GraphemeSpillter = require('grapheme-splitter');

// TODO: Handle typing

class AddMessage extends Component {
  state = {
    value: '',
    showEmoji: false
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.error !== this.props.error) {
      if (nextProps.error && nextProps.error.status === 402) {
        const message = getChatWithId(nextProps.id);

        this.setState({value: message ? message.text : ''});
      }
    }
  }

  componentDidMount() {
    const message = getChatWithId(this.props.id);

    if (message) {
      this.setState({value: message.text});
    }
  }

  showEmoji = () => {
    this.setState({
      showEmoji: true
    }, () => document.addEventListener('click', this.closeMenu));
  };

  closeMenu = e => {
    if (this.emojiPicker !== null && !this.emojiPicker.contains(e.target)) {
      this.setState({
        showEmoji: false
      }, () => document.removeEventListener('click', this.closeMenu));
    }
  };

  onChange = e => {
    const splitter = new GraphemeSpillter();
    // const channel = LaravelEcho.getEcho().private('messages.' + this.props.id);

    let value = '';

    if (e.unified) {
      value = this.state.value + this.addEmoji(e);
    } else {
      value = e.target.value;
    }

    const countPrev = splitter.countGraphemes(this.state.value);
    const countNew = splitter.countGraphemes(value);

    // setTimeout(() => {
    //   this.props.typing(this.props.id);
    // }, 300);

    if (countNew < countPrev || countNew <= 350) {
      this.setState({value});

      setMessageToLocalStorage(value, this.props.id);
    }
  };

  onSendMessage = () => {
    if (this.state.value.length) {
      this.props.sendMessage(this.state.value, this.props.id);
      this.setState({value: ''});
  
      removeMessageFromLocalStorage(this.props.id);
  
      window.dataLayer.push({
        'event': 'date',
        'eventCategory': 'Send',
        'eventAction': 'form',
        'eventLabel': 'Startchat'
      });
    }
  };

  onEnterPress = e => {
    if (e.key === 'Enter') {
      this.onSendMessage();
    }
  };

  addEmoji = e => {
    if (e.unified.length <= 5) {
      return String.fromCodePoint(`0x${e.unified}`);
    } else {
      let sym = e.unified.split('-');
      let codesArray = [];
      sym.forEach(el => codesArray.push('0x' + el));
      return String.fromCodePoint(...codesArray);
    }
  };

  render() {
    const { value } = this.state;
    const messagePriceCount = Math.ceil((value.length || 1) / 70);
  
    return (
      <AddMessageWrap>
        <div className="count-price-wrapper desktop">
          <div className="price">
            {messagePriceCount}
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.044 15.556c-2.283 0-4.198-.722-5.746-2.165C.766 11.933 0 10.062 0 7.778 0 5.493.766 3.63 2.298 2.187 3.846.729 5.761 0 8.044 0c2.5 0 4.479.992 5.932 2.976l-1.641.88c-.453-.647-1.063-1.173-1.83-1.579a5.183 5.183 0 00-2.461-.609c-1.72 0-3.158.579-4.315 1.736-1.142 1.142-1.712 2.6-1.712 4.374 0 1.773.57 3.239 1.712 4.396 1.157 1.142 2.595 1.713 4.315 1.713.875 0 1.696-.195 2.462-.586.766-.406 1.376-.94 1.829-1.6L14 12.58c-1.517 1.984-3.502 2.976-5.956 2.976z"
                fill="#DE60AD"/>
              <path
                d="M10.889 10.889H9.245l-1.32-2.21H6.876v2.21H5.444V4.667H8.59c.692 0 1.24.186 1.644.56.41.373.615.855.615 1.445 0 .51-.145.924-.434 1.241-.282.317-.628.52-1.038.606l1.512 2.37zm-2.51-3.377c.295 0 .537-.075.725-.224a.76.76 0 00.283-.616.733.733 0 00-.283-.606c-.188-.155-.43-.233-.726-.233H6.876v1.679h1.502z"
                fill="#DE60AD"/>
            </svg>
          </div>
        </div>
        <input
          placeholder='Write message here...'
          type='text'
          style={styles.input}
          autoComplete='off'
          maxLength={255}
          value={value}
          onKeyPress={this.onEnterPress}
          onChange={this.onChange}
        />
        {
          this.state.showEmoji && (
            <span style={styles.emojiPicker} ref={el => (this.emojiPicker = el)}>
              <Picker onSelect={this.onChange}/>
            </span>
          )
        }
        <Icon onClick={this.showEmoji}>
          <i className="far fa-grin"/>
        </Icon>

        <div className="count-price-wrapper">
          <div className="count">
            255
          </div>
          <div className="divider">
            /
          </div>
          <div className="price">
            {messagePriceCount}
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.044 15.556c-2.283 0-4.198-.722-5.746-2.165C.766 11.933 0 10.062 0 7.778 0 5.493.766 3.63 2.298 2.187 3.846.729 5.761 0 8.044 0c2.5 0 4.479.992 5.932 2.976l-1.641.88c-.453-.647-1.063-1.173-1.83-1.579a5.183 5.183 0 00-2.461-.609c-1.72 0-3.158.579-4.315 1.736-1.142 1.142-1.712 2.6-1.712 4.374 0 1.773.57 3.239 1.712 4.396 1.157 1.142 2.595 1.713 4.315 1.713.875 0 1.696-.195 2.462-.586.766-.406 1.376-.94 1.829-1.6L14 12.58c-1.517 1.984-3.502 2.976-5.956 2.976z"
                fill="#DE60AD"/>
              <path
                d="M10.889 10.889H9.245l-1.32-2.21H6.876v2.21H5.444V4.667H8.59c.692 0 1.24.186 1.644.56.41.373.615.855.615 1.445 0 .51-.145.924-.434 1.241-.282.317-.628.52-1.038.606l1.512 2.37zm-2.51-3.377c.295 0 .537-.075.725-.224a.76.76 0 00.283-.616.733.733 0 00-.283-.606c-.188-.155-.43-.233-.726-.233H6.876v1.679h1.502z"
                fill="#DE60AD"/>
            </svg>
          </div>
        </div>

        <Button as='button' onClick={this.onSendMessage}/>
      </AddMessageWrap>
    );
  };
}

AddMessage.propTypes = {
  sendMessage: PropTypes.func.isRequired
};

export default AddMessage;

const Button = styled(btn)`
	letter-spacing: 2px;
  height: 1.5rem;
  line-height: 1.5rem;
  
  &::before {
    content: "Send"
  }
  
  @media (max-width: 767px) {
    width: 1.625rem;
    min-width: 0;
    height: 1.5rem;
    margin-left: .9375rem;
    padding: 0;
    border: none;
    background: url(${require('../../assets/img/send-btn.svg')}) 50% no-repeat;
    background-size: contain;
    flex-shrink: 0;
    
    :focus {
      background-color: #fff !important
    }
    
    :hover {
      background-color: #fff !important
    }
    
    &::before {
      content: ''
    }
  }
`;

const Icon = styled.div`
  font-size: 1.5rem;
  margin-left: 10px;
  margin-right: 5px;
  cursor: pointer;
  display: flex;
  
  @media (max-width: 767px) {
   	margin-left: .75rem;
   	display: none;
	}
`;

const AddMessageWrap = styled.div`
  display: flex;
  padding: .625rem 1.875rem .625rem 1.25rem;
  border-top: .0625rem solid #dadee8;
  border-right: .0625rem solid #edeff4;
  align-items: center;
  
  input {
    height: 20px;
    padding: 0;  
    width: 100%;
    border: 0;
    background-color: transparent;
    color: #515151;
  
    ::-webkit-input-placeholder {
      font-size: 14px;
      color: #b8bfd3
    }
  
    ::-ms-input-placeholder {
      font-size: 14px;
      color: #b8bfd3
    }
  
    ::placeholder {
      font-size: 14px;
      color: #b8bfd3
    }
  
    :focus {
      outline: 0
    }
    
    @media (max-width: 767px) {
      height: 40px;
      padding: 0 10px;
      border: 1px solid #e8e8e8;
      background: #fff;
      border-radius: 20px;
    } 
  }
    
  @media (max-width: 767px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: .35rem .9375rem;
    border-right: none;
    background: #edeff4;
    height: 52px;
  }
`;

const styles = {
  emojiPicker: {
    position: 'absolute',
    bottom: 10,
    right: 0,
    cssFloat: 'right',
    marginLeft: '200px',
    width: 333,
    zIndex: 2,
    marginBottom: 45
  }
};

