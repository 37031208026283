import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import HeaderComponent from '../components/Header';
import {
  updateProfileInfo,
  initOnlineList,
  addUserToOnlineList,
  removeUserFromOnlineList,
  incrementMessagesCount,
  incrementMailCount
} from '../actions';

const mapDispatchToProps = {
  updateProfileInfo,
  initOnlineList,
  addUserToOnlineList,
  removeUserFromOnlineList,
  incrementMessagesCount,
  incrementMailCount
};

const mapStateToProps = ({profile, contacts, inbox}) => ({profile, contacts, inbox});

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));

export default enhance(HeaderComponent);