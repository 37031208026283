import React, { useState } from 'react';
import styled from 'styled-components';

import { Modal, ModalContent } from '../Modal';
import { btn } from '../styles';
import * as api from '../../api';
import Subscription from '../../containers/Subscription';
import withModalClose from '../HOC/withModalClose';
import { Event } from '../../Tracking';

const Unsubscribe = ({ updateProfileInfo, onClose, isOpen, innerRef, showMessage, subscription: { credits, credits_per_day, days, is_auto_renew, name } }) => {
  const [isUpgrade, setUpgrade] = useState(false);
  
  const unSubscribe = () => {
    api.unsubscribe()
      .then(last_subscription => {
        updateProfileInfo({ last_subscription });
        showMessage();
        onClose();
      });
  };
  
  const toggleSubscriptionPlan = () => {
    Event('userclicks', 'button', 'upgrade');
    setUpgrade(isUpgrade => !isUpgrade);
  };
  
  if (isUpgrade) {
    return <Subscription onClose={toggleSubscriptionPlan}/>;
  }
  
  return (
    <Modal isOpen={isOpen}>
      <PopupContent ref={innerRef}>
        <Close onClick={onClose}><i className='fal fa-times'/></Close>
        <Title>Subscription</Title>
        <Wrapper>
          <AccountInfo>
            <Plan>
              You have a <span> {name}</span> account
            </Plan>
            <AccountList>
              <li>{credits} Credits</li>
              {!!credits_per_day && (<li>+ {credits_per_day} credit(s) each day</li>)}
              <li>{credits + (credits_per_day * days)} for {days} days</li>
            </AccountList>
          </AccountInfo>
          <BtnWrap>
            {is_auto_renew && (<Button as='button' cancel onClick={unSubscribe}>Unsubscribe</Button>)}
            <Button as='button' onClick={toggleSubscriptionPlan}>Upgrade</Button>
          </BtnWrap>
        </Wrapper>
      </PopupContent>
    </Modal>
  
  );
};

export default withModalClose(Unsubscribe);

const Close = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
	color: #b8bfd3;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: opacity .33s cubic-bezier(.25, .46, .45, .94);
  z-index: 1;
  
  :hover {
    color: #4b4c59;
  }
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  
  @media (max-width: 480px) {
    justify-content: space-between;
  }
`;

const AccountList = styled.ul`
  display: flex;
  margin: 0 -.6875rem;
  padding: 0;
  list-style: none;
  
  li {
    margin: 0 .6875rem;

    @media (max-width: 480px) {
      margin: .3125rem .6875rem;
    }
  }
`;

const Plan = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: .625rem;
  color: #4b4c59;
  
  @media (max-width: 480px) {
    font-size: 1.125rem;
  }
  
  span {
    font-weight: 400;
    color: #4a90e2;
  }
`;

const AccountInfo = styled.div`
  width: 100%;
  margin-bottom: 1.75rem;

  @media (max-width: 480px) {
    margin-bottom: 1.25rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  @media (max-width: 480px) {
    justify-content: space-between;
  }
`;

const Button = styled(btn)`
  letter-spacing: .125rem;
  line-height: 1.875rem;
  min-width: 8rem;
  height: 1.875rem;

  @media (max-width: 480px) {
    width: calc(48%);
    margin: 0;
    min-width: 0;
  }
  
  ${({ cancel }) => cancel && `
    margin-right: 1.25rem;
    color: #b8bfd3;
    border: .0625rem solid #b8bfd3;
    line-height: 1.5rem;
    min-width: 6.25rem;
    background-color: #fff;

    :focus {
      background-color: rgba(221, 44, 87, .1);
    }
    
    :hover {
      background-color: rgba(221, 44, 87, .1);
    }
  
    @media (max-width: 319px) {
      min-width: 0;
    }
    
    @media (max-width: 767px) {
      min-width: 7.5rem;
      margin-right: .625rem;
      margin-left: 0;
    }
  `}
`;

const PopupContent = styled(ModalContent)`
  max-width: 28.4375rem;
  padding: 1.25rem;
  position: relative;
  width: 100%;    
  margin: 5.625rem auto 2.5rem;
  cursor: default;
  border-radius: .125rem;
  background-color: #fff;
`;

const Title = styled.b`
  font-size: 1.5rem;
  font-weight: 600;
  display: block;
  margin-bottom: 1.75rem;

  @media (max-width: 480px) {
    font-size: 1.125rem;
  }
`;