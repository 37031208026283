import React from 'react';
import { NavLink } from 'react-router-dom';

const VerifyItem = ({ email, show, resendVerificationEmail }) => {
  if (!show) {
    return null;
  }
  
  return (
    <li className="verify-email-wrapper">
      <div className="verify-email">
        <div className="verify-email__title">
          Check your Email
        </div>
        <div className="verify-email__icon">
          <svg width="69" height="52" viewBox="0 0 69 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.43761 0.25H60.5766C65.2309 0.25 69.0142 3.99266 69 8.57953V43.6845C69 48.2714 65.2166 52 60.5624 52H8.43761C3.78336 52 0 48.2714 0 43.6845V8.56546C0 3.97859 3.78336 0.25 8.43761 0.25ZM60.5767 48.2011C63.1037 48.2011 65.1595 46.175 65.1595 43.6845H65.1452V8.57952C65.1452 6.08911 63.0894 4.06301 60.5624 4.06301H8.43764C5.91064 4.06301 3.85477 6.08911 3.85477 8.57952V43.6845C3.85477 46.175 5.91064 48.2011 8.43764 48.2011H60.5767Z" fill="#DE60AD"/>
            <path d="M44.7202 24.6086L61.8512 9.98184C62.6484 9.2919 62.7208 8.119 61.9962 7.34627C61.2715 6.58733 60.0396 6.51834 59.228 7.20828L35.6039 27.396L30.995 23.4771C30.9805 23.4633 30.9661 23.4495 30.9661 23.4357C30.8646 23.3391 30.7631 23.2563 30.6472 23.1735L11.8929 7.19448C11.0813 6.50454 9.84934 6.57353 9.12467 7.34627C8.40001 8.119 8.47248 9.2919 9.2841 9.98184L26.6181 24.7328L9.35657 40.1185C8.57393 40.8222 8.53045 41.9951 9.26961 42.7541C9.66092 43.1404 10.1827 43.3474 10.7044 43.3474C11.1827 43.3474 11.661 43.1818 12.0378 42.8506L29.5602 27.2442L34.314 31.2872C34.6908 31.6046 35.1546 31.7564 35.6184 31.7564C36.0822 31.7564 36.5605 31.5908 36.9228 31.2734L41.807 27.1062L59.228 42.8644C59.6048 43.2094 60.0976 43.375 60.5758 43.375C61.0976 43.375 61.6049 43.1818 61.9962 42.7954C62.7353 42.0503 62.7063 40.8636 61.9237 40.1599L44.7202 24.6086Z" fill="#DE60AD"/>
          </svg>
        </div>
        <div className="verify-email__description">
          Click the confirmation link we have sent you on <span className="verify-email__email">{email}</span>
        </div>
      </div>
      <div className="verify-email-actions">
        <div className="verify-email-link" onClick={resendVerificationEmail}>
          Resend
        </div>
        
        <NavLink to={{ pathname: '/settings', editFields: ['email'] }} className="verify-email-link">
          Change
        </NavLink>
      </div>
    </li>
  );
};

export default VerifyItem;