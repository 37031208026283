export const withData = (state, data, meta) => {
  return {
    ...state,
    ...data,
    ...meta
  };
};

export const withSuccessStatus = (state, data) => {
  return withData(state, data, {
    loading: false,
    isLoaded: true,
  });
};

export const withFailureStatus = (state, data, error) => {
  return withData(state, data, {
    loading: false,
    isLoaded: true,
    error
  });
};