import React, {Component} from 'react';
import styled from 'styled-components';

import LettersBody from './LettersBody';
import {isMobile} from '../../utils/mobile';
import SendEmail from '../SendEmail';

class Letter extends Component {
  _isMounted = false;

  state = {
    viewingLetter: null,
    isReply: false
  };

  componentDidMount() {
    this._isMounted = true;
  
    const letter = this.props.errorEmails && this.props.errorEmails.find(letter => Number(letter.id) === Number(this.props.match.params.id));
  
    if (letter) {
      this.setState({ viewingLetter: letter });
    } else {
      
      this.getMail(this.props.match.params.id);
    }
    
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      const letter = this.props.errorEmails && this.props.errorEmails.find(letter => Number(letter.id) === Number(nextProps.match.params.id));
      
      if (letter) {
        this.setState({ viewingLetter: letter });
        
      } else {
        this.getMail(nextProps.match.params.id);
      }
    }
  }

  onCloseLetter = () => {
    this.setState({viewingLetter: null});
    this.props.history.push('/mailbox');
  };

  getMail = mailId => {
    if (Number.isFinite(+mailId) && mailId !== (this.state.viewingLetter && this.state.viewingLetter.id)) {
      this.setState({viewingLetter: null});
      this.props.getMailDetails(mailId)
        .then(mail => {
          if (mail) {
            this.setState({viewingLetter: mail});
          }
        });
    }
  };

  toggleEmailForm = () => this.setState(prevState => ({isReply: !prevState.isReply}));

  render() {
    const {type, profile} = this.props;
    const {viewingLetter, isReply} = this.state;

    if (!viewingLetter || !profile.id) {
      return (
        <Icon>
          <i className="fal fa-envelope"/>
        </Icon>
      );
    }

    return (
      <>
        <LettersBody
          type={type}
          myId={profile.id}
          replyMessage={this.toggleEmailForm}
          onClose={this.onCloseLetter}
          isModalPhoto={true}
          isModalContent={isMobile()}
          letter={viewingLetter}
        />
        {
          isReply && (
            <SendEmail
              isReply={isReply}
              letter={viewingLetter}
              onClose={this.toggleEmailForm}
              profile={viewingLetter && viewingLetter.sender}
              isOpen={isReply}
            />
          )
        }
      </>
    );
  }
}

export default Letter;

const Icon = styled.div`
	margin-top: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -350px;
  font-size: 228px;
  color: #b8bfd3;
  opacity: 0.2;
  
  @media (max-width: 767px) {
  	display: none;
  }
`;