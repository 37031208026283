import Cookies from 'universal-cookie';

import { endpoints } from './constants';

export const getUrlParams = url => {
  const urlParams = {};

  for (let value of new URL(url).searchParams.entries()) {
    urlParams[value[0]] = value[1];
  }

  return urlParams;
};


const url = process.env.REACT_APP_BASE_API_URL;

const errorCodes = [402, 423, 404, 422];

const fetching = ({ endpoint, method, body, data = true, json = true, stringify = true, additionalHeaders = {}, ...props }) => {
  const cookies = new Cookies();
  const token = props.token || getUrlParams(window.location.href).token || cookies.get('token') || sessionStorage.getItem('token') || localStorage.getItem('token');

  let headers = {
    'Authorization': `Bearer ${token}`,
    ...additionalHeaders
  };

  if (token && (!localStorage.getItem('token') || localStorage.getItem('token') !== token)) {
    localStorage.setItem('token', token);
    sessionStorage.setItem('token', token);
    localStorage.setItem('saveSession', 'on');

    if (process.env.REACT_APP_ENV !== 'local') {
      cookies.remove('token', { domain: `.${(document.domain.match(/[^\.]*\.[^.]*$/)[0])}` });
    }
  }

  if (cookies.get('click_id')) {
    headers['click-id'] = cookies.get('click_id');
  }

  if (stringify) {
    headers['Content-Type'] = 'application/json';
  }

  const requestOptions = { method, headers };

  if (body) {
    requestOptions.body = stringify ? JSON.stringify(body) : body;
  }

  return fetch(url + endpoint, requestOptions)
    .then(response => {
      if (errorCodes.includes(response.status)) {
        return response;

      } else if (response.status === 401) {
        if (localStorage.getItem('token') || sessionStorage.getItem('token') || !token) {
          localStorage.clear();
          sessionStorage.clear();
        }

        if (process.env.REACT_APP_ENV !== 'local') {
          window.location.href = (process.env.REACT_APP_REDIRECT_URL_PROMO || process.env.REACT_APP_REDIRECT_URL) + '?action=logout';
        }
      } else if (response.status === 500) {
        alert('Server error! Please try again later.');

      } else {
        return json ? response.json() : response;
      }

      return (errorCodes.includes(response.status)) ? response : (json ? response.json() : response);
    })
    .then(body => data ? (!body.message ? body.data : body) : body)
    .catch(error => {
      console.error(error);
    });
};

export const getFullProfile = ({ endpoint }) => fetching({
  method: 'get',
  endpoint: (endpoint ? endpoint : endpoints.MY_PROFILE),
  data: false
});

export const updateProfile = props => fetching({
  method: 'put',
  endpoint: endpoints.MY_PROFILE,
  data: false,
  ...props
});

export const sendMessage = props => fetching({
  method: 'post',
  endpoint: `/profiles/${props.id}/messages`,
  data: false,
  ...props
});

export const uploadPhoto = props => fetching({
  method: 'post',
  endpoint: endpoints.UPDATE_PROFILE_PHOTO,
  stringify: false,
  data: false,
  ...props
});

export const getMessages = (id, page = 1) => fetching({
  method: 'get',
  endpoint: `/profiles/${id}/messages?page=${page}`,
  data: false
});

export const getProfilesList = ({ page, query }) => fetching({
  method: 'get',
  endpoint: endpoints.PROFILES + `?page=${page || 1}` + (query ? query : ''),
  data: false
});

export const getAlbumsList = ({ endpoint, page }) => fetching({
  method: 'get',
  endpoint: (endpoint ? endpoint : endpoints.MY_ALBUMS) + `?page=${page || 1}`,
  data: false
});

export const updateAlbumsPhotos = props => fetching({
  method: 'put',
  endpoint: endpoints.MY_ALBUMS + props.id,
  ...props
});

export const getAlbum = ({ id, endpoint }) => fetching({
  method: 'get',
  endpoint: (endpoint ? endpoint : endpoints.MY_ALBUMS) + id
});

export const likePhoto = ({ profile_id, album_id, photo_id }) => fetching({
  method: 'post',
  endpoint: `/profiles/${profile_id}/albums/${album_id}/photos/${photo_id}/like`
});

export const getLettersList = ({ page, query }) => fetching({
  method: 'get',
  endpoint: endpoints.MY_MAILBOX + `?page=${page ? page : 1}` + query,
  data: false
});

export const markMessagesAsRead = id => fetching({
  method: 'put',
  endpoint: `/profiles/${id}/messages/mark-as-read`,
  json: false,
  data: false
});

export const sendEmailToUser = props => fetching({
  method: 'post',
  endpoint: endpoints.MY_MAILBOX,
  data: false,
  ...props
});

export const buyAlbum = ({ album_id, profile_id }) => fetching({
  method: 'post',
  endpoint: `/profiles/${profile_id}/albums/${album_id}/purchase/`,
  data: false
});

export const getTransactions = page => fetching({
  method: 'get',
  endpoint: '/me/credits?page=' + (page ? page : 1),
  data: false
});

export const getOrders = page => fetching({
  method: 'get',
  endpoint: '/me/orders?page=' + (page ? page : 1),
  data: false
});

export const deleteMyAccount = props => fetching({
  method: 'delete',
  endpoint: endpoints.MY_PROFILE,
  json: false,
  data: false,
  ...props
});

export const removeUserFromBlacklist = id => fetching({
  method: 'delete',
  endpoint: endpoints.BLACKLIST + id,
  json: false
});

export const addCard = ({ additionalHeaders, body }) => fetching({ method: 'post', endpoint: endpoints.CARD, additionalHeaders, body, data: false });

export const deleteCard = cardId => fetching({ method: 'delete', endpoint: endpoints.CARD + cardId, json: false });

export const getCardsList = () => fetching({ method: 'get', endpoint: endpoints.CARD });

export const typeMessage = ({ id }) => fetching({ method: 'post', endpoint: endpoints.TYPING + id });

export const getMailDetails = id => fetching({ method: 'get', endpoint: endpoints.MY_MAILBOX + id });

export const register = props => fetching({ method: 'post', endpoint: endpoints.REGISTER, ...props, data: false });

export const login = props => fetching({ method: 'post', endpoint: endpoints.LOGIN, ...props, data: false });

export const sendEmail = props => fetching({ method: 'post', endpoint: endpoints.SEND_EMAIL, ...props, data: false });

export const getCatalogs = () => fetching({ method: 'get', endpoint: endpoints.CATALOG, data: false });

export const getContacts = () => fetching({ method: 'get', endpoint: endpoints.CONTACTS, data: false });

export const logout = () => fetching({ method: 'delete', endpoint: endpoints.LOGOUT, json: false });

export const createAlbum = props => fetching({ method: 'post', endpoint: endpoints.MY_ALBUMS, ...props });

export const deleteAlbum = id => fetching({ method: 'delete', endpoint: endpoints.MY_ALBUMS + id, json: false });

export const markEmailAsRead = id => fetching({ method: 'put', endpoint: endpoints.MY_MAILBOX + id + '/mark-as-read' });

export const removeLetter = id => fetching({ method: 'delete', endpoint: endpoints.MY_MAILBOX + id, json: false });

export const addToFavorite = id => fetching({ method: 'post', endpoint: `/profiles/${id}/favorite` });

export const getContactsInfo = id => fetching({ method: 'get', endpoint: endpoints.CONTACTS + id });

export const getSubscriptionPlans = () => fetching({ method: 'get', endpoint: '/credit/plans' });

export const subscribe = props => fetching({
  method: 'post',
  endpoint: '/me/subscription/subscribe/',
  data: false, ...props
});

export const getCreditPacks = () => fetching({ method: 'get', endpoint: '/credit/packs' });

export const buyCredits = ({ planId, body }) => fetching({
  method: 'post',
  endpoint: '/me/credits/' + planId,
  body,
  data: false
});

export const getBlacklist = () => fetching({ method: 'get', endpoint: endpoints.BLACKLIST });

export const addUserToBlacklist = props => fetching({ method: 'post', endpoint: endpoints.BLACKLIST, ...props });

export const unsubscribe = () => fetching({ method: 'post', endpoint: '/me/subscription/unsubscribe' });

export const switchProfile = id => fetching({ method: 'post', endpoint: '/switch/all' });

export const checkOrderWithId = id => fetching({ method: 'get', endpoint: '/me/orders/' + id, data: false });

export const marketingClicks = body => fetching({ method: 'post', endpoint: '/marketing/clicks', body, data: false });

export const addComment = ({ body, id }) => fetching({
  method: 'post',
  endpoint: `/me/contacts/${id}/comment`,
  body
});

export const verifyEmail = token => fetching({ method: 'post', endpoint: '/verify/email', body: { token }, data: false });

export const resendVerificationEmail = () => fetching({ method: 'post', endpoint: '/verify/resend' });

export const getSimilarProfiles = id => fetching({ method: 'get', endpoint: `/profiles/${id}/similar?count=4` });
