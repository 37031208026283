import React, {useState} from 'react';

import validate from './validate';
import RenderField from './renderField';
import StatusCode from '../../StatusCode';

import * as Styles from '../styles';
import * as api from '../../../api';

function hideEmail(email) {
  const parts = email.split('@');
  return email[0] + '*'.repeat(parts[0].length - 1) + '@' + parts[1];
}

const Account = ({ email, editFields }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  
  const request = (key, value) => {
    setError('');

    return api.updateProfile({body: {[key]: value}})
      .then(response => {
        if (response.status) {
          return response.json();
          
        } else if (response.exception) {
          return response;
          
        } else if (key === 'email') {
          onSuccess();
        }

        return response;
      })
      .then(response => {
        if (response.errors) {
          setError(Object.keys(response.errors).map(error => response.errors[error][0])[0]);
        }

        return response;
      });
  };

  const validateValue = value => {
    const error = validate(value);

    if (error) {
      setError(error);
      
    } else {
      setError('');
    }

    return Boolean(error);
  };

  const onSuccess = () => setSuccess(success => !success);
  
  return (
    <>
      <Styles.Account>
        Your account
        <Styles.Error error={error}>{error}</Styles.Error>
      </Styles.Account>
      <Styles.FieldWrapper>
        <RenderField
          isEdit={(editFields || []).includes('email')}
          fieldKey='email'
          placeholder={email && hideEmail(email)}
          validate={validateValue}
          request={request}
        />
        <RenderField
          isEdit={(editFields || []).includes('password')}
          fieldKey='password'
          placeholder='*************'
          validate={validateValue}
          request={request}
        />
      </Styles.FieldWrapper>
      {
        success && (
          <StatusCode onClose={onSuccess} image={require('../../../assets/img/success-ico.svg')}>
            <p>Open the email and confirm your new email address</p>
          </StatusCode>
        )
      }
    </>
  );
};

export default Account;