import React from 'react';

const EmailVerifiedMessage = ({ show }) => {
  if (!show) {
    return null;
  }
  
  return (
    <div className="settings-confirm-wrapper">
      <div className="settings-confirm-icon">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M10.9998 21.9995C17.0748 21.9995 21.9995 17.0748 21.9995 10.9998C21.9995 4.92476 17.0748 0 10.9998 0C4.92476 0 0 4.92476 0 10.9998C0 17.0748 4.92476 21.9995 10.9998 21.9995Z" fill="#DE60AD"/>
          <path d="M12.5165 13.8984H9.45569L8.88179 2.19995H13.0631L12.5165 13.8984ZM12.5438 19.1756C12.1066 19.5916 11.5873 19.7996 10.9861 19.7996C10.3849 19.7996 9.86562 19.5916 9.42836 19.1756C9.00932 18.7597 8.7998 18.2744 8.7998 17.7198C8.7998 17.1479 9.00932 16.654 9.42836 16.238C9.86562 15.8221 10.3849 15.6141 10.9861 15.6141C11.5873 15.6141 12.1066 15.8221 12.5438 16.238C12.9811 16.654 13.1997 17.1479 13.1997 17.7198C13.1997 18.2744 12.9811 18.7597 12.5438 19.1756Z" fill="white"/>
        </svg>
      </div>
      <div className="settings-confirm-description">
        <p>Confirm your email and get 10 bonus credits!</p>
        <p>To complete your registration, please check your email inbox and follow on the confirmational link</p>
      </div>
    </div>
  );
};

export default EmailVerifiedMessage;