import styled from 'styled-components';

import {btn} from '../styles';

export const Fetching = styled.span`
  color: #3b5a9a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
  
  @media (max-width: 767px) {
    padding-bottom: 1rem;
    padding-top: 0;
  }
  
  img {
    width: 15px;
    margin-right: 10px;
  }
`;

export const PlaceHolder = styled.div`
  color: #515151;
  font-weight: 600;
  font-size: 1.15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
`;

export const Button = styled(btn)`
  margin-top: 1.45rem;
  padding: 0 2.75rem;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  
  ::-webkit-scrollbar {
    display: none;
	}
  
  @media (max-width: 767px) {
    margin: 0;
    padding: 0;
  }  
`;

export const Container = styled.div`
  position: relative;
  max-width: 52.1875rem;
  width: 100%;

  @media (max-width: 767px) {
    margin: 0;
    padding: 0;
    max-height: 100%;
  }
`;

export const New = styled.span`
  font-size: .75rem;
  font-weight: 600;
  position: absolute;
  top: .625rem;
  left: 0;
  padding: .1875rem .25rem;
  user-select: none;
  letter-spacing: .0625rem;
  text-transform: uppercase;
  color: #fff;
  background-color: #7ed321;
`;

export const Loader = styled.img`
  width: 50px;
  display: inline-block;
  border-radius: 15px;
  right: 0;
  left: 0;
  margin: auto;
  top: 41%;
  position: absolute;
`;

export const Photo = styled.img`
	display: block;
  width: 11.875rem;
  object-fit: cover;
  font-family: "object-fit: cover;", serif;
  
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Profile = styled.li`
  position: relative;
  overflow: hidden;
  margin: .78125rem;
  border-radius: .125rem;
  box-shadow: 0 0.125rem 0.375rem 0 rgba(184, 191, 211, .2);
  background-color: #fff;
  
  :hover {
    box-shadow: 0 0 4px 3px rgba(0, 66, 255, 0.3);
  }
  
	a {
		text-decoration: none;
		display: block;
		position: relative;
	}
	
	@media (max-width: 767px) {
    width: calc(100% / 3 - .75rem);
    margin: .375rem;
  }
	
	@media (max-width: 590px) {
    width: calc(100% / 2 - .375rem);
    min-width: 8.75rem;
    margin: .1875rem;
  }
`;

export const Status = styled.label`    
  @media (max-width: 480px) {
    right: .5625rem;
    bottom: .625rem;
    width: .5rem;
    height: .5rem;
  }

  font-size: 0;
  position: absolute;
  right: .8125rem;
  bottom: .8125rem;
  width: .625rem;
  height: .625rem;
  color: transparent;
  border-radius: 50%;
	background-color: ${props => props.online ? '#7ed321' : ''};
`;

export const CatalogList = styled.ul`
  display: flex;
  margin: -.78125rem;
  padding: 0;
  list-style: none;
  flex-wrap: wrap;
  
  @media (max-width: 767px) {
    margin: 0;
    padding: .625rem;
  }
`;

export const ProfilesName = styled.span`
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  max-width: 9.6875rem;
  height: 2.25rem;
  margin-left: .375rem;
  user-select: none;
  letter-spacing: .05rem;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  .check-validate {
    display: none;
  }
  
  ${({ show }) => show && `
     .check-validate {
       display: block;
     }
  `}
  
  @media (max-width: 480px) {
    font-size: .75rem;
    height: 1.75rem;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
  }
`;

export const notificationsWrapper = styled.div`
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   width: 100%;
   padding: 0 .8125rem;
   z-index: 10000;
`;

