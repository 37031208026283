import React, {Component} from 'react';
import styled from 'styled-components';

import AlbumCreator from './AlbumCreator';
import AddAlbum from './AddAlbum';
import Album from './Album';
import Carousel from '../../Carousel';

import {encodeBase64} from '../../../utils/encodeBase64';
import {loader} from '../../../assets';
import {createAlbum} from '../../../api';
import * as Styles from '../styles';
import './slick-carousel.css';

import Accordion from './accordion';

class Albums extends Component {
  state = {
    isOpen: false,
    step: 1
  };

  endpoint = this.props.match.params.id ? `/profiles/${encodeBase64(this.props.match.params.id)}/albums` : '';

  componentDidMount() {
    const {fetchAlbumsList} = this.props;
    fetchAlbumsList(this.endpoint);
  }

  createAlbum = values => {
    createAlbum({body: values})
      .then(album => this.props.addAlbum(album));

    this.onCloseAlbumCreator();
  };

  openAlbumCreator = () => this.setState({isOpen: true});

  onCloseAlbumCreator = () => this.setState({isOpen: false});

  setUpCarousel = () => {
    return {
      infinite: false,
      dots: false,
      slidesToShow: this.props.slidesToShow,
      speed: 300,
      slidesToScroll: 1,
      afterChange: this.afterChange,
      className: this.props.role === 'me' ? 'react-slick__wrapper' : '',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: this.props.slidesToShow,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
          }
        }
      ]
    };
  };

  afterChange = currentItem => {
    const {albums, loadMoreAlbums} = this.props;

    this.setState(prevState => ({step: prevState + 1}));

    let page = albums.links.next ? albums.links.next.split('?page=')[1] : null;
    if (page && (albums.data.length - 6 < currentItem)) {
      loadMoreAlbums(this.endpoint, page);
    }
  };

  render() {
    const {role, albums, profileData, toggleSubscriptionPanel, profile, user, updatePhotos, deleteAlbum, form} = this.props;

    if (albums.loading) {
      return (
        <LoaderWrap>
          <Styles.Loader src={loader}/>
        </LoaderWrap>
      );
    }

    return (
      <>
        <Accordion
          show={this.props.history.location.pathname === '/me' && !profile.is_agency}
          profile={{ ...profile, albums: albums.data }}
          openEditComponent={this.props.openEditComponent}
          updateProfileInfo={this.props.updateProfileInfo}
          openAlbum={this.openAlbumCreator}
        />
        <Container>
          <AlbumCreator
            formState={form}
            onSubmit={this.createAlbum}
            isOpen={this.state.isOpen}
            onClose={this.onCloseAlbumCreator}
          />
          {
            (albums.data && albums.data.length) ? (
              <Styles.CarouselContainer>
                <Carousel
                  settings={this.setUpCarousel()}
                  AddAlbum={role === 'me' && AddAlbum}
                  role={role}
                  openAlbumCreator={this.openAlbumCreator}
                  profile_id={profileData.id}
                  user={user}
                  myProfile={profile}
                  MapComponent={Album}
                  last_subscription={profile.last_subscription}
                  toggleSubscriptionPanel={toggleSubscriptionPanel}
                  images={albums.data}
                  deleteAlbum={deleteAlbum}
                  updatePhotos={updatePhotos}
                />
              </Styles.CarouselContainer>
            ) : (role === 'me' ? (
              <PlaceHolder>
                <b>You have no albums yet :(</b>
                <span>The presence of the album increases the conversion</span>
                <AddAlbum openAlbumCreator={this.openAlbumCreator}/>
              </PlaceHolder>
            ) : (
              <UserPlaceHolder>
                <b>Unfortunately the user has not uploaded any albums yet :(</b>
              </UserPlaceHolder>
            ))
          }
        </Container>
      </>
    );
  }
}

export default Albums;

const UserPlaceHolder = styled.div`
  padding: 2.625rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 767px) {
    padding: 0;
  }
`;

const PlaceHolder = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.75rem;
  padding-bottom: 0.618rem;
  
  @media (max-width: 767px) {
    padding: .8rem 0;
  }    

  div {
    background-color: #fff;
    height: auto;
  }

  b {
    padding: 0 !important;
    font-size: 1.125rem !important;
  }
  
  span {
    color: #b8bfd3;
    font-size: .75rem;
    margin-bottom: .35rem;
    text-align: center;
  }
`;

const Container = styled.div`
  border-bottom: .0625rem solid #edeff4;
  margin: 0 -1.25rem;
  position: relative;
  padding: 1.375rem 0;
  overflow: hidden;
  
  b {
    font-weight: 600;
    font-size: 1.125rem;
    color: #515151;
    text-align: center;
  }
  
  @media (max-width: 767px) {
    border: none;
    padding: .9375rem 0;
    margin: 0;
    min-height: auto;
    
    b {
      font-size: .875rem;
      padding: 1rem;
    }
  }
`;

const LoaderWrap = styled(Container)`
  height: 9.6875rem;
`;

