import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { NavLink } from 'react-router-dom';

const cookieName = 'emailVerify';

const verifyMessage = ({ show, email, resendVerificationEmail }) => {
  const [cookies, setCookie] = useCookies([cookieName]);
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    if (show && !cookies[cookieName] && !isOpen) {
      setIsOpen(true);
    }
  }, [show, isOpen, cookies]);
  
  const onClose = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    
    setCookie(cookieName, 'true', {
      path: '/',
      expires: new Date(tomorrow.setDate(tomorrow.getDate() + 1))
    });
    
    setIsOpen(false);
  };
  
  if (!isOpen) {
    return null;
  }
  
  return (
    <div className="verify-message">
      <div className="verify-message__close-btn" onClick={onClose}/>
      <div className="verify-message__icon">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z" fill="#DE60AD"/>
          <path d="M12.5167 13.8987H9.45583L8.88191 2.20001H13.0633L12.5167 13.8987ZM12.544 19.1761C12.1068 19.592 11.5875 19.8 10.9863 19.8C10.385 19.8 9.86577 19.592 9.4285 19.1761C9.00945 18.7601 8.79993 18.2749 8.79993 17.7202C8.79993 17.1483 9.00945 16.6544 9.4285 16.2384C9.86577 15.8225 10.385 15.6145 10.9863 15.6145C11.5875 15.6145 12.1068 15.8225 12.544 16.2384C12.9813 16.6544 13.1999 17.1483 13.1999 17.7202C13.1999 18.2749 12.9813 18.7601 12.544 19.1761Z" fill="white"/>
        </svg>
      </div>
      <div className="verify-message__description">
        You will receive 10 credits for confirming your email. To complete your registration, please check your email inbox and follow on the confirmational link we have sent you on <span className="verify-message__email">{email}</span><br/>
        If you have not received a confirmation message, select {' '}
        <span className="verify-message__link" onClick={resendVerificationEmail}>Resend</span> or {' '}
        <NavLink to={{ pathname: '/settings', editFields: ['email'] }} className="verify-message__link">Change</NavLink> {' '}
        your Email
      </div>
    </div>
  );
};

export default verifyMessage;