import React from 'react';
import {Field} from 'redux-form';
import styled from 'styled-components';

import * as Styles from './styles';
import RenderInterests from './renderInterest';
import {Event} from '../../Tracking';

const UserInterests = ({handleSubmit, selects, onClose, buttonText, step, lastStep, skip}) => {
  const handleClick = () => Event('send', 'form', 'step4');

  return (
    <Form as="form" onSubmit={handleSubmit}>
      <Styles.Title>Interests {step && (step + '/' + lastStep)}</Styles.Title>
      {onClose && (<Styles.Skip onClick={onClose}><i className='fal fa-times'/></Styles.Skip>)}
      <Styles.LookingPopup>
        <Field
          name="interests"
          interests={selects && selects.interests}
          component={RenderInterests}
          maxLength={6}
        />
        {onClose && (<Styles.Button as="button" close={onClose} onClick={onClose}>CANCEL</Styles.Button>)}
        {(step !== lastStep && skip) && (<Styles.Button as='button' skip={skip} onClick={skip}>SKIP</Styles.Button>)}
        <Styles.Button as="button" onClick={handleClick}>
          {buttonText || (step === lastStep ? 'CONFIRM' : 'NEXT')}
        </Styles.Button>
      </Styles.LookingPopup>
    </Form>
  );
};
export default UserInterests;

const Form = styled(Styles.Form)`
   max-width: 28.4375rem;
   padding: 1.25rem 3.75rem 2.5rem 1.25rem;
`;
