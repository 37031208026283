import {withSuccessStatus, withFailureStatus} from '../utils/withData';
import * as TYPES from '../constants/types';

const initialState = {
  data: [],
  error: null,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
  case TYPES.FETCH_ALBUMS_REQUEST:
    return {...state, loading: true, error: null};

  case TYPES.FETCH_ALBUMS_SUCCESS:
    return withSuccessStatus(state, action.albums);

  case TYPES.FETCH_ALBUMS_FAILURE:
    return withFailureStatus(state, [], action.error);

  case TYPES.FETCH_MORE_ALBUMS_REQUEST:
    return {...state, fetching: true, error: null};

  case TYPES.FETCH_MORE_ALBUMS_SUCCESS:
    return {
      ...state,
      page: action.page,
      data: [...state.data, ...action.albums.data],
      links: action.albums.links,
      fetching: false,
      error: null
    };

  case TYPES.FETCH_MORE_ALBUMS_FAILURE:
    return {...state, fetching: false, error: action.error};

  case TYPES.ADD_ALBUM: {
    return {...state, data: [action.album, ...state.data]};
  }

  case TYPES.DELETE_ALBUM: {
    const atIndex = state.data.findIndex(album => album.id === action.album);
    return {
      ...state,
      data: [
        ...state.data.slice(0, atIndex),
        ...state.data.slice(atIndex + 1)
      ]
    };
  }

  default:
    return state;
  }
};

