import React from 'react';

import {setLocalStorage} from './setLocalStorage';
import {TextArea} from './styles';

const GraphemeSpillter = require('grapheme-splitter');

const TextInput = ({input, meta: {error}, handleChange, maxLength, required}) => {
  const onChange = e => {
    const splitter = new GraphemeSpillter();
    const countPrev = splitter.countGraphemes(input.value);
    const countNew = splitter.countGraphemes(e.target.value);

    if (countNew < countPrev || countNew <= maxLength) {
      handleChange(e);
      input.onChange(e.target.value);
      setLocalStorage('text', e.target.value);
    }
  };

  return (
    <div className="textarea-wrapper">
      <TextArea
        {...input}
        required={required}
        maxLength={maxLength}
        type="text"
        onChange={onChange}
      />
      <div className="count-price-wrapper mailbox">
        <div className="count">
          {maxLength - input.value.length}
        </div>
        <div className="divider">
          /
        </div>
        <div className="price">
          10 <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.044 15.556c-2.283 0-4.198-.722-5.746-2.165C.766 11.933 0 10.062 0 7.778 0 5.493.766 3.63 2.298 2.187 3.846.729 5.761 0 8.044 0c2.5 0 4.479.992 5.932 2.976l-1.641.88c-.453-.647-1.063-1.173-1.83-1.579a5.183 5.183 0 00-2.461-.609c-1.72 0-3.158.579-4.315 1.736-1.142 1.142-1.712 2.6-1.712 4.374 0 1.773.57 3.239 1.712 4.396 1.157 1.142 2.595 1.713 4.315 1.713.875 0 1.696-.195 2.462-.586.766-.406 1.376-.94 1.829-1.6L14 12.58c-1.517 1.984-3.502 2.976-5.956 2.976z" fill="#DE60AD"/><path d="M10.889 10.889H9.245l-1.32-2.21H6.876v2.21H5.444V4.667H8.59c.692 0 1.24.186 1.644.56.41.373.615.855.615 1.445 0 .51-.145.924-.434 1.241-.282.317-.628.52-1.038.606l1.512 2.37zm-2.51-3.377c.295 0 .537-.075.725-.224a.76.76 0 00.283-.616.733.733 0 00-.283-.606c-.188-.155-.43-.233-.726-.233H6.876v1.679h1.502z" fill="#DE60AD"/></svg>
        </div>
      </div>
    </div>
  );
};

export default TextInput;