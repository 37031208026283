import React, {useRef} from 'react';
import Webcam from 'react-webcam';
import styled from 'styled-components';

const WebcamCapture = ({onCapture}) => {
  const webcam = useRef();

  const capture = e => {
    e.preventDefault();

    const photo = webcam.current.getScreenshot();

    if (photo) {
      let arr = photo.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      const file = new File([u8arr], 'profile_photo.jpeg', {type: mime});

      onCapture(file);
    }
  };

  return (
    <WebcamContainer>
      <Webcam
        audio={false}
        ref={webcam}
        screenshotFormat="image/jpeg"
        width='509px'
        height='380px'
      />
      <WebcamBtn>
        <div onClick={capture} onTouchStart={capture}>
          <i className='fas fa-camera'/>
        </div>
      </WebcamBtn>
    </WebcamContainer>
  );
};

export default WebcamCapture;

const WebcamBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
  
  div {
    width: 62px;
    height: 62px;
    border: 2px solid #3b5a9a;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    
    :hover {
      opacity: .85rem;
    }
  }
  
  i {
    font-size: 1.5rem;
    color: #3b5a9a
  }
`;

const WebcamContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	position: relative;
	button {
		border-color: red;
	  outline: none;
    margin-top: 16px;
    padding: 15px 21px;
    padding: ;
    background-color: #ff052a;
	}
`;
