import React from 'react';
import PropTypes from 'prop-types';

import withLoader from '../HOC/withLoader';
import Header from './Header';
import MessagesList from '../../containers/MessagesList';
import AddMessage from '../../containers/AddMessage';
import ShortProfileInfo from './ShortProfileInfo';
import * as Styles from './styles';
import { isMobile } from '../../utils/mobile';

const Dialog = ({ contact, contacts, id, history, isOnlineUser, profile, refs, messages, error, sent, updateContactsInfo }) =>
  <Styles.Container>
    <Styles.MessangerBox>
      <Header
        contact={contact}
        isOnline={isOnlineUser}
        refs={refs}
        messages={messages}
        history={history}
        contacts={contacts}
        creditsCount={profile.credits}
        isAgency={profile.is_agency}
        updateContactsInfo={updateContactsInfo}
      />
      <MessagesList
        profileId={id}
        contact={contact}
        profile={profile}
        refs={refs}
        error={error}
      />
      <AddMessage
        id={id}
        error={error}
        sent={sent}
      />
    </Styles.MessangerBox>
    {!isMobile() && (
      <ShortProfileInfo
        updateContactsInfo={updateContactsInfo}
        isAgency={profile.is_agency}
        history={history}
        isOnlineUser={isOnlineUser}
        contact={contact}
        contacts={contacts}
      />
    )}
  </Styles.Container>;

Dialog.propTypes = {
  contact: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired
};

export default withLoader(Dialog);
