import React, {Component} from 'react';
import styled from 'styled-components';

import {loader} from '../../assets';
import * as api from '../../api';

const row = ['Amount', 'Card', 'Type', 'date time'];

class Money extends Component {

  state = {
    transactions: [],
    loading: false,
    fetching: false,
    nextPage: null
  };

  componentDidMount() {
    this.setState({ loading: true });

    api.getOrders()
      .then(response => {
        if (response.ok !== false) {
          this.setState({
            transactions: response.data,
            currentPage: response.meta.current_page,
            nextPage: response.links.next,
            loading: false
          });
        }
      });
  }

  loadMoreTransactions = () => {
    this.setState({ fetching: true });

    const page = this.state.nextPage ? this.state.nextPage.split('?page=')[1] : null;

    api.getOrders(page)
      .then(response => {
        this.setState({
          transactions: [...this.state.transactions, ...response.data],
          currentPage: response.meta.current_page,
          nextPage: response.links.next,
          fetching: false
        });
      });
  };

  render() {
    const { transactions, fetching, nextPage } = this.state;

    if (!transactions.length) {
      return <div />;
    }

    return (
      <div>
        <Table>
          <thead>
            <tr>
              {row.map(cell => (
                <th key={cell}>{cell}</th>
              ))}
            </tr>
          </thead>
          <Tbody>
            {
              transactions.map(({ amount, currency, card_number, trans_date, type, transaction_type }, index) => (
                <tr key={JSON.stringify(trans_date)}>
                  <td>{`${amount} ${currency || ''}`}</td>
                  <td>{card_number}</td>
                  <td>{type} {transaction_type ? `(${transaction_type})` : ''}</td>
                  <td>{trans_date}</td>
                </tr>
              ))
            }
          </Tbody>
        </Table>
        {fetching ? (
          <LoaderWrap>
            <img src={loader} alt='loading' />
            <span>Please wait, loading...</span>
          </LoaderWrap>
        ) : nextPage && (<ShowMoreBtn onClick={this.loadMoreTransactions}>Show more</ShowMoreBtn>)}
      </div>
    );
  }
}

export default Money;

const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  justify-content: center;
  color: #3b5a9a;
  
  img {
    width: 15px;
    margin-right: 10px;
  }
`;

const ShowMoreBtn = styled.span`
  font-weight: 600;
  display: block;
  margin-top: 1.25rem;
  margin-bottom: .4375rem;
  text-align: center;
  text-transform: uppercase;
  color: #3b5a9a;

  :hover {
    cursor: pointer
  }
`;

const Table = styled.table`
  width: 100%;
  margin-top: 1.25rem;
  border-collapse: collapse;
  text-align: left;
  color: #4b4c59;

  th {
    font-size: .75rem;
    font-weight: 600;
    padding: .3125rem 1.25rem;
    text-transform: uppercase;
    color: #b8bfd3;
    border-bottom: .0625rem solid #dadee8;
    width: 25%; 
       
    @media (max-width: 480px) {
      padding: .625rem .3125rem;
      
      :nth-child(2) {
        width: 25%;
      }
      
      :first-child {
        padding-left: .9375rem
      }

      :last-child {
        padding-right: .9375rem;
        text-align: right
      }
    }
  }
`;
const Tbody = styled.tbody`
  td {
    padding: .9375rem 1.25rem .9375rem;
    border-bottom: .0625rem solid #dadee8;
  }

  @media (max-width: 480px) {
    td {
      padding: .625rem .3125rem;
      vertical-align: top;
      width: 33%;
    }

    td:first-child {
      padding-left: .9375rem;
    }

    td:last-child {
      padding-right: .9375rem;
      text-align: right;
    }
  }
`;