import styled from 'styled-components';

export const Svg = styled.svg`
	fill: #3b5a9a;
	width: 18px;
	height: 20px;
  position: absolute;
  right: -22px;
  top: 18px;
`;

export const Header = styled.div`
	text-align: center;
	position: relative;
	h1 {
		margin: 10px 0px 8px 0;
		color: #3b5a9a;
    font-size: 34px;
	}
	div {
		display: flex;
		justify-content: center;
		width: 100%;
	}
	span {
	  font-size: 15px;
    letter-spacing: .8px;
    color: #4d4d4d;
	}
`;

export const PlansContainer = styled.ul`
  @media (max-width: 767px) {
    padding: 1.25rem;
    flex-flow: column;
    align-items: center;
  }
  
  margin: 0 0 1rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 2.8125rem;
  list-style: none;
  flex-wrap: wrap;
`;

export const Plan = styled.li`
  display: flex;
  flex-direction: column;
  width: 11.875rem;
  height: 3.75rem;
  margin: .5rem .625rem;
  border: .0625rem solid transparent;
  border-radius: 1.875rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(184, 191, 211, .2);
  background-color: #fff;
  align-items: center;
  justify-content: center;

	:hover {
    cursor: pointer;
    border: ${props => props.active ? '1px solid #3b5a9a' : '1px solid #b8bfd3'};
	}

	border: ${props => props.active ? '1px solid #3b5a9a' : '1px solid transparent'};
	
	span:last-child {
		font-size: .875rem;
    letter-spacing: .05625rem;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	
	button {
		background-color: #dd4057;
    border: 0;
    color: #fff;
    outline: none;
    font-weight: 600;
    margin-top: 15px;
    font-size: 11px;
    letter-spacing: 2px;
    padding: 9px;
    width: 30%;
    cursor: pointer;
	}
`;

export const Close = styled.span`
  background: 0;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  color: #b8bfd3;
  position: absolute; 
  right: 2%;
  top: 1.9%;
  cursor: pointer;
  
  :hover {
    color: #4b4c59;
  }
`;

export const CreditsWrapper = styled.div`
	display: block;
	margin-top: 3.5rem;
  text-align: center;
`;

export const CreditsTitle = styled.h2`
  position: relative;
	font-size: 1.5rem;
  line-height: 1.313rem;
  text-align: center;
  display: inline-block;
  color: #3B5A9A;
  font-weight: 600;
  svg {
    top: -0.75rem;
    right: -1.125rem;
  }
`;

export const CreditsSubTitle = styled.div`
	font-size: 0.875rem;
  line-height: 0.938rem;
  text-align: center;
  color: #4B4C59;
  margin-top: 0.938rem;
  margin-bottom: 1.5rem;
`;


export const ListWrapper = styled.ul`
	margin: 0 0 1.5rem;
	padding: 0;
	list-style-type: none;
	max-width: 254px;
	margin-left: auto;
	margin-right: auto;
	&:last-child {
    li {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      div {
        &:first-child {
          width: 5.875rem;
        }
      }
    }
	}
`;

export const ListItem = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	border-bottom: 1px solid #E8E8E8;
`;

export const ListMessage = styled.div`
	font-size: 0.625rem;
  line-height: 0.75rem;
  letter-spacing: -0.207059px;
  text-transform: capitalize;
  color: #515151;
  width: 3.875rem;
  text-align: left;
`;

export const ListIcon = styled.div`
	position: relative;
	font-size: 1rem;
  line-height: 1.188rem;
  color: #3B5A9A;
  font-weight: 600;
  width: 2.813rem;
  text-align: left;
	svg {
	  position: relative;
	  top: auto;
	  right: auto;
	  margin-left: 0.25rem;
	  margin-bottom: -0.25rem;
	}
`;

export const ListAbout = styled.div`
  font-size: 0.5rem;
  line-height: 0.563rem;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #979797;
  width: 5.375rem;
  text-align: left;
`;

export const ListIconActive = styled.span`
  display: inline-block;
  position: relative;
  width: 0.625rem;
  height: 0.625rem;
  background-color: #7ED321;
  flex: 0 0 auto;
  border-radius: 100%;
  &:before {
    position: absolute;
    content: '';
    display: block;
    left: 0.188rem;
    top: 0.094rem;
    width: 0.188rem;
    height: 0.313rem;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    @media (min-width: 1024px) {
      left: 0.22rem;
      top: 0.12rem;
    }
  }
`;

export const ListIconInActive = styled.span`
  display: inline-block;
  position: relative;
  width: 0.625rem;
  height: 0.625rem;
  background: linear-gradient(0deg, #FDDB62, #FDDB62);
  flex: 0 0 auto;
  border-radius: 100%;
`;

export const CreditsProfile = styled.div `
  font-size: 0.5rem;
  line-height: 0.563rem;
  text-align: center;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #FFFFFF;
  background-color: #3B5A9A;
  border-radius: 2px;
  border: none;
  padding: 0.25rem 0.25rem 0.25rem 0.05rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.375rem;
`;

export const SubscriptionPlanWrapper = styled.div`
	display: block;
	margin-left: -15px;
	margin-right: -15px;
`;