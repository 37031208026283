import React, {Component} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

class Carousel extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.handleArrowNavigation);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleArrowNavigation);
  }

  handleArrowNavigation = event => {
    if (event.keyCode === 39 || event.keyCode === 68) {
      this.slick.slickNext();
    } else if (event.keyCode === 37 || event.keyCode === 65) {
      this.slick.slickPrev();
    }
  };

  render() {
    const {images, openAlbumCreator, AddAlbum, MapComponent} = this.props;
    return (
      <Slider {...this.props.settings} ref={e => this.slick = e}>
        {AddAlbum && (<AddAlbum openAlbumCreator={openAlbumCreator}/>)}
        {
          images.map((image, index) => (
            <MapComponent
              index={index}
              key={image.id}
              alt=''
              {...image}
              {...this.props}
            />
          ))
        }
      </Slider>
    );
  }
}

export default Carousel;