import {GetCardType} from '../utils/GetCardType';
import * as TYPES from '../constants/types';

const initialState = {
  data: [],
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
  case TYPES.GET_CARDS_LIST:
    return {
      ...state,
      data: action.cards.map(card => ({
        label: card.pan,
        icon: GetCardType(card.pan).icon,
        value: card.id.toString(),
        ...card
      }))
    };

  case TYPES.ADD_NEW_CARD: {
    return {...state, data: [...state.data, action.card]};
  }

  case TYPES.DELETE_CARD: {
    const atIndex = state.data.findIndex(card => card.id === action.card.id);

    return {
      ...state,
      data: [
        ...state.data.slice(0, atIndex),
        ...state.data.slice(atIndex + 1)
      ]
    };
  }

  default:
    return state;
  }
};

