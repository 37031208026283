import { connect } from 'react-redux';

import MessagesListComponent from '../components/Chat/MessagesList';
import { decrementMailCount, fetchMessage, loadMoreMessages, initErrorMessages, removeErrorMessage, removeErrorMail, addErrorMail, onSendMail } from '../actions';

const mapStateToProps = ({ messages, mailbox, profile, user }) => ({ messages, mailbox, profile, user });

const mapDispatchToProps = {
  loadMoreMessages,
  decrementMailCount,
  initErrorMessages,
  removeErrorMessage,
  removeErrorMail,
  fetchMessage,
  addErrorMail,
  onSendMail
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesListComponent);