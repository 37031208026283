import * as TYPES from '../constants/types';

const initialState = 0;

export default (state = initialState, action) => {
  switch (action.type) {
  case TYPES.GET_INITIAL_COUNT:
    return action.count;
  case TYPES.INCREMENT_INBOX_VALUE:
    return state + 1;
  case TYPES.DECREMENT_INBOX_VALUE:
    return state - 1;
  default:
    return state;
  }
};

