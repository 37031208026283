import { connect } from 'react-redux';

import AppComponent from '../components/App';
import {
  addMessage,
  addUserToOnlineList,
  createNewContact,
  fetchCatalog,
  fetchProfile,
  fetchWizardOptions,
  getContactsList,
  getInitialMailCount,
  getInitialMessagesCount,
  incrementMailCount,
  incrementMessagesCount,
  initOnlineList,
  receiveMessage,
  removeUserFromOnlineList,
  updateContact,
  updateContactsInfo,
  updateProfileInfo
} from '../actions';

const mapDispatchToProps = {
  fetchCatalog,
  getInitialMailCount,
  updateContact,
  createNewContact,
  incrementMailCount,
  addMessage,
  receiveMessage,
  updateProfileInfo,
  updateContactsInfo,
  initOnlineList,
  addUserToOnlineList,
  removeUserFromOnlineList,
  getInitialMessagesCount,
  incrementMessagesCount,
  fetchProfile,
  fetchWizardOptions,
  getContactsList,
};

const mapStateToProps = ({ profile, contacts, inbox, messages }) => ({ profile, contacts, inbox, messages });

export default connect(mapStateToProps, mapDispatchToProps)(AppComponent);