import React, { Component } from 'react';
import { Table } from './styles';

class PriceTable extends Component {
  render() {
    return (
      <div>
        <Table>
          <thead>
            <tr>
              <th>
                Item
              </th>
              <th>
                Price credits
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Chat message</td>
              <td>1 <small>(Per 70 symbols)</small></td>
            </tr>
            <tr>
              <td>Mail</td>
              <td>10 <small>(Per 1 letter)</small></td>
            </tr>
            <tr>
              <td>Private album</td>
              <td>30 <small>(Once per album)</small></td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

export default PriceTable;