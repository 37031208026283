import React, {PureComponent} from 'react';
import FileDragAndDrop from 'react-file-drag-and-drop';
import styled from 'styled-components';
import Cropper from 'react-easy-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Slider from './InputSlider';
import WebcamCapture from './Webcam';
import {btn} from '../../styles';
import {Modal} from '../../Modal';
import {uploadPhoto} from '../../../api';
import * as Icons from '../../../assets';
import {isMobile} from '../../../utils/mobile';
import './index.css';

class Photo extends PureComponent {
  state = {
    src: null,
    crop: {x: 0, y: 0},
    zoom: 1,
    aspect: 3 / 3,
    error: '',
    croppedAreaPixels: null,
    croppedImage: null,
    uploaded: false,
  };

  componentDidMount() {
    if (this.props.photo) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({src: reader.result}),
      );
      reader.readAsDataURL(this.props.photo);
      this.uploadPhoto(this.props.photo);
    }
  }

  handleError = message => {
    this.setState({error: message, src: !isMobile() ? null : this.state.src, croppedImageUrl: ''});
  };

  uploadPhoto = photo => {
    const form = new FormData();
    form.append('file', photo);

    this.setState({error: '', uploaded: false});

    uploadPhoto({body: form})
      .then(response => {
        this.setState({uploaded: true});

        if (response && response.status === 422) {
          return response.json();
        } else if (!response) {
          this.handleError('ERROR file is too big! Max file size 10MB.');
        }
      })
      .then(response => {
        if (response && response.errors) {
          let message = Object.keys(response.errors).map(error => response.errors[error][0])[0];
          this.handleError(message);
        }
      });
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({src: reader.result}));
      reader.readAsDataURL(e.target.files[0]);

      this.uploadPhoto(e.target.files[0]);
    }
  };

  handleDrop = dataTransfer => {
    const reader = new FileReader();
    reader.addEventListener('load', () => this.setState({src: reader.result}));
    reader.readAsDataURL(dataTransfer.files[0]);
    this.uploadPhoto(dataTransfer.files[0]);
  };

  handleWebcamCapture = file => {
    const reader = new FileReader();
    reader.addEventListener('load', () => this.setState({src: reader.result, camera: false}));
    reader.readAsDataURL(file);
    this.uploadPhoto(file);
  };

  uploadFile = () => {
    if (this.state.src && !this.state.error) {
      this.props.uploadFile(this.state.src, this.state.croppedAreaPixels);
    }
  };

  onCropChange = crop => {
    this.setState({crop});
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({croppedAreaPixels});
  };

  onZoomChange = zoom => {
    this.setState({zoom: +zoom});
  };

  toggleWebcam = () => this.setState({camera: !this.state.camera});

  onCloseModal = () => {
    this.setState({src: null, croppedImageUrl: '', uploaded: false, error: ''});
    this.props.onClose();
  };

  render() {
    const {crop, camera, src, zoom, aspect, uploaded, error} = this.state;
    return (
      <Modal isOpen={this.props.isOpen}>
        <Container ref={this.props.innerRef}>
          <Title>Create profile picture</Title>
          <Wrapper>
            {
              src ? (
                <div>
                  {
                    uploaded ? (
                      error ? isMobile() && (
                        <div>
                          <PreviewWrapper>
                            <PreviewPhoto src={src} alt="" style={{width: '100%'}}/>
                            <Loader src={Icons.error} style={{maxWidth: 70, opacity: 0.7}}/>
                          </PreviewWrapper>
                        </div>
                      ) : (
                        <Cropper
                          image={src}
                          crop={crop}
                          zoom={zoom}
                          aspect={aspect}
                          showGrid={false}
                          onCropChange={this.onCropChange}
                          onCropComplete={this.onCropComplete}
                          onZoomChange={this.onZoomChange}
                        />
                      )
                    ) : (
                      <PreviewWrapper>
                        <PreviewPhoto src={src} alt="" style={{width: '100%'}}/>
                        <Loader src={Icons.loader}/>
                      </PreviewWrapper>
                    )
                  }
                  {!Boolean(error) && (
                    <SliderWrapper uploaded={uploaded}>
                      <Slider
                        range={zoom}
                        minValue='1'
                        maxValue='3'
                        step='0.05'
                        updateRange={this.onZoomChange}
                      />
                    </SliderWrapper>
                  )}
                </div>
              ) : camera ? (
                <WebcamCapture onCapture={this.handleWebcamCapture}/>
              ) : !isMobile() && (
                <FileDragAndDrop onDrop={this.handleDrop}>
                  <StyledDrop>
                    <UploadIcon>
                      <i className='fal fa-cloud-upload'/>
                    </UploadIcon>
                    <UploadFormat>
                      JPG . JPEG . PNG
                      <span style={{display: 'flex', justifyContent: 'center', whiteSpace: 'nowrap'}}>Max image file size 10MB.</span>
                    </UploadFormat>
                    <UploadText>You can also upload files from</UploadText>
                    <InputFile type='file' name='file' id='file' onChange={this.onSelectFile}/>
                    <UploadButton as='label' htmlFor='file'>From Computer</UploadButton>
                    <UploadButton onClick={this.toggleWebcam}>From Webcam</UploadButton>
                  </StyledDrop>
                </FileDragAndDrop>
              )
            }
            <FooterWrapper>
              {
                (error || this.props.error) && (
                  <Error>
                    <i className="fas fa-exclamation-triangle"/>
                    <span>{this.state.error || this.props.error}</span>
                  </Error>
                )
              }
              <ButtonContainer>
                <Button as='button' close={this.onCloseModal} onClick={this.onCloseModal}>CANCEL</Button>
                <Button as='button' onClick={this.uploadFile}>SAVE CHANGES</Button>
              </ButtonContainer>
            </FooterWrapper>
          </Wrapper>
        </Container>
      </Modal>
    );
  }
}

export default Photo;

const Error = styled.div`
  display: flex;
  align-items: center;
  color: #ff0000;
  
  i {
    font-size: 1.5rem;
    margin-right: .5rem;
  }
  
  span {
    width: 8rem;
    font-weight: bold;
    font-size: .75rem;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  
  @media (max-width: 767px) {
    flex-direction: column;
    
    ${Error} {
      margin-bottom: 10px;
    }
  }
`;

const PreviewWrapper = styled.div`
  position: relative;
`;

const Loader = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 40px;
`;

const SliderWrapper = styled.div`
  opacity: 1;
  visibility: visible;

  ${({uploaded}) => !uploaded && `
    opacity: 0;
    visibility: hidden;
  `}
`;

const PreviewPhoto = styled.img`
  width: 100%;
  object-fit: contain;
  max-height: 60vh!important;
  filter: blur(5px);
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

const Button = styled(btn)`
	min-width: 128px;
  line-height: 30px;
  height: 30px;

	${({close}) => !close ? 'width: 170px;' : `
		margin-left: auto;
    margin-right: 10px;
    color: #b8bfd3;
    border-color: #b8bfd3;
    background-color: #fff;
    
    :focus {
      background-color: rgba(221, 44, 87, .1);
		}
	
		:hover {
      background-color: rgba(221, 44, 87, .1);		
		}
	`}
	
	@media (max-width: 767px) {
	  width: 50%;
	}
`;

const UploadButton = styled(btn)`
  letter-spacing: normal;
   
	:last-of-type {
    margin-left: 16px
	}
	
  min-width: 100px;
  height: 1.5rem;
  line-height: 1.5rem;
  color: #3b5a9a;
  border-color: #3b5a9a;
  background-color: #fff;

	:focus {
    background-color: rgba(221, 44, 87, .1);
  }
  
  :hover {
    background-color: rgba(221, 44, 87, .1);
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
  @media (max-width: 767px) {
    margin-bottom: 5.5rem;
  }
`;

const UploadIcon = styled.div`
	font-size: 48px;
  color: #3b5a9a;
  margin-top: 30px;
`;

const UploadText = styled.span`
	font-weight: 600;
  width: 100%;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 10px;
  letter-spacing: -.1px;
`;

const UploadFormat = styled.span`
	display: block;
  margin-top: 10px;
  width: 100%;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -.1px;
  text-align: center;
  color: rgba(35, 35, 39, .84);
`;

const InputFile = styled.input`
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;

const StyledDrop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  width: 509px;
  height: 246px;
  margin-bottom: 20px;
  background: url(${require('../../../assets/img/photo-upload-bg.svg')}) center/cover no-repeat;
`;

const Container = styled.div`
  position: relative;
  max-width: 570px;
  width: 100%;
  padding: 30px;
  margin: 90px auto auto;
  background-color: #fff;
  cursor: default;
  border-radius: 2px;
  
  @media (max-width: 767px) {
    padding: 0;
    margin: 0;
    max-width: 100%;
    padding: 0 1.15rem;
  }
`;

const Title = styled.b`
	display: block;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 28px;
  
  @media (max-width: 767px) {
    margin: 1rem 0px;
  }
`;
//
// const imageStyle = {
//   objectFit: 'cover',
//   width: '100%',
//   height: '100%',
//   maxHeight: isMobile() ? 'auto' : 600,
// };