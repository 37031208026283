import React, { useState } from 'react';
import styled from 'styled-components';

import Subscription from '../containers/Subscription';

const buttons = [
  {
    icon: 'fa-heart',
    name: 'search',
    path: {
      main: '/catalog',
      includes: ['/profile']
    }
  },
  {
    icon: 'fa-envelope',
    name: 'mail',
    path: {
      main: '/mailbox'
    }
  },
  {
    icon: 'fa-user-friends',
    name: 'chat',
    path: {
      main: '/contacts'
    }
  },
  {
    icon: 'fa-user',
    name: 'profile',
    path: {
      main: '/me',
      includes: ['/settings']
    }
  },
];

const IconLink = ({icon, name, path, checkSubscribe, pathname, unreadMessages, letters, handleClick, history}) => {
  const active = pathname.includes(path.main) || (path.includes && path.includes.some(path => pathname.includes(path)));
  const link = path.main;

  const onClick = () => checkSubscribe ? handleClick(link) : history.push(link);

  return (
    <IconContainer active={active}>
      <div style={styledLink} onClick={onClick}>
        <i className={active ? ('fas ' + icon) : 'far ' + icon} style={{color: active ? '#3b5a9a' : '#4b4c59'}}/>
        <span>{name}</span>
        {name === 'chat' && (Boolean(unreadMessages) && (<Number>{unreadMessages > 99 ? '99+' : unreadMessages}</Number>))}
        {name === 'mail' && (Boolean(letters) && (<Number>{letters > 99 ? '99+' : letters}</Number>))}
      </div>
    </IconContainer>
  );
};

const MobileNavigation = ({history, unreadMessages, inbox, profile}) => {
  const [isOpen, toggleSubscriptionPanel] = useState(false);

  const pathname = history.location.pathname;

  const handleClick = link => {
    if ((profile.last_subscription && profile.last_subscription.is_active) || profile.is_agency) {
      history.push(link);
    } else {
      toggleSubscriptionPanel(true);
    }
  };

  return (
    <>
      <Container path={pathname}>
        <Wrapper>
          <Nav>
            {
              buttons.map(button => (
                <IconLink
                  key={button.path.main}
                  pathname={pathname}
                  history={history}
                  letters={inbox}
                  handleClick={handleClick}
                  unreadMessages={unreadMessages}
                  {...button}
                />
              ))
            }
          </Nav>
        </Wrapper>
      </Container>
      {isOpen && (
        <Subscription onClose={() => toggleSubscriptionPanel(false)}/>
      )}
    </>
  );
};

export default MobileNavigation;

const styledLink = {
  position: 'relative',
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

const Number = styled.span`
  position: absolute;
  top: -8px;
  left: 11px;
	font-size: .75rem;
  display: flex;
  align-items: center;
  padding: 0 .3625rem;
  height: 1rem;
  letter-spacing: .046875rem;
  color: #fff;
  border-radius: .8125rem;
  background-color: #3b5a9a;
`;

const Container = styled.footer`
  position: fixed;
  z-index: 1050;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
    
  @media (max-width: 767px) {
    display: block;
  }
  
  ${({path}) => path.includes('messages') && `
    @media (max-width: 767px) {
      display: none;
    }
  `}
`;

const Wrapper = styled.div`
  box-shadow: 0 -2px 4px 0 #edeff4;
  height: 3rem;
  background-color: #fff;
  max-width: 71.25rem;
  margin: 0 auto;
  padding-right: .625rem;
  padding-left: .625rem;
`;

const Nav = styled.ul`
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  justify-content: space-around;
  align-items: center;
`;

const IconContainer = styled.li`
  font-size: 1rem;
  color: #4b4c59;
  
  span {
    font-size: .75rem;
    font-weight: ${props => props.active ? 600 : 400};
  }
`;