import {connect} from 'react-redux';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';

import MailboxComponent from '../components/MobileNavigation';

const mapStateToProps = ({inbox, unreadMessages, profile}) => ({inbox, unreadMessages, profile});

const enhance = compose(withRouter, connect(mapStateToProps, null));

export default enhance(MailboxComponent);