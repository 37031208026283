import React from 'react';
import styled from 'styled-components';
import {DropzoneComponent} from 'react-dropzone-component';
import 'react-dropzone-component/styles/filepicker.css';
import 'dropzone/dist/min/dropzone.min.css';

import {btn} from './styles';

class DropZone extends React.Component {
  eventHandlers = {
    success: (file, response) => {
      this.props.handleStatus && this.props.handleStatus(file);
      this.props.input && this.props.input.onChange([...this.props.input.value, response.data.id]);
    },

    removedfile: file => {
      const index = this.props.input && this.props.input.value.indexOf(JSON.parse(file.xhr.response).data.id);

      this.props.input && this.props.input.onChange([
        ...this.props.input.value.slice(0, index),
        ...this.props.input.value.slice(index + 1)
      ]);
    },

    error: response => {
      const message = (response.xhr && response.xhr.response) ? JSON.parse(response.xhr.response).errors.file[0] : 'ERROR file is too big! Max file size 10MB.';
      this.props.handleStatus && this.props.handleStatus(response.status, message);
    },
  };

  djsConfig = {
    addRemoveLinks: true,
    timeout: 30000,
    maxFiles: this.props.maxFiles || 50,
    maxFilesize: 10,
    headers: {
      'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      'Cache-Control': null,
      'X-Requested-With': null
    },
  };

  componentConfig = {
    iconFiletypes: ['.jpg', '.jpeg', '.png', '.gif'],
    postUrl: process.env.REACT_APP_BASE_API_URL + '/me/photos/',
  };

  handleClick = e => e.preventDefault();

  render() {
    return (
      <Dropzone>
        <DropzoneComponent
          eventHandlers={this.eventHandlers}
          config={this.componentConfig}
          djsConfig={this.djsConfig}
        >
          <div className="dz-message">
            <IconUpload>
              <i className="fal fa-cloud-upload"/>
            </IconUpload>
            <UploadIconFormats>
              JPG . JPEG . PNG
              <span style={{
                display: 'flex',
                justifyContent: 'center',
                whiteSpace: 'nowrap'
              }}>Max image file size 10MB.</span>
            </UploadIconFormats>
            <UploadText>You can also upload files from</UploadText>
            <ButtonWrapper>
              <UploadButton onClick={this.handleClick}>From your device</UploadButton>
            </ButtonWrapper>
          </div>
        </DropzoneComponent>
      </Dropzone>
    );
  }
}

export default DropZone;

const Dropzone = styled.div`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const UploadButton = styled(btn)`
  letter-spacing: normal;
	margin-top: 10px;
  min-width: 100px;
  height: 1.5rem;
  line-height: 1.5rem;
  color: #3b5a9a;
  border-color: #3b5a9a;
  background-color: #fff;
  font-size: .75rem !important;

	:focus {
    background-color: rgba(221, 44, 87, .1);
  }
  
  :hover {
    background-color: rgba(221, 44, 87, .1);
  }
`;

const UploadText = styled.span`
	font-weight: 600;
  width: 100%;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 10px;
  letter-spacing: -.1px;
  font-size: 13px;
`;

const IconUpload = styled.div`
	font-size: 48px !important;
  color: #3b5a9a !important;
  i {
    font-weight: 300 !important;  
  }
`;

const UploadIconFormats = styled.span`
	display: block;
  margin-top: 10px;
  margin-bottom: 35px;
  width: 100%;
  font-size: 13px !important;
  font-weight: 400;
  letter-spacing: -.1px;
  text-align: center;
  color: rgba(35, 35, 39, .84);
`;
