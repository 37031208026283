import styled from 'styled-components';

export const Content = styled.div`
	display: flex;
  align-items: flex-start;
  max-width: 71.25rem;
  padding-left: 10px;
  padding-right: 10px;
  margin: 20px auto;
  
  @media (max-width: 767px) {
    padding: 0;
    margin: 0 auto 30px auto;
    margin-bottom: 3rem !important;
  }
`;

export const Container = styled.div`
	position: fixed;
  height: 80px;
  z-index: 999;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: rgb(199, 195, 195) 0px 0px 5px 0px;
  width: 100%;
  margin: 0px auto;
  top: 0;
`;

export const Loader = styled.img`
  width: 50px;
  display: inline-block;
  border-radius: 15px;
  right: 0;
  left: 0;
  margin: auto;
  top: 41%;
  position: absolute;
`;

export const Button = styled.button`
	font-size: .85rem;
	letter-spacing: .8px;
	font-weight: bold;
	color: #fff;
	outline: none;
	border: none;
	cursor: pointer;
	box-shadow: rgb(199, 195, 195) 0px 0px 15px 0px;
	margin: 0 5%;
	padding: 9px 15px;
	background-color: ${props => props.theme ? props.theme : '#555'}
`;

export const ThreeDot = styled.b`
	display: flex;
  cursor: pointer;
  label {
    cursor: pointer;
  }
`;

export const Dot = styled.label`
	width: 5px;
  margin-right: 5px;
  border-radius: 50%;
  height: 5px;
  background-color: #ccc7c7;
`;

export const Menu = styled.div`
	position: absolute;
  z-index: 1;
  box-shadow: rgb(199,195,195) 0px 0px 5px 0px;
  background-color: #fff;
  width: 135px;
  margin-top: 10px;
  right: 5%;
`;

export const Option = styled.div`
  padding: 7px 8px;
  font-size: 12px;
  text-align: left;
  display: flex;
	
	span {
		color: #4b4c59;
	}
	
	:hover {
   background-color: #edeff4;
	}
`;

export const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Star = styled.button`
  padding: 0;
  margin-right: 6px;
  font-size: 1.5rem;
  color: ${props => props.isFavorite ? '#fc8330e3' : '#b8bfd3'} !important;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  
  :focus {
    outline: none;
  }
  
  @media (max-width: 767px) {
    font-size: 1.15rem;
  }
`;

export const Actions = styled.div`
	display: flex;
	align-items: center;
`;

export const Close = styled.span`
  background: 0;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  color: #b8bfd3;
  cursor: pointer;
  
  :hover {
    color: #4b4c59;
  }
`;

export const TabsItem = styled.div`
  position: relative;
  padding-bottom: .625rem;
  text-align: center;
  flex-grow: 1;
  cursor: pointer;
  
  ${({active}) => active && `
    font-weight: 700;
    color: #3b5a9a;
    &::before {
	    content: '';
	    position: absolute;
	    bottom: 0;
	    left: 0;
	    height: .125rem;
	    width: 100%;
	    background-color: #3b5a9a;
    }
  `}
  
  @media (max-width: 767px) {
    margin: 0 .9375rem;
  }
`;

export const btn = styled.button`
	font-size: .75rem;
  font-weight: 600;
  line-height: 1.875rem;
  display: inline-block;
  min-width: 8rem;
  height: 1.875rem;
  padding: 0 .3125rem;
  user-select: none;
  transition: .3s;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  letter-spacing: .05rem;
  text-transform: uppercase;
  color: #fff;
  border: .0625rem solid transparent;
  border-radius: .125rem;
  background-color: #3b5a9a;
  touch-action: manipulation;
  outline: none;
  
	:focus {
		text-decoration: none;
		cursor: pointer;
		outline: 0;
		background-color: #4c6dae;
	};
	
	:hover {
		text-decoration: none;
		cursor: pointer;
		outline: 0;
		background-color: #4c6dae;
	}
	
	@media (max-width: 480px) {
	  min-width: 0;
	}
`;

export const CheckboxCustom = styled.span`
  position: relative;
  flex-shrink: 0;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  border: 1px solid #b8bfd3;
  background-color: #fff;
  border-radius: 2px;
`;

export const Checkbox = styled.label`
  position: relative;
  display: flex;
  align-items: flex-start;
  user-select: none;
  cursor: pointer;

  :focus ~ ${CheckboxCustom} {
    border-color: #3b5a9a
  }
`;

export const CheckboxInput = styled.input`
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  
  ${({checked}) => checked && `
    ~ ${CheckboxCustom} {
      border-color: #3b5a9a;
      background-color: #3b5a9a;
       
      &::before {
        content: '';
        position: absolute;
        top: 4px;
        left: 3px;
        transform: rotate(-45deg);
        width: 7px;
        height: 5px;
        border: 2px solid #fff;
        border-top: none;
        border-right: none
      }
    }
  `} 
`;

export const CheckboxText = styled.span`
  white-space: initial;
  line-height: 1
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #b8bfd3;
  margin-top: 3px;
  letter-spacing: 2px
  margin-bottom: 3rem;
`;

