import React, {Component} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import {isMobile} from '../utils/mobile';

const Content = ({onClose, src}) => (
  <PhotoWrapper>
    <Close onClick={onClose}><i className='fal fa-times'/></Close>
    <PhotoContainer>
      <AlbumPhoto src={src}/>
    </PhotoContainer>
  </PhotoWrapper>
);

class PhotoView extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.handleClose);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleClose);
  }

  handleClose = event => {
    if (event.key === 'Escape') {
      this.props.onClose();
    }
  };

  render() {
    const {src, onClose, isOpen, isModal} = this.props;

    const styles = isMobile() ? mobileStyles : customStyles;

    const props = {onClose, src};

    if (isModal) {
      return (
        <Modal
          style={styles}
          ariaHideApp={false}
          isOpen={isOpen}
        >
          <Content {...props} />
        </Modal>
      );
    }

    return (
      <Content {...props} />
    );
  }
}

export default PhotoView;

const mobileStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    maxHeight: '100vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '100%',
    border: 0,
    padding: 0,
    marginRight: '-50%',
    width: '100%',
    overflow: 'inherit',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0)'
  }
};

const customStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    maxHeight: '100vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '100%',
    border: 0,
    width: '100%',
    padding: 0,
    marginRight: '-50%',
    overflow: 'inherit',
    transform: 'translate(-50%, -50%)',
    background: 'transparent'
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.8)'
  }
};

const PhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const AlbumPhoto = styled.img`
	max-width: 90vw;
  object-fit: contain;
  max-height: 90vh;
  padding: 15px 0;
  
  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 48px;
  }
`;

const Close = styled.span`
  z-index: 1;
  right: 0
  top: 0;
  position: absolute;
  cursor: pointer;
  background: 0;
  padding: 0;
  border: 0;
  font-size: 2.5rem;
  color: #fff;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .4s;
  
  i {
    opacity: .8;
    transition: opacity .4s;
  }
  
  :hover {
    background-color: rgb(0, 0, 0, .4);

    i {  
      opacity: 1;
    }
  }
  
  @media (max-width: 767px) {
    font-size: 1.75rem;
    background-color: rgb(0, 0, 0, .4);
  }
`;


const PhotoContainer = styled.div`
	margin: 0;
	position: relative;
	display: flex;
	justify-content: center;
  border-radius: 2px;
  cursor: pointer;
  
  span {
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: red;
    font-weight: 600;
  }
`;
