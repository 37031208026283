import React, {useState} from 'react';

import {CardInputWrapper} from './styles';
import {GetCardType} from '../../utils/GetCardType';

const CardField = ({maxLength, handleTextChange, input, i, meta, autoFocus, id}) => {
  const [value, setValue] = useState('');

  const handleInputChange = ({target}) => {
    let card = target.value.replace(/ /gi, '');

    if (!isNaN(card) && card.length <= 19) {
      if ((target.value.length < value.length)) {
        setValue(target.value);
      } else {
        setValue(target.value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 '));
      }
    }

    input && input.onChange(card);

    if (card.length === maxLength) {
      autoFocus(id);
    }
  };

  const onKeyUp = e => {
    if (e.keyCode === 13) {
      autoFocus(id);
    }
  };

  const icon = GetCardType(value.replace(/ /gi, '')).icon;

  return (
    <CardInputWrapper
      style={{border: (meta && meta.touched && meta.error) ? '.0625rem solid red' : '.0625rem solid #dadee8'}}>
      <img src={icon || require('../../assets/icons/creditcard-solid.png')} alt=""/>
      <input
        onKeyUp={onKeyUp}
        type="text"
        name="number"
        placeholder="0000 0000 0000 0000"
        value={value}
        onChange={handleInputChange}
      />
    </CardInputWrapper>
  );
};

export default CardField;