import styled from 'styled-components';

export const CardInputWrapper = styled.div`
  display: flex;
  padding-left: .5625rem;
  align-items: center;
  margin: 0 .25rem .625rem;
  border: .0625rem solid #dadee8;

  img {
    width: 100%;
    max-width: 1.5rem;
  }
  
  input {
    height: 1.875rem;
    width: 100%;
    padding: 0 .625rem;
    outline: 0;
    border: 0 !important;  
      
    ::-webkit-input-placeholder {
      color: #b8bfd3
    }
      
    ::-moz-placeholder {
      color: #b8bfd3;
      opacity: 1
    }
  }
`;