import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  CreditsProfile,
  CreditsSubTitle,
  ListAbout,
  ListIconActive,
  ListIconInActive,
  ListItem,
  ListMessage,
  ListWrapper
} from '../Credits/styles';
import { basicUsersFields } from '../../constants';

export default function FreeCredits({ onCloseModal, profile }) {
  const history = useHistory();
  
  const handleEditProfileClick = () => {
    onCloseModal();
    
    const state = !Boolean(profile.photo) ? 'editPhoto' : !Boolean(profile.interests && profile.interests.length) ? 'editInterests' : 'editMode';
    
    history.push({
      pathname: '/me',
      state: { [state]: true }
    });
  };
  
  const isProfileCompleted = basicUsersFields.every(field => {
    return Array.isArray(profile[field]) ? profile[field].length : profile[field];
  });
  
  const isEmailVerified = Boolean(profile.email_verified_at);
  
  if (isEmailVerified && isProfileCompleted) {
    return null;
  }
  
  return (
    <>
      <CreditsSubTitle>Win free credits</CreditsSubTitle>
      
      <ListWrapper>
        <ListItem>
          <ListMessage>Registration</ListMessage>
          <ListIconActive/>
          <ListAbout/>
        </ListItem>
        
        <ListItem>
          <ListMessage>Email confirmation</ListMessage>
          {isEmailVerified ? <ListIconActive/> : <ListIconInActive/>}
          {isEmailVerified ? <ListAbout/> : <ListAbout>Awaiting</ListAbout>}
        </ListItem>
        <ListItem>
          <ListMessage>the Profile filling out</ListMessage>
          {isProfileCompleted ? <ListIconActive/> : <ListIconInActive/>}
          {isProfileCompleted ? <div/> : (
            <CreditsProfile onClick={handleEditProfileClick}>Go to profile</CreditsProfile>
          )}
        </ListItem>
      </ListWrapper>
    </>
  );
}