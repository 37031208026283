import { withFailureStatus, withSuccessStatus } from '../utils/withData';
import * as TYPES from '../constants/types';
import {
  initEmailErrorsList,
  removeLetterFromLocalStorage,
  saveLetterToLocalStorage
} from '../components/Chat/LocalStorage';

const initialState = {
  data: [],
  errorEmails: initEmailErrorsList(),
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
  case TYPES.FETCH_MAIL_HISTORY_REQUEST:
    return {
      ...state,
      loading: true,
      error: null
    };

  case TYPES.FETCH_MAIL_HISTORY_SUCCESS:
    return withSuccessStatus(state, action.mailbox);

  case TYPES.FETCH_MAIL_HISTORY_FAILURE:
    return withFailureStatus(state, [], action.error);

  case TYPES.FETCH_MORE_MAILS_REQUEST:
    return {
      ...state,
      fetching: true,
      error: null
    };

  case TYPES.FETCH_MORE_MAILS_SUCCESS:
    return {
      ...state,
      data: [...state.data, ...action.mailbox.data],
      links: action.mailbox.links,
      page: action.page,
      fetching: false
    };

  case TYPES.FETCH_MORE_MAILS_FAILURE:
    return {
      ...state,
      fetching: false,
      error: action.error
    };

  case TYPES.READ_LETTER: {
    if (action.letter) {
      const index = state.data.findIndex(letter => letter.id === action.letter.id);
      return {
        ...state,
        data: [
          ...state.data.slice(0, index),
          action.letter,
          ...state.data.slice(index + 1)
        ]
      };
    } else {
      return state;
    }
  }
  
  case TYPES.REMOVE_LETTER : {
    const index = state.data.findIndex(letter => letter.id === action.letter.id);
    return {
      ...state,
      data: [
        ...state.data.slice(0, index),
        ...state.data.slice(index + 1)
      ]
    };
  }
  
  case TYPES.INIT_ERROR_MAILS: {
    return {
      ...state,
      errorEmails: initEmailErrorsList()
    };
  }
  
  case TYPES.ADD_ERROR_MAIL: {
    return {
      ...state,
      errorEmails: saveLetterToLocalStorage(action.payload)
    };
  }
  
  case TYPES.REMOVE_ERROR_MAIL: {
    return {
      ...state,
      errorEmails: removeLetterFromLocalStorage(action.payload)
    };
  }
    
  case TYPES.SEND_LETTER: {
    return {
      ...state,
      data: [action.payload, ...state.data],
    };
  }
  
  default:
    return state;
  }
};