import {withSuccessStatus, withFailureStatus} from '../utils/withData';
import * as TYPES from '../constants/types';

const initialState = {
  data: [],
  error: null,
  loading: false,
  isLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case TYPES.FETCH_CONTACTS_REQUEST:
    return {...state, loading: true, error: null};

  case TYPES.FETCH_CONTACTS_SUCCESS:
    return withSuccessStatus(state, action.contacts);

  case TYPES.FETCH_CONTACTS_FAILURE:
    return withFailureStatus(state, [], action.error);

  case TYPES.ADD_TO_FAVORITE_LIST: {
    const index = state.data.findIndex(contact => contact.id === action.contact.id);

    if (~index) {
      return {
        ...state,
        data: [
          ...state.data.slice(0, index),
          Object.assign({}, state.data[index], {is_favorite: true}),
          ...state.data.slice(index + 1)
        ]
      };
    }

    return {
      ...state,
      data: [
        ...state.data,
        action.contact
      ]
    };
  }

  case TYPES.DELETE_FROM_FAVORITE_LIST: {
    const index = state.data.findIndex(contact => contact.id === action.contact.id);
    const notFavorite = Object.assign({}, state.data[index], {is_favorite: false});

    if (notFavorite.last_message) {
      return {
        ...state,
        data: [
          ...state.data.slice(0, index),
          notFavorite,
          ...state.data.slice(index + 1)
        ]
      };
    }

    return {
      ...state,
      data: [
        ...state.data.slice(0, index),
        ...state.data.slice(index + 1)
      ]
    };
  }

  case TYPES.UPDATE_CONTACTS_INFO: {
    const index = state.data.findIndex(contact => contact.id === action.profile_id);

    if (~index) {
      const contact = {
        ...state.data[index],
        ...action.data
      };

      return {
        ...state,
        data: [
          ...state.data.slice(0, index),
          contact,
          ...state.data.slice(index + 1)
        ]
      };
    }

    return state;
  }

  case TYPES.MARK_MESSAGES_AS_READ: {
    const index = state.data.findIndex(contact => contact.id === action.contactId);
    const contact = Object.assign({}, state.data[index], {
      last_message: {...state.data[index].last_message, read_at: true}
    });

    return {
      ...state,
      data: [
        ...state.data.slice(0, index),
        contact,
        ...state.data.slice(index + 1)
      ]
    };
  }

  case TYPES.UPDATE_LAST_MESSAGE: {
    const index = state.data.findIndex(contact => contact.id === action.userId);
    const updatedContact = Object.assign({}, state.data[index], {...state.data[index], last_message: action.message});
    return {
      ...state,
      data: [
        ...state.data.slice(0, index),
        updatedContact,
        ...state.data.slice(index + 1)
      ]
    };
  }

  case TYPES.CREATE_NEW_CONTACT: {
    const findIndex = state.data.findIndex(({ id }) => action.contact.id === id);
    
    if (findIndex === -1) {
      return {
        ...state,
        data: [
          ...state.data,
          action.contact
        ]
      };
    }
    
    return state;
  }

  default:
    return state;
  }
};

