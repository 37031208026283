import React from 'react';
import { useHistory } from 'react-router';
import { Table } from './styles';
import { basicUsersFields } from '../../constants';

const PriceTable = ({ profile }) => {
  const history = useHistory();
  
  const handleEditProfileClick = () => {
    const state = !Boolean(profile.photo) ? 'editPhoto' : !Boolean(profile.interests && profile.interests.length) ? 'editInterests' : 'editMode';
    
    history.push({
      pathname: '/me',
      state: { [state]: true }
    });
  };
  
  const isProfileCompleted = basicUsersFields.every(field => {
    return Array.isArray(profile[field]) ? profile[field].length : profile[field];
  });
  
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Credits</th>
            <th>How to get</th>
            <th>status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>10</td>
            <td>Registration</td>
            <td><div className="credit-status is-active"/></td>
          </tr>
          <tr>
            <td>10</td>
            <td>Email confirmation</td>
            <td>
              {profile.email_verified_at ? (
                <div className="credit-status is-active"/>
              ) : (
                <div className="credit-status"/>
              )}
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td>the Profile filling out</td>
            <td>
              {isProfileCompleted ? (
                <div className="credit-status is-active"/>
              ) : (
                <button className="blue-button blue-button--profile" onClick={handleEditProfileClick}>Go to profile</button>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default PriceTable;