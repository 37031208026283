import React from 'react';
import styled from 'styled-components';

const Range = ({updateRange, range, step, maxValue, minValue}) => {
  const incrementRange = () => updateRange(+range + +step);

  const decrementRange = () => updateRange(+range - +step);

  return (
    <RangeWrapper>
      <i className='far fa-minus' onClick={decrementRange}/>
      <Slider
        type="range"
        value={JSON.stringify(range)}
        min={minValue}
        max={maxValue}
        step={step}
        onChange={e => updateRange(e.target.value)}
      />
      <i className='far fa-plus' onClick={incrementRange}/>
    </RangeWrapper>
  );
};

export default Range;

const RangeWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  margin-top: 29px;
  
  i {
    color: #4b4c59;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  i:first-child {
    padding-right: 16px;
  }
  
  i:last-child {
    padding-left: 16px;
  }
  
  input::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: #4b4c59;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(81, 81, 81, 0.2);
  }

  input::-moz-range-thumb {
    -webkit-appearance: none;
    border: 0;
    height: 1.5rem;
    width: 1.5rem;
    box-shadow: 0 2px 4px 0 rgba(81, 81, 81, 0.2);
    border-radius: 50%;
    background: #4b4c59;
    cursor: pointer;
  }

  input::-moz-range-track {
    -webkit-appearance: none;
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: lightblue;
    border-radius: 5px;
  }
`;

const Slider = styled.input`
  outline: none;
  background: #dadee8;
  height: 6px;
  -webkit-appearance: none;
  min-width: 200px;
  width: 100%;
  border-radius: 5px;
`;
