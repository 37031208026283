import React, { createRef, useState } from 'react';
import { ErrorButton, Option, OptionItemIcon, OptionList, StyledWrapper } from '../styles';
import ErrorMessageSvg from '../../../assets/svg/errorMessage.svg';
// import useOnClickOutside from '../hooks/useClickOutside';

const ErrorMessageActions = ({ isError, sendMessage, sendLetter, message, removeMessage, onTouchStart }) => {
  const [open, setOpen] = useState(false);
  const ref = createRef();
  
  const toggleDropdownActions = () => {
    setOpen(prevState => !prevState);
  };

  const handleDeleteMessage = () => {
    removeMessage(message);
    
    setOpen(false);
  };
  
  const handleResendMessage = () => {
    removeMessage(message);
    
    if (sendMessage) {
      sendMessage(message.text, message.profile_id);
    }
    
    if (sendLetter) {
      sendLetter(message);
    }
    
    setOpen(false);
  };
  
  const handleToggleTouch = () => {
    if (onTouchStart) {
      toggleDropdownActions();
    }
  };
  
  const handleResendTouch = () => {
    if (onTouchStart) {
      handleResendMessage();
    }
  };
  
  // useOnClickOutside(ref, () => handleClose());
  
  if (!isError) {
    return null;
  }
  
  return (
    <StyledWrapper ref={ref}>
      <div>
        <ErrorButton isError={isError} onClick={toggleDropdownActions} onTouchStart={handleToggleTouch}>
          <img src={ErrorMessageSvg} alt="error_alt"/>
        </ErrorButton>
       
        <OptionList open={open} className="error-optionList">
          <Option onClick={handleResendMessage} onTouchStart={handleResendTouch}>
            <OptionItemIcon>
              {/*{option.icon && (<i className={option.icon}/>)}*/}
            </OptionItemIcon>
            <span>Resend</span>
          </Option>
          
          <Option onClick={handleDeleteMessage} onTouchStart={handleDeleteMessage}>
            <OptionItemIcon>
              {/*{option.icon && (<i className={option.icon}/>)}*/}
            </OptionItemIcon>
            <span>Delete</span>
          </Option>
        </OptionList>
      </div>
    </StyledWrapper>
  );
};

export default ErrorMessageActions;
