import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {I18nextProvider} from 'react-i18next';
import {CookiesProvider} from 'react-cookie';

import i18n from './i18n';
import './test-setup';
import './index.css';
import './fonts.css';

import App from './containers/App';
import store from './reducers/store';

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <CookiesProvider>
      <Provider store={store}>
        <App/>
      </Provider>
    </CookiesProvider>
  </I18nextProvider>,
  document.getElementById('root')
);

