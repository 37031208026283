import React, {PureComponent} from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import Filter from './FilterBar';
import Search from './Search';
import List from './List';
import Status from './Status';
import * as Styles from '../styles';
import {loader} from '../../../../assets';

// TODO: Refactor this component, duplication exist

function filterByName(data, value) {
  return data.filter(user => {
    let val = value.toLowerCase().replace(/ /g, '');
    let name = user.name.toLowerCase().replace(/ /g, '');
    return name.includes(val);
  });
}

function filterByKey(data, key) {
  return _.filter(data, user => user[key]);
}

function filterBlockedUsers(data) {
  return _.filter(data, user => !user.is_blocked);
}

class Contacts extends PureComponent {
  state = {
    list: 'All',
    searchTerm: '',
    status: '',
    contacts: [],
    currentDisplayed: []
  };

  componentDidMount() {
    if (this.props.contacts.data.length) {
      this.setState({
        currentDisplayed: this.filterContactsList({data: this.props.contacts.data}),
        contacts: this.props.contacts.data
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const {contacts} = nextProps;
    if (JSON.stringify(this.props.contacts.data) !== JSON.stringify(contacts.data)) {
      this.setState({
        currentDisplayed: this.filterContactsList({data: contacts.data}),
        contacts: contacts.data
      });
    }
  };

  filterContactsList = query => {
    const {list, status, searchTerm, data} = query;
    const {contacts} = this.state;

    let search = 'searchTerm' in query ? searchTerm : this.state.searchTerm;
    let isOnline = 'status' in query ? status : this.state.status;
    let listType = list || this.state.list;

    let result = filterBlockedUsers(filterByName(data || contacts, search));

    switch (listType) {
    case 'Match':
      result = filterByKey(result, 'is_match');
      break;
    case 'Favorites':
      result = filterByKey(result, 'is_favorite');
      break;
    default:
      break;
    }

    if (isOnline) {
      result = _.filter(result, user => ~this.props.onlineUsers.indexOf(user.id));
    }

    return result;
  };

  handleChange = (key, value) => {
    this.setState({
      currentDisplayed: this.filterContactsList({[key]: value}),
      [key]: value
    });
  };

  render() {
    const {onlineUsers, toggleEmailForm, onClose} = this.props;
    const {currentDisplayed, searchTerm, contacts} = this.state;

    if (this.props.contacts.loading) {
      return <Styles.Loader src={loader}/>;
    }
    return (
      <SideBarContacts>
        <ContactsHead>
          <Header>
            <TextWrap>
              <span>Select Contact</span>
            </TextWrap>
            <Status onChange={this.handleChange}/>
            <Close onClick={onClose}><i className='fal fa-times'/></Close>
          </Header>
          <Search
            onChange={this.handleChange}
            searchTerm={searchTerm}
          />
        </ContactsHead>
        <SideBarTabs>
          <Filter onChange={this.handleChange}/>
          <List
            toggleEmailForm={toggleEmailForm}
            onlineUsers={onlineUsers}
            initialContacts={contacts}
            myId={this.props.myId}
            contacts={currentDisplayed}
          />
        </SideBarTabs>
      </SideBarContacts>
    );
  }
}

export default Contacts;

const Close = styled.div`
  display: none;
  background: 0;
  padding: 0;
  border: 0;
  position: absolute;
  right: 0;
  font-size: 1.5rem;
  color: #b8bfd3;
  
  :hover {
    color: #4b4c59;
  }
  
  @media (max-width: 767px) {
    display: block;
  }
`;

const ContactsHead = styled.div`
  background-color: #fff;
	
	@media (max-width: 767px) {
    padding: .9375rem;
    position: fixed;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 70rem;
    transform: translate(-50%, 0);
    z-index: 2;
	}
`;

const SideBarContacts = styled.div`
	padding: .25rem 1.25rem 0 1.25rem;
  background-color: #fff;
  height: calc(100% - 3.9375rem);
  
  @media (max-width: 767px) {
    padding-top: 0;
    padding-right: .9375rem;
    padding-left: .9375rem;
    height: auto;
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: .625rem;
  justify-content: space-between;
  align-items: center;
  position: relative;
  
  @media (max-width: 767px) {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.0625rem;
  }
`;

const TextWrap = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 1.125rem;
  font-weight: 600;
  color: #4b4c59;
  
  @media (max-width: 767px) {
  	span {
      margin-right: .9375rem;
  	}
  }
`;

const SideBarTabs = styled.div`
  margin: 0 -1.25rem;
    
	@media (max-width: 767px) {
		padding-top: 8.125rem;
    margin-right: -.9375rem;
    margin-left: -.9375rem
	}
`;