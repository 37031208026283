import {Component} from 'react';
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

class ExampleBoundary extends Component {
  state = {
    error: null,
    eventId: null
  };

  componentDidCatch(error, errorInfo) {
    this.setState({error});

    Sentry.withScope(scope => {
      const eventId = Sentry.captureException(error);

      scope.setExtras(errorInfo);
      this.setState({eventId});
    });
  }

  render() {
    return this.props.children;
  }
}

export default ExampleBoundary;