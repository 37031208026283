import React, {Component} from 'react';

import Subscription from '../../../containers/Subscription';
import Photo from './Photo';

class PhotoPreview extends Component {

  state = {
    subscriptionPanelIsOpen: false
  };

  openSubscribePanel = () => this.setState({subscriptionPanelIsOpen: true});

  render() {
    const {photos} = this.props;

    if (this.state.subscriptionPanelIsOpen) {
      return <Subscription onClose={this.closeSubscriptionPanel}/>;
    }

    return (
      photos.map((photo, index) => (
        <Photo
          {...this.props}
          photo={photo}
          index={index}
          key={photo.id}
          openSubscribePanel={this.openSubscribePanel}
        />
      ))
    );
  }
}

export default PhotoPreview;