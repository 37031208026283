import React from 'react';
import * as api from '../api';

const LogoutPage = () => {
  api.logout();
  localStorage.clear();
  sessionStorage.clear();

  window.location.href = (process.env.REACT_APP_REDIRECT_URL_PROMO || process.env.REACT_APP_REDIRECT_URL) + '?action=logout';
};

export default LogoutPage;
