import React, {Component} from 'react';

import {addToFavorite} from '../api';
import * as Styles from './styles';

class AddToFavorite extends Component {
  state = {
    isFavorite: this.props.isFavorite,
    displayedFavorite: this.props.isFavorite,
    addingToFavorite: false
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.isFavorite !== nextProps.isFavorite) {
      this.setState({displayedFavorite: nextProps.isFavorite});
    }
  }

  toggleFavorite = e => {
    e.preventDefault();

    const {addContactToFavoritesList, deleteContactFromFavoritesList, profile} = this.props;

    this.setState({displayedFavorite: !this.state.displayedFavorite, addingToFavorite: true});

    addToFavorite(profile.id)
      .then(contact => {
        if (contact.is_favorite) {
          addContactToFavoritesList(contact);
        } else {
          deleteContactFromFavoritesList(contact);
        }

        this.setState({isFavorite: contact.is_favorite, addingToFavorite: false});
      });
  };

  render() {
    const {role} = this.props;
    const {addingToFavorite, displayedFavorite} = this.state;
    return (
      role !== 'me' && (
        <Styles.Star
          disabled={addingToFavorite}
          onClick={this.toggleFavorite}
          isFavorite={displayedFavorite}
        >
          <i className={(displayedFavorite ? 'fas' : 'far') + ' fa-star'}/>
        </Styles.Star>
      )
    );
  }
}

export default AddToFavorite;