import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DebounceInput } from 'react-debounce-input';

import { NavLink } from '../Link';
import { isMobile } from '../../utils/mobile';
import { unknownUser } from '../../assets';
import OnCloseButton from '../onCloseButton';
import DropDownMenu from '../DropDownMenu';
import SendEmail from '../SendEmail';
import NotesComponent from './Notes';

import * as api from '../../api';
import * as Styles from './styles';

const Header = ({ messages, refs, isOnline, creditsCount, contact: { name, age, photo, id }, isAgency, contacts, contact, history, updateContactsInfo }) => {
  const [isOpen, setOpen] = useState(false);
  const [isNotesWindowOpen, setIsNotesWindowOpen] = useState(false);
  
  const scrollToMessage = (refs, id) => {
    refs[id].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };
  
  const handleSearch = e => {
    const match = messages.data.length && messages.data.filter(message => {
      let value = e.target.value.toLowerCase().replace(/ /g, '');
      let text = message.text.toLowerCase().replace(/ /g, '');
      
      return text.includes(value);
    });
    
    if (match.length === 1) {
      scrollToMessage(refs, match[0].id);
    }
  };
  
  const getDropDownOptions = () => {
    return [
      {
        icon: 'fal fa-times',
        name: 'Block',
        callback: () => {
          api.addUserToBlacklist({ body: { profile_id: id } });
          history.push('/me');
        }
      },
    ];
  };
  
  const toggleNotesWindow = () => setIsNotesWindowOpen(isNotesWindowOpen => !isNotesWindowOpen);
  
  const toggleEmailForm = () => setOpen(isOpen => !isOpen);
  
  const myContact = contacts.data.find(({ id }) => id === contact.id);
  
  const initialComment = myContact && contacts.data.find(({ id }) => id === contact.id).comment;
  
  return (
    <>
      {
        isMobile() ? (
          <HeaderWrap>
            <Wrapper>
              <NavLink style={{ textDecoration: 'none' }} to={'/profile/' + btoa(id)}>
                <LeftContent>
                  <ProfileWrapper>
                    <UserBoxAvatar>
                      <AvatarImg src={photo ? photo.src : unknownUser} alt="avatar"/>
                      <UserOnline isOnline={isOnline}/>
                    </UserBoxAvatar>
                    <UserName>{(name && name.length > 14 ? name.slice(0, 11) + '...' : name) + (age ? (', ' + age) : '')}</UserName>
                  </ProfileWrapper>
                </LeftContent>
              </NavLink>
              <ContentControls>
                {
                  isAgency && myContact && (
                    <Notes>
                      <i className="fas fa-sticky-note" onClick={toggleNotesWindow}/>
                      {isNotesWindowOpen && (
                        <NotesComponent
                          updateContactsInfo={updateContactsInfo}
                          initialTextValue={initialComment}
                          onClose={toggleNotesWindow}
                          id={id}
                        />
                      )}
                    </Notes>
                  )
                }
                <Email className='far fa-envelope' onClick={toggleEmailForm}/>
                <DropDownMenu options={getDropDownOptions()}/>
                <OnCloseButton/>
              </ContentControls>
              {Boolean(!isAgency) && creditsCount === 0 && (
                <InfoWrapper>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M14 7A7 7 0 110 7a7 7 0 0114 0zM7.875 3.5a.875.875 0 11-1.75 0 .875.875 0 011.75 0zm-1.75 2.625a.875.875 0 000 1.75V10.5a.875.875 0 00.875.875h.875a.875.875 0 000-1.75V7A.875.875 0 007 6.125h-.875z" fill="#3B5A9A"/></svg>
                  <InfoDescription>
                    To write in chat, please check if you have enough credits for it.
                    Price: 1 Credit per 1 message (70 symbols)
                  </InfoDescription>
                </InfoWrapper>
              )}
            </Wrapper>
          </HeaderWrap>
        ) : (
          <Styles.Header>
            <Styles.InputWrap>
              <i className="far fa-search"/>
              <DebounceInput
                placeholder='Search in chat'
                onChange={handleSearch}
                debounceTimeout={1000}
              />
            </Styles.InputWrap>
          </Styles.Header>
        )
      }
      {
        isOpen && (
          <SendEmail
            profile={contact}
            onClose={toggleEmailForm}
            isOpen={isOpen}
          />
        )
      }
    </>
  );
};

Header.propTypes = {
  contact: PropTypes.object.isRequired
};

export default Header;

const Notes = styled.div`
  cursor: pointer;
  
  i {
    font-size: 1rem;
    letter-spacing: 4px;
    text-align: center;
    color: #3b5a9a;
  }
`;

const Email = styled.i`
  color: #4a90e2;
  cursor: pointer;
  font-size: 1rem;
  padding: 0 10px 3px 7px;
`;

const ContentControls = styled.div`
  right: 11px;
  display: flex;
  align-items: center;
  top: 15px;
  right: 20px;
  
  div {
    cursor: pointer;
  }
`;

const UserName = styled.span`
  font-size: 1.125rem;
  font-weight: 600;
  display: block;
  margin-left: .625rem;
`;

const UserOnline = styled.span`
  font-size: .75rem;
  display: ${props => props.isOnline ? 'inline-block' : 'none'};
  margin-left: 1.625rem;
  letter-spacing: .0625rem;
  position: absolute;
  right: -.3125rem;
  bottom: .3125rem;

	&::before {
    position: absolute;
    top: 50%;
    left: -1.1rem;
    content: "";
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: #7ed321;
    width: .5625rem;
    height: .5625rem;
    border: .0625rem solid #fff;
	}
`;

const AvatarImg = styled.img`
	display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-family: "object-fit: cover;", serif;
  object-fit: cover;
`;

const UserBoxAvatar = styled.div`
  position: relative;
  width: 2.25rem;
  height: 2.25rem;
  margin-right: 0;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: 2.875rem;
  svg {
    flex: 0 0 auto;
  }
`;

const InfoDescription = styled.div`
  font-size: 0.825rem;
  line-height: 0.75rem;
  margin-left: 0.5rem;
  color: #515151;
`;

const HeaderWrap = styled.header`
  border-bottom: .0625rem solid #dadee8;
  box-shadow: none;
  z-index: 1;
  
  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 0.125rem 0.25rem 0 #edeff4;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  max-width: 71.25rem;
  margin: 0 auto;
  padding-right: .625rem;
  padding-left: .625rem;
  padding-top: 0.625rem;
  padding-bottom: 1rem;
`;
