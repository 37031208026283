import React from 'react';
import { getMessageByType } from '../../../../utils/getMessageByType';

export default function Message({ author, message, onClick, refs, isError, sendLetter, fetchMessage, removeErrorMessage }) {
  const callback = () => {
    if (message.error) {
      onClick(message);
    } else {
      onClick(message.entity ? message.entity.id : '');
    }
  };
  
  return (
    <div ref={refs[message.id]}>
      {getMessageByType({ message, author, callback, isError, fetchMessage, sendLetter, removeErrorMessage, onTouchStart: true })}
    </div>
  );
}