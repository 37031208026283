import {connect} from 'react-redux';

import profileComponent from '../components/Profile';
import {fetchUserProfile, fetchProfile, updateProfileInfo, fetchMessage, updatePhotos, deleteAlbum} from '../actions';

const mapStateToProps = state => ({
  myProfile: state.profile,
  user: state.user,
  selects: state.selects,
  onlineUsers: state.onlineUsers
});

const mapDispatchToProps = {
  sendMessage: fetchMessage,
  updateProfileInfo,
  fetchUserProfile,
  fetchProfile,
  updatePhotos,
  deleteAlbum
};

export default connect(mapStateToProps, mapDispatchToProps)(profileComponent);