import React, {useState} from 'react';
import styled from 'styled-components';
import {reduxForm, Field, reset} from 'redux-form';
import {compose} from 'redux';

import validate from './validate';
import DragAndDrop from '../../DragAndDrop';
import Switch from '../../Switch';
import withModalClose from '../../HOC/withModalClose';
import {Modal, ModalContent} from '../../Modal';
import {isMobile} from '../../../utils/mobile';
import renderField from './renderField';
import {btn} from '../../styles';
import * as Styles from '../../Album/styles';

const AlbumCreator = ({isOpen, onClose, handleSubmit, innerRef, formState}) => {
  const [error, setError] = useState('');
  const [maxPhotosLength] = useState(50);

  const handleStatus = (status, message) => setError(status === 'error' ? message : '');

  const handleClose = () => {
    setError('');
    onClose();
  };

  const photos = formState && formState.album && formState.album.values && formState.album.values.photos;

  return (
    <Modal isOpen={isOpen}>
      <AlbumContainer as='form' onSubmit={handleSubmit} ref={innerRef}>
        <Title>Create new album</Title>
        {
          isMobile() ? (
            <div style={{marginBottom: '2rem'}}>
              <Styles.Editor>
                <Styles.FieldLabel>
                  <label htmlFor='name'>Album name</label>
                  <span>*</span>
                </Styles.FieldLabel>
                <Field
                  component={renderField}
                  type='text'
                  name='name'
                />
              </Styles.Editor>
              <Styles.HideAlbum>
                <TextWrap>
                  <span>Hidden album</span>
                  <span>Visible only to your contacts</span>
                </TextWrap>
                <Field
                  defaultValue={0}
                  name='is_private'
                  component={Switch}
                />
              </Styles.HideAlbum>
            </div>
          ) : (
            <AlbumSettings>
              <AlbumNameWrap>
                <Styles.FieldLabel>
                  <label htmlFor='name'>Album name</label>
                  <span>*</span>
                </Styles.FieldLabel>
                <Field
                  component={renderField}
                  type='text'
                  name='name'
                />
              </AlbumNameWrap>
              <HideAlbum>
                <TextWrap>
                  <span>Hidden album</span>
                  <span>Visible only to your contacts</span>
                </TextWrap>
                <Field
                  defaultValue={0}
                  name='is_private'
                  component={Switch}
                />
              </HideAlbum>
            </AlbumSettings>
          )
        }
        <PopupTitle>Upload your photos</PopupTitle>
        <PopupText>Drag and drop your file here</PopupText>
        <UploadedInfo>{photos && !!photos.length && (photos.length + '/' + maxPhotosLength + ' uploaded')}</UploadedInfo>
        <MessagesWrap>
          <Field
            name='photos'
            handleStatus={handleStatus}
            component={DragAndDrop}
            maxFiles={maxPhotosLength}
          />
          <FooterWrapper>
            {
              error && !isMobile() && (
                <Error>
                  <i className='fas fa-exclamation-triangle'/>
                  <span>{error}</span>
                </Error>
              )
            }
            <ButtonContainer error={error}>
              <Button as='button' close={onClose} onClick={handleClose}>CANCEL</Button>
              <Button as='button' type='submit'>SAVE CHANGES</Button>
            </ButtonContainer>
          </FooterWrapper>
        </MessagesWrap>
      </AlbumContainer>
    </Modal>
  );
};

const afterSubmit = (result, dispatch) => dispatch(reset('album'));

const enhance = compose(withModalClose, reduxForm({
  form: 'album',
  onSubmitSuccess: afterSubmit,
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
}));

export default enhance(AlbumCreator);

const UploadedInfo = styled.div`
  margin-bottom: 10px;
  color: #dd2c57;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TextWrap = styled.div`
  margin-right: 47px;
`;

const Error = styled.div`
  display: flex;
  align-items: center;
  color: #ff0000;
  
  i {
    font-size: 1.5rem;
    margin-right: .5rem;
  }
  
  span {
    width: 8rem;
    font-weight: bold;
    font-size: .75rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${props => props.error ? 'auto' : '100%'};
  
  @media (max-width: 767px) {
    width: 100%;
    button {
      min-width: 50%;
    }
  }
`;

const Button = styled(btn)`
	min-width: 128px;
  line-height: 30px;
  height: 30px;

	${({close}) => !close ? 'width: 170px;' : `
		margin-left: auto;
    margin-right: 10px;
    color: #b8bfd3;
    border-color: #b8bfd3;
    background-color: #fff;
    
    :focus {
      background-color: rgba(221, 44, 87, .1);
		}
	
		:hover {
      background-color: rgba(221, 44, 87, .1);		
		}
	`}
`;

const MessagesWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  @media (max-width: 767px) {
    margin-bottom: 5.5rem;
  }
`;

const PopupText = styled.span`
	display: block;
  margin-bottom: 10px;
`;

const PopupTitle = styled.span`
	display: block;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 600;
`;

const HideAlbum = styled.div`
  display: flex;
  align-items: center;
  
  div {
    
    span:first-child {
      font-weight: 600;
      display: block;
    }
    
    span:last-child {
	    font-size: 10px;
	    color: #9b9b9b;
    }
  }
`;

const AlbumNameWrap = styled.div`
  display: flex;
  align-items: center;
  
  span {
    font-weight: 600;
    margin-right: 10px;
  }
  
  input {
    outline: none;
    width: 176px;
    height: 30px;
    border-radius: 2px;
    padding: 0 10px;
    border: 1px solid #dadee8;
    color: #515151;
  }
`;

const AlbumSettings = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
`;

const AlbumContainer = styled(ModalContent)`
  max-width: 540px;
  padding: 20px;
  height: auto !important;
`;

const Title = styled.b`
	display: block;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 28px;
  
  @media (max-width: 767px) {
    font-size: 1rem;
  }
`;
