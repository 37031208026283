import React from 'react';
import styled from 'styled-components';
import {Field} from 'redux-form';

import * as Styles from './styles';
import Location from './Location';
import RenderField from './renderField';
import Datepicker from './Calendar';
import {Event} from '../../Tracking';
import {isMobile} from '../../utils/mobile';

import {required, minValue, date, isAgeValid} from './validate';

const minValue3 = minValue(3);
const requiredName = required('Please enter a Name or Nick');

const RenderTextarea = ({maxLength, name, input}) => {
  const handleChange = e => input && input.onChange(e);
  return (
    <Styles.Textarea>
      <textarea
        {...input}
        maxLength={maxLength}
        onChange={handleChange}
      />
      <span style={{color: 'rgb(184, 191, 211)'}}>{maxLength - input.value.length}</span>
    </Styles.Textarea>
  );
};

const UserDetails = ({handleSubmit, step, lastStep, onClose, buttonText, skip, selects}) => {
  const handleClick = () => Event('send', 'form', 'step1');

  return (
    <Form as="form" onSubmit={handleSubmit}>
      <Styles.Title>Information {step && (step + '/' + lastStep)}</Styles.Title>
      {onClose && (<Styles.Skip onClick={onClose}><i className='fal fa-times'/></Styles.Skip>)}
      <Field
        name="name"
        type="text"
        label="Your name"
        Error={Error}
        Container={Styles.Container}
        validate={[requiredName, minValue3]}
        isRequire
        component={RenderField}
      />
      {
        isMobile() ? (
          <Field
            name="birth_date"
            type="date"
            label="Your birthday"
            validate={[date, isAgeValid]}
            Error={Error}
            Container={Styles.Container}
            component={RenderField}
          />
        ) : (
          <Field
            name='birth_date'
            label='Your birthday'
            validate={[date, isAgeValid]}
            component={Datepicker}
          />
        )
      }
      <Location options={selects.country}/>
      <Text>Short bio</Text>
      <Styles.AboutContainer>
        <Field
          name='about'
          component={RenderTextarea}
          maxLength={300}
        />
        {onClose && (<Styles.Button as='button' close={onClose} onClick={onClose}>CANCEL</Styles.Button>)}
        {(step !== lastStep && skip) && (<Styles.Button as='button' skip={skip} onClick={skip}>SKIP</Styles.Button>)}
        <Styles.Button
          as='button'
          type="submit"
          onClick={handleClick}
        >
          {buttonText || (step === lastStep ? 'CONFIRM' : 'NEXT')}
        </Styles.Button>
      </Styles.AboutContainer>
    </Form>
  );
};

export default UserDetails;

const Text = styled.span`
	display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 9px;
`;

const Error = styled(Styles.Error)`
	padding-left: 8rem;
	
	@media (max-width: 767px) {
	  margin: 0;
	  padding: 0;
	}
`;

const Form = styled.form`
  position: relative;
  max-width: 28.4375rem;
  padding: 1.25rem 4.6875rem 2.5rem 1.25rem;
  width: 100%;
  background-color: #fff;
  cursor: default;
  border-radius: 2px;
  
  @media (max-width: 767px) {
    padding-top: 2.5rem;
  }
  
  @media (max-width: 480px) {
    padding: 1.25rem;
    height: auto;
    align-self: flex-start;
  }
`;
