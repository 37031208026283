import { connect } from 'react-redux';

import MailboxComponent from '../components/Mailbox';
import {
  decrementMailCount,
  deleteLetter,
  fetchMailsList,
  initErrorMails,
  loadMoreMails,
  readLetter,
  removeErrorMail,
  addErrorMail,
  onSendMail
} from '../actions';

const mapDispatchToProps = {
  fetchMailsList,
  decrementMailCount,
  loadMoreMails,
  readLetter,
  deleteLetter,
  initErrorMails,
  removeErrorMail,
  addErrorMail,
  onSendMail
};

const mapStateToProps = state => ({
  mailbox: state.mailbox,
  profile: state.profile,
  form: state.form,
  contacts: state.contacts,
  onlineUsers: state.onlineUsers
});

export default connect(mapStateToProps, mapDispatchToProps)(MailboxComponent);