import React, { Component, } from 'react';
import PropTypes from 'prop-types';

import * as Styles from '../styles';
import { loader } from '../../../assets';
import RefilCredits from '../../Credits';
import Subscription from '../../../containers/Subscription';
import { getMailDetails, markEmailAsRead, sendEmailToUser } from '../../../api';
import SendEmail from '../../SendEmail';
import ReadEmail from './ReadEmail';
import Messages from './messages';
import { Event } from '../../../Tracking';

class MessagesList extends Component {
  messageEnd = React.createRef();
  messageList = React.createRef();
  
  state = {
    isReply: false,
    isError: false,
    openedEmail: null,
    emailIsOpen: false
  };
  
  toggleEmailForm = () => {
    this.setState(prevState => ({
      isReply: !prevState.isReply,
      emailIsOpen: false
    }));
  };
  
  scrollToBottom = () => {
    this.messageEnd.current.scrollIntoView();
  };
  
  holdScrollPos = position => {
    const { scrollHeight, clientHeight } = this.messageList.current;
    this.messageList.current.scrollTop = (scrollHeight - clientHeight) - position;
  };
  
  onScroll = () => {
    const {contact, messages} = this.props;

    const isLoadMore = this.messageList.current.scrollTop === 0;

    if (isLoadMore) {
      let page = messages.links.next ? messages.links.next.split('?page=')[1] : null;
      if (page) {
        this.props.loadMoreMessages(contact.id, page);
      }
    }
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentWillUpdate(nextProps) {
    const { scrollHeight, clientHeight, scrollTop } = this.messageList.current;
    
    const filterMailboxByUserId = mail => mail.receiver.id === Number(nextProps.profileId);
    
    const currentErrorMessages = this.props.messages.errorMessages || [];
    const nextErrorMessages = nextProps.messages.errorMessages || [];
    
    const currentErrorEmails = this.props.mailbox.errorEmails || [];
    const nextErrorEmails = nextProps.mailbox.errorEmails || [];
    
    const isMessagesHistoryChanged = nextProps.messages.data.length !== this.props.messages.data.length;
    const isErrorMessagesChanged = currentErrorMessages.length !== nextErrorMessages.length;
    const isErrorEmailsChanged = currentErrorEmails.filter(filterMailboxByUserId).length !== nextErrorEmails.filter(filterMailboxByUserId).length;
    
    this.historyChanged = isMessagesHistoryChanged || isErrorMessagesChanged || isErrorEmailsChanged;
    
    this.lastScrollPosition = scrollHeight - clientHeight;
  
    if (this.historyChanged) {
      const scrollPos = scrollTop;
      const scrollBottom = (scrollHeight - clientHeight);
      this.scrollAtBottom = (scrollBottom <= 0) || (scrollPos > scrollBottom - 200);
    }
  };

  onCloseLetter = () => {
    this.setState({ openedEmail: null, emailIsOpen: false, isError: false });
  };

  openEmail = letter => {
    const {profile, decrementMailCount} = this.props;
    
    if (typeof letter === 'object') {
      this.setState({ isError: true, openedEmail: letter });
      localStorage.setItem('letter', JSON.stringify(letter));
      
    } else {
      this.setState({emailIsOpen: false});
  
      getMailDetails(letter)
        .then(mail => {
          if (mail) {
            if (profile.id && (profile.id === mail.receiver_id) && !mail.read_at) {
              markEmailAsRead(letter)
                .then(() => decrementMailCount());
            }
          }
      
          this.setState({
            openedEmail: mail,
            emailIsOpen: true
          });
        });
    }
  };
  
  resendLetter = letter => {
    Event('send', 'form', 'sendmail');
    
    let urlParams = {
      receiver_id: letter.receiver_id,
      sent_at: new Date().toJSON(),
      subject: letter.subject,
      ...letter,
    };
    
    sendEmailToUser({ body: urlParams }).then(response => {
      if (response.status >= 400) {
        this.props.addErrorMail(letter);
      }
      
      if (response.data) {
        this.props.onSendMail(response.data);
      }
      
      return response;
    });
  };

  componentDidUpdate() {
    if (this.historyChanged) {
      if (this.scrollAtBottom) {
        this.scrollToBottom();
      } else if (this.messageList.current.scrollTop === 0) {
        this.holdScrollPos(this.lastScrollPosition);
      }
    }
  };

  render() {
    const { messages, contact, profile, refs, error, profileId, fetchMessage, initErrorMessages, removeErrorMessage, removeErrorMail, mailbox } = this.props;
    const { openedEmail, isReply, emailIsOpen, isError } = this.state;
  
    const isHasSubscription = profile && profile.last_subscription && profile.last_subscription.is_active;
    const isCreditsError = error && error.status === 402;
    const isSubscriptionError = error && error.status === 423;
    
    return (
      <>
        {isCreditsError && isHasSubscription && (<RefilCredits/>)}
        {(isSubscriptionError || (isCreditsError && !isHasSubscription)) && (<Subscription/>)}
        <Styles.Section
          ref={this.messageList}
          onScroll={this.onScroll}
          onTouchEnd={e => e.preventDefault()}
        >
          {messages.fetching && (
            <Styles.PaginationLoader>
              <img src={loader} alt=""/>
            </Styles.PaginationLoader>
          )}
        
          <Messages
            initErrorMessages={initErrorMessages}
            removeErrorMessage={removeErrorMessage}
            contact={contact}
            removeErrorMail={removeErrorMail}
            messages={messages}
            mails={mailbox.errorEmails}
            openEmail={this.openEmail}
            fetchMessage={fetchMessage}
            resendLetter={this.resendLetter}
            profileId={profileId}
            profile={profile}
            refs={refs}
          />
        
          <div ref={this.messageEnd}/>
        </Styles.Section>
        {Boolean(openedEmail && openedEmail.id && !isReply) && (
          <ReadEmail
            isOpen={emailIsOpen}
            myId={profile.id}
            onClose={this.onCloseLetter}
            replyMessage={this.toggleEmailForm}
            letter={openedEmail}
          />
        )}
        {(isReply || isError) && (
          <SendEmail
            isReply={isReply}
            letter={openedEmail}
            onClose={openedEmail.error ? this.onCloseLetter : this.toggleEmailForm}
            profile={openedEmail && openedEmail.error ? openedEmail.profile : openedEmail.sender}
            isOpen={isReply || isError}
          />
        )}
      </>
    );
  };
}

MessagesList.propTypes = {
  messages: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired
};

export default MessagesList;
