import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import {uploadPhoto} from '../../api';
import {isMobile} from '../../utils/mobile';
import {loader} from '../../assets';

import {setLocalStorage} from './setLocalStorage';

const UploadPhoto = ({input}) => {
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const {photo} = JSON.parse(localStorage.getItem('letter'));

    if (photo) {
      setFileName(photo.fileName);

      input.onChange(photo.photo_id);
    }
  }, []);

  const removePhoto = () => {
    if (!loading) {
      setFileName('');
      input.onChange('');
      setLocalStorage('photo', {fileName: '', photo_id: ''});
    }
  };

  const upload = e => {
    const file = e.target.files[0];
    if (file) {
      const form = new FormData();
      form.append('file', file);

      setFileName(file.name);
      setLoading(true);
      setError('');

      uploadPhoto({body: form})
        .then(response => {
          setLoading(false);
          if (response && response.status === 422) {
            return response.json();
          } else if (response.data) {
            input.onChange(response.data.id);
            setLocalStorage('photo', {fileName: file.name, photo_id: response.data.id});
          }
        })
        .then(response => {
          if (response && response.errors) {
            setError(Object.keys(response.errors).map(error => response.errors[error][0])[0]);
            setFileName('');
          }
        });
    }
  };

  const maxLength = isMobile() ? 9 : 44;
  const name = fileName.length > maxLength ? fileName.slice(0, maxLength - 2) + '...' : fileName;

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <InputWrapper loading={loading ? loading.toString() : ''}>
        <FileInput id="file" type="file" onChange={upload}/>
        <Label htmlFor="file">
          <i className="far fa-image"/>
          {!name && (<span style={{whiteSpace: 'nowrap'}}>Add photo</span>)}
        </Label>
        <span>{name}</span>
        {
          name && (
            loading ? (
              <Loader src={loader} alt=""/>
            ) : (
              <RemovePhoto onClick={removePhoto}>
                <i className="far fa-trash-alt"/>
              </RemovePhoto>
            )
          )
        }
      </InputWrapper>
      {
        error && (
          <Error>
            <i className='fas fa-exclamation-triangle'/>
            <span>{error}</span>
          </Error>
        )
      }
    </div>
  );
};

export default UploadPhoto;

const Error = styled.div`
  display: flex;
  align-items: center;
  color: #ff0000;
  margin-top: 15px;
  
  i {
    font-size: 1.5rem;
    margin-right: .5rem;
  }
  
  span {
    font-weight: bold;
    font-size: .75rem;
  }
`;

const Loader = styled.img`
  width: 100%;
  max-width: 15px;
  margin: 0 5px;
`;

const RemovePhoto = styled.div`
	font-size: 15px;
  margin-left: 7px;
  color: #4b4c59;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #3b5a9a;
  
	span {
		font-size: 16px;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 15rem;
		opacity: ${props => props.loading ? 0.7 : 1};
	}
	
	:hover {
    cursor: pointer;
	}
	
	@media (max-width: 767px) {
	  flex-shrink: 0;
	}
`;

const
  Label = styled.label`
	display: flex;
	align-items: center;
  font-size: 1.5rem;
  margin-right: 7px;
  cursor: pointer;
  
  span {
  	font-size: 14px;
  	margin-left: 10px;
  }
`;

const
  FileInput = styled.input`
	display: none;
`;