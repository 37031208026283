import React from 'react';

import * as Styles from './styles';

const renderField = ({input, label, type, flex, meta: {touched, error}, Error, Container, isRequire}) =>
  <Container>
    <div>
      <Styles.TitleWrap>
        <Styles.Span flex={flex}>{label}</Styles.Span>
        {isRequire && (<Styles.Require>*</Styles.Require>)}
      </Styles.TitleWrap>
      <Styles.Input
        {...input}
        error={touched && error}
        type={type}
      />
    </div>
  </Container>;

export default renderField;


