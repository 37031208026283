import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { NavLink } from '../Link';
import NotesComponent from './Notes';
import AddToFavorite from '../../containers/AddToFavorite';
import Albums from '../../containers/Albums';
import { unknownUser } from '../../assets';
import PersonalData from '../Profile/DetailInfo/PersonalData';
import DropDownMenu from '../DropDownMenu';
import SendEmail from '../SendEmail';
import { btn } from '../styles';
import * as api from '../../api';
import Subscription from '../../containers/Subscription';

const ShortProfileInfo = props => {
  const {
    history,
    isOnlineUser,
    isAgency,
    contact: { photo, name, age, about, city, country, id, is_favorite, last_seen },
    contact,
    contacts,
    updateContactsInfo
  } = props;
  
  const [isOpen, setOpen] = useState(false);
  const [subscriptionModalIsOpen, setSubscriptionModalIsOpen] = useState(false);
  const [isNotesWindowOpen, setIsNotesWindowOpen] = useState(false);
  
  const getDropDownOptions = () => {
    return [
      {
        icon: 'fal fa-times',
        name: 'Block',
        callback: () => {
          api.addUserToBlacklist({ body: { profile_id: id } });
          history.push('/me');
        }
      },
    ];
  };
  
  const toggleNotesWindow = () => setIsNotesWindowOpen(isNotesWindowOpen => !isNotesWindowOpen);
  
  const goBack = () => history.goBack();
  
  const toggleEmailForm = () => setOpen(isOpen => !isOpen);
  
  const is_online = !!isOnlineUser;
  
  const myContact = contacts.data.find(({ id }) => id === contact.id);
  
  const initialComment = myContact && contacts.data.find(({ id }) => id === contact.id).comment;
  
  return (
    <>
      {subscriptionModalIsOpen && (<Subscription/>)}
      <ProfileWrapper>
        <Star>
          <AddToFavorite isFavorite={is_favorite} profile={contact}/>
        </Star>
        {
          isAgency && myContact && (
            <Notes>
              <i className="fas fa-sticky-note" onClick={toggleNotesWindow}/>
              {isNotesWindowOpen && (
                <NotesComponent
                  updateContactsInfo={updateContactsInfo}
                  initialTextValue={initialComment}
                  onClose={toggleNotesWindow}
                  id={id}
                />
              )}
            </Notes>
          )
        }
        <Controls>
          <DropDownMenu options={getDropDownOptions()}><i className='far fa-ellipsis-h'/></DropDownMenu>
          <Close onClick={goBack}><i className='fal fa-times'/></Close>
        </Controls>
        <UserBox>
          <NavLink to={'/profile/' + btoa(id)}>
            <AvatarWrap>
              <Photo src={photo ? photo.src : unknownUser}/>
              {is_online && (<IsOnline/>)}
            </AvatarWrap>
          </NavLink>
          <UserBoxInfo>
            <UserName>{name + (age ? (', ' + age) : '')}</UserName>
            {
              (city && country) && (
                <Location>
                  <i className="fas fa-map-marker-alt"/>
                  {city + ', ' + country}
                </Location>
              )
            }
            {!is_online && last_seen && (
              <div style={{ textAlign: 'center', width: '100%', margin: '10px 0' }}>
                <LastSeen>Last seen {last_seen}</LastSeen>
              </div>
            )}
            <Text>{about && (about.length > 155 ? about.slice(0, 155) + '...' : about)}</Text>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button onClick={toggleEmailForm}>Send email</Button>
            </div>
          </UserBoxInfo>
        </UserBox>
        <AlbumWrap>
          <Albums
            {...props}
            toggleSubscriptionPanel={() => setSubscriptionModalIsOpen(prevState => !prevState)}
            slidesToShow={2}
            slidesToScroll={2}
            profileData={contact}
          />
        </AlbumWrap>
        <UserInfo>
          <PersonalData profileData={contact}/>
        </UserInfo>
      </ProfileWrapper>
      {
        isOpen && (
          <SendEmail
            profile={contact}
            onClose={toggleEmailForm}
            isOpen={isOpen}
          />
        )
      }
    </>
  );
};

export default withRouter(ShortProfileInfo);

const LastSeen = styled.span`
  color: #b8bfd3;
`;

const Button = styled(btn)`
  box-shadow: 0 0.125rem 0.25rem 0 rgba(74, 144, 226, .5);
  line-height: 1.5rem;
  min-width: 6.25rem;
  height: 1.5rem;
  letter-spacing: .05rem;
  background-color: #3b5a9a;
  margin-top: 10px;
  
  @media (max-width: 767px) {
    margin-left: .375rem;
    padding: 0 1.5625rem;
    height: 1.7rem;
  }
	
	:focus {
	  background-color: #4c6dae;
		text-decoration: none;
		cursor: pointer;
		outline: 0;
	}
	
	:hover {
		background-color: #4c6dae;
		text-decoration: none;
		cursor: pointer;
		outline: 0;
	}
`;

const Controls = styled.div`
  right: 11px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  
  div {
    cursor: pointer;
  }
`;

const Close = styled.div`
  background: 0;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  color: #b8bfd3;
  
  :hover {
    color: #4b4c59;
  }
`;

const Star = styled.div`
	position: absolute;
`;

const Notes = styled.div`
  position: absolute;
  top: 3.75rem;
  left: 1.65rem;
  cursor: pointer;
  
  i {
    font-size: 22px;
    letter-spacing: 4px;
    text-align: center;
    color: #3b5a9a;
  }
`;

const AlbumWrap = styled.div`
  margin: 10px 0;
  height: 109px;
  
  b {
    font-size: .875rem;
    padding: 0.875rem 0 1.275rem 0;
    font-weight: 600;
  }
  
  div {
    border: none;
    margin: 0;
    padding: 0;
  }
`;

const UserInfo = styled.div`
  display: flex;

  div { 
    margin-left: 0;
  }
`;

const Text = styled.p`
  text-align: justify;
	margin-bottom: 0;
	word-break: break-word;
`;

const Location = styled.span`
 	justify-content: center;
  margin-bottom: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  
  i {
  	font-size: 16px;
  	color: #dd2c57;
    margin-right: 5px;
  }
`;

const UserName = styled.span`
	display: block;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  margin-bottom: 4px;
`;

const ProfileWrapper = styled.div`
	position: relative;
  width: 100%;
  padding: 20px;
  max-width: 16.5625rem;
  
  @media (max-width: 767px) {
    display: none;
  }
`;

const UserBoxInfo = styled.div`
	max-width: 402px;
`;

const UserBox = styled.div`    
	display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0
`;

const AvatarWrap = styled.div`
	position: relative;
  flex-shrink: 0;
  margin-right: 0;
  width: 72px;
  height: 72px;
  margin-bottom: 10px;
`;

const Photo = styled.img`
	display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const IsOnline = styled.span`
  display: inline-block;
  margin-left: 26px;
  font-size: 12px;
  letter-spacing: 1px;
  position: absolute;
  right: 4px;
  bottom: 8px;

	&::before {
    content: '';
    position: absolute;
    left: -16px;
    top: 50%;
    width: 12px;
    height: 12px;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: #7ed321;
    border: 2px solid #fff;
	}
`;