import React, {Component} from 'react';
import Modal from 'react-modal';

import * as Styles from '../styles';
import Carousel from '../../Carousel';
import Photo from './Photo';
import Subscription from '../../../containers/Subscription';
import {isMobile} from '../../../utils/mobile';
// import DropDownMenu from './DropDownMenu';

class PhotoView extends Component {
  state = {
    currentPhotoId: this.props.images[this.props.currentSlide].id,
    isSubscribe: this.props.profile.last_subscription && this.props.profile.last_subscription.is_active,
    subscriptionPanelIsOpen: false,
    photoIsOpen: false
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleClose);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleClose);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.photoIsOpen !== this.props.photoIsOpen) {
      this.setState({photoIsOpen: nextProps.photoIsOpen});
    }
  }

  handleClose = event => {
    if (event.key === 'Escape') {
      this.onClose();
    }
  };

  sendEmail = () => {
    this.props.sendEmail();
    this.onClose();
  };

  setUpCarousel = () => {
    return {
      initialSlide: +this.props.currentSlide,
      infinite: true,
      slidesToShow: 1,
      speed: 300,
      slidesToScroll: 1,
      adaptiveHeight: true,
      draggable: true,
      focusOnSelect: true,
      swipeToSlide: true,
      pauseOnHover: false,
      lazyLoad: true,
      dots: true,
      afterChange: photoIndex => {
        this.setState({currentPhotoId: this.props.images[photoIndex].id});
      }
    };
  };


  onClose = () => {
    this.props.togglePhotoView(null);
    this.setState({photoIsOpen: false});
  };

  openSubscribePanel = () => {
    this.setState({subscriptionPanelIsOpen: true});
    this.onClose();
  };

  // setupOptions = () => {
  //   return [
  //     {
  //       label: 'Report',
  //       icon: 'fal fa-flag-alt'
  //     }
  //   ];
  // };

  render() {
    if (this.state.subscriptionPanelIsOpen) {
      return <Subscription onClose={this.closeSubscriptionPanel}/>;
    }

    const styles = isMobile() ? mobileStyles : customStyles;

    return (
      <Modal
        style={styles}
        ariaHideApp={false}
        isOpen={this.state.photoIsOpen}
      >
        <Styles.PhotoWrapper>
          <Styles.ControlsBar id='actions' >
            {/*<DropDownMenu options={this.setupOptions()} role={this.props.role}/>*/}
            <Styles.Close onClick={this.onClose}><i className='fal fa-times'/></Styles.Close>
          </Styles.ControlsBar>
          <Carousel
            onClose={this.onClose}
            isSubscribe={this.state.isSubscribe}
            openSubscribePanel={this.openSubscribePanel}
            settings={this.setUpCarousel()}
            MapComponent={Photo}
            {...this.props}
            sendEmail={this.sendEmail}
          />
        </Styles.PhotoWrapper>
      </Modal>
    );
  }
}

const mobileStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    maxHeight: '100vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '100%',
    border: 0,
    padding: 0,
    marginRight: '-50%',
    width: '100%',
    overflow: 'inherit',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
  },
  overlay: {
    zIndex: 1222,
    backgroundColor: 'rgba(0, 0, 0)'
  }
};

const customStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    maxHeight: '100vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '100%',
    border: 0,
    width: '100%',
    padding: 0,
    marginRight: '-50%',
    overflow: 'inherit',
    transform: 'translate(-50%, -50%)',
    background: 'transparent'
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.8)'
  }
};

export default PhotoView;
