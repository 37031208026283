import { takeLatest, call, put, takeEvery } from 'redux-saga/effects';

import * as TYPES from './constants/types';
import * as api from './api';

function* fetchUserProfile(action) {
  const response = yield call(api.getFullProfile, { endpoint: action.endpoint });
  
  if (response.status === 404) {
    yield put({ type: TYPES.FETCH_USER_PROFILE_FAILURE, error: response });
    
  } else {
    yield put({ type: TYPES.FETCH_USER_PROFILE_SUCCESS, profile: response.data });
  }
}

function* fetchProfile() {
  try {
    const profile = yield call(api.getFullProfile, {});
    
    yield put({ type: TYPES.FETCH_PROFILE_SUCCESS, profile: profile.data });
    
  } catch (error) {
    console.error(error);
    
    yield put({ type: TYPES.FETCH_PROFILE_FAILURE, error });
  }
}

function* handleNewMessage(action) {
  const { text, id } = action;
  const response = yield call(api.sendMessage, { body: { text }, id });
  
  if (response.status === 402) {
    yield put({ type: TYPES.ADD_MESSAGE_FAILURE, response });
    
  } else if (response.status === 423) {
    yield put({ type: TYPES.ADD_MESSAGE_FAILURE, response });
    
  } else {
    yield put({ type: TYPES.ADD_MESSAGE, message: response.data });
  }
  
  if (response.status >= 400) {
    yield put({ type: TYPES.ADD_ERROR_MESSAGE, message: { text, profileId: id } });
  }
}

function* handleTyping(action) {
  const { uid } = action;
  
  yield call(api.typeMessage, { uid });
  yield put({ type: TYPES.TYPING_START, uid });
}

function* fetchCatalog(action) {
  try {
    const catalog = yield call(api.getProfilesList, { page: 1, query: action.query });
    
    yield put({ type: TYPES.FETCH_CATALOG_SUCCESS, catalog });
  } catch (error) {
    yield put({ type: TYPES.FETCH_CATALOG_SUCCESS, error });
  }
}

function* loadMoreProfiles(action) {
  try {
    const currentPage = action.page || 1;
    const catalog = yield call(api.getProfilesList, { page: currentPage, query: action.query });
    
    yield put({ type: TYPES.FETCH_MORE_PROFILES_SUCCESS, catalog, page: currentPage });
  } catch (error) {
    console.error(error);
    
    yield put({ type: TYPES.FETCH_MORE_PROFILES_FAILURE, error });
  }
}

function* loadMoreMessages(action) {
  try {
    const currentPage = action.page || 1;
    const messages = yield call(api.getMessages, action.userId, currentPage);
    
    yield put({ type: TYPES.FETCH_MORE_MESSAGES_SUCCESS, messages, page: currentPage });
  } catch (error) {
    console.error(error);
    
    yield put({ type: TYPES.FETCH_MORE_MESSAGES_FAILURE, error });
  }
}

function* fetchChatHistory(action) {
  try {
    if (action.userId) {
      const messages = yield call(api.getMessages, action.userId);
      
      yield put({ type: TYPES.FETCH_CHAT_HISTORY_SUCCESS, messages });
    }
  } catch (error) {
    console.error(error);
    
    yield put({ type: TYPES.FETCH_CHAT_HISTORY_FAILURE, error });
  }
}

function* fetchWizardOptions() {
  try {
    const selects = yield call(api.getCatalogs);
    
    yield put({ type: TYPES.FETCH_WIZARD_OPTIONS_SUCCESS, selects });
    
  } catch (error) {
    console.error(error);
    
    yield put({ type: TYPES.FETCH_WIZARD_OPTIONS_FAILURE, error });
  }
}

function* fetchAlbumsList(action) {
  try {
    const albums = yield call(api.getAlbumsList, { endpoint: action.endpoint, query: action.query });
    
    yield put({ type: TYPES.FETCH_ALBUMS_SUCCESS, albums });
  } catch (error) {
    console.error(error);
    
    yield put({ type: TYPES.FETCH_ALBUMS_FAILURE, error });
  }
}

function* fetchAlbum(action) {
  try {
    const album = yield call(api.getAlbum, { id: action.id, endpoint: action.endpoint });
    
    yield put({ type: TYPES.FETCH_ALBUM_SUCCESS, album });
  } catch (error) {
    console.error(error);
    
    yield put({ type: TYPES.FETCH_ALBUM_FAILURE, error });
  }
}

function* updatePhotos(action) {
  try {
    const album = yield call(api.updateAlbumsPhotos, { id: action.id, body: action.photos });
    
    yield put({ type: TYPES.UPDATE_PHOTOS_SUCCESS, album });
  } catch (error) {
    console.error(error);
    
    yield put({ type: TYPES.UPDATE_PHOTOS_FAILURE, error });
  }
}

function* loadMoreAlbums(action) {
  try {
    const currentPage = action.page || 1;
    const albums = yield call(api.getAlbumsList, { endpoint: action.endpoint, page: currentPage });
    
    yield put({ type: TYPES.FETCH_MORE_ALBUMS_SUCCESS, albums, page: currentPage });
  } catch (error) {
    console.error(error);
    
    yield put({ type: TYPES.FETCH_MORE_ALBUMS_FAILURE, error });
  }
}

function* loadMoreMails(action) {
  try {
    const { page, query } = action;
    const currentPage = page || 1;
    const currentQuery = query || '';
    const mailbox = yield call(api.getLettersList, { page: currentPage, query: currentQuery });
    
    yield put({ type: TYPES.FETCH_MORE_MAILS_SUCCESS, mailbox, page: currentPage });
  } catch (error) {
    console.error(error);
    
    yield put({ type: TYPES.FETCH_MORE_MAILS_FAILURE, error });
  }
}

function* fetchMailboxHistory(action) {
  try {
    const mailbox = yield call(api.getLettersList, { query: action.query || '' });
    
    yield put({ type: TYPES.FETCH_MAIL_HISTORY_SUCCESS, mailbox });
  } catch (error) {
    console.error(error);
    
    yield put({ type: TYPES.FETCH_MAIL_HISTORY_FAILURE, error });
  }
}

function* fetchContactsList() {
  try {
    const contacts = yield call(api.getContacts);
    
    yield put({ type: TYPES.FETCH_CONTACTS_SUCCESS, contacts });
  } catch (error) {
    console.error(error);
    
    yield put({ type: TYPES.FETCH_CONTACTS_FAILURE, error });
  }
}

function* handleResendVerificationEmail() {
  try {
    const body = yield call(api.resendVerificationEmail);
    
    yield put({ type: TYPES.RESEND_VERIFICATION_EMAIL_SUCCESS, payload: body.message });
  } catch (error) {
    console.error(error);
  
    yield put({ type: TYPES.RESEND_VERIFICATION_EMAIL_FAILURE, error });
  }
}

function* handleSimilarProfileRequest(action) {
  try {
    const body = yield call(api.getSimilarProfiles, action.id);
    
    if (Array.isArray(body)) {
      yield put({ type: TYPES.GET_SIMILAR_PROFILES_SUCCESS, payload: body });
    }
    
  } catch (error) {
    console.error(error);
    
    yield put({ type: TYPES.GET_SIMILAR_PROFILES_FAILURE, error });
  }
}

export function* watcherSaga() {
  yield takeLatest(TYPES.FETCH_PROFILE_REQUEST, fetchProfile);
  yield takeLatest(TYPES.FETCH_CATALOG_REQUEST, fetchCatalog);
  yield takeLatest(TYPES.FETCH_CHAT_HISTORY_REQUEST, fetchChatHistory);
  yield takeLatest(TYPES.FETCH_MAIL_HISTORY_REQUEST, fetchMailboxHistory);
  yield takeLatest(TYPES.FETCH_USER_PROFILE_REQUEST, fetchUserProfile);
  yield takeLatest(TYPES.FETCH_WIZARD_OPTIONS_REQUEST, fetchWizardOptions);
  yield takeLatest(TYPES.FETCH_ALBUMS_REQUEST, fetchAlbumsList);
  yield takeLatest(TYPES.FETCH_ALBUM_REQUEST, fetchAlbum);
  yield takeLatest(TYPES.FETCH_MORE_PROFILES_REQUEST, loadMoreProfiles);
  yield takeLatest(TYPES.FETCH_MORE_ALBUMS_REQUEST, loadMoreAlbums);
  yield takeLatest(TYPES.FETCH_MORE_MAILS_REQUEST, loadMoreMails);
  yield takeLatest(TYPES.FETCH_MORE_MESSAGES_REQUEST, loadMoreMessages);
  yield takeLatest(TYPES.UPDATE_PHOTOS_REQUEST, updatePhotos);
  yield takeLatest(TYPES.FETCH_CONTACTS_REQUEST, fetchContactsList);
  yield takeLatest(TYPES.TYPING_REQUEST, handleTyping);
  yield takeLatest(TYPES.RESEND_VERIFICATION_EMAIL_REQUEST, handleResendVerificationEmail);
  yield takeLatest(TYPES.GET_SIMILAR_PROFILES_REQUEST, handleSimilarProfileRequest);
  yield takeEvery(TYPES.FETCH_MESSAGE, handleNewMessage);
}
