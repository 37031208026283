import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import * as Styles from '../styles';
import { likePhoto } from '../../../api';
import { trash } from '../../../assets';

import { Heart } from '../../../assets/svg/Heart';
import { isMobile } from '../../../utils/mobile';

class PreviewPhoto extends PureComponent {
  state = {
    liked: this.props.photo.is_liked,
    isSubscribe: this.props.profile.last_subscription && this.props.profile.last_subscription.is_active
  };
  
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.photo.is_liked !== this.props.photo.is_liked) {
      this.setState({ liked: nextProps.photo.is_liked });
    }
  }

  likePhoto = () => {
    const {profile_id, album_id, setLikeToPhoto, photo} = this.props;

    if (!this.state.liked) {
      likePhoto({ profile_id, album_id, photo_id: photo.id });
  
      setLikeToPhoto(photo);
  
      this.setState({ liked: true });
    }
  };

  handleClick = e => {
    this.props.togglePhotoView(e.target.id);
  };

  render() {
    const {index, photo, editMode, removePhoto, role} = this.props;

    return (
      <Styles.PhotoContainer>
        <Styles.Photo
          id={index}
          src={photo.thumbnail_src}
          onClick={this.handleClick}
        />
        {
          role !== 'me' && !isMobile() && (!this.state.liked ? (
            <Like
              id={photo.id}
              onClick={this.likePhoto}
            >
              <Heart id={photo.id}/>
            </Like>
          ) : (
            <Like liked={this.state.liked}>
              <i className='fas fa-heart'/>
            </Like>
          ))
        }
        {
          editMode && (
            <Trash
              src={trash}
              id={photo.id}
              onClick={removePhoto}/>
          )
        }
      </Styles.PhotoContainer>
    );
  }
}

PreviewPhoto.propTypes = {
  removePhoto: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  photo: PropTypes.object.isRequired
};

export default PreviewPhoto;

export const Like = styled.div`
  position: absolute;
  display: flex;
  bottom: 10px;
  right: 10px;
  background: rgba(255, 255, 255, .5);
  border: 1px solid #fff;
  padding: 11px 9px 8px;
  font-size: 24px;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 #4b4c59;
  transition: .2s;
  
  svg {
    width: 24px;
    height: 24px;
    fill: ${props => props.liked ? '#dd2c57' : '#fff'};
  }
  
  i {
    color: #dd2c57;
  }
  
  border-color: ${props => props.liked ? '#dd2c57' : '#fff'};

	:hover {
    cursor: pointer;
    opacity: .7
	}
	
	:focus {
    outline: 0;
    border-color: #dd2c57
	}
`;

export const Trash = styled.img`
	cursor: pointer;
	position: absolute;
  right: 6px;
  bottom: 6px;
  background-color: #d20000;
  border-radius: 50%;
  padding: 8px;
  width: 2.25rem;
  height: 2.25rem;
`;