import React from 'react';
import { Field } from 'redux-form';

import * as Styles from './styles';

const Sex = () => {
  return (
    <Styles.SexWrapper>
      <span className="title">I'm looking for</span>
      <div className='radio_group'>
        <label>
          <Field
            name="gender"
            component="input"
            type="radio"
            value="MALE"
          />
          Male
        </label>
        <label>
          <Field
            name="gender"
            component='input'
            type="radio"
            value="FEMALE"
          />
          Female
        </label>
      </div>
    </Styles.SexWrapper>
  );
};

export default Sex;