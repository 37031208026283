import React, { Component } from 'react';
import { change, Field, reduxForm, submit } from 'redux-form';

import ExtendedSearch from './ExtendedSearch';
import * as Styles from './styles';
import RenderSelects from './RenderSelects';
import { getAgeOptions } from '../../utils/getAgeOptions';
import Checkbox from './Checkbox';
import { isMobile } from '../../utils/mobile';
import RenderInterests from '../Wizard/renderInterest';
import validate from './validate';
import Select from './Select';
import Sex from './sex';

class Form extends Component {

  state = {
    maxAge: 70
  };

  componentDidMount() {
    document.addEventListener('keyup', this.onEnterPress, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onEnterPress, false);
  }

  onEnterPress = e => {
    if (e.keyCode === 13) {
      this.props.dispatch(submit('searchBar'));
    }
  };

  setupCountryOptions = () => {
    const {selects} = this.props;

    const options = Object.keys(selects.data.country_filter).reduce((result, option) => {
      return result.concat({
        value: option,
        label: selects.data.country[option].text.replace(/catalog\.country\./gi, '')
      });
    }, [])
      .sort((a, b) => (a.value < b.value) ? -1 : (a.value > b.value) ? 1 : 0);

    return [{value: '', label: 'Anywhere'}, ...options];
  };

  clearFormValues = e => {
    e.preventDefault();
    localStorage.removeItem('query');

    this.props.clearSearchSettings();

    Object.keys(this.props.Form.searchBar.values).forEach(key => {
      this.props.dispatch(change('searchBar', key, ''));
    });
  };
  
  handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  render() {
    const {onClose, toggleSearchPanel, extendedSearch, handleSubmit, selects, toggleInterestsPanel, interests} = this.props;

    const form = this.props.Form.searchBar;
    return (
      <Styles.Form onSubmit={handleSubmit}>
        <Styles.Header>
          <Styles.Close onClick={onClose}>
            <i className='fal fa-times'/>
          </Styles.Close>
          <Styles.TitleWrap>
            <Styles.Title>Search</Styles.Title>
          </Styles.TitleWrap>
          <Field
            name='is_online'
            component={Checkbox}
            label='Online'
          />
          <Field
            name='is_new'
            component={Checkbox}
            label='New'
          />
        </Styles.Header>
        <Styles.StyledSearchField>
          <div className="title">Name</div>
          <Field type="text" name="name" component="input" onKeyDown={this.handleKeyDown}/>
        </Styles.StyledSearchField>
        <Styles.FieldContainer>
          <Styles.Location>
            <Styles.LocationLabel>Location</Styles.LocationLabel>
            <Styles.LocationInputWrap>
              <Field
                defaultValue={{value: '', label: 'Anywhere'}}
                currentValue={form && (form.values.hasOwnProperty('country') ? form.values.country : null)}
                name="country"
                placeholder={'Anywhere'}
                component={Select}
                options={this.setupCountryOptions()}
              />
            </Styles.LocationInputWrap>
          </Styles.Location>
          <Styles.AgeWrapper>
            <span
              style={{
                fontWeight: 600,
                minWidth: isMobile() ? '35%' : 'auto',
                marginLeft: isMobile() ? 0 : '1.75rem',
                marginRight: isMobile() ? 0 : '5px'
              }}
            >
              Age
            </span>
            <Field
              width={'65px'}
              component={RenderSelects}
              name='age_from'
              options={getAgeOptions(18, this.state.maxAge).map(age => ({value: age.toString(), label: age}))}
            />
            <span style={{ lineHeight: 1, margin: '0 10px' }}>–</span>
            <Field
              width={'65px'}
              component={RenderSelects}
              name='age_to'
              age_from={Form && Form.values && (+Form.values.age_from + 1)}
              options={getAgeOptions(19, this.state.maxAge).map(age => ({ value: age.toString(), label: age }))}
            />
          </Styles.AgeWrapper>
        </Styles.FieldContainer>
        <Sex userGender={this.props.userGender}/>
        <Styles.ExtendedSearchWrap isOpen={extendedSearch} onClick={toggleSearchPanel}>
          <span>Extended search</span>
          <Styles.SearchIcon>
            <i className={extendedSearch ? 'far fa-angle-up' : 'far fa-angle-down'}/>
          </Styles.SearchIcon>
        </Styles.ExtendedSearchWrap>
        {
          extendedSearch && (
            <ExtendedSearch selects={selects.data}/>
          )
        }
        <Styles.InterestsSearchWrap isOpen={interests} onClick={toggleInterestsPanel}>
          <span>Interests</span>
          <Styles.SearchIcon>
            <i className={interests ? 'far fa-angle-up' : 'far fa-angle-down'}/>
          </Styles.SearchIcon>
        </Styles.InterestsSearchWrap>
        {
          interests && (
            <Styles.Interests>
              <Field
                name="interests"
                values={this.props.Form.searchBar.values}
                initialValues={this.props.initialValues}
                interests={selects.data.interests}
                component={RenderInterests}
              />
            </Styles.Interests>
          )
        }
        <Styles.ButtonContainer>
          <Styles.Button onClick={this.clearFormValues}>Clear all</Styles.Button>
          <Styles.Search as='button' type='submit'>Search</Styles.Search>
        </Styles.ButtonContainer>
      </Styles.Form>
    );
  }
}

export default reduxForm({
  form: 'searchBar',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(Form);