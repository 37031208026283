import React from 'react';
import styled from 'styled-components';

import Edit from '../Edit';
import Interests from './Interests';
import PersonalData from './PersonalData';
import LookingFor from './LookingFor';

const DetailInfo = ({profileData, initialValues, ...props}) => {
  return (
    <Container>
      <Interests
        {...props}
        interests={profileData.interests}
        name={profileData.name}
      />
      <PersonalData
        {...props}
        profileData={profileData}
      />
      <LookingFor
        {...props}
        text={profileData.looking_for}
      />
      <Edit
        initialValues={initialValues}
        profile={profileData}
        {...props}
      />
    </Container>
  );
};

export default DetailInfo;

const Container = styled.div`
  display: flex;
  padding-top: 1.25rem;
  height: 18.5725rem;
  
  @media (max-width: 767px) {
    padding-top: .3125rem;
    flex-flow: column;
    height: auto;
  }
`;