import React from 'react';
import Modal from 'react-modal';

const styles = {
  content: {
    backgroundColor: 'transparent',
    border: 0,
  },
  overlay: {
    position: 'absolute',
    zIndex: 100,
    backgroundColor: 'rgba(75, 76, 89, 0.5)'
  }
};

function ModalInfo({ show }) {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={show}
      style={styles}
    />
  );
}

export default ModalInfo;