import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Contact from './Contact';

const List = ({contacts, initialContacts, myId, onlineUsers, toggleEmailForm}) =>
  <ContactsBox>
    {
      !contacts.length && (
        <Placeholder>
          {!initialContacts.length ? (<span style={{textAlign: 'center'}}>You have no messages yet, <br/> start chating :)</span>) : !contacts.length && 'No match found.'}
        </Placeholder>
      )
    }
    {
      Object.keys(contacts).map(user => {
        return (
          <Contact
            toggleEmailForm={toggleEmailForm}
            is_online={~onlineUsers.indexOf(contacts[user].id)}
            myId={myId}
            user={contacts[user]}
            key={contacts[user].id}
          />
        );
      })
    }
  </ContactsBox>;

List.propTypes = {
  contacts: PropTypes.array.isRequired,
  initialContacts: PropTypes.array.isRequired
};

export default List;

const Placeholder = styled.span`
  color: #b8bfd3;
  font-size: .875rem;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const ContactsBox = styled.div`
	overflow-x: hidden;
	overflow-y: auto;
	
	::-webkit-scrollbar { 
    display: none; 
  }
  
  height: 25.75rem;
  
  @media (max-width: 767px) {
  	max-height: 100%;
  	position: relative;
    z-index: 1;
    height: auto;
  }
`;


