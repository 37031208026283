import React, {Component} from 'react';
import Select from 'react-select';

class SelectSearch extends Component {

  state = {
    selectedOption: null
  };

  componentDidMount() {
    const {meta, input, defaultValue, currentValue} = this.props;
    if (this.props.meta) {
      const value = currentValue !== undefined ? currentValue : (input.value ? input.value : meta.initial);
      this.setState({selectedOption: value ? {value, label: value} : null});
    } else if (defaultValue) {
      this.setState({selectedOption: defaultValue});
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.input.value === '') {
      this.setState({selectedOption: nextProps.defaultValue || null});
    }
  }

  handleChange = option => {
    this.setState({selectedOption: option});
    this.props.onChange && this.props.onChange(option);
    this.props.input && this.props.input.onChange(option.value);
  };

  render() {
    const {options, placeholder} = this.props;
    return (
      <div>
        <Select
          value={this.state.selectedOption}
          onChange={this.handleChange}
          options={options}
          placeholder={placeholder || ''}
        />
      </div>
    );
  }
}

export default SelectSearch;

