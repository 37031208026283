import {connect} from 'react-redux';

import catalogComponent from '../components/Catalog';
import {fetchCatalog, loadMoreProfiles, resendVerificationEmail} from '../actions';

const mapStateToProps = state => ({
  catalog: state.catalog,
  onlineUsers: state.onlineUsers,
  profile: state.profile,
  search: state.profile.search,
  myGender: state.profile.gender
});

const mapDispatchToProps = {
  fetchCatalog,
  loadMoreProfiles,
  resendVerificationEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(catalogComponent);