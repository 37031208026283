import styled from 'styled-components';
import React from 'react';

export const Title = styled.b`
  font-size: .75rem;
  font-weight: 600;
  margin-bottom: .625rem;
  color: #4d4d4d;
`;

export const Price = styled.div`
	span:first-child {
		font-size: 14px;
    font-weight: 600;
    margin-right: 2px
	}
	
	span:last-child {
	  font-size: 12px;
    color: #4b4c59;
    font-weight: 400
	}
	
	b {
		font-size: 34px;
	}
`;

export const Trial = styled.p`
  font-size: 12px;
  text-transform: uppercase;
  opacity: 0;
  visibility: hidden;
  position: relative;
  color: #3b5a9a;
  font-weight: bold;
  bottom: 12px;
  
  ${({ isTrial }) => Boolean(isTrial) && `
    opacity: 1;
    visibility: visible;
  `}
`;

export const Recommended = styled.p`
  color: #7ed321;
  font-size: 12px;
  text-transform: uppercase;
  opacity: 0;
  visibility: hidden;
  position: relative;
  
  ${({ isRecommended }) => isRecommended && `
    opacity: 1;
    visibility: visible;
  `}
  
   ${({ isTrial }) => Boolean(isTrial) && `
    top: -22px;
  `}
`;

export const PlansContainer = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: center;
  flex-wrap: wrap;
  
  @media (max-width: 767px) {
    flex-flow: column;
  }
`;

export const Description = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  
  li {
    font-size: 12px;
    color: #515151;
    margin: 8px 0;
  }
`;

export const YourPlan = styled.p`
	margin-top: 29px;
  font-size: 14px;
  font-weight: 600;
  color: #4b4c59;
`;

export const Plan = styled.li`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 171px;
  height: 253px;
  padding: 20px;
  box-shadow: 0 2px 18px 0 rgba(184, 191, 211, .2);
  background-color: #fff;
  color: #3b5a9a;
  margin: 25px 5px;
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  transition: all .1s;
  
  ${({subscription, block}) => (subscription || block) ? `
    cursor: default;
    
    ${Price} {
      color: #dadee8;
    }
  ` : `
    &:hover {
      border: 1px solid rgb(184, 191, 211);
      transform: scale(1.05);
    }
  `}
  
  ${({chosen}) => chosen && `
    border: 1px solid #3b5a9a;
    transform: scale(1.05);
    
    &:hover {
      border: 1px solid #3b5a9a;      
    }
  `}
  
  @media (max-width: 767px) {
    margin-bottom: 1.25rem;
    width: 13.1rem;
    height: 21.3rem;
  }
`;

export const Loader = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 25px;

	img {
	  width: 15px;
    height: 15px;
	}
`;

export const Close = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  background: 0;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  color: #b8bfd3;
  cursor: pointer;
  transition: opacity .33s cubic-bezier(.25, .46, .45, .94);
  z-index: 1;
  
  :hover {
    color: #4b4c59;
  }
`;

export const Hint = styled.span`
  position: absolute;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  top: 1rem;
  right: 1rem;

  :hover span {
    visibility: visible;
    opacity: 1;
  }

  span {
    font-size: .875rem;
    position: absolute;
    top: -1.3125rem;
    left: 50%;
    visibility: hidden;
    width: max-content;
    max-width: 17.1875rem;
    height: auto;
    padding: .1875rem .8125rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: .2s;
    transition: .2s;
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
    opacity: 0;
    z-index: 1;
    color: #fff;
    background-color: rgba(75, 76, 89, .9)
  }

  span::before {
    position: absolute;
    top: 100%;
    left: 50%;
    content: "";
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border: .5rem solid transparent;
    border-top: .3125rem solid rgba(75, 76, 89, .9)
  }

  @media (max-width: 360px) {
    span {
      transform: translate(-86%, -100%)
    }

    span::before {
      right: 0;
      left: auto;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%)
    }
  }
  
  i {
    font-size: .75rem;
    color: #dadee8;
    
    @media (max-width: 767px) {
      position: relative;
      top: .125rem;
    }
  }
`;

export const ActiveSvg = () => (
  <div className="top-item">
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.065 11.45L30.313 15l2.014 3.017a.92.92 0 01-.081 1.126l-2.423 2.7.23 3.62a.921.921 0 01-.632.934l-3.448 1.128-1.61 3.252a.92.92 0 01-1.016.492l-3.549-.752c-2.917 1.947-3.09 2.092-3.286 2.138l-.068.014a.921.921 0 01-.79-.219l-2.7-2.423-3.62.23a.921.921 0 01-.933-.632l-1.129-3.448-3.25-1.61a.921.921 0 01-.493-1.016L4.281 20l-2.014-3.017a.921.921 0 01.081-1.126l2.423-2.7-.23-3.62a.921.921 0 01.632-.934l3.448-1.128 1.61-3.251a.921.921 0 011.016-.493l3.549.752 3.017-2.014a.922.922 0 011.126.081l2.7 2.423 3.62-.23a.921.921 0 01.934.632l1.128 3.448 3.251 1.61c.377.187.58.605.493 1.016z"
        fill="#DE60AD"/>
      <path
        d="M13.215 20.557h-1.05v-2.61H9.053v2.61H8.004v-5.982h1.05v2.448h3.111v-2.448h1.05v5.982zM20.032 19.795c-.574.58-1.306.87-2.197.87-.89 0-1.623-.29-2.197-.87-.574-.586-.861-1.328-.861-2.224 0-.897.287-1.636.86-2.216.575-.586 1.307-.879 2.198-.879s1.623.293 2.197.88c.574.58.861 1.318.861 2.215 0 .896-.287 1.638-.86 2.224zm-2.197-.063c.592 0 1.07-.203 1.435-.61.365-.412.547-.93.547-1.551 0-.628-.182-1.145-.547-1.552-.365-.406-.843-.61-1.435-.61-.598 0-1.08.204-1.444.61-.359.407-.538.924-.538 1.552 0 .627.18 1.145.538 1.551.365.407.846.61 1.444.61zM24.712 20.557h-1.05V15.5h-1.811v-.924h4.673v.924h-1.812v5.058z"
        fill="#fff"/>
    </svg>
  </div>
);