import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {TabsItem} from '../../../styles';

const Checkboxes = ['All', 'Match', 'Favorites'];

class FilterBar extends PureComponent {
  state = {
    listType: 'All'
  };

  handleClick = e => {
    this.props.onChange('list', e.target.id);
    this.setState({listType: e.target.id});
  };

  render() {
    return (
      <TabsContainer>
        {
          Checkboxes.map(value => (
            <TabsItem
              onClick={this.handleClick}
              key={value}
              id={value}
              active={value === this.state.listType}
            >
              {value}
            </TabsItem>
          ))
        }
      </TabsContainer>
    );
  }
}

FilterBar.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default FilterBar;

const TabsContainer = styled.div`
  display: flex;
  border-bottom: .0625rem solid #edeff4;
  padding-right: .9375rem;
  padding-left: .9375rem;
  
  @media (max-width: 767px) {
    position: fixed;
    top: 6.25rem;
    left: 50%;
    width: 100%;
    background-color: #fff;
    padding: 0;
    list-style: none;
    max-width: 70rem;
    transform: translate(-50%, 0);
    margin-bottom: .625rem;
    z-index: 2;
    
    div {
      font-size: .875rem;
    }
  }
`;



