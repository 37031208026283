import React from 'react';

const Coins = ({Svg}) =>
  <Svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.342 20c-2.935 0-5.397-.927-7.387-2.783C.985 15.343 0 12.937 0 10c0-2.937.985-5.333 2.955-7.188C4.945.937 7.407 0 10.342 0c3.216 0 5.758 1.275 7.628 3.826l-2.11 1.13c-.584-.83-1.367-1.507-2.352-2.028a6.663 6.663 0 00-3.166-.783c-2.211 0-4.06.744-5.548 2.232C3.327 5.845 2.593 7.72 2.593 10c0 2.28.734 4.164 2.201 5.652 1.487 1.469 3.337 2.203 5.548 2.203 1.125 0 2.18-.251 3.165-.754.986-.521 1.77-1.207 2.352-2.057L18 16.174C16.05 18.724 13.498 20 10.342 20z" fill="#DE60AD"/>
    <path d="M14 14h-2.113l-1.698-2.843H8.84V14H7V6h4.044c.89 0 1.595.24 2.113.72.528.48.791 1.1.791 1.859 0 .655-.186 1.187-.557 1.595a2.418 2.418 0 01-1.335.78L14 14zm-3.228-4.342c.38 0 .692-.096.934-.288a.976.976 0 00.363-.791.943.943 0 00-.363-.78c-.242-.2-.553-.3-.934-.3H8.841v2.16h1.931z" fill="#DE60AD"/>
  </Svg>;

export default Coins;