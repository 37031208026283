import React from 'react';
import styled from 'styled-components';

import * as Styles from './styles';
import Select from '../Select';

const normalize = str => str ? str.charAt(0).toUpperCase() + str.slice(1).replace(/_/, ' ').toLowerCase() : str;

const Selects = props =>
  <Styles.FieldContainer>
    <Span>{normalize(props.label)}</Span>
    <Select {...props} />
  </Styles.FieldContainer>;

export default Selects;

const Span = styled.span`
	@media (max-width: 767px) {
    font-size: .75rem;
    font-weight: 600;
    width: auto;
    flex-shrink: 0;
    padding-right: 0;
  }
  
  flex-shrink: 0;
  padding-right: .9375rem;
  display: block;
  width: 5rem;
  color: #515151;
`;
