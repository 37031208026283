import React from 'react';
import {Field} from 'redux-form';

import Select from '../Select';
import * as Styles from './styles';

const normalize = str => str.charAt(0).toUpperCase() + str.slice(1).replace(/_/, ' ').toLowerCase();

const unnecessaryKeys = ['gender', 'interests', 'languages_filter', 'education', 'notifications', 'country', 'country_filter'];

const ExtendedSearch = ({selects, isOpen}) =>
  <>
    <Styles.SelectsContainer isOpen={isOpen}>
      {
        Object.keys(selects)
          .filter(key => !unnecessaryKeys.includes(key))
          .map(key => (
            <Styles.OptionsItem key={key}>
              <span>{normalize(key)}</span>
              <Field
                name={key}
                component={Select}
                placeholder="Not chosen"
                options={
                  Object.keys(selects[key]).map(selectItem => ({
                    value: selectItem,
                    label: selects[key][selectItem].text
                  }))
                }
              />
            </Styles.OptionsItem>
          ))
      }
    </Styles.SelectsContainer>
  </>;

export default ExtendedSearch;