import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';

import MessagesReducer from './Messages';
import ProfileReducer from './Profile';
import CatalogReducer from './Catalog';
import UserProfile from './UserProfile';
import WizardReducer from './Wizard';
import AlbumsReducer from './Albums';
import AlbumDetailsReducer from './AlbumDetails';
import MailboxReducer from './Mailbox';
import UnreadMailCountReducer from './UnreadMailCount';
import ContactsReducer from './Contacts';
import OnlineReducer from './OnlineUsers';
import UnreadMessagesCountReducer from './UnreadMessagesCount';
import CardsReducer from './Cards';
import ModalsReducer from './modals';
import SimilarProfilesReducer from './similarProfiles';

export default combineReducers({
  form: reduxFormReducer,
  messages: MessagesReducer,
  profile: ProfileReducer,
  catalog: CatalogReducer,
  user: UserProfile,
  selects: WizardReducer,
  albums: AlbumsReducer,
  album: AlbumDetailsReducer,
  mailbox: MailboxReducer,
  inbox: UnreadMailCountReducer,
  contacts: ContactsReducer,
  onlineUsers:  OnlineReducer,
  unreadMessages: UnreadMessagesCountReducer,
  cards: CardsReducer,
  modals: ModalsReducer,
  similarProfiles: SimilarProfilesReducer,
  routing: routerReducer,
});