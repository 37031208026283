import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Status from './Status';

const SearchField = ({onChange, searchTerm}) =>
  <Wrapper>
    <InputWrap>
      <i className="far fa-search"/>
      <Input
        type="text"
        placeholder='Search'
        value={searchTerm}
        onChange={e => onChange('searchTerm', e.target.value)}
      />
    </InputWrap>
    <Status onChange={onChange}/>
  </Wrapper>;

SearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired
};

export default SearchField;

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const InputWrap = styled.div`
  display: flex;
  padding-left: .5625rem;
  border-radius: .9375rem;
  background-color: #edeff4;
  align-items: center;
  margin-bottom: 1.5625rem;
  margin-right: .5rem;
  
  @media (max-width: 767px) {
    border: .0625rem solid #edeff4;
    font-size: .875rem;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 1.875rem;
  padding: 0 .625rem;
  border: 0;
  background-color: transparent;
  color: #515151

	::-webkit-input-placeholder {
    font-size: .875rem;
    color: #b8bfd3
	}

	::-ms-input-placeholder {
    font-size: .875rem;
    color: #b8bfd3
	}

	::placeholder {
	  font-size: .875rem;
	  color: #b8bfd3
	}

	:focus {
	  outline: 0
	}
`;