import React, { useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import Subscription from '../../containers/Subscription';
import RefilCredits from '../Credits';

import { isMobile } from '../../utils/mobile';
import * as Styles from './styles';
import Credits from './credits';
import Money from './money';
import Unsubscribe from './Unsubscribe';
import { Event } from '../../Tracking';
import UnsubscribeMessage from './message';

const Tabs = [
  {
    name: 'Credits',
    Grid: Credits
  },
  {
    name: 'Money',
    Grid: Money
  }
];

const Payments = ({ history, updateProfileInfo, profile: { last_subscription, is_agency, credits } }) => {
  const [filter, setFilter] = useState(Tabs[0].name);
  const [refillCreditsPanelIsOpen, setCreditsPanelIsOpen] = useState(false);
  const [subscriptionPanelIsOpen, setSubscriptionPanelIsOpen] = useState(false);
  const [isShowMessage, setIsShowMessage] = useState(false);
  
  const toggleUnsubscribeMessage = () => setIsShowMessage(isShowMessage => !isShowMessage);
  
  const onFilterChange = e => setFilter(e.target.id);
  
  const toggleCreditsPanel = () => setCreditsPanelIsOpen(refillCreditsPanelIsOpen => !refillCreditsPanelIsOpen);
  
  const toggleSubscriptionPanel = () => {
    Event('userclicks', 'button', 'upgrade');
    setSubscriptionPanelIsOpen(subscriptionPanelIsOpen => !subscriptionPanelIsOpen);
  };
  
  const goBack = () => history.goBack();
  
  const Grid = Tabs.find(({ name }) => name === filter).Grid;
  
  return (
    <Styles.PaymentsWrap>
      {
        !isMobile() ? (
          <Styles.HeaderWrap>
            <Styles.Back onClick={goBack}>
              <i className="far fa-angle-left"/>
              Back to settings
            </Styles.Back>
            <Styles.TitleWrap>
              <Styles.Title>Payments</Styles.Title>
              <Styles.Balance>{credits} credits</Styles.Balance>
              {
                !is_agency && (
                  (last_subscription && last_subscription.is_active)? (
                    <Styles.Button onClick={toggleCreditsPanel}><i className="fas fa-coins"/>Refill
                      credits</Styles.Button>
                  ) : (
                    <Styles.Button lightning onClick={toggleSubscriptionPanel}>Upgrade account</Styles.Button>
                  )
                )
              }
            </Styles.TitleWrap>
          </Styles.HeaderWrap>
        ) : (
          <Styles.MobileWrap>
            <Styles.TitleRow>
              <NavLink to={'/settings'} style={{ textDecoration: 'none' }}>
                <Styles.Back>
                  <i className="far fa-angle-left"/>
                  <Styles.Title>Payments</Styles.Title>
                </Styles.Back>
              </NavLink>
              <Styles.Balance>{credits} credits</Styles.Balance>
            </Styles.TitleRow>
            <Styles.ButtonWrap>
              <Styles.Button onClick={toggleCreditsPanel}>Refill credits</Styles.Button>
              <Styles.MobSubscribe onClick={toggleSubscriptionPanel}>Subscription</Styles.MobSubscribe>
              <Styles.MobSubscribe as={NavLink} to="/pricing">Pricing</Styles.MobSubscribe>
            </Styles.ButtonWrap>
          </Styles.MobileWrap>
        )
      }
      <div>
        <Styles.TabsWrap>
          <Styles.TabsList>
            {
              Tabs.map(({ name }) => (
                <Styles.TabsItem
                  onClick={onFilterChange}
                  key={name}
                  id={name}
                  active={filter === name}
                >
                  {name}
                </Styles.TabsItem>
              ))
            }
          </Styles.TabsList>
          <div>
            {!is_agency && (<Styles.Subscribe onClick={toggleSubscriptionPanel}>Subscription</Styles.Subscribe>)}
            {' | '}
            <Styles.Subscribe as={NavLink} to="/pricing">Pricing</Styles.Subscribe>
          </div>
        </Styles.TabsWrap>
      </div>
      
      <Grid/>
      {
        isShowMessage && last_subscription && last_subscription.is_active && (
          <UnsubscribeMessage
            isOpen={isShowMessage}
            onClose={toggleUnsubscribeMessage}
            subscription={last_subscription}
          />
        )
      }
      {
        subscriptionPanelIsOpen && ((last_subscription && last_subscription.is_active )? (
          <Unsubscribe
            isOpen={subscriptionPanelIsOpen}
            onClose={toggleSubscriptionPanel}
            showMessage={toggleUnsubscribeMessage}
            updateProfileInfo={updateProfileInfo}
            subscription={last_subscription}
          />
        ) : (<Subscription onClose={toggleSubscriptionPanel}/>))
      }
      {refillCreditsPanelIsOpen && (<RefilCredits onClose={toggleCreditsPanel}/>)}
    </Styles.PaymentsWrap>
  );
};

export default withRouter(Payments);
