import React, { PureComponent } from 'react';

import Dialog from './Dialog';
import * as api from '../../api';

class Chat extends PureComponent {
  
  _isMounted = false;
  
  state = {
    contact: {},
  };
  
  componentDidMount() {
    this._isMounted = true;
    this.connection(atob(this.props.match.params.id));
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { match, messages } = nextProps;
    const id = atob(match.params.id);
    
    if (id !== atob(this.props.match.params.id)) {
      this.connection(id);
      
    } else if (
      messages.data.length &&
      messages.data[nextProps.messages.data.length - 1].sender_id === +id &&
      !messages.data[nextProps.messages.data.length - 1].read_at
    ) {
      api.markMessagesAsRead(id)
        .then(() => {
          api.getFullProfile({})
            .then(response => {
              this.props.getInitialMessagesCount(response.data.unread_messages_count);
            });
        });
    }
  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }
  
  connection(userId) {
    Promise.all([
      api.getFullProfile({ endpoint: `/profiles/${userId}` }),
      this.props.fetchChatHistory(userId),
    ])
      .then(res => {
        if (this._isMounted) {
          this.props.getChatUserProfile(res[0].data);
          this.setState({ contact: res[0].data });
        }
      });
  }
  
  createRefs = () => {
    const { messages } = this.props;
    
    return messages.data.length && messages.data.reduce((acc, value) => {
      acc[value.id] = React.createRef();
      return acc;
    }, {});
  };
  
  render() {
    const { messages, match, history, onlineUsers, profile, contacts, updateContactsInfo } = this.props;
    const isOnlineUser = ~onlineUsers.indexOf(+atob(match.params.id));
    const refs = this.createRefs();
    
    const messageStatus = {
      error: messages.error,
      sent: messages.sent,
      loading: messages.loading
    };
    
    return (
      <Dialog
        updateContactsInfo={updateContactsInfo}
        contacts={contacts}
        refs={refs}
        isOnlineUser={isOnlineUser}
        history={history}
        profile={profile}
        contact={this.state.contact}
        messages={messages}
        id={atob(match.params.id)}
        {...messageStatus}
      />
    );
  }
}

export default Chat;
