const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Please enter a name of album';
  } else if (values.name.replace(/ /gi, '').length < 3) {
    errors.name = 'Name must have at least 3 symbols';
  }

  return errors;
};

export default validate;



