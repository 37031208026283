import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';

import { Modal, ModalContent } from '../Modal';
import { basicUsersFields, mainInfoFields } from '../../constants';
import { isMobile } from '../../utils/mobile';

const PhotoEditContent = ({ show }) => {
  if (!show) {
    return null;
  }
  
  return (
    <li className="modal-list-item">
      <div className="modal-list-item-icon"/>
      Set the avatar. Beauties want to see you and set the most close visual contact
    </li>
  );
};

const InfoEditContent = ({ show }) => {
  if (!show) {
    return null;
  }
  
  return (
    <li className="modal-list-item">
      <div className="modal-list-item-icon"/>
      Fill the information about yourself and your hobbies in. There are much higher chances to find a
      soulmate, when you have a common causes and synergie
    </li>
  );
};

const MainContent = ({ profile }) => {
  const history = useHistory();
  const cookieName = 'bonusesModal';
  const isProfileCompleted = basicUsersFields.every(field => {
    return Array.isArray(profile[field]) ? profile[field].length : profile[field];
  });
  
  const [show, setShow] = useState(false);
  const [cookies, setCookie] = useCookies([cookieName]);
  
  useEffect(() => {
    if (
      profile.isLoaded &&
      !profile.is_agency &&
      !show &&
      (!Boolean(isProfileCompleted) || !Boolean(profile.photo)) &&
      !cookies[cookieName] &&
      !history.location.search.includes('welcome')
    ) {
      setShow(true);
    }
  }, [profile]);
  
  const handleClose = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    
    setCookie(cookieName, 'true', {
      path: '/',
      expires: new Date(tomorrow.setDate(tomorrow.getDate() + 1))
    });
    
    setShow(false);
  };
  
  const handleClick = () => {
    let state = '';
    
    const isProfileCompleted = mainInfoFields.every(field => profile[field]);
    const isInterestsCompleted = Boolean(profile.interests && profile.interests.length);
    const isPhotoCompleted = Boolean(profile.photo);
    
    if (isMobile()) {
      state = !isProfileCompleted ? 'editMode' : !isInterestsCompleted && 'editInterests';
      
    } else {
      state = !isPhotoCompleted ? 'editPhoto' : !isProfileCompleted ? 'editMode' : !isInterestsCompleted && 'editMode';
    }
    
    window.dataLayer.push({
      'event': 'date',
      'eventCategory': 'userclicks',
      'eventAction': 'button',
      'eventLabel': 'do_it'
    });
    
    history.push({
      pathname: '/me',
      state: { [state]: true }
    });
    
    handleClose();
  };
  
  return (
    <Modal isOpen={show}>
      <ModalContent>
        <div className="modal-wrapper modal-wrapper--bonus">
          <div className="modal-title">
            Welcome to DateJasmin!
          </div>
          <div className="modal-description">
            We give you 10 bonus credits and bless you on successful spouse searches. To get this bonus, please meet the
            following conditions
          </div>
          <div className="modal-list-wrapper">
            <div className="modal-list-title">
              Fill out your profile:
            </div>
            <ul className="modal-list">
              <PhotoEditContent show={!Boolean(profile.photo)}/>
              <InfoEditContent show={!Boolean(isProfileCompleted)}/>
            </ul>
          </div>
          <div className="modal-button-wrapper">
            <button className="modal-button modal-button--cancel" onClick={handleClose}>Cancel</button>
            <button className="modal-button modal-button--thank" onClick={handleClick}>Let's do it!</button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

const BonusesModal = ({ profile }) => {
  if (Object.keys(profile).length > 2) {
    return (
      <MainContent profile={profile} />
    );
  }
  
  return null;
};


const mapStateToProps = ({ profile }) => ({ profile });

export default connect(mapStateToProps)(BonusesModal);