import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { SubscriptionPlanWrapper } from '../../Credits/styles';

import RenderPlan from './plan';
import { ActiveSvg } from '../styles';
import { Event } from '../../../Tracking';

export default function Plans({ chosenPlan, onChoosePlan, plans, lastSubscription }) {
  const activePlan = lastSubscription && lastSubscription.is_active && lastSubscription;
  
  const highPriority = Math.max(...plans.map(({ priority }) => priority));
  
  const recommended = plans.find(({ priority }) => priority === highPriority);
  
  const handleClick = plan => {
    const isYourPlan = (chosenPlan && (chosenPlan.id || chosenPlan.plan_id)) === plan.id;
    
    Event('userclicks', 'subscribe', plan.name);
    
    if (!isYourPlan) {
      onChoosePlan(plan);
    }
  };
  
  return (
    <SubscriptionPlanWrapper>
      <div className="tabs-title">Subscription plan</div>
      <div className="tabs-subtitle">
        When you run of free credits, choose a subscription plan to have a communication set.
        Within the plan you can also refill credits
      </div>
      <Tabs className="tabs-wrapper" selectedIndex={
        plans.length
          ? plans.findIndex(plan => plan.id === (chosenPlan && chosenPlan.id))
          : 0
      }>
        <TabList>
          {plans.map((plan, index) => (
            <Tab
              tabIndex={index}
              key={plan.id}
              id={plan.id}
              onClick={() => handleClick(plan)}
            >
              {plan.name} {recommended.id === plan.id && (<ActiveSvg/>)}
            </Tab>
          ))}
          
          {plans.length === 0 && activePlan && (
            <Tab tabIndex={0}>
              {activePlan.name}
            </Tab>
          )}
        
        </TabList>
        {plans.map(plan => (
          <TabPanel key={plan.id}>
            <RenderPlan
              key={plan.id}
              id={plan.id}
              credits={plan.credits}
              name={plan.name}
              price={plan.price}
              credits_per_day={plan.credits_per_day}
              days={plan.days}
              trial_days={plan.trial_days}
              trial_credits={plan.trial_credits}
              currency={plan.currency}
            />
          </TabPanel>
        ))}
        
        {plans.length === 0 && activePlan && (
          <TabPanel tabId={0}>
            <RenderPlan {...activePlan} />
          </TabPanel>
        )}
      </Tabs>
    </SubscriptionPlanWrapper>
  );
}