import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Subscription from '../../containers/Subscription';

const Mailbox = ({ history, letters }) => {
  const [subscriptionPanelIsOpen, toggleSubscriptionPanel] = useState(false);
  
  const handleClick = () => {
    history.push('/mailbox');
  };

  const onClose = () => toggleSubscriptionPanel(false);

  const onSuccess = () => history.push('/mailbox');

  return (
    <>
      {subscriptionPanelIsOpen && (<Subscription onClose={onClose} onSuccess={onSuccess}/>)}
      <Wrapper onClick={handleClick}>
        <Container>
          <TextWrap>
            <Icon>
              <i className="fas fa-envelope"/>
            </Icon>
            <MailText>Mailbox</MailText>
          </TextWrap>
        </Container>
        {letters ? (<MailNumber>{letters > 99 ? '99+' : letters}</MailNumber>) : <MailNumber>0</MailNumber>}
      </Wrapper>
    </>
  );
};

export default withRouter(Mailbox);

const Wrapper = styled.div`
  color: #4d4d4d;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem;
  background-color: #fff;
  border-bottom: .0625rem solid #f6f7f9;
`;

const Icon = styled.div`
	color: #4b4c59;
  font-size: 16px;
  margin-right: 5px
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const MailText = styled.span`
	color: #4b4c59;
  font-size: 1.125rem;
  font-weight: 600
`;

const MailNumber = styled.span`
	font-size: .75rem;
  display: flex;
  align-items: center;
  padding: 0 .5625rem;
  height: 1.125rem;
  letter-spacing: .046875rem;
  color: #fff;
  border-radius: .8125rem;
  background-color: #3b5a9a;
`;

const TextWrap = styled.div`
  display: flex;
  align-items: baseline;
`;