import React from 'react';
import styled from 'styled-components';

import * as api from '../../../api';

const Notification = ({ notification, disabled_notifications, updateProfileInfo, text, email, push, sound }) => {
  const newPushNotifications = ['LIKES_COUNT', 'MESSAGES_COUNT', 'FAVORITE_MESSAGES'];
  
  const handleChange = e => {
    const target = e.target.value;
    
    let disabledNotifications = disabled_notifications || [];
    let atIndex = disabled_notifications[target].indexOf(notification);
    
    if (atIndex !== -1) {
      disabledNotifications = {
        ...disabled_notifications,
        [target]: disabledNotifications[target].slice(0, atIndex).concat(disabledNotifications[target].slice(atIndex + 1))
      };
      
    } else {
      disabledNotifications = {
        ...disabled_notifications,
        [target]: disabledNotifications[target].concat(notification)
      };
    }
    
    api.updateProfile({ body: { disabled_notifications: disabledNotifications } });
    
    updateProfileInfo({ disabled_notifications: disabledNotifications });
  };
  
  return (
    <NotificationItem>
      <Title>{text}</Title>
      <Radio>
        <RadioInput
          disabled={!email}
          type="checkbox"
          value="email"
          checked={disabled_notifications ? disabled_notifications['email'].indexOf(notification) === -1 : true}
          onChange={handleChange}
        />
        <RadioCustom/>
      </Radio>
      <Radio>
        <RadioInput
          disabled={(push && !newPushNotifications.includes(notification)) || (!newPushNotifications.includes(notification) && !push)}
          type="checkbox"
          value="push"
          checked={disabled_notifications ? disabled_notifications['push'].indexOf(notification) === -1 : true}
          onChange={handleChange}
        />
        <RadioCustom/>
      </Radio>
      <Radio>
        <RadioInput
          disabled={!sound}
          type="checkbox"
          value="sound"
          checked={disabled_notifications ? disabled_notifications['sound'].indexOf(notification) === -1 : true}
          onChange={handleChange}
        />
        <RadioCustom/>
      </Radio>

    </NotificationItem>
  );
};

export default Notification;

const RadioCustom = styled.span`
	display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #dadee8;
`;

const RadioInput = styled.input`
  display: none;
  
  ${({ disabled }) => disabled && `
    opacity: 0;
    cursor: default;
    visibility: hidden;
    
    ~ ${RadioCustom} {
      cursor: default;
      opacity: 0;
      cursor: default;
      visibility: hidden;
    }
  `}

	${({ checked }) => checked && `
		~ ${RadioCustom} {
	    background: url(${require('../../../assets/img/radio.svg')}) center/cover no-repeat;
	    border: 0
		}
	`} 
`;

const Radio = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  width: 8rem;
  @media (max-width: 767px) {
    width: 8.5rem;
  }
`;

const NotificationItem = styled.li`
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #edeff4;

	:last-of-type {
    border: 0
	}
	
	
	
	label:first-child {
	margin-left: 6.125rem;
	
	@media (max-width: 480px) {
	  margin-left: 5.625rem;
	}
		//margin-left: 0;
		//
    //@media (max-width: 480px) {
    //  margin-left: 0;
    //}
	}
	
	
	
	@media (max-width: 767px) {
    padding-right: .9375rem;
    padding-left: .9375rem;
  }
`;

const Title = styled.span`  
  @media (max-width: 1024px) {
    min-width: 12.8rem;
  }

  @media (max-width: 767px) {
    min-width: 15rem;
  }
  
  @media (max-width: 575px) {
    min-width: 10rem;
  }
  
  @media (max-width: 480px) {
    min-width: 7rem;
  }
  
  font-weight: 600;
  display: inline-block;
  min-width: 13.2rem;
  max-width: 6rem;
`;