import React from 'react';
import styled from 'styled-components';

import AddCards from '../../containers/Card';
import ConfirmWindow from '../ConfirmWindow';
import Select from '../Select';
import StatusCode from '../StatusCode';

import * as api from '../../api';
import {GetCardType} from '../../utils/GetCardType';

const RenderCard = ({card, onDelete}) => {
  const handleClick = () => onDelete(card);

  return (
    <CardsItem>
      <div>
        <img src={card.icon} alt=""/>
        <span>{card.label}</span>
      </div>
      <DeleteButton onClick={handleClick}>
        <i className="far fa-trash-alt"/>
      </DeleteButton>
    </CardsItem>
  );
};

class Card extends React.Component {
  state = {
    modalIsOpen: false,
    deletedCard: null,
    cardFormIsOpen: false,
    isShowMessage: false
  };

  deleteCard = () => {
    const {deletedCard} = this.state;

    api.deleteCard(deletedCard.id)
      .then(() => {
        this.props.deleteCard(deletedCard);
        this.toggleConfirmationModal();
      });
  };

  toggleMessageWindow = () => this.setState(prevState => ({isShowMessage: !prevState.isShowMessage}));

  toggleCardForm = () => this.setState(prevState => ({cardFormIsOpen: !prevState.cardFormIsOpen}));

  toggleConfirmationModal = () => this.setState(prevState => ({modalIsOpen: !prevState.modalIsOpen}));

  onDelete = card => {
    if (this.props.cards.data.length > 1) {
      this.setState({deletedCard: card, modalIsOpen: true});
    } else {
      this.toggleMessageWindow();
    }
  };

  onSelectCard = card => {
    api.updateProfile({body: {default_card_id: card.id}})
      .then(() => this.props.updateProfileInfo({default_card: card}));
  };

  render() {
    const {cards, profile: {default_card}} = this.props;
    const {modalIsOpen, cardFormIsOpen, isShowMessage} = this.state;

    const defaultCard = default_card && (
      Object.assign({}, default_card, {
        label: default_card.pan,
        icon: GetCardType(default_card.pan).icon,
        value: default_card.id.toString(),
        ...default_card
      })
    );

    return (
      <div>
        <Title>Payment settings</Title>
        <PaymentTitle>Select Default</PaymentTitle>
        <SelectWrap>
          <Select
            defaultOption={defaultCard}
            options={cards.data}
            onChange={this.onSelectCard}
            placeholder='Not chosen'
          />
        </SelectWrap>
        {!cardFormIsOpen && cards.data.length !== 0 && (<PaymentTitleTwo>Manage cards</PaymentTitleTwo>)}
        <CardsList>
          {
            !cardFormIsOpen && (
              cards.data.map(card => (
                <RenderCard
                  key={card.id}
                  onDelete={this.onDelete}
                  card={card}
                />
              ))
            )
          }
        </CardsList>
        <AddCards
          isFirstSubscribe={this.props.isFirstSubscribe}
          formName='SetupCard'
          toggleCardForm={this.toggleCardForm}
          cardsListAvailable={false}
        />
        <ConfirmWindow
          onClose={this.toggleConfirmationModal}
          isOpen={modalIsOpen}
          callback={this.deleteCard}
        >
          <p style={{textAlign: 'center'}}>Are you sure you want to delete this card?</p>
        </ConfirmWindow>
        {
          isShowMessage && (
            <StatusCode
              status='warning'
              onClose={this.toggleMessageWindow}
              image={require('../../assets/icons/message-icon.png')}
            >
              <p>Unable to delete a single card. If you want to use another card, first add it and then delete the unnecessary one.</p>
            </StatusCode>
          )
        }
      </div>
    );
  }
}

export default Card;

const Title = styled.b`
	display: block;
  font-size: 18px;
  font-weight: 600;
  
  @media (max-width: 767px) {
    margin-bottom: 1.25rem;
  }
  
  margin-bottom: 26px;
`;

const PaymentTitle = styled.span`
	display: block;
  margin-bottom: .625rem;
  
  @media (max-width: 480px) {
    font-size: .875rem;
	}
`;

const SelectWrap = styled.div`
  margin-bottom: 1.125rem;
  position: relative;
  display: inline-block;
  color: #515151;
  border-radius: .125rem;
  background-color: #fff;
  max-width: 16.5625rem;
  width: 100%;
  height: 30px;
  
  li {
    letter-spacing: .5px;
    padding: 1.3125rem 1.5625rem 1.3125rem .6125rem;
  }
  
  div {
    width: 100%;
  
    i {
      right: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

	:hover {
    cursor: pointer;
	}
`;


const PaymentTitleTwo = styled.span`
	display: block;
	
	@media (max-width: 480px) {
    font-size: .875rem;
    margin-bottom: 1.875rem;
	}
`;

const CardsList = styled.ul`
	max-width: 16.5625rem;
  margin: 0;
  padding: 0;
  list-style: none;
  
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    
    li {
      display: flex;
      justify-content: space-between;
      padding: 8px 0;
    }
  }
`;

const CardsItem = styled.li`
	font-size: 12px;
	display: flex;
  border-bottom: 1px solid #edeff4;
  padding: 17px 0;
  align-items: center;
  
  div {
    display: flex;
    align-items: center;
  }
  
  img {
    margin-right: 10px;
    width: 100%;
    height: 100%;
    max-width: 2rem;
  }
`;

const DeleteButton = styled.button`
  font-size: 16px;
  color: #d0021b;
  margin-left: auto;
  padding: 0;
  border: 0;
  background: 0;

	:hover {
    cursor: pointer
	}
`;