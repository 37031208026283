import React from 'react';
import styled from 'styled-components';

const CheckBox = ({onChange, text, autoRenew}) => {
  return (
    <Checkbox>
      <CheckboxInput
        type='checkbox'
        onChange={onChange}
        checked={autoRenew}
      />
      <CheckboxCustom/>
      <CheckboxText>{text}</CheckboxText>
    </Checkbox>
  );
};

export default CheckBox;

const Checkbox = styled.label`
	position: relative;
	display: flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
	justify-content: center;
`;

const CheckboxCustom = styled.span`
  position: relative;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: .375rem;
  border: .0625rem solid #b8bfd3;
  border-radius: .125rem;
  background-color: #fff;
  flex-shrink: 0;
`;

const CheckboxText = styled.span`
	font-size: 1rem;
	white-space: initial;
	color: #4b4c59;
	line-height: 1;
`;

const CheckboxInput = styled.input`
	position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  
  :focus ~ ${CheckboxCustom} {
    border-color: #84c44b;
  }
  
  :checked ~ ${CheckboxCustom} {
    border-color: #84c44b;
		background-color: #84c44b;
  }
  
  :checked ~ ${CheckboxCustom}::before {
    content: '';
		position: absolute;
		top: 4px;
		left: 3px;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
		width: 7px;
		height: 5px;
		border: 2px solid #fff;
		border-top: none;
		border-right: none
  }
`;
