import React from 'react';
import styled from 'styled-components';
import {reduxForm} from 'redux-form';

import PersonalDetails from '../Wizard/PersonalDetails';
import UserSearch from '../Wizard/UserSearch';
import UserInterests from '../Wizard/UserInterests';
import UserDetails from '../Wizard/UserDetails';

import withModalClose from '../HOC/withModalClose';
import {Modal, ModalContent} from '../Modal';

const stepComponentMap = {
  PersonalDetails,
  UserSearch,
  UserInterests,
  UserDetails,
};

const Edit = ({modalIsOpen, component, onSubmit, onClose, selects, profile, updateProfileInfo, initialValues, innerRef}) => {
  const Component = stepComponentMap[component];

  const Form = reduxForm({
    form: component,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true
  })(Component);

  return (
    <Modal isOpen={modalIsOpen}>
      <PopupContent ref={innerRef}>
        <Form
          updateProfileInfo={updateProfileInfo}
          initialValues={{...profile, ...initialValues}}
          buttonText='SAVE CHANGES'
          onClose={onClose}
          selects={selects}
          onSubmit={onSubmit}
        />
      </PopupContent>
    </Modal>
  );
};

export default withModalClose(Edit);

const PopupContent = styled(ModalContent)`
  @media (max-width: 480px) {
    padding: 0;
  }
`;
