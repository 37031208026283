import Echo from 'laravel-echo';

window.io = require('socket.io-client');

class LaravelEchoClass {
  init(authToken) {
    authToken = authToken || sessionStorage.getItem('token') || localStorage.getItem('token');

    this.echo = new Echo({
      auth: {
        headers: {
          Authorization: 'Bearer ' + authToken
        }
      },
      broadcaster: 'socket.io',
      host: process.env.REACT_APP_BASE_API_URL + ':' + process.env.REACT_APP_ECHO_PORT
    });

    return new Promise(resolve => {
      return this.echo.connector.socket.on('connect', resolve);
    });
  }

  reconnect(token) {
    this.echo.disconnect();
    return this.init(token);
  }

  getEcho() {
    return this.echo;
  }
}

export default LaravelEchoClass;
