export function setMessageToLocalStorage(text, profileId) {
  const item = localStorage.getItem('chat');

  if (item) {
    const chats = JSON.parse(item);
    const currentChat = {text, id: profileId};
    const atIndex = chats.findIndex(chat => chat.id === currentChat.id);

    let updatedChats = chats;

    if (~atIndex) {
      updatedChats = [
        ...chats.slice(0, atIndex),
        currentChat,
        ...chats.slice(atIndex + 1)
      ];
    } else {
      updatedChats = [...updatedChats, currentChat];
    }

    localStorage.setItem('chat', JSON.stringify(updatedChats));
  } else {
    localStorage.setItem('chat', JSON.stringify([{text, id: profileId}]));
  }
}

export function removeMessageFromLocalStorage(profileId) {
  const item = localStorage.getItem('chat');

  if (item) {
    const chats = JSON.parse(item);
    const atIndex = chats.findIndex(chat => chat.id === profileId);

    if (~atIndex) {
      localStorage.setItem('chat', JSON.stringify([...chats.slice(0, atIndex), ...chats.slice(atIndex + 1)]));
    }
  }
}

export function getChatWithId(id) {
  const item = localStorage.getItem('chat');
  
  return item ? JSON.parse(item).find(chat => chat.id === id) : null;
}

export function setErrorMessageToLocalStorage({ text, profileId }) {
  const chats = localStorage.getItem('chatErrors') && JSON.parse(localStorage.getItem('chatErrors'));
  
  const newMessage = { text, profile_id: profileId, type: 'MESSAGE', author: 'me', created_at: new Date() };
  
  if (chats) {
    const currentChat = chats[profileId] || [];
    
    const updatedChat = {
      ...chats,
      [profileId]: [...currentChat, { ...newMessage, id: currentChat.length }]
    };
    
    localStorage.setItem('chatErrors', JSON.stringify(updatedChat));
    
    return updatedChat[profileId];
    
  } else {
    const updatedChat = {
      [profileId]: [{ ...newMessage, id: 0 }]
    };
    
    localStorage.setItem('chatErrors', JSON.stringify(updatedChat));
    
    return updatedChat[profileId];
  }
}

export function removeErrorMessageFromLocalStorage({ id, profile_id }) {
  const chats = localStorage.getItem('chatErrors') && JSON.parse(localStorage.getItem('chatErrors'));
  
  if (chats[profile_id]) {
    const atIndex = chats[profile_id].findIndex(message => message.id === id);
    
    if (atIndex !== -1) {
      const updatedChats = {
        ...chats,
        [profile_id]: [
          ...chats[profile_id].slice(0, atIndex),
          ...chats[profile_id].slice(atIndex + 1)
        ]
      };
      
      localStorage.setItem('chatErrors', JSON.stringify(updatedChats));
  
      return updatedChats[profile_id];
    }
  }
}

export function getErrorChatWithId(id) {
  const chats = localStorage.getItem('chatErrors') && JSON.parse(localStorage.getItem('chatErrors'));
  
  return chats && chats[id];
}

export function saveLetterToLocalStorage(letter) {
  const letters = localStorage.getItem('emailErrors') && JSON.parse(localStorage.getItem('emailErrors'));
  
  if (letters) {
    const emailErrors = [...letters, { ...letter, created_at: letter.sent_at, author: 'me', entity: { ...letter }, type: 'LETTER', id: letters.length, error: true, profile: letter.receiver }];
    
    localStorage.setItem('emailErrors', JSON.stringify(emailErrors));
    
    return emailErrors;
  } else {
    const emailErrors = [{ ...letter, id: 0, created_at: letter.sent_at, author: 'me', entity: { ...letter }, type: 'LETTER', error: true, profile: letter.receiver }];
    localStorage.setItem('emailErrors', JSON.stringify(emailErrors));
    
    return emailErrors;
  }
}

export function removeLetterFromLocalStorage(letter) {
  const letters = JSON.parse(localStorage.getItem('emailErrors'));
  
  const atIndex = letters.findIndex(({ id }) => letter.id === id);
  
  if (atIndex !== -1) {
    const emailErrors = [
      ...letters.slice(0, atIndex),
      ...letters.slice(atIndex + 1)
    ];
    
    localStorage.setItem('emailErrors', JSON.stringify(emailErrors));
    
    return emailErrors;
  }
}

export function initEmailErrorsList() {
  return localStorage.getItem('emailErrors') && JSON.parse(localStorage.getItem('emailErrors'));
}