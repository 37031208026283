import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as Styles from './styles';

const DropDownMenu = ({options, onClose}) => (
  <Styles.DropDownContainer>
    <Options>
      <Dots><i className='far fa-ellipsis-h'/></Dots>
      <OptionList>
        {
          options.map(option => (
            <Styles.Option
              key={option.name}
              onClick={option.callback}
            >
              <OptionItemIcon>
                {option.icon && (<i className={option.icon}/>)}
              </OptionItemIcon>
              <span>{option.name}</span>
            </Styles.Option>
          ))
        }
      </OptionList>
    </Options>
    {onClose && (
      <Styles.Close onClick={onClose}><i className='fal fa-times'/></Styles.Close>)}
  </Styles.DropDownContainer>
);

DropDownMenu.propTypes = {
  options: PropTypes.array.isRequired
};

export default DropDownMenu;

const Dots = styled.div`
  background: 0;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  color: #b8bfd3;
  margin-right: .75rem;

  :hover {
    color: #4b4c59;
  }  
`;

const OptionItemIcon = styled.div`
	margin-right: 5px;
  color: #b8bfd3;
  font-size: .875rem;
  width: .85rem;
`;

const OptionList = styled.span`
  position: absolute;
  top: 40px;
  display: flex;
  flex-direction: column;
  min-width: 128px;
  background-color: #EDEFF4;
  box-shadow: 0 2px 4px rgba(184, 191, 211, .5);
  z-index: 1;
  border-radius: 2px;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  right: 6px;

	&::before {
    content: '';
    position: absolute;
	  right: 29px;
	  top: -13px;
    border: 8px solid transparent;
	  border-bottom: 5px solid #EDEFF4;
	}
`;

const Options = styled.div`
	:hover ${OptionList} {
    opacity: 1;
    visibility: visible;
  }
`;

