import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import * as api from '../../api';
import { getSubscriptionPlans, subscribe } from '../../api';

import { Modal, ModalContent } from '../Modal';
import CheckBox from './CheckBox';

import Card from '../../containers/Card';
import * as Styles from './styles';
import { isOrderId } from '../../utils/isOrderId';
import { whiteLoader } from '../../assets';
import { btn } from '../styles';
import StatusCode from '../StatusCode';
import TrialPopup from './TrialPopup';
import { eventTransaction } from '../../utils/googleAnalyticEventSubscribe';
import Coins from '../../assets/svg/coins';
import 'react-tabs/style/react-tabs.css';

import { CreditsTitle, CreditsWrapper, Svg, } from '../Credits/styles';
import Prices from '../common/prices';
import FreeCredits from '../common/freeCredits';

import Plans from './plans';

class Subscription extends Component {
  state = {
    isOpen: false,
    autoRenew: true,
    success: false,
    buying: false,
    chosenPlan: this.props.initialSubscriptionPlan,
    showTrialMessage: false,
    card: null,
    error: '',
    plans: []
  };
  
  componentDidMount() {
    const { profile, history, initialSubscriptionPlan } = this.props;
  
    localStorage.setItem('autoRenew', true);
  
    getSubscriptionPlans()
      .then(response => {
        const plans = response.sort((a, b) => a.price > b.price ? 1 : (a.price < b.price ? -1 : 0));
        const chosenPlan = localStorage.getItem('plan') ? JSON.parse(localStorage.getItem('plan')) : null;
      
        this.setState({ plans, isOpen: true, chosenPlan: initialSubscriptionPlan || chosenPlan });
      });
  
    if (isOrderId()) {
      api.checkOrderWithId(history.location.search.replace('?order_id=', ''))
        .then(response => {
          if (!response.status) {
            if (response.data.error_message) {
              this.setError(response.data.error_message);
            }
          }
        });
    }
    
    this.setState({ card: profile.default_card });
    
    localStorage.setItem('modal', 'subscription');
  }
  
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { profile: { default_card } } = nextProps;
    if (JSON.stringify(nextProps.profile.default_card) !== JSON.stringify(this.props.profile.default_card)) {
      this.setState({ card: default_card });
    }
  }
  
  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    const last_subscription = nextProps.profile.last_subscription;
    const { plans, chosenPlan } = nextState;
  
    // this.props.initialCreditPack;
  
    const activeSubscription = last_subscription && last_subscription.is_active && plans.find(plan => plan.id === last_subscription.plan_id);
    
    const activePlans = plans.filter(plan => activeSubscription ? (activeSubscription.priority < plan.priority) : true);
    
    const isChosenPlanActive = activePlans.find(plan => plan.id === (chosenPlan && chosenPlan.id));
    
    const highPriority = Math.max(...plans.map(({ priority }) => priority));
    const recommended = activePlans.find(({ priority }) => priority === highPriority);
    
    if (!chosenPlan && activePlans.length !== 0) {
      const plan = recommended || activePlans[0];
      
      this.setState({ chosenPlan: plan });
      
      localStorage.setItem('plan', JSON.stringify(plan));
    }
    
    if (chosenPlan && !isChosenPlanActive) {
      this.setState({ chosenPlan: null });
      
      localStorage.removeItem('plan');
    }
  }
  
  onClose = () => {
    this.props.onClose && this.props.onClose();
    this.state.success && this.props.onSuccess && this.props.onSuccess();
    this.setState({ isOpen: false });
    
    localStorage.removeItem('modal');
    localStorage.removeItem('plan');
    localStorage.removeItem('autoRenew');
  };
  
  onSubscribe = () => {
    const { chosenPlan, card, autoRenew, buying } = this.state;
    
    if (chosenPlan && card && !buying) {
      this.setState({ buying: true });
      
      subscribe({ body: { plan_id: chosenPlan.id, is_auto_renew: autoRenew, card_id: card.id } })
        .then(response => {
          if (response.data) {
            const { id, name, price } = chosenPlan;
            eventTransaction({ id, name, price, category: 'subscribe' });
            
            localStorage.removeItem('plan');
  
            this.setState({ buying: false, success: true, isOpen: false, error: '' });
            this.props.updateProfileInfo({ last_subscription: response.data });
            
          } else if (response.status === 422) {
            return response.json();
          }
          
          this.setState({ buying: false });
        })
        .then(response => {
          if (response && response.errors) {
            this.setState({
              error: Object.keys(response.errors).map(error => response.errors[error][0])[0],
              buying: false,
              success: false
            });
          }
        });
    }
  };
  
  onChoosePlan = plan => {
    localStorage.setItem('plan', JSON.stringify(plan));
    
    if (
      plan.trial_days &&
      plan.trial_credits &&
      this.props.profile.is_trial_subscription_available &&
      !sessionStorage.getItem('trial_popup')
    ) {
      sessionStorage.setItem('trial_popup', 'showed');
  
      this.toggleTrialMessage();
    }
  
    this.setState({ chosenPlan: plan });
  };
  
  toggleTrialMessage = () => this.setState(prevState => ({ showTrialMessage: !prevState.showTrialMessage }));
  
  handleAutoRenew = () => {
    if (this.state.autoRenew) {
      localStorage.removeItem('autoRenew');
      
    } else {
      localStorage.setItem('autoRenew', true);
    }
    this.setState(prevState => ({ autoRenew: !prevState.autoRenew }));
  };
  
  onSelectCard = card => this.setState({ card });
  
  setError = error => this.setState({ error });
  
  renderPurchaseButton = ({ type = 'button', disabled = false, callback = null }) => {
    const { error, chosenPlan, buying,} = this.state;
  
    return (
      <PurchaseBtnWrap type={type}>
        <Purchase as='div' onClick={callback || this.onSubscribe} error={error} disabled={disabled || !chosenPlan}>
          {buying ? (<img src={whiteLoader} alt=""/>) : 'PURCHASE'}
        </Purchase>
        {Boolean(chosenPlan && this.props.profile.is_trial_subscription_available && chosenPlan.trial_days && chosenPlan.trial_credits) && (
          <span>You won’t be charged now</span>)}
      </PurchaseBtnWrap>
    );
  };
  
  render() {
    const { error, plans, isOpen, chosenPlan, autoRenew, success } = this.state;
    const { profile: { last_subscription, loading }, innerRef } = this.props;
  
    const activeSubscription = (last_subscription && last_subscription.is_active) && last_subscription;
  
    const highPriority = Math.max(...plans.map(({ priority }) => priority));
    const recommended = plans.find(({ priority }) => priority === highPriority);
  
    const filteredPlans =
      plans
        .filter(plan => activeSubscription ? (activeSubscription.priority <= plan.priority) : true)
        .filter(plan => (activeSubscription && (activeSubscription.id || activeSubscription.plan_id)) !== plan.id);
  
    const isFirstSubscribe = !Boolean(last_subscription);
  
    return (
      <>
        <Modal isOpen={!loading && isOpen}>
        
          <PopupContent ref={innerRef}>
          
            <CreditsWrapper>
              <CreditsTitle>
                Credits
                <Coins Svg={Svg}/>
              </CreditsTitle>
              <Prices/>
              <FreeCredits
                profile={this.props.profile}
                onCloseModal={this.onClose}
              />
            </CreditsWrapper>
          
            <Plans
              plans={filteredPlans}
              lastSubscription={last_subscription}
              chosenPlan={chosenPlan}
              onChoosePlan={this.onChoosePlan}
              recommended={recommended}
            />
          
            <Styles.Close onClick={this.onClose}>
              <i className='fal fa-times'/>
            </Styles.Close>
            <Payment>
              <PaymentTitle>Select payment method</PaymentTitle>
              <Card
                onSelectCard={this.onSelectCard}
                setError={this.setError}
                formName='BuySubscription'
                isFirstSubscribe={isFirstSubscribe && !this.state.card}
                renderPurchaseButton={this.renderPurchaseButton}
              />
              {(!isFirstSubscribe || this.state.card) && (this.renderPurchaseButton({}))}
            </Payment>
            {
              error && (
                <div style={{
                  fontSize: 12,
                  color: 'red',
                  marginTop: '1rem',
                  paddingBottom: '.5rem',
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <span style={{ maxWidth: '16.5625rem' }}>{error}</span>
                </div>
              )
            }
            <div style={{ marginTop: '2rem' }}>
              <CheckBox autoRenew={autoRenew} onChange={this.handleAutoRenew} text='auto renew'/>
            </div>
          </PopupContent>
        </Modal>
        <TrialPopup
          isOpen={this.state.showTrialMessage}
          onClose={this.toggleTrialMessage}
          trial_info={chosenPlan}
        />
        {success && (
          <StatusCode onClose={this.onCloseMessage} image={require('../../assets/img/success-ico.svg')}>
            <p>The payment was a success.</p>
          </StatusCode>
        )}
      </>
    );
  }
}

export default withRouter(Subscription);

const PaymentTitle = styled.b`
  @media (max-width: 480px) {
    font-size: .875rem;
    padding: 0 1.875rem;
  }

  font-size: 1.125rem;
  font-weight: 600;
  margin-top: -.625rem;
  margin-bottom: .9375rem;
  padding: 0 .8125rem;
  text-align: center;
  letter-spacing: .06875rem;
  background-color: #fff;
`;

const Payment = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #b8bfd3;
  
  @media (max-width: 767px) {
     margin-bottom: 5rem;
  }
`;

const Purchase = styled(btn)`
  max-width: 16.5625rem;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.375rem;
  
  ${({ error }) => error && `
    margin-top: 0;
  `}
  
  ${({ disabled }) => disabled && `
    cursor: not-allowed !important;
    background-color: rgba(163, 163, 163, 0.5);
    
    &:hover {
      background-color: rgba(163, 163, 163, 0.5);
    }
  `}
  
  img {
    width: 100%;
    max-width: 1rem;
  }
  
  @media (max-width: 480px) {
    letter-spacing: .125rem;
  }
`;

const PopupContent = styled(ModalContent)`
  @media (max-width: 767px) {
    margin: 0;
    min-width: auto;  
    text-align: center;
  }
  
  @media (max-width: 480px) {
    height: auto;
    align-self: flex-start;
  }
  
  position: relative;
  width: 100%;
  min-width: 46rem;
  max-width: max-content;
  padding: 1.875rem;
  cursor: default;
  border-radius: .125rem;
  background-color: #fff;
`;

const PurchaseBtnWrap = styled.button`
  position: relative;
  width: 100%;
  max-width: 16.5625rem;
  margin-top: 1rem;
  border: 0;
  padding: 0;
  
  ${Purchase} {
    margin: 0;  
  }
  
  span {
    position: absolute;
    right: -12.5rem;
    bottom: 7px;
    font-weight: bold;
    color: #555;
    letter-spacing: 0.5px;
    
    @media screen and (max-width: 767px) {
      position: static;
      text-align: center;
      margin-top: 15px;
    }
  }
`;
//
// <Title>Upgrade your account!</Title>
// <Text>
// Open Unlimited emails <br/>
// Free time chat <br/>
// Free Likes
// </Text>
// <Styles.PlansContainer>
//   {
//     filteredPlans
//       .map(plan => (
//         <Plan
//           isTrialAvailable={is_trial_subscription_available && plan.trial_credits && plan.trial_days}
//           isRecommended={recommended && recommended.id === plan.id}
//           chosenPlan={chosenPlan}
//           onChoosePlan={this.onChoosePlan}
//           key={plan.id}
//           card={card}
//           currentPlan={activeSubscription}
//           plan={plan}
//         />
//       ))
//   }
//   {
//     filteredPlans.length === 0 && activeSubscription && (
//       <Plan card={card} plan={activeSubscription}/>
//     )
//   }
// </Styles.PlansContainer>