import React from 'react';
import { connect } from 'react-redux';

import { getSimilarProfiles } from '../../../actions';
import { useSimilarProfilesApi } from './api';
import Profile from './components/profile';
import Loader from './components/loading';

const SimilarProfiles = ({ getSimilarProfiles, profile, role, similarProfiles, onlineUsers }) => {
  useSimilarProfilesApi({ getSimilarProfiles, profile, role, similarProfiles });
  
  if (role === 'me') {
    return null;
  }
  
  return (
    <div className="profile-catalog-wrapper">
      <div className="profile-catalog-title">Girls you also may like</div>
      <ul className="profile-catalog-list">
        {similarProfiles.data.map(profile => (
          <Profile
            isOnline={onlineUsers.indexOf(profile.id) !== -1}
            key={profile.id}
            age={profile.age}
            id={profile.id}
            name={profile.name}
            photo={profile.photo}
          />
        ))}
      </ul>
      <Loader loading={similarProfiles.loading} />
    </div>
  );
};

const mapStateToProps = ({ similarProfiles, user, onlineUsers }) => ({ similarProfiles, profile: user, onlineUsers });

const mapDispatchToProps = { getSimilarProfiles };

export default connect(mapStateToProps, mapDispatchToProps)(SimilarProfiles);