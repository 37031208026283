import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { deleteAlbum } from '../../../api';
import { unknownUser } from '../../../assets';

class Album extends PureComponent {
  
  state = {
    is_private: false
  };
  
  componentDidMount() {
    this.setState({ is_private: this.props.is_private });
  }

  openAlbum = () => {
    const { history, profile_id, id, toggleSubscriptionPanel, myProfile } = this.props;
  
    if (
      !this.state.is_private ||
      myProfile.isAgency ||
      profile_id === myProfile.id ||
      myProfile.gender === 'FEMALE' ||
      (myProfile.last_subscription && myProfile.last_subscription.is_active)
    ) {
      history.push(`/profiles/${btoa(profile_id)}/albums/${id}`);
      
    } else {
      toggleSubscriptionPanel();
    }
  };

  setupOptions = () => {
    const {is_private} = this.state;
    const {profile_id, id} = this.props;
    return [
      {
        label: 'Edit',
        icon: 'fal fa-pen',
        callback: () => {
          this.props.history.push({
            pathname: `/profiles/${btoa(profile_id)}/albums/${id}`,
            state: {editMode: true}
          });
        }
      },
      {
        label: is_private ? 'Make open' : 'Make private',
        icon: 'fal fa-lock-alt',
        callback: () => {
          const body = {
            is_private: !this.state.is_private
          };

          this.props.updatePhotos(this.props.id, body);
          this.setState({is_private: !is_private});
        }
      },
      {
        label: 'Delete',
        icon: 'fal fa-trash-alt',
        callback: () => {
          deleteAlbum(this.props.id)
            .then(() => this.props.deleteAlbum(this.props.id));
        }
      },
    ];
  };

  render() {
    const {cover, onDragStart, photos_count, role, is_private, is_purchased } = this.props;

    return (
      <AlbumItem>
        {
          role === 'me' && (
            <AlbumOptions>
              <OptionsIcon>
                <i className='fas fa-ellipsis-h'/>
              </OptionsIcon>
              <OptionsList>
                {
                  this.setupOptions().map(option => (
                    <OptionItem key={option.label} onClick={option.callback}>
                      <OptionItemIcon>
                        <i className={option.icon}/>
                      </OptionItemIcon>
                      {option.label}
                    </OptionItem>
                  ))
                }
              </OptionsList>
            </AlbumOptions>
          )
        }
        <div onClick={this.openAlbum} style={{height: '100%'}}>
          <Image
            onDragStart={onDragStart}
            src={cover ? cover.src : unknownUser}
          />
          <PhotosNumber>
            <AlbumItemIcon>
              <i className={this.state.is_private ? 'fas fa-lock' : 'fas fa-camera'}/>
              <span>{photos_count}</span>
            </AlbumItemIcon>
          </PhotosNumber>
          
          {is_private && !is_purchased && (
            <CreditsNumber>
              <AlbumItemIcon>
                <div className="price">
                  30 <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.044 15.556c-2.283 0-4.198-.722-5.746-2.165C.766 11.933 0 10.062 0 7.778 0 5.493.766 3.63 2.298 2.187 3.846.729 5.761 0 8.044 0c2.5 0 4.479.992 5.932 2.976l-1.641.88c-.453-.647-1.063-1.173-1.83-1.579a5.183 5.183 0 00-2.461-.609c-1.72 0-3.158.579-4.315 1.736-1.142 1.142-1.712 2.6-1.712 4.374 0 1.773.57 3.239 1.712 4.396 1.157 1.142 2.595 1.713 4.315 1.713.875 0 1.696-.195 2.462-.586.766-.406 1.376-.94 1.829-1.6L14 12.58c-1.517 1.984-3.502 2.976-5.956 2.976z" fill="#DE60AD"/><path d="M10.889 10.889H9.245l-1.32-2.21H6.876v2.21H5.444V4.667H8.59c.692 0 1.24.186 1.644.56.41.373.615.855.615 1.445 0 .51-.145.924-.434 1.241-.282.317-.628.52-1.038.606l1.512 2.37zm-2.51-3.377c.295 0 .537-.075.725-.224a.76.76 0 00.283-.616.733.733 0 00-.283-.606c-.188-.155-.43-.233-.726-.233H6.876v1.679h1.502z" fill="#DE60AD"/></svg>
                </div>
              </AlbumItemIcon>
            </CreditsNumber>
          )}
         
        </div>
      </AlbumItem>
    );
  }
}

export default withRouter(Album);

const AlbumItemIcon = styled.div`
	display: flex;
	align-items: center;
	
	i {
		font-size: 18px;
		margin-right: 6px;
	}
`;

const CreditsNumber = styled.span`
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #fff;
  user-select: none;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, .5);
 
  .price {
    display: flex;
    align-items: center;
    
    svg {
     margin: 0 3px;
    }
  }
`;

const PhotosNumber = styled.span`
	position: absolute;
  bottom: 5px;
  left: 7px;
  color: #fff;
  user-select: none;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, .5)
`;

const OptionsList = styled.div`
  position: absolute;
  z-index: 2;
  top: 2rem;
  right: -1.5625rem;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  min-width: 8rem;
  transition: .3s;
  opacity: 0;
  border-radius: .125rem;
  box-shadow: 0 0.125rem 0.25rem rgba(184, 191, 211, .5);
  background-color: #fff;
	
	&::before {
	  position: absolute;
    top: -.8125rem;
    right: 1.8125rem;
    content: "";
    border: .5rem solid transparent;
    border-bottom: .3125rem solid #fff;
	}
`;

const AlbumOptions = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 6px;
  right: 6px;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 14px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, .5);
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  
  @media (max-width: 767px) {
    visibility: visible;
    opacity: 1;
  }

	:hover {
    cursor: pointer;
    
    ${OptionsList} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const Image = styled.img`
  height: 100%;
  display: block;
  width: 100%;
  font-family: "object-fit: cover;", serif;
  object-fit: cover;
`;

const OptionItem = styled.div`
	display: flex;
	align-items: center;
  padding: 0.4375rem 0.5rem;
  font-size: .75rem;
  text-align: left;

	:hover {
    background-color: #edeff4;
	}
`;

const OptionItemIcon = styled.div`
	margin-right: 5px;
  color: #b8bfd3;
`;

const OptionsIcon = styled.div`
	font-size: 1rem;
  color: #fff; 
  
  :hover ${OptionsList} {
    visibility: visible;
    opacity: 1;
  }
`;

const AlbumItem = styled.div`
  width: 100%;
  display: inline-block;
  position: relative;
  height: 6.5625rem;
  cursor: pointer;
  
  :hover ${AlbumOptions} {
    opacity: 1;
    visibility: visible;
  }
  
	@media (max-width: 767px) {
	  width: 100%;
    height: 6.325rem;
	}
`;