import React, { useState } from 'react';
import styled from 'styled-components';
import { withCookies } from 'react-cookie';

import { NavLink } from '../Link';
import Account from './Account';
import Card from './Card';
import Notifications from './Notifications';
import Blacklist from './Blacklist';
import Privacy from './Privacy';
import ConfirmWindow from '../ConfirmWindow';
import { isMobile } from '../../utils/mobile';

import * as Styles from './styles';
import * as api from '../../api';
import Gender from './gender';
import EmailVerifiedMessage from './verified';

const Settings = ({myProfile: {is_hidden, is_agency, email}, myProfile, updateProfileInfo, history, getCardsList, cards, deleteCard, ...props}) => {
  const [deleteConfirmation, toggleModal] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const toggleConfirmWindow = () => toggleModal(deleteConfirmation => !deleteConfirmation);

  const onPasswordChange = e => setPassword(e.target.value);

  const hideAccount = () => {
    updateProfileInfo({is_hidden: !is_hidden});
    api.updateProfile({body: {is_hidden: !is_hidden}});
  };

  const deleteAccount = () => {
    api.deleteMyAccount({body: {password}})
      .then(response => {
        if (response.status !== 422) {
          localStorage.clear();
          sessionStorage.clear();

          window.location.href = window.location.origin;
        } else {
          return response.json();
        }
      })
      .then(body => {
        if (body && body.errors) {
          let message = Object.keys(body.errors).map(error => body.errors[error][0])[0];
          setError(message);
        }
      });
  };

  const logout = () => {
    api.logout();
    localStorage.clear();
    sessionStorage.clear();

    window.location.href = (process.env.REACT_APP_REDIRECT_URL_PROMO || process.env.REACT_APP_REDIRECT_URL) + '?action=logout';
  };

  const goBack = () => history.goBack();
  

  const editFields = history.location.editFields;
  return (
    <>
      <div className="settings-wrapper">
        <EmailVerifiedMessage show={!Boolean(myProfile.email_verified_at) && !myProfile.is_agency} />
      
        <Styles.Settings>
          <Styles.Title>
            <h2>Settings</h2>
            <Styles.Close onClick={goBack}><i className='fal fa-times'/></Styles.Close>
          </Styles.Title>
          {!Boolean(is_agency) && (<Account email={email} updateProfileInfo={updateProfileInfo} editFields={editFields}/>)}
          {!Boolean(is_agency) && (<Gender updateProfileInfo={updateProfileInfo} profile={myProfile} />)}
          {
            !Boolean(is_agency) && (
              <Card
                cards={cards}
                getCardsList={getCardsList}
                deleteCard={deleteCard}
                updateProfileInfo={updateProfileInfo}
                profile={myProfile}
              />
            )
          }
          <Notifications
            {...props}
            updateProfileInfo={updateProfileInfo}
            myProfile={myProfile}
          />
          {!Boolean(is_agency) && (<Privacy hideAccount={hideAccount} value={is_hidden}/>)}
          <Blacklist/>
          <LinksList>
            <LinksItem>
              <Styles.Logout onClick={logout}>
                <Icon><i className="far fa-power-off"/></Icon>
                Logout
              </Styles.Logout>
            </LinksItem>
            {
              !Boolean(is_agency) && (
                <LinksItem>
                  <NavLink style={Link} to='/payments'>Payment</NavLink>
                </LinksItem>
              )
            }
            
            {
              isMobile() && (
                <LinksItem>
                  <a
                    href={(process.env.REACT_APP_REDIRECT_URL_PROMO || process.env.REACT_APP_REDIRECT_URL) + '/notice'}
                    style={Link}
                    rel="noopener noreferrer"
                    target='_blank'
                  >
                    <Support>Privacy</Support>
                  </a>
                </LinksItem>
              )
            }
            
            <LinksItem>
              <NavLink
                style={Link}
                to='/support'
              >
                <Support>Support <i className='fal fa-angle-right'/>
                </Support>
              </NavLink>
            </LinksItem>
            {!Boolean(is_agency) && (<LinksItem onClick={toggleConfirmWindow}>Delete account</LinksItem>)}
          </LinksList>
          <ConfirmWindow
            onClose={toggleConfirmWindow}
            isOpen={deleteConfirmation}
            callback={deleteAccount}
          >
            <Styles.ConfirmDeleting>
              <p>
                Are you sure you want to delete your account? <br/>
                Type your password.
              </p>
              <div>
                <span style={{visibility: error ? 'visible' : 'hidden', opacity: error ? 1 : 0}}>{error || 'error'}</span>
                <input
                  type="password"
                  value={password}
                  onChange={onPasswordChange}
                  style={{border: error ? '1px solid red' : '1px solid #dadee8'}}
                />
              </div>
            </Styles.ConfirmDeleting>
          </ConfirmWindow>
        </Styles.Settings>
      </div>
    </>
  );
};

export default withCookies(Settings);

const Support = styled.div`
  display: flex;
  align-items: center;
  
  i {
    padding-left: .25rem;
    color: #b8bfd3;
    font-size: 1.5rem;
  }
`;

const Icon = styled.div`
	color: #3b5a9a;
  margin-right: 4px;
`;

const LinksList = styled.ul`
	margin: 0;
  padding: 0;
  list-style: none;
  
  @media (max-width: 767px) {
    margin-right: -.9375rem;
    margin-left: -.9375rem;
  }
  
  li:last-child {
  	font-weight: 400;
  }
`;

const LinksItem = styled.li`
	padding: 20px 0;
  font-weight: 600;
  border-top: 1px solid #edeff4;
  cursor: pointer;
  
  @media (max-width: 767px) {
    padding-right: .9375rem;
    padding-left: .9375rem;
  }
`;

const Link = {
  textDecoration: 'none'
};