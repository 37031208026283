import React from 'react';
import {Field} from 'redux-form';
import styled from 'styled-components';

import {isMobile} from '../../utils/mobile';
import * as Styles from './styles';
import {Event} from '../../Tracking';

const UserSearch = ({handleSubmit, onClose, buttonText, step, lastStep, skip}) => {
  const handleClick = () => Event('send', 'form', 'step2');
  return (
    <Form as="form" onSubmit={handleSubmit}>
      <Styles.Title>I'm looking for {step && (step + '/' + lastStep)}</Styles.Title>
      {onClose && (<Styles.Skip onClick={onClose}><i className='fal fa-times'/></Styles.Skip>)}
      {!isMobile() && (<Span>Short bio</Span>)}
      <AboutContainer>
        <Textarea
          as={Field}
          name="looking_for"
          component="textarea"
          maxLength={160}
        />
        {onClose && (<Styles.Button as="button" close={onClose} onClick={onClose}>CANCEL</Styles.Button>)}
        {(step !== lastStep && skip) && (<Styles.Button as='button' skip={skip} onClick={skip}>SKIP</Styles.Button>)}
        <Styles.Button as="button" type="submit" onClick={handleClick}>
          {buttonText || (step === lastStep ? 'CONFIRM' : 'NEXT')}
        </Styles.Button>
      </AboutContainer>
    </Form>
  );
};

export default UserSearch;

const Form = styled(Styles.Form)`
  max-width: 28.4375rem;
  padding: 1.25rem 4.6875rem 2.5rem 1.25rem;
`;

const AboutContainer = styled(Styles.AboutContainer)`
  display: flex;
  flex-wrap: wrap;
`;

const Span = styled(Styles.Span)`
	display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 9px;
`;

const Textarea = styled(Styles.Textarea)`
  width: 100%;
  height: 7.0625rem;
  margin-bottom: .875rem;
  padding: .3125rem;
  resize: none;
  transition: .3s;
  color: #4b4c59;
  border: .0625rem solid #dadee8;
  
  @media (max-width: 480px) {
    height: 18.125rem;
  }
`;
