import React, {Component} from 'react';
import styled from 'styled-components';

import * as api from '../../api';

class Blacklist extends Component {
  state = {
    list: [],
    showMore: false
  };

  componentDidMount() {
    api.getBlacklist()
      .then(list => this.setState({list}));
  }

  toggleBlockedList = () => this.setState({showMore: !this.state.showMore});

  unblockUser = e => {
    const index = this.state.list.findIndex(user => user.id === +e.target.id);

    api.removeUserFromBlacklist(+e.target.id);

    this.setState({
      list: [
        ...this.state.list.slice(0, index),
        ...this.state.list.slice(index + 1)
      ]
    });
  };

  render() {
    const BlockedList = this.state.showMore ? this.state.list : this.state.list.slice(0, 3);

    return (
      <Blocked>
        <Title>
          <span>Blocked users</span>
          <span />
          {this.state.list.length > 3 && (
            <span onClick={this.toggleBlockedList}>{this.state.showMore ? 'See less' : 'See all'}</span>)}
        </Title>
        <UsersList>
          {
            BlockedList.length ? (
              BlockedList.map(user => (
                <UserWrap key={user.id}>
                  <span>{user.name}</span>
                  <span onClick={this.unblockUser} id={user.id}>Unblock</span>
                </UserWrap>
              ))
            ) : (
              <span style={{ color: '#3b5a9a' }}>0 users</span>
            )
          }
        </UsersList>
      </Blocked>
    );
  }
}

export default Blacklist;

const Blocked = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  
  @media (max-width: 767px) {
    margin-right: -.9375rem;
    margin-left: -.9375rem;
    padding-right: .9375rem;
    padding-left: .9375rem;
  }
`;

const Title = styled.div`
	display: flex;
  flex-direction: column;
  font-size: .875rem;
	
	span:first-child {
	  margin-bottom: 4px;
    font-weight: 600;
	}
	
	span:last-child {
		color: #3b5a9a;
		cursor: pointer;
		
		:hover {
		  text-decoration: underline;
		}
	}
`;

const UsersList = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 160px;
  
  @media (max-width: 480px) {
    margin-left: 1.25rem;
  }
`;

const UserWrap = styled.div`
  margin-bottom: 5px;

	:last-of-type {
    margin-bottom: 0;
	}

	span:last-child {
		color: #3b5a9a;
		margin-left: 4px;
		cursor: pointer;
		
		:hover {
			text-decoration: underline;
		}
	}
`;

