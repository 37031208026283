import React, { useState } from 'react';
import styled from 'styled-components';

import withData from './withData';
import { packRow } from './constants';
import { Table } from './styles';
import RefilCredits from '../Credits';
import { currencySymbols } from '../../constants';

const lastCellStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  minWidth: '140px',
  paddingRight: '0'
};

const TableRow = ({ handleBuy, isSubscribe, credits, first_price, price, plan, currency, isHidePurchase }) => {
  const handleClick = () => {
    window.dataLayer.push({
      'event': 'date',
      'eventCategory': 'userclicks',
      'eventAction': 'button',
      'eventLabel': 'buy'
    });
    
    handleBuy(plan);
  };
  
  const titleDisableText = !Boolean(isSubscribe) ? 'Credits purchase is possible after subscription.' : '';
  
  return (
    <tr>
      <td>{credits}</td>
      <td>{currencySymbols[currency] || currency}{first_price}</td>
      <td>
        <div style={lastCellStyle}>
          {currencySymbols[currency] || currency}{price}
          <StyledButton
            title={titleDisableText}
            className="blue-button"
            onClick={handleClick}
            disabled={!Boolean(isSubscribe)}
            isHidden={isHidePurchase}
          >
            Buy
          </StyledButton>
        </div>
      </td>
    </tr>
  );
};

const CreditPacksTable = ({ data, isSubscribe, isHidePurchase }) => {
  const [creditsPanelIsOpen, setCreditsPanelIsOpen] = useState(false);
  const [activeCreditsPack, setActiveCreditsPack] = useState(null);
  
  const toggleCreditsForm = plan => {
    setActiveCreditsPack(creditsPanelIsOpen ? null : plan);
    setCreditsPanelIsOpen(prevState => !prevState);
  };

  return (
    <div>
      {creditsPanelIsOpen && (
        <RefilCredits
          onClose={toggleCreditsForm}
          initialCreditPack={activeCreditsPack}
        />
      )}
      
      <Table>
        <thead>
          <tr>
            {packRow.map(cell => (
              <th key={cell}>{cell}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(plan => (
            <TableRow
              key={plan.id}
              plan={plan}
              isSubscribe={isSubscribe}
              isHidePurchase={isHidePurchase}
              handleBuy={toggleCreditsForm}
              {...plan}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const StyledButton = styled.button`
  ${({ isHidden }) => isHidden && `
    display: none;
  `}
  
  ${({ disabled }) => disabled && `
    background-color: #d4d3d3;
    cursor: default;
    box-shadow: none;
  `}
`;

export default withData(CreditPacksTable);