import styled from 'styled-components';
import {btn} from '../styles';

export const StyledWrapper = styled.div`
  max-width: 16.5625rem;
  padding-top: .3125rem;
  margin-bottom: .5rem;
  position: relative;
`;

export const CreateCardWrapper = styled.form`
  opacity: ${props => props.loading ? .5 : 1};
  
  h5 {
    margin-bottom: .3125rem;
    font-size: .875rem;
    letter-spacing: .05625rem;
  }
`;

export const Loader = styled.img`
  width: 2rem;
  position: absolute;
  opacity: 1 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const CardNumInputs = styled.div`
  margin: 0 -.25rem;
`;

export const AddCardRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardsBtnWrap = styled.div`
  margin-top: .825rem;
  display: flex;
  
  ${({loading}) => loading && `
    button {
      cursor: default;
    }
  `}
`;

export const CardBtn = styled(btn)`
  min-width: 128px;
  line-height: 24px;
  height: 24px;
  width: 65%;

  :first-of-type {
    width: 30%;
		margin-left: auto;
    margin-right: 10px;
    color: #b8bfd3;
    border-color: #b8bfd3;
    background-color: #fff;
    min-width: 0;
     
    :focus {
      background-color: rgba(221, 44, 87, .1);
		}
	
		:hover {
      background-color: rgba(221, 44, 87, .1);		
		}
	}
`;

export const SelectContainer = styled.div`
  :first-of-type {
    margin-right: 5px;
  }
  
  input {
    text-align: center;
    margin-bottom: 0;
  }
`;

export const AddCardCol = styled.div`
    flex-shrink: 0;
    display: flex;
    width: 50%;
`;

export const CvvWrap = styled.div`
  position: relative;

   input {
      max-width: 4.6875rem;
      margin-bottom: 0
   }
   
   input[type=number]::-webkit-inner-spin-button, 
   input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
   }
   
   i {
    position: absolute;
    top: 50%;
    right: .8125rem;
    transform: translate(0, -50%);
    color: #dadee8;
    font-size: .8125rem
  }
`;

export const Settings = styled.div`
	position: relative;
  max-width: 40.3125rem;
  width: 100%;
  padding: 20px;
  border-radius: 2px;
  color: #515151;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(184, 191, 211, .2);
  
  @media (max-width: 767px) {
    box-shadow: none; 
    max-width: 100%;
    padding: .9375rem;
    box-shadow: none;
  }
`;

export const ConfirmDeleting = styled.div`
  p {
    text-align: center;
    line-height: 1.25rem;
    
    @media (max-width: 767px) {
      font-size: .85rem;
    }
  }
  
  div {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    span {
      color: red;
      font-size: .75rem;
      text-align: center;
      padding: 5px 0;
    }
  }
  
  input {
    width: 55%;
    outline: none;
    border: 1px solid #dadee8;
    height: 1.875rem;
    color: gray;
    padding: 0 .5rem;
    font-size: 1rem;
    
    @media (max-width: 767px) {
      width: 80%;
    }
  }
`;

export const Close = styled.span`
  position: absolute;
  right: .8rem;
  top: .8rem;
  cursor: pointer;
  background: 0;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  color: #b8bfd3;
  
  :hover {
    color: #4b4c59;
  }
`;

export const Title = styled.div`
	display: flex;
  justify-content: space-between;
  align-items: center;
  
  h2 {
    font-weight: 600;
    margin-bottom: 1.625rem;
    
    @media (max-width: 767px) {
      font-size: 1.5rem;
      margin-bottom: 3.125rem;
    }   
  }
`;

export const Account = styled.b`
	display: block;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 26px;
  
  button {
    margin-left: 4px;
    font-size: 14px;
    color: #3b5a9a;
    border: 0;
    background: 0;
    padding: 0;
    cursor: pointer;
  }
`;

const Field = styled.div`
  height: 48px;
  display: flex;
  
  button {
    cursor: pointer;
    border: 0;
    font-weight: 600;
    font-size: .75rem;
    letter-spacing: 2px;
    outline: none;
    color: #3b5a9a;
    line-height: 30px;
    margin-left: .5rem;
    background: 0;
  }
  
  input {
    width: 40%;
    outline: none;
    border: 1px solid #dadee8;
    height: 1.875rem;
    color: gray;
    padding: 0 .5rem;
  }
  
  span:first-child {
    display: inline-block;
    font-weight: 600;
    min-width: 15.375rem;
    
    @media (max-width: 767px) {
      min-width: 6.25rem;
    }
  }  
`;

export const FieldEmail = styled(Field)`
  font-size: .875rem;
  padding-bottom: .9375rem;
  padding-top: .9375rem;
  border-bottom: .0625rem solid #edeff4;
  display: flex;
  align-items: center;
  
  img {
    width: 15px;
    height: 15px;
    display: flex;
    align-self: center;
    margin-left: 15px;
  }
  
  .account-settings__btn-wrapper {
    display: flex;
    align-items: center;
    padding: 5px;
  }

  @media (max-width: 767px) {
    margin-right: -.9375rem;
    margin-left: -.9375rem;
    padding-right: .9375rem;
    padding-left: .9375rem;
  }
`;

export const FieldWrapper = styled.div`
  ${FieldEmail}:first-of-type {
    padding-top: 0;
  }

  ${FieldEmail}:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

export const Container = styled.div`
	padding: 10px 20px;
	h1, h2 {
		color: #444;
	}
	
	h2 {
		font-size: 18px;
	}
	
	h1 {
		font-size: 20px;
	}
`;

export const Notifications = styled.b`
	display: block;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 26px;
  margin-top: 1.5rem;
  
  @media (max-width: 767px) {
    margin-bottom: 1.25rem;
  }
`;

export const Button = styled(btn)`
	letter-spacing: 2px;
  height: 1.5rem;
  line-height: 1.5rem;
  margin: 1.5rem 0;
`;

export const Error = styled.span`
  color: red;
	font-size: .75rem;
	padding-left: .5rem;
	opacity: 0;
  visibility: hidden;
  
  ${({error}) => error && `
    visibility: visible;
    opacity: 1;
  `}
`;

export const Privacy = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #edeff4;
  
  @media (max-width: 767px) {
    margin-right: -.9375rem;
    margin-left: -.9375rem;
    padding-right: .9375rem;
    padding-left: .9375rem;
  }
  
 
  @media (max-width: 480px) {
    justify-content: space-between;
  }
`;

export const Logout = styled.div`
	display: flex;
	cursor: pointer;
`;

export const Delete = styled.button`
	font-weight: 600;
`;

export const NotificationsMethods = styled.div`
  display: flex;
  margin-left: 13.2rem;;
  
  .checkbox_row_title {
    width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px) {
      width: 8.5rem;
    }
  }
  
  @media (max-width: 767px) {
    margin-left: 15rem;
  }
  
  @media (max-width: 575px) {
    margin-left: 10rem;
  }

	@media (max-width: 480px) {
    margin-left: 7rem;
  }
`;
