export function getQueryString(query) {
  return Object.keys(query).reduce((result, key) => {
    if (query[key] === '' || query[key] === null) {
      return result + '';
    }

    if (Array.isArray(query[key])) {
      let queryFromArray = '';

      query[key].forEach(item => {
        queryFromArray += key + '[]=' + item + '&';
      });

      return result + queryFromArray;
    }

    return result + key + '=' + query[key] + '&';
  }, '&');
}