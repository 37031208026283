import {connect} from 'react-redux';

import PaymentsComponent from '../components/Payments';
import {updateProfileInfo} from '../actions';

const mapStateToProps = ({profile}) => ({profile});

const mapDispatchToProps = {
  updateProfileInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsComponent);