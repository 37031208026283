import React, {} from 'react';
import styled from 'styled-components';

import SwitchButton from '../Switch';
import * as Styles from './styles';

const Privacy = ({ hideAccount, value }) =>
  <>
    <Title>Privacy</Title>
    <Styles.Privacy>
      <TextWrap>
        <span>Hide account</span>
        <span>
          You can hide your account from search. <br/>
          Users will not be able to view your profile
        </span>
      </TextWrap>
      <SwitchButton onChange={hideAccount} value={value}/>
    </Styles.Privacy>
  </>;

export default Privacy;

const Title = styled.b`
	display: block;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 26px;
`;

const TextWrap = styled.div`
  @media (max-width: 480px) {
    font-size: .875rem;
    margin-right: 2.5rem;
  }
  
  margin-right: 5.125rem;
	
	span:first-child {
		font-weight: 600;
    display: block;
    margin-bottom: .125rem;
	}
	
	span:last-child {
		font-size: 10px;
    color: #9b9b9b;
    
    @media (max-width: 480px) {
      line-height: .75rem;
      display: inline-block;
    }
	}
`;