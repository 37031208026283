import React from 'react';
import Collapse from 'rc-collapse';
import Panel from 'rc-collapse/lib/Panel';
import 'rc-collapse/assets/index.css';
import { NavLink } from 'react-router-dom';

import { detailInfo } from '../../../constants';
import UploadAvatar from './uploadAvatar';
import { isMobile } from '../../../utils/mobile';

const ShowHeart = percent => {
  if (percent > 0 && percent < 40) {
    return (
      <path className="30%" d="M11 18L18.9282 11H3.07178L11 18Z" fill="#DE60AD"/>
    );
  }
  
  if (percent > 20 && percent < 60) {
    return (
      <path className="48%" d="M11 19L20.1602 9.5H2L11 19Z" fill="#DE60AD"/>
    );
  }
  
  if (percent > 60 && percent < 80) {
    return (
      <path
        className="66%"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0187 1.93033C20.6342 2.53232 21.1264 3.24854 21.4677 4.03891C21.8217 4.86145 22.0025 5.73493 22 6.63388C22 7.48189 21.8268 8.36556 21.483 9.2645C21.1978 10.0157 20.7827 10.795 20.2556 11.5819C19.4203 12.8272 18.2718 14.1259 16.8457 15.4425C14.4799 17.625 12.1421 19.13 12.0428 19.1936L11.4393 19.5807C11.1719 19.7514 10.8281 19.7514 10.5607 19.5807L9.95716 19.1936C9.85784 19.1325 7.51753 17.625 5.15429 15.4425C3.7282 14.1259 2.57969 12.8272 1.74441 11.5819C1.21727 10.795 0.804722 10.0157 0.516957 9.2645C0.173168 8.36556 0 7.48189 0 6.63388C0 5.73493 0.180808 4.86145 0.534783 4.03891C0.875206 3.24803 1.36751 2.53164 1.98379 1.93033C2.60261 1.32424 3.3233 0.848032 4.12293 0.516975C4.95057 0.173186 5.82659 1.78996e-05 6.73063 1.78996e-05C7.9861 1.78996e-05 9.21101 0.343807 10.2755 0.993187C10.5301 1.14853 10.7721 1.31915 11.0013 1.50505C11.2305 1.31915 11.4724 1.14853 11.727 0.993187C12.7915 0.343807 14.0164 1.78996e-05 15.2719 1.78996e-05C16.1668 -0.00204182 17.0532 0.173678 17.8796 0.516975C18.6766 0.846735 19.4028 1.32655 20.0187 1.93033ZM19.985 7.5H2.01752C1.96384 7.2099 1.93541 6.92082 1.93541 6.63388C1.93541 4.03891 4.08218 1.93542 6.73063 1.93542C8.59219 1.93542 10.2067 2.97443 11.0013 4.4922C11.7958 2.97443 13.4103 1.93542 15.2719 1.93542C17.9203 1.93542 20.0671 4.03891 20.0671 6.63388C20.0671 6.92082 20.0387 7.2099 19.985 7.5Z"
        fill="#DE60AD"
      />
    );
  }
  
  if (percent > 80 && percent < 100) {
    return (
      <path className="82%" d="M9.975 19L18.5 12L21 8V5H1L1.5 8.5L4.2 12.7826L9.975 19Z" fill="#DE60AD"/>
    );
  }
  
  if (percent === 100) {
    return (
      <path
        className="100%"
        d="M21.4677 4.03891C21.1264 3.24854 20.6342 2.53232 20.0187 1.93033C19.4028 1.32655 18.6766 0.846735 17.8796 0.516975C17.0532 0.173678 16.1668 -0.00204182 15.2719 1.78996e-05C14.0164 1.78996e-05 12.7915 0.343807 11.727 0.993187C11.4724 1.14853 11.2305 1.31915 11.0013 1.50505C10.7721 1.31915 10.5301 1.14853 10.2755 0.993187C9.21101 0.343807 7.9861 1.78996e-05 6.73063 1.78996e-05C5.82659 1.78996e-05 4.95057 0.173186 4.12293 0.516975C3.3233 0.848032 2.60261 1.32424 1.98379 1.93033C1.36751 2.53164 0.875206 3.24803 0.534783 4.03891C0.180808 4.86145 0 5.73493 0 6.63388C0 7.48189 0.173168 8.36556 0.516957 9.2645C0.804722 10.0157 1.21727 10.795 1.74441 11.5819C2.57969 12.8272 3.7282 14.1259 5.15429 15.4425C7.51753 17.625 9.85784 19.1325 9.95716 19.1936L10.5607 19.5807C10.8281 19.7514 11.1719 19.7514 11.4393 19.5807L12.0428 19.1936C12.1421 19.13 14.4799 17.625 16.8457 15.4425C18.2718 14.1259 19.4203 12.8272 20.2556 11.5819C20.7827 10.795 21.1978 10.0157 21.483 9.2645C21.8268 8.36556 22 7.48189 22 6.63388C22.0025 5.73493 21.8217 4.86145 21.4677 4.03891Z"
        fill="#DE60AD"
      />
    );
  }
  
  return null;
};

const Header = ({ percent, item, showArrow = false, updateProfileInfo }) => {
  return (
    <div className="collapse-header-item">
      {showArrow && (
        <div className="collapse-header-item__arrow">
          <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.8947 10.5776C16.5097 10.7701 16.0447 10.6946 15.7404 10.3902L10.2071 4.85701C9.81659 4.46649 9.18343 4.46649 8.7929 4.85701L3.25966 10.3902C2.9553 10.6946 2.49033 10.7701 2.10534 10.5776L1.29096 10.1704C0.680462 9.86513 0.548426 9.05149 1.03107 8.56884L8.7929 0.80701C9.18343 0.416486 9.81659 0.416486 10.2071 0.80701L17.969 8.56884C18.4516 9.05149 18.3196 9.86513 17.7091 10.1704L16.8947 10.5776Z"
              fill="#B8BFD3"/>
          </svg>
        </div>
      )}
      <div className="collapse-header-item__icon">
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21.4677 4.03891C21.1264 3.24854 20.6342 2.53232 20.0187 1.93033C19.4028 1.32655 18.6766 0.846735 17.8796 0.516975C17.0532 0.173678 16.1668 -0.00204182 15.2719 1.78996e-05C14.0164 1.78996e-05 12.7915 0.343807 11.727 0.993187C11.4724 1.14853 11.2305 1.31915 11.0013 1.50505C10.7721 1.31915 10.5301 1.14853 10.2755 0.993187C9.21101 0.343807 7.9861 1.78996e-05 6.73063 1.78996e-05C5.82659 1.78996e-05 4.95057 0.173186 4.12293 0.516975C3.3233 0.848032 2.60261 1.32424 1.98379 1.93033C1.36751 2.53164 0.875206 3.24803 0.534783 4.03891C0.180808 4.86145 0 5.73493 0 6.63388C0 7.48189 0.173168 8.36556 0.516957 9.2645C0.804722 10.0157 1.21727 10.795 1.74441 11.5819C2.57969 12.8272 3.7282 14.1259 5.15429 15.4425C7.51753 17.625 9.85784 19.1325 9.95716 19.1936L10.5607 19.5807C10.8281 19.7514 11.1719 19.7514 11.4393 19.5807L12.0428 19.1936C12.1421 19.13 14.4799 17.625 16.8457 15.4425C18.2718 14.1259 19.4203 12.8272 20.2556 11.5819C20.7827 10.795 21.1978 10.0157 21.483 9.2645C21.8268 8.36556 22 7.48189 22 6.63388C22.0025 5.73493 21.8217 4.86145 21.4677 4.03891ZM11.0013 17.5664C11.0013 17.5664 7.97367 15.6265 5.4177 13C3.55074 11.0815 1.93541 8.79666 1.93541 6.63388C1.93541 4.03891 4.08218 1.93542 6.73063 1.93542C8.59219 1.93542 10.2067 2.97443 11.0013 4.4922C11.7958 2.97443 13.4103 1.93542 15.2719 1.93542C17.9203 1.93542 20.0671 4.03891 20.0671 6.63388C20.0671 8.79666 18.4518 11.0815 16.5848 13C14.0289 15.6265 11.0013 17.5664 11.0013 17.5664Z"
            fill="#DE60AD"
          />
          {ShowHeart(percent)}
        </svg>
        <div className="collapse-header-item__icon-count">
          {percent}%
        </div>
      </div>
      <div className="collapse-header-item__description">
        {item.description + ' '}
        {item.component ? <item.component updateProfileInfo={updateProfileInfo}/> : (
          <NavLink
            to={{ pathname: item.link || '/me', state: item.state }}
            onClick={item.callback || (() => {})}
            className="collapse-header-item__link"
          >
            {item.linkText}
          </NavLink>
        )}
        
      </div>
    </div>
  );
};

const RenderCollapseItem = ({ description, callback, linkText, state, component: Component, updateProfileInfo }) => {
  return (
    <div className="collapse-item">
      <div className="collapse-item__desription">
        {description + ' '}
        {
          Component
            ? <Component updateProfileInfo={updateProfileInfo}/>
            : (
              <NavLink
                to={{ pathname: '/me', state }}
                onClick={callback}
                className="collapse-header-item__link"
              >
                {linkText}
              </NavLink>
            )
        }
      </div>
    </div>
  );
};

const Accordion = ({ profile, openEditComponent, openAlbum, show, updateProfileInfo }) => {
  const userDetailsRequiredKeys = ['age', 'city', 'about', 'country'];
  
  const personalDetailsRequiredKeys = Object.keys(detailInfo);
  
  const collapseItem = [
    {
      description: 'We give you free credits as far as you are fulfilling your heart and your profile. Make women to meet and like you - set your avatar.',
      complete: Boolean(profile.photo),
      linkText: 'Start',
      component: isMobile() && UploadAvatar,
      state: { editPhoto: true },
      id: 0,
    },
    {
      description: 'Please fill in the main information about yourself. This is your text visit card for beauties. You are not so far to win bonus credints!',
      callback: () => openEditComponent('UserDetails'),
      linkText: 'Start',
      complete: userDetailsRequiredKeys.every(key => profile[key]),
      id: 1,
    },
    {
      description: 'Add photos to your main album to show your life style and yourself. Girls will like it!',
      callback: () => openAlbum(),
      complete: Boolean(profile.albums && profile.albums.length),
      linkText: 'Add',
      id: 2,
    },
    {
      description: 'Please fill in the information about your interests to chat and have an informative communication.',
      callback: () => openEditComponent('UserInterests'),
      complete: Boolean(profile.interests && profile.interests.length),
      linkText: 'Start',
      id: 3,
    },
    {
      description: 'To chat and have an informative communication, please fill in the information about yourself.',
      callback: () => openEditComponent('PersonalDetails'),
      complete: personalDetailsRequiredKeys.every(key => profile[key] !== null),
      linkText: 'Start',
      id: 4,
    },
    {
      description: 'To have an obvious and easy match, please write what kind of woman you are looking for.',
      callback: () => openEditComponent('UserSearch'),
      complete: Boolean(profile.looking_for),
      linkText: 'Start',
      id: 5,
    },
  ];
  
  const defaultItem = {
    description: 'To have an obvious and easy match, please write what kind of woman you are looking for.',
    link: '/catalog',
    linkText: 'Start',
  };
  
  const collapseItemsToShow = collapseItem.filter(item => !item.complete);
  
  const headerItem = collapseItemsToShow.length === 0 ? defaultItem : collapseItemsToShow[0];
  
  const percent = (collapseItem.filter(item => item.complete).length * (100 / collapseItem.length)).toFixed(0);
  
  if (!show) {
    return null;
  }
  
  return (
    <Collapse accordion={true}>
      <Panel
        header={
          <Header
            percent={Number(percent)}
            item={headerItem}
            showArrow={collapseItemsToShow.length > 1}
            updateProfileInfo={updateProfileInfo}
          />
        }
        showArrow={false}
        headerClass="collapse-header"
      >
        {collapseItemsToShow
          .slice(1)
          .map(({ id, description, link, linkText, callback, state, component }) => (
            <RenderCollapseItem
              key={id}
              description={description}
              link={link}
              linkText={linkText}
              callback={callback}
              state={state}
              component={component}
              updateProfileInfo={updateProfileInfo}
            />
          ))}
      </Panel>
    </Collapse>
  );
};

export default Accordion;