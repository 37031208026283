import React from 'react';
import styled from 'styled-components';
import {Heart} from '../assets/svg/Heart';

export function getLastMessageByType(message, author) {
  switch (message.type) {
  case 'PHOTO': {
    return (
      <Like>
        <Heart/>
        {author === 'me' ? 'Liked photo' : 'Liked your photo'}
      </Like>
    );
  }

  case 'MESSAGE': {
    return <span>{message.text.slice(0, 22) + (message.text.length > 22 ? ' ...' : '')}</span>;
  }

  case 'LETTER': {
    return (
      <Mailbox>
        <Mail>
          <i className='fas fa-envelope'/>
        </Mail>
        {author === 'me' ? 'You send email' : 'Send you email'}
      </Mailbox>
    );
  }

  default:
    break;
  }
}

const Like = styled.div`
	display: flex;
	align-items: center;
	
	svg {
		margin-right: 5px;
		fill: #dd2c57;
		width: 12px;
		height: 12px;
	}
`;

const Mailbox = styled.div`
	display: flex;
	align-items: center;
`;

const Mail = styled.div`
  i {
    color: #4b4c59 !important;
    font-size: 12px;
    margin-right: 5px;
  }
`;

