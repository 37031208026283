import {connect} from 'react-redux';

import SearchBarComponent from '../components/SearchBar';
import { fetchCatalog, updateProfileInfo } from '../actions';

const mapStateToProps = state => ({
  selects: state.selects,
  generalSearch: state.profile.search,
  form: state.form,
  myGender: state.profile.gender
});

const mapDispatchToProps = {
  fetchCatalog,
  updateProfileInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarComponent);