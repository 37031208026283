import React, {Component} from 'react';

import getRandomFloat from '../../utils/getRandomFloat';
import Profile from './Profile';
import * as Styles from './styles';
import {loader} from '../../assets';
import {getQueryString} from '../../utils/getQueryString';
import {getItemFromLocalStorage} from '../../utils/localStorage';
import SearchBar from '../../containers/SearchBar';

import VerifyMessage from './verifyMessage';
import VerifyItem from './verifyItem';

class Catalogs extends Component {
  catalog = React.createRef();

  state = {
    isOpen: false,
    age_from: 18,
    age_to: 70,
  };

  componentDidMount() {
    if (this.props.profile.id) {
      this.fetchWithQuery({method: this.props.fetchCatalog,});
    }
    
    if (!sessionStorage.getItem('catalog_rotation')) {
      sessionStorage.setItem('catalog_rotation', getRandomFloat(-1, 1));
    }

    document.addEventListener('scroll', this.listenToScroll);
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.profile.id !== this.props.profile.id) {
      this.fetchWithQuery({method: this.props.fetchCatalog, props: nextProps});
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.listenToScroll);
  }

  fetchWithQuery = ({method, page, props}) => {
    const {age_from, age_to} = this.state;

    const rotationQuery = `random=${sessionStorage.getItem('catalog_rotation')}`;
    const currentProps = props ? props : this.props;

    const queryObj = getItemFromLocalStorage({item: 'query'});
    
    if (queryObj) {
      const queryString = getQueryString(queryObj);
      method(queryString + rotationQuery, page);

    } else if (currentProps.search) {
      const queryString = getQueryString(currentProps.search);
      method(queryString + rotationQuery, page);

    } else {

      method(`&age_from=${age_from}&age_to=${age_to}&${rotationQuery}`, page);
    }
  };

  listenToScroll = () => {
    const {fetching, links} = this.props.catalog;
    const page = (links && links.next) ? links.next.split('?page=')[1] : null;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = +((winScroll / height) * 100).toFixed(0);

    if (scrolled > 75 && page && !fetching) {
      this.fetchWithQuery({method: this.props.loadMoreProfiles, page});
    }
  };

  toggleSearchPanel = () => this.setState({isOpen: !this.state.isOpen});

  render() {
    const {loading, data, fetching,} = this.props.catalog;
    
    const { email, email_verified_at, is_agency } = this.props.profile;
    
    const isShowVerifyMessage = email && !Boolean(email_verified_at) && !Boolean(is_agency);

    if (loading) {
      return (
        <Styles.Loader src={loader}/>
      );
    }

    return (
      <Styles.Container className="catalog">
        <VerifyMessage
          show={isShowVerifyMessage}
          email={email}
          resendVerificationEmail={this.props.resendVerificationEmail}
        />
        {
          data.length ? (
            <Styles.CatalogList
              onScroll={this.onScroll}
              ref={this.catalog}
            >
              {
                data.slice(0, 6).map(profile => (
                  <Profile
                    {...profile}
                    key={profile.id}
                    is_online={this.props.onlineUsers.indexOf(profile.id) !== -1}
                  />
                ))
              }
              <VerifyItem
                show={isShowVerifyMessage}
                email={email}
                email_verified_at={email_verified_at}
                resendVerificationEmail={this.props.resendVerificationEmail}
              />
              {
                data.slice(6).map(profile => (
                  <Profile
                    {...profile}
                    key={profile.id}
                    is_online={this.props.onlineUsers.indexOf(profile.id) !== -1}
                  />
                ))
              }
            </Styles.CatalogList>
          ) : (!data.length && this.props.profile.id) ? (
            <Styles.PlaceHolder>
              There are no matches for your query (
              <Styles.Button onClick={this.toggleSearchPanel}>Refine search</Styles.Button>
            </Styles.PlaceHolder>
          ) : (
            <div/>
          )
        }
        {fetching && (<Styles.Fetching><img src={loader} alt='loader'/>Please wait, loading...</Styles.Fetching>)}
        <SearchBar
          isOpen={this.state.isOpen}
          onClose={this.toggleSearchPanel}
        />
      </Styles.Container>
    );
  };
}

export default Catalogs;

