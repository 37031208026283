import React, { Component } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { Modal } from '../Modal';
import { getQueryString } from '../../utils/getQueryString';
import Form from './Form';
import { Container } from './styles';
import withModalClose from '../HOC/withModalClose';
import * as api from '../../api';

class SearchBar extends Component {
  state = {
    extendedSearch: false,
    interests: false,
    query: {}
  };

  componentDidMount() {
    this.getQueryObject();
  }

  getQueryObject = () => {
    this.setState({
      query: Object.assign(
        {},
        this.props.generalSearch,
        localStorage.getItem('query') ? JSON.parse(localStorage.getItem('query')) : {},
      )
    });
  };

  toggleSearchPanel = () => this.setState(prevState => ({extendedSearch: !prevState.extendedSearch}));

  toggleInterestsPanel = () => this.setState(prevState => ({interests: !prevState.interests}));

  setGeneralSearchQuery = query => {
    const queryKeys = ['age_from', 'age_to'];

    let search = Object.assign({}, query);

    Object.keys(query).forEach(key => {
      if (!queryKeys.includes(key)) {
        delete search[key];
      }

      if (!query[key]) {
        delete query[key];
      }
    });

    api.updateProfile({body: {search}})
      .then(() => this.props.updateProfileInfo({search}));

    localStorage.setItem('query', JSON.stringify(query));

    this.props.onClose();
  };

  clearSearchSettings = () => {
    this.setState({
      query: Object.assign(
        {},
        localStorage.getItem('query') ? JSON.parse(localStorage.getItem('query')) : {},
      )
    });
  };

  handleSubmit = values => {
    const { fetchCatalog, history } = this.props;
  
    const query = Object.assign({}, values);
  
    this.setGeneralSearchQuery(query);
  
    fetchCatalog(getQueryString(query));
  
    if (!history.location.pathname.includes('/catalog')) {
      history.push('/catalog');
    }
  };

  render() {
    const {isOpen, onClose, generalSearch, selects, form, innerRef, myGender} = this.props;
    const {query, extendedSearch, interests} = this.state;

    return (
      <Modal isOpen={isOpen}>
        <Container ref={innerRef}>
          <Form
            userGender={myGender}
            Form={form}
            selects={selects}
            generalSearch={generalSearch}
            initialValues={query}
            extendedSearch={extendedSearch}
            interests={interests}
            clearSearchSettings={this.clearSearchSettings}
            toggleInterestsPanel={this.toggleInterestsPanel}
            toggleSearchPanel={this.toggleSearchPanel}
            onSubmit={this.handleSubmit}
            onClose={onClose}
          />
        </Container>
      </Modal>
    );
  }
}

const enhance = compose(withModalClose, withRouter);

export default enhance(SearchBar);
