import ReactGA from 'react-ga';

export const initGA = trackingID => ReactGA.initialize(trackingID);

export const TransactionEvent = transaction => {
  // Object.keys(transaction).forEach(key => {
  //   const field = transaction[key];
  //   if (typeof field === 'string') {
  //     ReactGA.set(key, field);
  //   } else if (typeof field === 'object' && !Array.isArray(transaction[key])) {
  //     ReactGA.set(key, {});
  //   }
  // });
  ReactGA.set('transaction', transaction);
  ReactGA.event({...transaction});
};

export const Event = (category, action, label, params = {}) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    ...params
  });
};

