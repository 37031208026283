import React from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';


const onCloseButton = ({history}) => {
  const goBack = history.goBack;

  return (
    <Close onClick={goBack}>
      <i className='fal fa-times'/>
    </Close>
  );
};

export default withRouter(onCloseButton);

export const Close = styled.span`
  background: 0;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  color: #b8bfd3;
  cursor: pointer;
  
  :hover {
    color: #4b4c59;
  }
`;
