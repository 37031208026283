import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {ru, en} from './locales';

let initialLanguage = localStorage.getItem('language');

const options = {
  interpolation: {
    escapeValue: false,
  },

  debug: true,

  resources: {
    ru: {
      common: ru.ru,
    },
    en: {
      common: en.en,
    },
  },

  fallbackLng: initialLanguage || 'en',

  ns: ['common'],

  defaultNS: 'common',

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  },
};

i18n
  .use(initReactI18next)
  .init(options);


export default i18n;
