import {withSuccessStatus, withFailureStatus} from '../utils/withData';
import * as TYPES from '../constants/types';

const initialState = {
  data: {},
  error: null,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
  case TYPES.FETCH_WIZARD_OPTIONS_REQUEST:
    return {...state, loading: true, error: null};
  case TYPES.FETCH_WIZARD_OPTIONS_SUCCESS:
    return withSuccessStatus(state, {data: action.selects});
  case TYPES.FETCH_WIZARD_OPTIONS_FAILURE:
    return withFailureStatus(state, {}, action.error);
  default:
    return state;
  }
};

