import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AddAlbum = ({openAlbumCreator}) =>
  <ButtonWrapper onClick={openAlbumCreator}>
    <CreateAlbum>
      <ImagesIcon>
        <i className="fas fa-images"/>
      </ImagesIcon>
      Add album
    </CreateAlbum>
  </ButtonWrapper>;

AddAlbum.propTypes = {
  openAlbumCreator: PropTypes.func.isRequired
};

export default AddAlbum;

const CreateAlbum = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 6.5625rem;
  cursor: pointer;
  font-size: .75rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 0;
  user-select: none;
  text-transform: uppercase;
  color: #3b5a9a;
  border: 0;
  background-color: #edeff4;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  z-index: 1;

	:hover {
    cursor: pointer;
	}
	
	@media (max-width: 767px) {
    height: 6.375rem;
	}
`;

const ImagesIcon = styled.div`
	font-size: 18px;
  margin-bottom: 4px
`;