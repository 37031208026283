import React, { PureComponent } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import Filter from './FilterBar';
import Search from './Search';
import List from './List';
import { toLocalDate } from '../../../utils/toLocalDate';
import { isMobile } from '../../../utils/mobile';

function filterByName(data, value) {
  return data.filter(user => {
    let val = value.toLowerCase().replace(/ /g, '');
    let name = user.name.toLowerCase().replace(/ /g, '');
    return name.includes(val);
  });
}

function filterByKey(data, key) {
  return _.filter(data, user => user[key]);
}

function filterBlockedUsers(data) {
  return _.filter(data, user => !user.is_blocked);
}

function sortMessageDate(array) {
  return JSON.parse(JSON.stringify(array))
    .map(user => {
      if (user.last_message) {
        user.last_message.created_at = toLocalDate(user.last_message.created_at.replace(/-/gi, '/'));
      }
      return user;
    })
    .sort((a, b) => {
      if (a.last_message && b.last_message) {
        return b.last_message.created_at > a.last_message.created_at ? 1 : (b.last_message.created_at < a.last_message.created_at ? -1 : 0);
        
      } else if (a.last_message && !b.last_message) {
        return 0 > a.last_message.created_at ? 1 : (0 < a.last_message.created_at ? -1 : 0);
        
      } else if (b.last_message && !a.last_message) {
        return b.last_message.created_at > 0 ? 1 : (b.last_message.created_at < 0 ? -1 : 0);
        
      } else {
        return 0;
      }
    });
}

class Contacts extends PureComponent {
  state = {
    list: 'All',
    searchTerm: '',
    status: '',
    contacts: [],
    currentDisplayed: []
  };

  componentDidMount() {
    if (isMobile()) {
      window.scrollTo(0, 0);
    }

    if (this.props.contacts.data.length) {
      this.setState({
        currentDisplayed: this.filterContactsList({data: this.props.contacts.data}),
        contacts: this.props.contacts.data
      });
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    const {contacts} = nextProps;
    if (JSON.stringify(this.props.contacts.data) !== JSON.stringify(contacts.data)) {
      this.setState({
        currentDisplayed: this.filterContactsList({data: contacts.data}),
        contacts: contacts.data
      });
    }
  };

  filterContactsList = query => {
    const {list, status, searchTerm, data} = query;
    const {contacts} = this.state;

    let search = 'searchTerm' in query ? searchTerm : this.state.searchTerm;
    let isOnline = 'status' in query ? status : this.state.status;
    let listType = list || this.state.list;

    let result = filterBlockedUsers(filterByName(data || contacts, search));

    switch (listType) {
    case 'Match':
      result = filterByKey(result, 'is_match');
      break;
    case 'Favorites':
      result = filterByKey(result, 'is_favorite');
      break;
    default:
      break;
    }

    if (isOnline) {
      result = _.filter(result, user => ~this.props.onlineUsers.indexOf(user.id));
    }

    return sortMessageDate(result);
  };

  handleChange = (key, value) => {
    this.setState({
      currentDisplayed: this.filterContactsList({[key]: value}),
      [key]: value
    });
  };

  render() {
    const { markMessagesAsRead, onlineUsers, unreadMessages, contacts: { loading, isLoaded }, myId, is_agency, last_subscription } = this.props;
    const { currentDisplayed, searchTerm, contacts } = this.state;

    return (
      <SideBarContacts>
        <ContactsHead>
          <Header>
            <ContactsTitleWrap>
              <TextWrap>
                <Icon>
                  <i className='fas fa-user-friends'/>
                </Icon>
                <span>Contacts</span>
              </TextWrap>
              {unreadMessages? (<MessageNumber>{unreadMessages > 99 ? '99+' : unreadMessages}</MessageNumber>) : <MessageNumber>0</MessageNumber>}
            </ContactsTitleWrap>
          </Header>
          <Search
            onChange={this.handleChange}
            searchTerm={searchTerm}
          />
        </ContactsHead>
        <SideBarTabs>
          <Filter onChange={this.handleChange}/>
          <List
            isLoaded={isLoaded}
            is_agency={is_agency}
            last_subscription={last_subscription}
            loading={loading}
            onlineUsers={onlineUsers}
            markMessagesAsRead={markMessagesAsRead}
            initialContacts={contacts}
            myId={myId}
            contacts={currentDisplayed}
          />
        </SideBarTabs>
      </SideBarContacts>
    );
  }
}

export default Contacts;

const ContactsTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: default;
`;

const MessageNumber = styled.span`
	font-size: .75rem;
  display: flex;
  align-items: center;
  padding: 0 .5625rem;
  height: 1.125rem;
  letter-spacing: .046875rem;
  color: #fff;
  border-radius: .8125rem;
  background-color: #3b5a9a;
`;

const ContactsHead = styled.div`
  background-color: #fff;
	
	@media (max-width: 767px) {
    padding-top: .9375rem;
    width: 100%;
    max-width: 70rem;
	}
`;

const SideBarContacts = styled.div`
	padding: 1.25rem 1.25rem 0 1.25rem;
  background-color: #fff;
  height: calc(100% - 3.9375rem);
  
  @media (max-width: 767px) {
    padding-top: 0;
    padding-right: .9375rem;
    padding-left: .9375rem;
    height: auto !important;
    margin: 0;
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: .625rem;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 767px) {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.0625rem;
  }
`;

const TextWrap = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 1.125rem;
  font-weight: 600;
  color: #4b4c59;
  
  @media (max-width: 767px) {
  	span {
      margin-right: .9375rem;
  	}
  }
`;

const Icon = styled.div`
	font-size: 16px;
  color: #4b4c59;
  margin-right: 5px;
  
  @media (max-width: 767px) {
  	display: none;
  }
`;

const SideBarTabs = styled.div`
  margin: 0 -1.25rem;
    
	@media (max-width: 767px) {
    margin-right: -.9375rem;
    margin-left: -.9375rem
	}
`;