import {connect} from 'react-redux';

import AddToFavoriteComponent from '../components/AddToFavorite';
import {addContactToFavoritesList, deleteContactFromFavoritesList} from '../actions';

const mapDispatchToProps = {
  addContactToFavoritesList,
  deleteContactFromFavoritesList
};

export default connect(null, mapDispatchToProps)(AddToFavoriteComponent);