import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';

import {isMobile} from '../utils/mobile';

export const ModalContent = styled.div`
  position: relative;
  width: 100%;
  max-width: fit-content;
  margin: 90px auto auto;
  background-color: #fff;
  cursor: default;
  border-radius: 2px;
  color: #515151;

  ${isMobile() &&
`
  &&& {
    max-width: 100%;
    margin: 0;
    height: 100%;
  }
`}
`;

const customStyles = (overrides = {}) => {
  const mobileOverrides = isMobile()
    ? {
      content: {
        position: 'fixed',
        transform: 0,
        padding: 0,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%'
      }
    }
    : {};
  return Object.assign(
    {},
    {
      content: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        border: 0,
        backgroundColor: 'transparent',
      },
      overlay: {
        zIndex: 1222,
        backgroundColor: 'rgba(75, 76, 89, 0.5)'
      }
    },
    mobileOverrides,
    overrides
  );
};

export const Modal = ({styles, ...props}) => {
  const style = customStyles(styles);
  return (
    <ReactModal style={style} ariaHideApp={false} htmlOpenClassName={'ReactModal__Html--open'} {...props}>
      {props.children}
    </ReactModal>
  );
};
