import * as TYPES from '../constants/types';

const initialState = {
  message: '',
  error: null,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
  case TYPES.RESEND_VERIFICATION_EMAIL_REQUEST:
    return { ...state, loading: true, error: null };
      
  case TYPES.RESEND_VERIFICATION_EMAIL_SUCCESS:
    return { ...state, loading: false, message: action.payload };
      
  case TYPES.RESEND_VERIFICATION_EMAIL_FAILURE:
    return { ...state, loading: false, error: null, message: '' };
      
  case TYPES.CLOSE_VERIFY_MESSAGE_MODAL:
    return initialState;
      
  default:
    return state;
  }
};

