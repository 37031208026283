import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React from 'react';
import { syncHistoryWithStore } from 'react-router-redux';
import { createBrowserHistory } from 'history';

import Profile from './containers/Profile';
import Chat from './containers/Chat';
import Catalog from './containers/Catalog';
import Settings from './containers/Settings';
import Album from './containers/Album';
import Mailbox from './containers/Mailbox';
import Payments from './containers/Payments';
import MobileNavigation from './containers/MobileNavigation';
import Header from './containers/Header';
import Account from './containers/Account';
import Pricing from './containers/Pricing';
import Logout from './containers/Logout';

import ContactsPage from './components/Contacts';
import * as Styles from './components/styles';
import Notification from './components/Notification';
import CookiePopup from './components/cookiePopup';
import VerifyEmailMessage from './components/modals/verifyEmailMessage';

import store from './reducers/store';

const history = syncHistoryWithStore(createBrowserHistory(), store);

const routes = [
  {
    path: '/catalog',
    exact: true,
    Component: Catalog
  },
  {
    path: '/me',
    Component: Profile
  },
  {
    path: '/profile/:id',
    Component: Profile
  },
  {
    path: '/messages/:id',
    Component: Chat
  },
  {
    path: '/profiles/:profile_id/albums/:id',
    Component: Album
  },
  {
    path: '/mailbox',
    Component: Mailbox
  },
  {
    path: '/settings',
    Component: Settings
  },
  {
    path: '/payments',
    Component: Payments
  },
  {
    path: '/pricing',
    Component: Pricing
  },
  {
    path: '/contacts',
    Component: ContactsPage
  },
  {
    path: '/logout',
    Component: Logout
  }
];

const Routes = () => {
  return (
    <BrowserRouter history={history}>
      <div>
        <CookiePopup/>
        <Header/>
        <Notification/>
        <VerifyEmailMessage />
        <Styles.Content pathname={window.location.pathname}>
          <Account/>
          <Switch>
            {routes.map(({ exact = false, path, Component }) => (
              <Route
                key={path}
                path={path}
                exact={exact}
                render={props => (<Component {...props} />)}
              />
            ))}
          </Switch>
        </Styles.Content>
        <MobileNavigation/>
      </div>
    </BrowserRouter>
  );
};

export default Routes;
