import styled from 'styled-components';
import {btn} from '../styles';
import {isMobile} from '../../utils/mobile';

export const Online = styled.span`
  display: inline-block;
  position: relative;
  margin-left: 26px;
  font-size: 12px;
  letter-spacing: 1px;

	&::before {
	  content: '';
	  position: absolute;
	  left: -16px;
	  top: 50%;
	  width: 12px;
	  height: 12px;
	  transform: translateY(-50%);
	  border-radius: 50%;
	  background-color: #7ed321
	}
	
	@media (max-width: 767px) {
    position: absolute;
    right: 0;
    bottom: .625rem;
    
    &::before {
      width: 1rem;
      height: 1rem;
      border: 1px solid #fff; 
    }  
  }
`;

export const StartChat = styled(btn)`
  box-shadow: 0 0.125rem 0.25rem 0 rgba(74, 144, 226, .2);
  line-height: 1.5rem;
  min-width: 6.25rem;
  height: 1.5rem;
  letter-spacing: .05rem;
  
  @media (max-width: 767px) {
    padding: 0 1.5125rem;
    height: 1.7rem;
  }
`;

export const SendEmail = styled(btn)`
  margin-left: .8125rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(74, 144, 226, .5);
  line-height: 1.5rem;
  min-width: 6.25rem;
  height: 1.5rem;
  letter-spacing: .05rem;
  background-color: #4a90e2;
  
  @media (max-width: 767px) {
    margin-left: .375rem;
    padding: 0 1.5625rem;
    height: 1.7rem;
  }
	
	:hover {
		background-color: #3483de;
		text-decoration: none;
		cursor: pointer;
		outline: 0;
	}
`;

export const InputWrap = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  background-color: #edeff4;
  padding: 3px;
  
  div {
    height: auto;
  }
    
  @media (max-width: 767px) {
    background-color: #fff;
    height: auto;
    padding: 0;
    
    div {
      padding: .4rem 2.3125rem;
    }
  }
`;

export const ChatInput = styled.input`
  border: 0;
  background-color: transparent;
  padding: 0 6px;
  color: #515151;
  height: auto;
  font-size: .75rem;
  display: ${() => isMobile() ? 'none' : 'block'};
  
	:focus {
    outline: 0;
	}

	::-webkit-input-placeholder {
    font-size: .75rem;
    color: #b8bfd3;
	}

	::-ms-input-placeholder {
    font-size: .75rem;
	}

	::placeholder {
    font-size: .75rem;
	}
	
  ::-moz-placeholder {
    color: #b8bfd3;
  }

  :-ms-input-placeholder {
    color: #b8bfd3;
  }

  :-moz-placeholder {
    color: #b8bfd3;
  }
`;

export const UserName = styled.span`
	display: block;
  font-size: 1.5rem;
  font-weight: 600;
  
  @media (max-width: 767px) {
    font-size: 1.125rem;
  }
`;

export const LocationIcon = styled.div`
	font-size: 16px;
  color: #dd2c57;
  margin-right: 5px;
  margin-left: ${props => props.role === 'me' ? '4px' : '7px'};
  
  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

export const Upgrade = styled(btn)`
  display: flex;
  min-width: 11.25rem;
  margin-left: auto;
  box-shadow: 0 0 0.375rem 0 rgb(59, 90, 154, .5);
  align-items: center;
  justify-content: center;
  letter-spacing: .05rem;
  font-weight: 600;
  line-height: 1.5rem;
  height: 1.5rem;
  border-color: #3b5a9a;
  outline: none;
  margin: 0;
    
  &::before {
	  content: '';
		width: .625rem;
    height: 1rem;
    margin-right: .3125rem;
		background: url(${require('../../assets/icons/settings-btn-icon.svg')}) center/cover no-repeat;
  }
`;


export const BtnIcon = styled.div`
	i {
		font-size: 12px;
	  margin-right: 6px
	}
`;

export const Icon = styled.div`
	margin-right: 6px;
  font-size: 18px;
  color: #4a90e2
`;

export const ShowMore = styled.span`
  color: #b8bfd3;
  cursor: pointer;
`;

export const Btn = styled.button`
  @media (max-width: 480px) {
    min-width: 0;
    height: 28px;
  }
  
  font-size: .75rem;
  font-weight: 600;
  line-height: 1.875rem;
  display: inline-block;
  min-width: 8rem;
  height: 1.875rem;
  padding: 0 .3125rem;
  user-select: none;
  transition: .3s;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  letter-spacing: .05rem;
  text-transform: uppercase;
  color: #fff;
  border: .0625rem solid transparent;
  border-radius: .125rem;
  background-color: #3b5a9a;
  touch-action: manipulation;
	
	:focus {
		text-decoration: none;
		cursor: pointer;
		outline: 0;
		background-color: #4c6dae;
	}
	
	:hover {
		text-decoration: none;
		cursor: pointer;
		outline: 0;
		background-color: #4c6dae;
	}	
`;

export const EditProfile = styled(Btn)`
  @media (max-width: 480px) {
    width: 50%;
    padding: 0;
  }
  
  @media (max-width: 767px) {
    min-width: 0;
    margin-right: .375rem;
    padding: 0 .9375rem;
  }
  
  min-width: 9.25rem;
  margin-right: 1.25rem;
  letter-spacing: .05rem;
  font-weight: 600;
  
  line-height: 1.5rem;
  height: 1.5rem;
  color: #3b5a9a;
  border-color: #3b5a9a;
  background-color: #fff;
  outline: none;
 
	:focus {
    background-color: rgba(221, 44, 87, .1)
  }
  
  :hover { 
    background-color: rgba(221, 44, 87, .1)    
  }
`;

export const RefillCredits = styled(Btn)`
  line-height: 1.5rem;
  min-width: 9.25rem;
  height: 1.5rem;
  letter-spacing: .05rem;
  box-shadow: 0 0 0.375rem 0 rgb(59, 90, 154, .5);
  
  svg {
    margin-right: 6px;
  }
  
  @media (max-width: 480px) {
    width: 50%;
  }
    
  @media (max-width: 767px) {
    font-weight: 400;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 7px;
  
  @media (max-width: 767px) {
    justify-content: ${props => props.role === 'me' ? 'center' : 'flex-start'};
    margin-left: 0;
    
    ${EditProfile} {
      min-width: 0;
      margin-right: .375rem;
      padding: 0 .9375rem
    }

    ${RefillCredits} {
      font-weight: 400;
      min-width: 0;
    }
  }
  
  @media (max-width: 480px) {
    ${EditProfile} {
      width: 50%;
      padding: 0;
    }
    
    ${RefillCredits} {
      width: 50%;
    }
  }
`;

export const Close = styled.span`
  background: 0;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  color: #b8bfd3;
  cursor: pointer;
  
  :hover {
    color: #4b4c59;
  }
`;

export const Loader = styled.img`
  width: 35px;
  display: inline-block;
  border-radius: 15px;
  right: 0;
  left: 0;
  margin: auto;
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
`;

export const Form = styled.form`
  background-color: #fff;
  display: inline-block;
  padding: 22px 20px 0px 20px;
  border-radius: 15px;
  right: 0;
  left: 0;
  margin: auto;
`;

export const Hr = styled.hr`
	display: block;
  border: 0;
  border-top: 1px solid #e8e8e8;
  height: 1px;
`;

export const ProfileContainer = styled.div`
	position: relative;
  width: 100%;
  padding: 1.25rem;
  border-radius: 2px;
  color: #515151;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(184, 191, 211, .2);  
  max-width: 48.575rem;
  
  @media (max-width: 767px) {
    max-width: 100%;
    box-shadow: none;
    
    ${({ isUserProfile }) => isUserProfile && `
      padding-top: 5rem;
    `}
  }
`;

export const CarouselContainer = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  padding-bottom: .9375rem;
  
  @media (max-width: 767px) {
    display: flex;
    flex-flow: column;
    padding-bottom: 0;
  }
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
`;


export const About = styled.p`
  text-align: justify;
  word-break: break-word;
  margin-bottom: .75rem;
  width: 100%;
  font-size: .875rem;
  margin-left: 7px;
 
  @media (max-width: 767px) {
    min-height: initial;
    height: auto;
    margin-left: 0px;
  }
`;

export const Button = styled.button`
	font-size: .85rem;
	letter-spacing: .8px;
	font-weight: bold;
	color: #fff;
	outline: none;
	border: none;
	cursor: pointer;
	box-shadow: rgb(199, 195, 195) 0px 0px 15px 0px;
	margin: 0 5%;
	padding: 9px 15px;
  border-radius: 5px;
	background-color: ${props => props.theme ? props.theme : '#555'}
`;

export const UserInfo = styled.div`
	max-width: 25.125rem;
	cursor: default;
	
	@media (max-width: 767px) {
    max-width: 100%;
    padding-top: .625rem
  }
`;

export const UpdatePhoto = styled.span`
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  padding-left: 22px;
  position: absolute;
  left: 50%;
  bottom: 30%;
  color: #fff;
  transform: translate(-50%, -65%);
  
	&::before {
	  content: '';
	  position: absolute;
	  left: 0;
	  width: 17.5px;
	  height: 14px;
	  background: url(${require('../../assets/icons/update-avatar-icon.svg')}) center/cover no-repeat
	} 
	
	@media (max-width: 767px) {
    padding-left: 1rem;
    position: absolute;
    left: 50%;
    bottom: 76%;
	}
`;

export const Status = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
   
  @media (max-width: 767px) {
    justify-content: center;
    flex-direction: ${props => props.is_online ? 'row' : 'column'};    
    align-items: initial;
  }
`;

export const Info = styled.p`
  font-size: .875rem;
  display: flex;
  
  span {
    display: flex;
    flex-shrink: 0;
  }
  
  span:last-child {
    margin-left: 3px;
  }
`;

export const Title = styled.span`
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  display: flex;
  margin-bottom: .625rem;
  align-items: center;
  
  i {
    @media (max-width: 767px) {
      margin-left: 4px;
    }
  }
`;

export const Photo = styled.img`
	display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  
  ${({uploading}) => uploading && `
    filter: blur(2px);
  `}
`;

export const ControlsMob = styled.div`
  right: 0;
  width: 100%;
  padding: ${props => props.role === 'me' && '0 1.25rem'};
  justify-content: space-between;
  position: absolute;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    top: ${props => props.role === 'me' ? '.25rem' : '4.25rem'};
    
    span:first-child {
      position: relative;
      right: .3125rem;
      display: block;
      width: 1.5625rem;
      height: 2.1875rem;
      background: url(${require('../../assets/img/lightning-ico.svg')}) 50%/contain no-repeat;
    }
    
    a {
      font-size: 1.5em;
      color: #4b4c59;
    }
  }
  
  ${({role}) => role !== 'me' && `
    justify-content: flex-end;
    div {
      position: initial;
    }
    
    label {
      width: 4px;
      height: 4px;
      margin-right: 3px;
    }
  `}
`;

export const PersonalDetails = styled.div`
  max-width: 11.0625rem;
  margin-left: 5.375rem;
  
  @media (max-width: 767px) {
    margin-top: .9375rem;
    margin-left: 0;
  }  
`;

export const LookingFor = styled.div`
  max-width: 11.6875rem;
  margin-left: auto;
  max-width: 11.6875rem;
  min-width: 10.6875rem;
  
  @media (max-width: 767px) {
    margin-top: 1.25rem;
    margin-left: 0;
  }
`;

export const Interests = styled.div`
  max-width: 6.5625rem;
    
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const ContainerProfileWrapper = styled.div`
  display: block;
  width: 100%;
`;
