import styled from 'styled-components';

export const Placeholder = styled.div`
  width: 100%;
  font-size: .875rem;
  color: #b8bfd3;
  padding-left: .3125rem;
  text-align: center;
`;

export const Header = styled.div`
  padding: 10px 30px 10px 20px;
  border-bottom: 1px solid #dadee8;
  border-right: 1px solid #edeff4;
  display: flex;
  align-items: center;
`;

export const PaginationLoader = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	align-items: baseline;
	
	img {
		width: 15%;
	}
`;

export const Photo = styled.img`
	width: 50px;
	height: 50px;
	border-radius: 50%;
	cursor: pointer;
`;

export const Close = styled.span`
  font-size: 28px;
  position: absolute; 
  right: 2%;
  color: #ccc7c7;
  top: 2.5%;
  cursor: pointer;
`;

export const Container = styled.div`
	position: relative;
	max-width: 52.1875rem;
  width: 100%;
  border-radius: 2px;
  color: #515151;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(184, 191, 211, .2);
  display: flex;
  align-items: flex-start;
  padding: 0;
  
  @media (max-width: 767px) {
    box-shadow: none;
    font-size: .875rem;
  }
`;

export const MessangerBox = styled.div`
	max-width: 35.625rem;
  width: 100%;
  
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const Section = styled.div`
  padding: 15px 30px 15px 20px;
  min-height: 36.75rem;
  max-height: 36.75rem;
  border-right: 1px solid #edeff4;
  overflow-y: auto;
  
  ::-webkit-scrollbar { 
    display: none; 
  }
  
	@media (max-width: 767px) {
    padding: 9rem .9375rem 1.875rem;
    border-right: none;
    max-height: 100%;
    overflow: hidden;
    min-height: auto;
	}
`;

export const InputWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 9px;
  border-radius: 15px;
  position: relative;
  width: 100%;
  
  i {
    position: absolute;
    left: 15px;
  }
  
  input {
    width: 100%;
    height: 30px;
    padding: 0 10px;
    padding-left: 2rem;
    border: 0;
    background-color: transparent;
    color: #515151;
    border-radius: 15px;
  
    ::-webkit-input-placeholder {
      font-size: 14px;
      color: #b8bfd3;
    }
  
    ::-ms-input-placeholder {
      font-size: 14px;
      color: #b8bfd3;
    }
  
    ::placeholder {
      font-size: 14px;
      color: #b8bfd3;
    }
  
    :focus {
      outline: 0;
      background-color: #edeff4;
    }
  }
`;

export const ErrorButton = styled.button`
   display: ${props => props.isError ? 'block' : 'none'};
   cursor: pointer;
   outline: none;
   border: 0;
   background: transparent;
   
  img {
    width: 22px;
    cursor: pointer;
    height: 22px;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

export const OptionItemIcon = styled.div`
	margin-right: 5px;
  color: #b8bfd3;
  font-size: .875rem;
  width: .85rem;
`;

export const OptionList = styled.span`
  position: absolute;
  top: 35px;
  display: flex;
  flex-direction: column;
  min-width: 128px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(184, 191, 211, .5);
  z-index: 1;
  border-radius: 2px;
  opacity: ${props => props.open ? 1 : 0};
  visibility: ${props => props.open ? 'visible' : 'hidden'};
  transition: .3s;
  right: -16px;

	&::before {
    content: '';
    position: absolute;
	  right: 29px;
	  top: -13px;
    border: 8px solid transparent;
	  border-bottom: 5px solid #fff
	}
`;

export const Option = styled.button`
  padding: 7px 8px;
  font-size: 12px;
  text-align: left;
  display: flex;
  cursor: pointer;
  outline: none;
  border: 0;
  background: #fff;
	
	span {
		color: #4b4c59;
	}
	
	:hover {
   background-color: #edeff4;
	}
`;