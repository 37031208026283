import {createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {watcherSaga} from '../sagas';

import rootReducer from '../reducers';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(watcherSaga);

export default store;
