import React, { createRef, useState } from 'react';

import Photo from '../Photo';
import getCroppedImg from '../Photo/Crop';
import { updateProfile, uploadPhoto } from '../../../api';
import styled from 'styled-components';

const UploadAvatar = ({ updateProfileInfo }) => {
  const [photoIsOpen, togglePhoto] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  
  const photoUpload = createRef(null);
  
  const updatePhoto = event => {
    setCurrentPhoto(event.target.files[0]);
    togglePhoto(true);
  };
  
  const onClose = () => {
    togglePhoto(photoIsOpen => !photoIsOpen);
    setCurrentPhoto(null);
  };
  
  const uploadFile = (src, cropped) => {
    const form = new FormData();
    
    getCroppedImg(src, cropped).then(response => {
      form.append('file', response.file);
      uploadPhoto({ body: form })
        .then(response => {
          updateProfile({ body: { photo_id: response.data.id } })
            .then(response => {
              updateProfileInfo({ photo: response.data.photo });
            });
        });
      
      onClose();
    });
  };
  
  return (
    <>
      <div>
        <InputFile type='file' name='file' id='file' onChange={updatePhoto} ref={photoUpload}/>
        <label htmlFor='file' className="collapse-header-item__link">Start</label>
      </div>
      {
        photoIsOpen && (
          <Photo
            isOpen={photoIsOpen}
            photo={currentPhoto}
            onClose={onClose}
            uploadFile={uploadFile}
          />
        )
      }
    </>
  );
};

const InputFile = styled.input`
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;

export default UploadAvatar;