import {connect} from 'react-redux';

import {updateProfileInfo, getCardsList, deleteCard} from '../actions';
import SettingsComponent from '../components/Settings';

const mapStateToProps = state => ({
  myProfile: state.profile,
  selects: state.selects,
  cards: state.cards
});

const mapDispatchToProps = {
  updateProfileInfo,
  getCardsList,
  deleteCard
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsComponent);
