import { connect } from 'react-redux';

import ChatComponent from '../components/Chat';
import { fetchChatHistory, getInitialMessagesCount, updateContactsInfo, getChatUserProfile } from '../actions';

const mapStateToProps = ({ messages, profile, onlineUsers, contacts }) => ({
  messages,
  profile,
  onlineUsers,
  error: messages.error,
  sent: messages.sent,
  contacts
});

const mapDispatchToProps = {
  fetchChatHistory,
  updateContactsInfo,
  getInitialMessagesCount,
  getChatUserProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatComponent);