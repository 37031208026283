import React, { useEffect, useState } from 'react';
import { Modal, ModalContent } from '../Modal';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';

const WelcomeModal = ({ profile }) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  
  useEffect(() => {
    if (history.location.search.includes('welcome') && Object.keys(profile).length > 2) {
      setShow(true);
    }
  }, [history, profile]);
  
  const handleClose = () => {
    setShow(false);
    history.push(history.location.pathname);
  };
  
  return (
    <Modal isOpen={show}>
      <ModalContent>
        <div className="modal-wrapper modal-wrapper--welcome">
          <div className="modal-title">
            Welcome to DateJasmin!
          </div>
          <div className="modal-description">
            Hello!
          </div>
          <div className="modal-description">
            DateJasmin team is glad you’ve joined our dating platform.
          </div>
          <div className="modal-list-wrapper">
            <div className="modal-list-title">
              Let’s see what you can do for free:
            </div>
            <ul className="modal-list">
              <li className="modal-list-item">
                <div className="modal-list-item-icon"/>
                view girls’ profiles and common photo albums. Admire the profiles of Slavic beauties and read about
                their hobbies
              </li>
              <li className="modal-list-item">
                <div className="modal-list-item-icon"/>
                spend your 10 bonus credits by chatting and find common topics for conversation
              </li>
              <li className="modal-list-item">
                <div className="modal-list-item-icon"/>
                win 20 more free credits by email confirming and profile fulfilling!
              </li>
            </ul>
          </div>
          <div className="modal-button-wrapper">
            <button className="modal-button modal-button--cancel" onClick={handleClose}>Cancel</button>
            <button className="modal-button modal-button--thank" onClick={handleClose}>Thank you!</button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = ({ profile }) => ({ profile });

export default connect(mapStateToProps)(WelcomeModal);