// TODO: Validate card number with 10-14 symbols

const validate = values => {
  const errors = {};

  if (!values.pan) {
    errors.pan = 'Please enter a credit card number';
  } else if (values.pan.length < 14) {
    errors.pan = 'Card number must contains at least 14 digits.';
  }

  if (!values.cvv) {
    errors.cvv = 'Please enter a cvv';
  } else if (values.cvv.length < 3) {
    errors.cvv = 'Your cvv code have to contain 3 numeral';
  }

  if (!values.expiration_month) {
    errors.expiration_month = 'Please enter a month of end';
  }

  if (!values.expiration_year) {
    errors.expiration_year = 'Please enter a year of end';
  }

  if (!values.holder) {
    errors.holder = 'Please enter a holder';
  }

  return errors;
};

export default validate;

