import styled from 'styled-components';

import {btn} from '../styles';
import {ModalContent} from '../Modal';

export const Require = styled.span`
  position: relative;
  padding-left: .25rem !important;
  top: 0;
  right: 0;
  color: red;
`;

export const TitleWrap = styled.div`
  flex-direction: row !important;
`;

export const Span = styled.span`
  font-weight: 600;
  
  @media (max-width: 480px) {
    margin-bottom: .1875rem;
  }
     
  ::selection {
   background: transparent;
  }
  ::-moz-selection {
    background: transparent;
  }
`;

export const Input = styled.input`
  max-width: 256px;
  border: ${props => props.error ? '1px solid red' : '1px solid #dadee8'};
  background-color: #fff;
  border-radius: 2px;
  padding-left: 14px;
	height: 30px;
	padding: 0 10px;
	color: #515151;
	width: 65%;
	
	::-webkit-input-placeholder {
	  font-size: 14px;
	  color: #b8bfd3
	}
	
	::-ms-input-placeholder {
	  font-size: 14px;
	  color: #b8bfd3
	}
	
	::placeholder {
	  font-size: 14px;
	  color: #b8bfd3
	}
	
	:focus {
	  outline: 0
	}
	
	@media (max-width: 480px) {
    max-width: 90%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  
  div {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

	:nth-of-type(3) {
    margin-bottom: 26px;
	}
	
	@media (max-width: 480px) {
	  margin-bottom: 5px;
	
	  div {
      margin-bottom: .9375rem;
      flex-flow: column;
      align-items: flex-start;
    }

    :nth-of-type(3) {
      margin-bottom: 0;
	  }
  }
`;

export const Error = styled.span`
  color: red;
	text-align: left;
	font-size: 11px;
	margin-top: 3px;
	padding-left: 25%;
`;

export const Title = styled.b`
 font-size: 1.5rem;
 font-weight: 600;
 display: block;
 margin-bottom: 1.75rem;

 @media (max-width: 480px) {
  font-size: 1.125rem;
 }
  
  @media (max-width: 767px) {
    margin-bottom: .9375rem;
  }
`;

export const Info = styled.h2`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: #555;
  margin: 25px 0;
`;

export const Button = styled(btn)`
  line-height: 1.875rem;
  min-width: 8rem;
  height: 1.875rem;
  
  @media (max-width: 480px) {
    width: calc(48%);
    margin: 0;
    min-width: 0;
    letter-spacing: .05rem;
  }
  
  ${({close, skip}) => (close || skip) && `
    margin-left: auto;
    margin-right: 20px;
    background-color: #fff;    
    color: #b8bfd3;
    border-color: #b8bfd3;

		:focus {
	    background-color: rgba(221, 44, 87, .1);
    }
    
    :hover {
      background-color: rgba(221, 44, 87, .1);
    }
    
    @media (max-width: 480px) {
      margin: 0;
      min-width: 0;
    }
    
    @media (max-width: 319px) {
      min-width: 0;
    }
  `}
  
  ${({ skip }) => skip && `
    margin-left: 0;
  `}
`;

export const LookingPopup = styled.div`
	display: flex;
  flex-wrap: wrap;
  
  @media (max-width: 767px) {
    justify-content: space-between;
  }
`;

export const Form = styled(ModalContent)`
  max-width: 355px;
  padding: 20px 75px 40px 20px;
  
  @media (max-width: 767px) {
    padding: 15px 15px 0 15px;
    max-width: 100%;
    width: initial;
  }
`;

export const FieldContainer = styled.div`
  @media (max-width: 767px) {
    border-bottom: .0625rem solid #dadee8;
    margin-bottom: 0;
    height: 2.1875rem;
    
    div {
      border: none;
    }
  }
  
  @media (max-width: 767px) {
    justify-content: space-between;
  }
  
  input {
    color: #515151;
    position: relative;
    display: inline-block;
    padding: .3125rem;
    border: .0625rem solid #dadee8;
    border-radius: .125rem;
    background-color: #fff;
    cursor: pointer;
    width: 10rem;
    min-height: 2.8335rem;
    outline: none;
    
    &:focus {
      border: .0625rem solid #b8bfd3;
    }
  }
  
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
`;

export const FormContainer = styled(ModalContent)` 
  max-width: 600px;
  
  form {
    margin-top: 0;
  }
`;

export const Skip = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  padding: 0;
  cursor: pointer;
  transition: opacity .33s cubic-bezier(.25, .46, .45, .94);
  z-index: 1;
  background: 0;
  border: 0;
  color: #b8bfd3;
  
  :hover {
    color: #4b4c59;
  }
`;

export const MessageWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  @media (max-width: 767px) {
    justify-content: space-between;
  }
`;

export const Options = styled(MessageWrap)`
  justify-content: space-between;
	
	@media (max-width: 767px) {
    flex-flow: column;
    border-top: .0625rem solid #dadee8;
    margin: 0;
    width: 100%;
    margin-bottom: 2rem;
  }
`;

export const PrevStep = styled.span`
	font-size: 1.5rem;
  background-color: #e8e8e896;
  position: absolute;
  top: 5%;
  left: 3%;
  cursor: pointer;
  border-radius: 50%;
  padding: 4px 10px;
  
  ::selection {
   background: transparent;
  }
  
  ::-moz-selection {
   background: transparent;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Textarea = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  
  span {
    float: right;
    color: rgb(184, 191, 211);
  }
  
  textarea {
    @media (max-width: 480px) {
      height: 11.5625rem;
    }
    
    width: 100%;
    height: 7.0625rem;
    padding: .3125rem;
    resize: none;
    transition: .3s;
    color: #4b4c59;
    border: .0625rem solid #dadee8;
    overflow: auto;
    
    :focus {
      outline: none;
    } 
  }
`;

export const AboutContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  @media (max-width: 480px) {
    justify-content: space-between;
  }
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  height: 39px;
`;

export const TabsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 12px;
  flex-grow: 1;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: #fff;
  background-color: #dadee8;
  font-weight: 600;

  ${({active}) => active && `
    font-weight: 600;
    color: #3b5a9a;
    background-color: #fff
  `}
`;
