import React from 'react';
import styled from 'styled-components';
import {Field} from 'redux-form';

import * as Styles from './styles';
import Select from '../SearchBar/Select';

const Location = ({options, isRequire}) => {
  let selects = options || [];

  if (!Array.isArray(selects)) {
    selects = (
      Object
        .keys(options)
        .reduce((result, option) => {
          return result.concat({
            value: option,
            label: options[option].text.replace(/catalog\.country\./gi, '')
          });
        }, [])
        .sort((a, b) => (a.value < b.value) ? -1 : (a.value > b.value) ? 1 : 0)
    );
  }
  return (
    <Wrapper>
      <Styles.Span style={{flexShrink: 0}}>
        Your Location
        {isRequire && (<Styles.Require>*</Styles.Require>)}
      </Styles.Span>
      <LocationContainer>
        <div style={{width: '50%', marginRight: '2rem'}}>
          <Field
            name="country"
            placeholder={'Country'}
            component={Select}
            options={selects}
          />
        </div>
        <Input
          as={Field}
          name="city"
          width="18%"
          placeholder="city"
          component="input"
          type="text"
        />
      </LocationContainer>
    </Wrapper>
  );
};

export default Location;

const Input = styled(Styles.Input)`
	width: 40%;
	margin-right: 12px;
`;

const LocationContainer = styled.div`
	justify-content: start !important;
	width: 65%;
	display: flex;
	align-items: center;
	
	@media (max-width: 480px) {
	  flex-flow: row !important;
	  margin-left: 0;
	}
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;

	:nth-of-type(3) {
    margin-bottom: 26px;
	}
	
	@media (max-width: 480px) {
	  ${Styles.Span} {
      margin-bottom: .9375rem;	  
	  }
	  
    flex-flow: column;
    align-items: flex-start;

    :nth-of-type(4) {
      margin-bottom: 0;
	  }
  }
`;