import React from 'react';
import styled from 'styled-components';

import Select from '../Select';

const normalize = val => typeof val === 'string' ? val.charAt(0).toUpperCase() + val.slice(1).replace(/_/, ' ').toLowerCase() : val;

const Selects = props =>
  <FieldContainer>
    {
      props.label && (
        <Span>
          {normalize(props.label)}
        </Span>
      )
    }
    <Select {...props} />
  </FieldContainer>;

export default Selects;

const Span = styled.span`
  font-weight: 600;
  margin-right: 5px;
`;

const FieldContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
 
  @media (max-width: 767px) {
    width: 100%;
    border-top: .0625rem solid #dadee8;
    border-bottom: .0625rem solid #dadee8;
    justify-content: space-between;
    div {
      border: none;
    }
  }
`;