import React, { useState } from 'react';
import styled from 'styled-components';

import { btn } from '../styles';
import { addComment } from '../../api';
import { isMobile } from '../../utils/mobile';

const Notes = ({ onClose, id, initialTextValue, updateContactsInfo }) => {
  const [text, setText] = useState(initialTextValue || '');
  
  const handleChange = e => setText(e.target.value);
  
  const handleSave = () => {
    if (text) {
      addComment({ body: { text }, id })
        .then(response => {
          if (response) {
            updateContactsInfo(id, response);
            onClose();
          }
        });
      
    } else {
      onClose();
    }
  };
  
  return (
    <NotesPopupWrapper>
      <Close onClick={onClose}>
        <i className='fal fa-times'/>
      </Close>
      <div className="notes_title">Note</div>
      <div className="text_area_wrapper">
        <textarea
          cols={isMobile() ? 30 : 40}
          rows="14"
          onChange={handleChange}
          value={text}
        />
      </div>
      <div className="button_wrapper">
        <Button onClick={handleSave} type="button">save</Button>
      </div>
    </NotesPopupWrapper>
  );
};

export default Notes;

const Button = styled(btn)`
  min-width: 100px;
  margin-top: 10px;
  margin-left: 0;
  line-height: initial;
  height: 23px;
  letter-spacing: 1.5px;
`;

const NotesPopupWrapper = styled.div`
  position: absolute;
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 rgba(75, 76, 89, 0.5);
  z-index: 10;
  top: 0;
  left: -20rem;
  background: #fff;
  padding: 18px;
  
  @media screen and (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
    top: initial;
  }
  
  textarea {
    color: #b8bfd3;
    border-radius: 2px;
    border: solid 1px #b8bfd3;
    resize: none;
    outline: none;
    padding: 5px;
  }
  
  .notes_title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #515151;
  }
  
  .button_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const Close = styled.span`
  background: 0;
  padding: 0;
  border: 0;
  position: absolute;
  right: 5px;
  top: 5px;
  
  i {
    font-size: 1.5rem;
    color: #b8bfd3;
    cursor: pointer;
    
    :hover {
      color: #4b4c59;
    }
  }
  
`;
