import React from 'react';
import Coins from '../../assets/svg/coins';
import { CreditsSubTitle, ListAbout, ListIcon, ListItem, ListMessage, ListWrapper, Svg } from '../Credits/styles';

export default function Prices() {
  return (
    <>
      <CreditsSubTitle>
        Credits are used to send letters, smiles and view private albums.
      </CreditsSubTitle>
      
      <ListWrapper>
        <ListItem>
          <ListMessage>Chat message</ListMessage>
          <ListIcon>
            1
            <Coins Svg={Svg}/>
          </ListIcon>
          <ListAbout>per 70 symbols</ListAbout>
        </ListItem>
        
        <ListItem>
          <ListMessage>Mail</ListMessage>
          <ListIcon>
            10
            <Coins Svg={Svg}/>
          </ListIcon>
          <ListAbout>per 1 letter</ListAbout>
        </ListItem>
        
        <ListItem>
          <ListMessage>Private album</ListMessage>
          <ListIcon>
            30
            <Coins Svg={Svg}/>
          </ListIcon>
          <ListAbout>Once per album
          </ListAbout>
        </ListItem>
      </ListWrapper>
    </>
  );
};