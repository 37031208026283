import React from 'react';

import {Modal} from '../../Modal';
import LettersBody from '../../Mailbox/LettersBody';

const Email = props =>
  <Modal isOpen={props.isOpen} styles={customStyles}>
    <LettersBody {...props}/>
  </Modal>;

export default Email;

const customStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    maxHeight: '100vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '100%',
    border: 0,
    width: '100%',
    padding: 0,
    marginRight: '-50%',
    overflow: 'inherit',
    transform: 'translate(-50%, -50%)',
    background: 'transparent'
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(75, 76, 89, 0.5)'
  }
};
