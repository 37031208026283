import React from 'react';

import Header from './Header';
import DetailInfo from './DetailInfo';
import SimilarProfiles from './SimilarProfiles';

import SendEmail from '../SendEmail';
import RefillCredits from '../Credits';
import withLoader from '../HOC/withLoader';

import Albums from '../../containers/Albums';
import Subscription from '../../containers/Subscription';

import * as Styles from './styles';

const CurrentProfile = props =>
  <Styles.ContainerProfileWrapper>
    <Styles.ProfileContainer isUserProfile={props.isUserProfile}>
      <Header {...props} />
      <Albums {...props} slidesToShow={6}/>
      <DetailInfo {...props} />
      {
        props.emailFormIsOpen && (
          <SendEmail
            onClose={props.toggleEmailForm}
            profile={props.profileData}
            isOpen={props.emailFormIsOpen}
          />
        )
      }
      {props.subscriptionPanelIsOpen && (<Subscription onClose={props.toggleSubscriptionPanel}/>)}
      {props.refillCreditsPanelIsOpen && (<RefillCredits onClose={props.toggleRefillCreditsPanel}/>)}
    </Styles.ProfileContainer>

    <SimilarProfiles role={props.role} />
  </Styles.ContainerProfileWrapper>;

export default withLoader(CurrentProfile);
