export const FETCH_MORE_MESSAGES_REQUEST = 'FETCH_MORE_MESSAGES_REQUEST';
export const FETCH_MORE_MESSAGES_SUCCESS = 'FETCH_MORE_MESSAGES_SUCCESS';
export const FETCH_MORE_MESSAGES_FAILURE = 'FETCH_MORE_MESSAGES_FAILURE';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export const ADD_MESSAGE_FAILURE = 'ADD_MESSAGE_FAILURE';

export const TYPING_REQUEST = 'TYPING_REQUEST';
export const TYPING_START = 'TYPING_START';
export const TYPING_END = 'TYPING_END';

export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const FETCH_CATALOG_REQUEST = 'FETCH_CATALOG_REQUEST';
export const FETCH_CATALOG_SUCCESS = 'FETCH_CATALOG_SUCCESS';
export const FETCH_CATALOG_FAILURE = 'FETCH_CATALOG_FAILURE';

export const FETCH_MORE_PROFILES_REQUEST = 'FETCH_MORE_PROFILES_REQUEST';
export const FETCH_MORE_PROFILES_SUCCESS = 'FETCH_MORE_PROFILES_SUCCESS';
export const FETCH_MORE_PROFILES_FAILURE = 'FETCH_MORE_PROFILES_FAILURE';

export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';

export const FETCH_CHAT_HISTORY_REQUEST = 'FETCH_CHAT_HISTORY_REQUEST';
export const FETCH_CHAT_HISTORY_FAILURE = 'FETCH_CHAT_HISTORY_FAILURE';
export const FETCH_CHAT_HISTORY_SUCCESS = 'FETCH_CHAT_HISTORY_SUCCESS';

export const FETCH_WIZARD_OPTIONS_REQUEST = 'FETCH_WIZARD_OPTIONS_REQUEST';
export const FETCH_WIZARD_OPTIONS_SUCCESS = 'FETCH_WIZARD_OPTIONS_SUCCESS';
export const FETCH_WIZARD_OPTIONS_FAILURE = 'FETCH_WIZARD_OPTIONS_FAILURE';

export const FETCH_ALBUMS_REQUEST = 'FETCH_ALBUMS_REQUEST';
export const FETCH_ALBUMS_SUCCESS = 'FETCH_ALBUMS_SUCCESS';
export const FETCH_ALBUMS_FAILURE = 'FETCH_ALBUMS_FAILURE';

export const FETCH_ALBUM_REQUEST = 'FETCH_ALBUM_REQUEST';
export const FETCH_ALBUM_SUCCESS = 'FETCH_ALBUM_SUCCESS';
export const FETCH_ALBUM_FAILURE = 'FETCH_ALBUM_FAILURE';

export const FETCH_MORE_ALBUMS_REQUEST = 'FETCH_MORE_ALBUMS_REQUEST';
export const FETCH_MORE_ALBUMS_SUCCESS = 'FETCH_MORE_ALBUMS_SUCCESS';
export const FETCH_MORE_ALBUMS_FAILURE = 'FETCH_MORE_ALBUMS_FAILURE';

export const ADD_ALBUM = 'ADD_ALBUM';
export const DELETE_ALBUM = 'DELETE_ALBUM';
export const REMOVE_PHOTO = 'REMOVE_PHOTO';

export const UPDATE_PHOTOS_REQUEST = 'UPDATE_PHOTOS_REQUEST';
export const UPDATE_PHOTOS_SUCCESS = 'UPDATE_PHOTOS_SUCCESS';
export const UPDATE_PHOTOS_FAILURE = 'UPDATE_PHOTOS_FAILURE';

export const FETCH_MAIL_HISTORY_REQUEST = 'FETCH_MAIL_HISTORY_REQUEST';
export const FETCH_MAIL_HISTORY_SUCCESS = 'FETCH_MAIL_HISTORY_SUCCESS';
export const FETCH_MAIL_HISTORY_FAILURE = 'FETCH_MAIL_HISTORY_FAILURE';

export const FETCH_MORE_MAILS_REQUEST = 'FETCH_MORE_MAILS_REQUEST';
export const FETCH_MORE_MAILS_SUCCESS = 'FETCH_MORE_MAILS_SUCCESS';
export const FETCH_MORE_MAILS_FAILURE = 'FETCH_MORE_MAILS_FAILURE';

export const READ_LETTER = 'READ_LETTER';
export const REMOVE_LETTER = 'REMOVE_LETTER';

export const GET_INITIAL_COUNT = 'GET_INITIAL_COUNT';
export const INCREMENT_INBOX_VALUE = 'INCREMENT_INBOX_VALUE';
export const DECREMENT_INBOX_VALUE = 'DECREMENT_INBOX_VALUE';

export const FETCH_CONTACTS_REQUEST = 'FETCH_CONTACTS_REQUEST';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_FAILURE = 'FETCH_CONTACTS_FAILURE';

export const LIKE_PHOTO = 'LIKE_PHOTO';

export const ADD_TO_FAVORITE_LIST = 'ADD_TO_FAVORITE_LIST';
export const DELETE_FROM_FAVORITE_LIST = 'DELETE_FROM_FAVORITE_LIST';
export const MARK_MESSAGES_AS_READ = 'MARK_MESSAGES_AS_READ';

export const CREATE_NEW_CONTACT = 'CREATE_NEW_CONTACT';
export const UPDATE_LAST_MESSAGE = 'UPDATE_LAST_MESSAGE';
export const UPDATE_PROFILE_INFO = 'UPDATE_PROFILE_INFO';

export const UPDATE_ALBUM = 'UPDATE_ALBUM';
export const UPDATE_CONTACTS_INFO = 'UPDATE_CONTACTS_INFO';

export const INIT_ONLINE_LIST = 'TYPES.INIT_ONLINE_LIST';

export const REMOVE_USER_FROM_ONLINE_LIST = 'REMOVE_USER_FROM_ONLINE_LIST';
export const ADD_USER_TO_ONLINE_LIST = 'ADD_USER_TO_ONLINE_LIST';

export const GET_INITIAL_MESSAGES_COUNT = 'GET_INITIAL_MESSAGES_COUNT';
export const INCREMENT_MESSAGES_VALUE = 'INCREMENT_MESSAGES_VALUE';

export const GET_CARDS_LIST = 'GET_CARDS_LIST';
export const ADD_NEW_CARD = 'ADD_NEW_CARD';
export const DELETE_CARD = 'DELETE_CARD';

export const RESEND_VERIFICATION_EMAIL_REQUEST = 'RESEND_VERIFICATION_EMAIL_REQUEST';
export const RESEND_VERIFICATION_EMAIL_SUCCESS = 'RESEND_VERIFICATION_EMAIL_SUCCESS';
export const RESEND_VERIFICATION_EMAIL_FAILURE = 'RESEND_VERIFICATION_EMAIL_FAILURE';

export const CLOSE_VERIFY_MESSAGE_MODAL = 'CLOSE_VERIFY_MESSAGE_MODAL';

export const GET_SIMILAR_PROFILES_REQUEST = 'GET_SIMILAR_PROFILES_REQUEST';
export const GET_SIMILAR_PROFILES_SUCCESS = 'GET_SIMILAR_PROFILES_SUCCESS';
export const GET_SIMILAR_PROFILES_FAILURE = 'GET_SIMILAR_PROFILES_FAILURE';

export const INIT_ERROR_MESSAGES = 'INIT_ERROR_MESSAGES';
export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';
export const REMOVE_ERROR_MESSAGE = 'REMOVE_ERROR_MESSAGE';

export const INIT_ERROR_MAILS = 'INIT_ERROR_MAILS';
export const ADD_ERROR_MAIL = 'ADD_ERROR_MAIL';
export const REMOVE_ERROR_MAIL = 'REMOVE_ERROR_MAIL';
export const SEND_LETTER = 'SEND_LETTER';
export const INIT_CHAT_USER_PROFILE = 'INIT_CHAT_USER_PROFILE';