import React, { Component, createRef } from 'react';

class renderField extends Component {

  input = createRef();

  state = {
    value: ''
  };

  onChange = e => {
    const pattern = /.{3,}/;

    this.props.input.onChange(e);

    if (!pattern.test(e.target.value) && this.props.meta.error) {
      e.target.setCustomValidity(this.props.meta.error);
    } else {
      e.target.setCustomValidity('');
    }

    this.setState({value: e.target.value});
  };

  componentWillReceiveProps(nextProps, nextContext) {
    this.input.current.setCustomValidity(nextProps.meta.error || '');
  };

  render() {
    const {input, meta: {touched, error}} = this.props;

    return (
      <input
        {...input}
        ref={this.input}
        onChange={this.onChange}
        pattern=".{3,}"
        autoComplete="off"
        value={this.state.value}
        type="text"
        style={{border: touched && error && '1px solid red'}}
        required
      />
    );
  };
}

export default renderField;