import React from 'react';

import {loader} from '../../assets';
import * as Styles from '../styles';

export default Component => ({loading, ...props}) => {
  if (loading) {
    return <Styles.Loader src={loader} alt=''/>;
  }
  return <Component {...props} />;
};

