import React, {useState} from 'react';
import styled from 'styled-components';
import {reduxForm, Field, reset} from 'redux-form';
import {compose} from 'redux';

import {btn} from '../styles';
import DragAndDrop from '../DragAndDrop';
import {Modal, ModalContent} from '../Modal';
import {isMobile} from '../../utils/mobile';
import withModalClose from '../HOC/withModalClose';

const AddPhoto = ({isOpen, onClose, handleSubmit, innerRef, formState}) => {
  const [error, setError] = useState('');
  const [maxPhotosLength] = useState(50);

  const handleStatus = (status, message) => setError(status === 'error' ? message : '');

  const handleClose = () => {
    setError('');
    onClose();
  };

  const photos = formState && formState.album && formState.album.values && formState.album.values.photos;

  return (
    <Modal isOpen={isOpen}>
      <AlbumContainer as="form" onSubmit={handleSubmit} ref={innerRef}>
        <Title>Add photos</Title>
        <Text>Drag and drop your file here</Text>
        <UploadedInfo>{photos && !!photos.length && (photos.length + '/' + maxPhotosLength + ' uploaded')}</UploadedInfo>
        <PhotoUploadWrap>
          <Field
            name="photos"
            handleStatus={handleStatus}
            component={DragAndDrop}
            maxFiles={maxPhotosLength}
          />
          <FooterWrapper>
            {
              error && !isMobile() && (
                <Error>
                  <i className="fas fa-exclamation-triangle"/>
                  <span>{error}</span>
                </Error>
              )
            }
            <ButtonContainer error={error}>
              <Button as='button' close={onClose} onClick={handleClose}>CANCEL</Button>
              <Button as='button' type='submit'>SAVE CHANGES</Button>
            </ButtonContainer>
          </FooterWrapper>
        </PhotoUploadWrap>
      </AlbumContainer>
    </Modal>
  );
};

const afterSubmit = (result, dispatch) => dispatch(reset('photos'));

const enhance = compose(withModalClose, reduxForm({form: 'album', onSubmitSuccess: afterSubmit}));

export default enhance(AddPhoto);

const UploadedInfo = styled.div`
  margin-bottom: 10px;
  color: #dd2c57;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Error = styled.div`
  display: flex;
  align-items: center;
  color: #ff0000;
  
  i {
    font-size: 1.5rem;
    margin-right: .5rem;
  }
  
  span {
    width: 8rem;
    font-weight: bold;
    font-size: .75rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${props => props.error ? 'auto' : '100%'};
  
  @media (max-width: 767px) {
    width: 100%;
    button {
      min-width: 50%;
    }
  }
`;

const Button = styled(btn)`
	min-width: 128px;
  line-height: 30px;
  height: 30px;

	${({close}) => !close ? 'width: 170px;' : `
		margin-left: auto;
    margin-right: 10px;
    color: #b8bfd3;
    border-color: #b8bfd3;
    background-color: #fff;
    
    :focus {
      background-color: rgba(221, 44, 87, .1);
		}
	
		:hover {
      background-color: rgba(221, 44, 87, .1);		
		}
	`}
`;

const PhotoUploadWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Text = styled.span`
	display: block;
  margin-bottom: 10px;
`;

const AlbumContainer = styled(ModalContent)`
  max-width: 540px;
  padding: 20px;
  height: auto !important;
  
  @media (max-width: 767px) {
    padding-bottom: 58px;  
  }
`;

const Title = styled.b`
	display: block;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 28px;
`;
