import React from 'react';
import Coins from '../../../assets/svg/coins';
import { Svg } from '../../Credits/styles';
import { currencySymbols } from '../../../constants';
// import * as Styles from '../styles';

// const Hint = ({ credits, credits_per_day, days, isTrialAvailable, trial_credits, trial_days }) => {
//   return (
//     <Styles.Hint>
//       <i className="fas fa-question-circle"/>
//       <span>
//         {
//           `You will get full access to the platform for ${days} days.
//           Also, you will get ${credits} credits immediately
//           ${!credits_per_day ? '.' : (' and ' + credits_per_day + ' credits every day during the subscription term.')}
//           ${isTrialAvailable ? ('Available ' + trial_credits + ' credits for ' + trial_days + ' day(s) for a trial period') : ''}`
//         }
//       </span>
//     </Styles.Hint>
//   );
// };

export default function Plan({ credits, name, price, credits_per_day, days, currency }) {
  const totalCredits = credits + credits_per_day * days;
  
  return (
    <div className="subscribe-plan">
      <div className="subscribe-plan-title">{name}</div>
      <div className="subscribe-plan-price">
        {price} {currencySymbols[currency] || currency}
      </div>
      <div className="subscribe-plan-description">
        <p>{credits} Credits at once</p>
        {Boolean(credits_per_day) && (<p>+{credits_per_day} Bonus each day</p>)}
        <p>Total {totalCredits} <Coins Svg={Svg}/> for {days} days</p>
      </div>
    </div>
  );
}