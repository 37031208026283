import React, { useState, } from 'react';

import * as Styles from '../styles';
import { loader } from '../../../assets';

const RenderField = ({ fieldKey, placeholder, request, validate, isEdit }) => {
  const [edit, setEdit] = useState(isEdit);
  const [fetching, setFetching] = useState(false);
  const [value, setValue] = useState('');
  
  const onOpenEdit = () => setEdit(true);
  
  const onCloseEdit = () => setEdit(false);
  
  const onChange = e => setValue(e.target.value);
  
  const onSubmit = () => {
    if (!validate({ [fieldKey]: value })) {
      setFetching(true);
      
      request(fieldKey, value)
        .then(response => {
          setFetching(false);
          
          if (!response.errors) {
            setValue('');
            onCloseEdit();
          }
        });
    }
  };
  
  if (edit) {
    return (
      <Styles.FieldEmail>
        <span>{fieldKey.charAt(0).toUpperCase() + fieldKey.slice(1)}</span>
        <input type={fieldKey} value={value} onChange={onChange}/>
        <div className="account-settings__btn-wrapper">
          {<button onClick={onCloseEdit}>CANCEL</button>}
          {fetching ? (<img src={loader} alt=""/>) : (<button onClick={onSubmit}>SAVE</button>)}
        </div>
      </Styles.FieldEmail>
    );
  }
  
  return (
    <Styles.FieldEmail>
      <span>{fieldKey.charAt(0).toUpperCase() + fieldKey.slice(1)}</span>
      <span>{placeholder}</span>
      <button onClick={onOpenEdit}><i className="far fa-pen"/></button>
    </Styles.FieldEmail>
  );
};

export default RenderField;