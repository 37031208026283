import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Cookies from 'universal-cookie';

import { Event } from '../../Tracking';

import { NavLink } from '../Link';
import UserBox from './UserBox';
import SearchBar from '../../containers/SearchBar';
import Subscription from '../../containers/Subscription';
import RefilCredits from '../Credits';
import { Logo, HeaderContainer, HeaderSearchSettings, SearchIcon, Wrapper } from './styles';
import * as api from '../../api';
import SendEmail from '../SendEmail';
import { isOrderId } from '../../utils/isOrderId';
import StatusCode from '../StatusCode';
import { getAllUrlParams } from '../../utils/getUrlParams';
import { eventTransaction } from '../../utils/googleAnalyticEventSubscribe';
import BonusesModal from '../modals/bonuses';
import WelcomeModal from '../modals/welcome';

const Header = ({ profile, history, updateProfileInfo, initOnlineList, addUserToOnlineList, removeUserFromOnlineList }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [subscriptionPanelIsOpen, toggleSubscriptionPanel] = useState(false);
  const [refillCreditsPanelIsOpen, toggleCreditsPanel] = useState(false);
  const [letterFormIsOpen, toggleLetterForm] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState('');
  
  useEffect(() => {
    const paths = ['/messages', '/catalog', '/mailbox', '/contacts'];
  
    if (window.innerWidth < 768) {
      setTimeout(() => {
        const launcher = document.getElementById('launcher');
    
        if (launcher) {
          if (paths.some(route => history.location.pathname.includes(route))) {
            launcher.style.display = 'none';
        
          } else {
            launcher.style.display = 'block';
            launcher.style.width = 'inherit';
          }
        }
      }, 1500);
    }
  });
  
  useEffect(() => {
    const cookies = new Cookies();
    
    const params = getAllUrlParams(window.location.href);
    
    if (window.location.search && params.utm_source && !cookies.get('click_id')) {
      api.marketingClicks({
        params,
        referrer: document.referrer
      })
        .then(response => {
          if (response && response.data) {
            const date = new Date();
            date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
            
            cookies.set('click_id', response.data.id, {
              path: '/',
              expires: date
            });
          }
        });
    }
  }, [history.location.search]);
  
  useEffect(() => {
    const modal = localStorage.getItem('modal');
    
    if (isOrderId()) {
      const orderId = history.location.search.replace('?order_id=', '');
      
      api.checkOrderWithId(orderId)
        .then(response => {
          if (!response.status) {
            if (response.data.error_message) {
              setMessage(response.data.error_message);
            }
          }
          
          const order = response.data;
          
          if (order.status === 'SUCCESS') {
            window.dataLayer.push({
              'event': 'date',
              'eventCategory': 'send',
              'eventAction': 'form',
              'eventLabel': 'add_card'
            });
          }
          
          if (response && order.status === 'SUCCESS' && localStorage.getItem('plan')) {
            api.getCardsList()
              .then(response => {
                if (response && Array.isArray(response) && response.length === 1 && response[0].order_id === order.id) {
                  const subscriptionPlan = JSON.parse(localStorage.getItem('plan'));
                  
                  api.subscribe({
                    body: {
                      plan_id: subscriptionPlan.id,
                      is_auto_renew: Boolean(localStorage.getItem('autoRenew')),
                      card_id: response[0].id
                    }
                  })
                    .then(response => {
                      if (response.data) {
                        const { id, name, price } = subscriptionPlan;
                        
                        localStorage.removeItem('modal');
                        localStorage.removeItem('plan');
                        
                        eventTransaction({ id, name, price, category: 'subscribe' });
                        updateProfileInfo({ last_subscription: response.data });
                        setSuccess(true);
                        
                      } else if (response.status === 422) {
                        return response.json();
                      }
                    })
                    .then(response => {
                      if (response && response.errors) {
                        setMessage(Object.keys(response.errors).map(error => response.errors[error][0])[0]);
                      }
                    });
                  
                } else {
                  openModalIfNeed(modal);
                }
              });
            
          } else {
            openModalIfNeed(modal);
          }
        });
      
    } else {
      openModalIfNeed(modal);
    }
    
    if (localStorage.getItem('letter')) {
      toggleLetterPanel();
    }
    
  }, []);
  
  const isFiltersActive = () => {
    if (localStorage.getItem('query') && profile.search) {
      if (Object.keys(JSON.parse(localStorage.getItem('query'))).length !== 0) {
        return true;
      }
    }
    
    return false;
  };
  
  const openModalIfNeed = modal => {
    switch (modal) {
    case 'subscription': {
      toggleSubscriptionForm();
      break;
    }
    
    case 'credits': {
      toggleCreditsPlans();
      break;
    }
    
    default:
      break;
    }
  };
  
  const toggleLetterPanel = () => toggleLetterForm(letterFormIsOpen => !letterFormIsOpen);
  
  const toggleSearchBar = () => setIsOpen(isOpen => !isOpen);

  const toggleSubscriptionForm = () => {
    Event('userclicks', 'button', 'upgrade');
    toggleSubscriptionPanel(subscriptionPanelIsOpen => !subscriptionPanelIsOpen);
  };
  
  const toggleCreditsPlans = () => toggleCreditsPanel(refillCreditsPanelIsOpen => !refillCreditsPanelIsOpen);
  
  const toggleOnlineStatus = () => {
    if (profile.LaravelEcho) {
      const LaravelEcho = profile.LaravelEcho;
      
      api.updateProfile({ body: { is_online: !profile.is_online } })
        .then(() => {
          if (!profile.is_online) {
            LaravelEcho
              .getEcho()
              .join('online')
              .here(users => {
                initOnlineList(users);
                
              })
              .joining(user => {
                addUserToOnlineList(user.id);
                
              })
              .leaving(user => {
                removeUserFromOnlineList(user.id);
              });
            
          } else {
            LaravelEcho.getEcho().leave('online');
          }
          
          updateProfileInfo({ is_online: !profile.is_online });
        });
    }
  };
  
  return (
    <>
      {subscriptionPanelIsOpen && (<Subscription onClose={toggleSubscriptionForm}/>)}
      {refillCreditsPanelIsOpen && (<RefilCredits onClose={toggleCreditsPlans}/>)}
      <WelcomeModal />
      <BonusesModal />
      {
        !subscriptionPanelIsOpen && !refillCreditsPanelIsOpen && letterFormIsOpen && (
          <SendEmail
            onClose={toggleLetterPanel}
            isOpen={letterFormIsOpen}
          />
        )
      }
      {
        message && (
          <StatusCode
            status='error'
            onClose={() => setMessage('')}
            image={require('../../assets/icons/decline-ico.svg')}
          >
            <p>{message}</p>
          </StatusCode>
        )
      }
      
      {
        success && (
          <StatusCode onClose={() => setSuccess('')} image={require('../../assets/img/success-ico.svg')}>
            <p>The payment was a success.</p>
          </StatusCode>
        )
      }
      
      <HeaderContainer>
        <div className="button-wrapper-test">
        </div>

        <Wrapper>
          <NavLink to='/catalog' style={{ textDecoration: 'none', marginRight: '7.35rem' }}>
            <Logo>
              <img src={require('../../assets/svg/logo.svg')} alt='logo'/>
            </Logo>
          </NavLink>
          <HeaderSearchSettings onClick={toggleSearchBar} color={isFiltersActive() ? '#3b5a9a' : '#4b4c59'}>
            <SearchIcon>
              <i className='far fa-search'/>
            </SearchIcon>
            <span>Search settings</span>
            {isFiltersActive() && (<span className='dot'>&#8226;</span>)}
          </HeaderSearchSettings>
          <UserBox
            initOnlineList={initOnlineList}
            addUserToOnlineList={addUserToOnlineList}
            removeUserFromOnlineList={removeUserFromOnlineList}
            profile={profile}
            toggleOnlineStatus={toggleOnlineStatus}
            upgradeAccount={toggleSubscriptionForm}
            refillCredits={toggleCreditsPlans}
          />
        </Wrapper>
        <SearchBar
          isOpen={isOpen}
          history={history}
          onClose={toggleSearchBar}
        />
      </HeaderContainer>
      <HeaderMobile pathname={history.location.pathname}>
        <MobWrapper>
          <Button onClick={toggleSearchBar} className={`mobile-search-btn ${isFiltersActive() ? 'active' : ''}`}>
            <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="6" height="2" rx="1" fill="#DE60AD"/>
              <rect y="3" width="8" height="2" rx="1" fill="#DE60AD"/>
              <rect y="6" width="9" height="2" rx="1" fill="#DE60AD"/>
            </svg>
            <i className='far fa-search'/>
          </Button>
          <Button onClick={() => history.push('/catalog')} className="mobile-header__go-back-btn">
            <i className="fas fa-chevron-left" />
          </Button>
          <NavLink to='/catalog' style={styleLink}>
            <Logo>
              <img src={require('../../assets/svg/logo.svg')} alt=""/>
            </Logo>
          </NavLink>

          {!profile.is_agency && (
            <NavLink to='/pricing' className="count-wrapper">
              {profile.credits} <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.342 20c-2.935 0-5.397-.927-7.387-2.783C.985 15.343 0 12.937 0 10c0-2.937.985-5.333 2.955-7.188C4.945.937 7.407 0 10.342 0c3.216 0 5.758 1.275 7.628 3.826l-2.11 1.13c-.584-.83-1.367-1.507-2.352-2.028a6.663 6.663 0 00-3.166-.783c-2.211 0-4.06.744-5.548 2.232C3.327 5.845 2.593 7.72 2.593 10c0 2.28.734 4.164 2.201 5.652 1.487 1.469 3.337 2.203 5.548 2.203 1.125 0 2.18-.251 3.165-.754.986-.521 1.77-1.207 2.352-2.057L18 16.174C16.05 18.724 13.498 20 10.342 20z" fill="#DE60AD"/><path d="M14 14h-2.113l-1.698-2.843H8.84V14H7V6h4.044c.89 0 1.595.24 2.113.72.528.48.791 1.1.791 1.859 0 .655-.186 1.187-.557 1.595a2.418 2.418 0 01-1.335.78L14 14zm-3.228-4.342c.38 0 .692-.096.934-.288a.976.976 0 00.363-.791.943.943 0 00-.363-.78c-.242-.2-.553-.3-.934-.3H8.841v2.16h1.931z" fill="#DE60AD"/></svg>
            </NavLink>
          )}
        </MobWrapper>
      </HeaderMobile>
    </>
  );
};

export default Header;

const styleLink = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};

const HeaderMobile = styled.header`
	position: fixed;
  z-index: 1020;
  top: 0;
  display: none;
  width: 100%;
  box-shadow: 0 2px 4px 0 #edeff4;
  
  .mobile-search-btn {
    display: flex;
  }
  
  .mobile-header__go-back-btn {
    display: none;
  }
  
  ${({ pathname }) => !pathname.includes('/catalog') && `
    .mobile-header__go-back-btn {
      display: flex;
    }
    
    .mobile-search-btn {
      display: none;
    }
  `}
    
  @media (max-width: 767px) {
    display: ${props => props.pathname.includes('/catalog') || props.pathname.includes('/profile') ? 'block' : 'none'};
  }  
`;

const MobWrapper = styled.div`
  position: relative;
  display: flex;
  height: 3rem;
  background-color: #fff;
  justify-content: flex-start;
  max-width: 71.25rem;
  margin: 0 auto;
  padding-right: .625rem;
  padding-left: .625rem;
`;

const Button = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background: 0;
  align-items: center;
	outline: none;
	cursor: pointer;
	
	i {
		font-size: 1.125rem;
    margin-left: .625rem;
    color: #3b5a9a;
	}
`;