import React, {} from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import Contacts from '../../containers/Contacts';
import MailBox from './MailBox';

// import Wizard from '../../containers/Wizard';

import * as Styles from './styles';

const Links = [
  {
    path: '/privacy',
    text: 'Privacy and Data Protection Policy',
    local: false
  },
  {
    path: '/terms',
    text: 'Terms of Service',
    local: false
  },
  {
    path: '/privacynotice',
    text: 'Privacy notice',
    local: false
  },
  {
    path: '/consent',
    text: 'Consent Form',
    local: false
  },
  {
    path: '/protection',
    text: 'Profile Identification and Scam Protection Policy',
    local: false
  },
  {
    path: '/sitemap',
    text: 'Sitemap',
    local: false
  },
  {
    path: '/pricing',
    text: 'Pricing',
    local: true
  },
];

const Sidebar = ({ inbox, profile }) => {
  
  const openWizardIfNeed = () => {
    const keys = ['country', 'city'];
    
    for (let i = 0; i < keys.length; i++) {
      if (!profile[keys[i]] && profile.id) {
        return <div/>;
        // return <Wizard initialValues={profile}/>;
      }
    }
  };
  
  function MenuLink(props) {
    if (props.local) {
      return (
        <NavLink to={props.path}>
          {props.text}
        </NavLink>
      );
    }
    
    return (
      <a
        href={(process.env.REACT_APP_REDIRECT_URL_PROMO ||
          process.env.REACT_APP_REDIRECT_URL) + props.path}
        target='_blank'
        rel="noopener noreferrer"
      >
        {props.text}
      </a>
    );
  }
  
  return (
    <Styles.SideBar>
      <ContactBox>
        <MailBox letters={inbox} profile={profile}/>
        <Contacts/>
      </ContactBox>
      <LinksWrapper>
        <DocLinks>
          {
            Links.map((link, i) => (
              <React.Fragment key={link.path}>
                <MenuLink path={link.path} local={link.local} text={link.text}/>
                {Links.length !== i + 1 && (<span>・</span>)}
              </React.Fragment>
            ))
          }
        </DocLinks>
        <span>Datejasmin © 2019</span>
      </LinksWrapper>
      {openWizardIfNeed()}
    </Styles.SideBar>
  );
};

export default Sidebar;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #aaa;
  font-size: .75rem;
  padding-top: 1rem;
`;

const DocLinks = styled.div` 
  padding-bottom: .375rem;

  a {
    text-decoration: none;
    color: #aaa;
    
    :hover {
      text-decoration: underline;
    }
  }
`;

const ContactBox = styled.div`
	box-shadow: 0 2px 4px rgba(184, 191, 211, 0.2);
  border-radius: .125rem;
  box-shadow: 0 0.125rem 0.25rem rgba(184, 191, 211, .2);

	@media (max-width: 767px) {
		box-shadow: none;
	}
`;