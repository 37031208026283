import React, { Component } from 'react';
import styled from 'styled-components';

import { unknownUser } from '../../assets';
import { btn } from '../styles';
import Echo from '../../utils/echo';
import * as api from '../../api';
import SwitchButton from '../Switch';

const sortByMessagesCount = (a, b) => {
  if (a.unread_messages_count === b.unread_messages_count) {
    return b.unread_mail_count - a.unread_mail_count;
  }

  return b.unread_messages_count - a.unread_messages_count;
};

class SwitchAccounts extends Component {
  state = {
    displayedProfiles: [],
    tokens: []
  };

  componentDidMount() {
    const profiles = this.props.switch_profiles.filter(({ id }) => id !== this.props.profile.id);
    this.setState({ displayedProfiles: profiles || [] });

    if (profiles) {
      api.switchProfile()
        .then(data => {
          const tokens = data.filter(({ id }) => id !== this.props.profile.id);
          this.setState({ tokens });

          tokens.forEach(({ token }, index) => {
            const profile = profiles[index];
            const LaravelEcho = new Echo();

            LaravelEcho.init(token)
              .then(() => {
                this.setState({
                  displayedProfiles: [
                    ...this.state.displayedProfiles.slice(0, index),
                    { ...profile, LaravelEcho, token },
                    ...this.state.displayedProfiles.slice(index + 1)
                  ]
                });

                return (
                  LaravelEcho
                    .getEcho()
                    .private('messages.' + profile.id)
                    .listen('.NewMessage', data => {
                      const { type, receiver_id } = data.message;

                      if (receiver_id === profile.id) {
                        if (type === 'MESSAGE') {
                          this.incrementMessagesCount(profile.id);

                        } else if (type === 'LETTER') {
                          this.incrementMailsCount(profile.id);
                        }
                      }
                    })
                    .listen('.ProfileUpdated', data => {
                      const { displayedProfiles } = this.state;
                      const atIndex = displayedProfiles.findIndex(({ id }) => profile.id === id);
                      const updatedProfile = displayedProfiles.find(({ id }) => profile.id === id);

                      this.setState({
                        displayedProfiles: [
                          ...displayedProfiles.slice(0, atIndex),
                          { ...updatedProfile, is_online: data.is_online },
                          ...displayedProfiles.slice(atIndex + 1)
                        ]
                      });
                    })
                );
              });
          });
        });
    }
  }

  toggleStatusOfAllUsers = action => {
    const { displayedProfiles } = this.state;

    displayedProfiles.forEach(({ token, id, is_online }) => {
      if (action === 'connect' ? !is_online : is_online) {
        api.updateProfile({ token, body: { is_online: action === 'connect' } });
      }
    });

    this.setState({
      displayedProfiles: displayedProfiles.map(profile => ({
        ...profile,
        is_online: action === 'connect'
      }))
    });
  };

  toggleOnlineStatus = profile => {
    const { token } = this.state.tokens.find(({ id }) => id === profile.id);

    if (token) {
      api.updateProfile({ token, body: { is_online: !profile.is_online } })
        .then(() => {
          const displayedProfiles = this.state.displayedProfiles;
          const updatedProfile = this.state.displayedProfiles.find(({ id }) => profile.id === id);
          const atIndex = this.state.displayedProfiles.findIndex(({ id }) => profile.id === id);

          this.setState({
            displayedProfiles: [
              ...displayedProfiles.slice(0, atIndex),
              { ...updatedProfile, is_online: !profile.is_online },
              ...displayedProfiles.slice(atIndex + 1)
            ]
          });
        });
    }
  };

  incrementMessagesCount = profileId => {
    const displayedProfiles = this.state.displayedProfiles.map(profile => {
      if (profile.id === profileId) {
        return { ...profile, unread_messages_count: profile.unread_messages_count + 1 };
      } else {
        return profile;
      }
    });

    this.setState({ displayedProfiles });
  };

  incrementMailsCount = profileId => {
    const displayedProfiles = this.state.displayedProfiles.map(profile => {
      if (profile.id === profileId) {
        return { ...profile, unread_mail_count: profile.unread_mail_count + 1 };
      } else {
        return profile;
      }
    });

    this.setState({ displayedProfiles });
  };

  switchProfile = profileId => {
    const profile = this.state.tokens.find(({ id }) => id === profileId);

    localStorage.setItem('token', profile.token);
    sessionStorage.setItem('token', profile.token);

    window.location.href = `${window.location.origin}/catalog`;
  };

  render() {
    const profiles = this.state.displayedProfiles.sort(sortByMessagesCount);
    const totalMessagesCount = profiles.reduce((acc, profile) => {
      return acc + (profile.unread_messages_count || 0);
    }, 0);

    return (
      <Wrapper>
        <Options>
          <Button>
            Change account
            <MailNumber>{totalMessagesCount > 99 ? '99+' : totalMessagesCount}</MailNumber>

          </Button>
          <OptionList>
            <div className="controls_status_wrapper">
              <Button onClick={() => this.toggleStatusOfAllUsers('connect')}>Set online</Button>
              <Button onClick={() => this.toggleStatusOfAllUsers('disconnect')}>Set offline</Button>
            </div>
            {
              profiles && profiles.length ? (
                profiles.filter(({ id }) => id !== this.props.profile.id).map(profile => (
                  <Option key={profile.name}>
                    <SwitchButton onChange={() => this.toggleOnlineStatus(profile)} value={profile.is_online}/>
                    <ProfileWrap onClick={() => this.switchProfile(profile.id)}>
                      <ImageWrap id={profile.id}>
                        <img id={profile.id} src={profile.photo ? profile.photo.src : unknownUser} alt=""/>
                      </ImageWrap>
                      <UserName id={profile.id}>{profile.name}</UserName>
                    </ProfileWrap>

                    <Count>
                      <div>{profile.unread_messages_count} mes.</div>
                      <div>{profile.unread_mail_count} mails</div>
                    </Count>

                  </Option>
                ))
              ) : (
                <Message>No profiles available</Message>
              )
            }
          </OptionList>
        </Options>
      </Wrapper>
    );
  }
}

export default SwitchAccounts;

const MailNumber = styled.span`
	font-size: .75rem;
  display: flex;
  align-items: center;
  padding: 0 .5625rem;
  height: 1rem;
  letter-spacing: .046875rem;
  color: #fff;
  border-radius: .8125rem;
  background-color: #3b5a9a;
  margin-top: auto;
  margin-bottom: auto;
`;

const Count = styled.div`
  div {
    color: #555;
    font-size: .75rem;
  }
`;

const ProfileWrap = styled.div`
  display: flex;
  align-items: center;
`;

const Message = styled.span`
  padding: .55rem;
  color: #b8bfd3;
  cursor: default;
  text-align: center;
  font-size: .875rem;
`;

const Wrapper = styled.div`
  position: relative;
`;

const UserName = styled.span`
  color: #515151;
  font-size: .875rem;
  letter-spacing: .055rem;
  font-weight: 600;
`;

const ImageWrap = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  
  img {
    width: 100%;
    border-radius: 50%;
    height: 100%;
    o-object-fit: cover;
  }
  
  margin-right: .6775rem;
`;

const OptionList = styled.span`
  max-height: 50vh;
  overflow-y: auto;
  width: 100%;
  position: absolute;
  top: 1.5rem;
  display: flex;
  flex-direction: column;
  min-width: 128px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(184, 191, 211, .5);
  z-index: 1;
  border-radius: 2px;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  
  .controls_status_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px 0;
    
    button {
      min-width: auto;
      width: 45%;
      margin: 0;
      
      :first-of-type {
       color: #28a745;
       border-color: #28a745;
        
       &:hover {
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;
      }
    }
      
      :last-of-type {
        color: #dc3545;
        border-color: #dc3545;
        
        &:hover {
          color: #fff;
          background-color: #dc3545;
          border-color: #dc3545;
        }
      }
    }
  }
`;

const Options = styled.div`
	:hover ${OptionList} {
    opacity: 1;
    visibility: visible;
  }
`;

const Option = styled.div`
  padding: 7px 8px;
  font-size: 12px;
  text-align: left;
  cursor: pointer;
  display: flex;
	justify-content: space-between;
	
	span {
		color: #4b4c59;
		text-overflow: ellipsis;
    overflow: hidden; 
    width: 3.75rem; 
    white-space: nowrap;
    @media (min-width: 768px) and (max-width: 870px) {
      width: 3.1rem; 
    }
	}
	
	:hover {
   background-color: #edeff4;
	}
`;

export const Button = styled(btn)`
  width: 100%;
  min-width: 12.25rem;
  margin-right: 1.25rem;
  letter-spacing: .05rem;
  font-weight: 600;
  display: flex;
  justify-content: space-around;
  line-height: 1.5rem;
  height: 1.5rem;
  color: #3b5a9a;
  border-color: #3b5a9a;
  background-color: #fff;
  outline: none;
 
	:focus {
    background-color: inherit;
  }
  
  :hover { 
    background-color: inherit;
  }
`;
