import styled from 'styled-components';
import {btn} from '../styles';

export const MobSubscribe = styled.span`
  display: inline-block;
  text-decoration: underline;
  color: #3b5a9a;
  cursor: pointer;

  :hover {
    text-decoration: none
  }

  font-size: 1.125rem;
`;

export const Subscribe = styled.span`
  display: inline-block;
  text-decoration: underline;
  color: #3b5a9a;
  cursor: pointer;

  :hover {
    text-decoration: none
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: center

  @media (max-width: 767px) {
    width: 100%;
    align-items: initial;
    flex-direction: column;
  }
  
  span {
    display: inline-block !important;
  }
`;

export const TabsItem = styled.div`
  position: relative;
  padding-bottom: .625rem;
  text-align: center;
  flex-grow: 1;

  :hover {
    cursor: pointer;
  }
  
  ${({active}) => active && `
    font-weight: 700;
    color: #3b5a9a
    
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: .125rem;
      content: "";
      background-color: #3b5a9a
    }
  `}
`;

export const TabsWrap = styled.div`
  display: flex;
  padding-right: 2.6875rem;
  padding-left: .9375rem;
  border-bottom: .0625rem solid #edeff4;
  justify-content: space-between;
`;

export const TabsList = styled.div`
  width: 14.375rem;
  border: 0;
  display: flex;
`;

export const Button = styled(btn)`
  margin-left: .6875rem;
  letter-spacing: .05rem;
  box-shadow: 0 0 0.375rem 0 rgb(59,90,154,.5);
  
  i {
    font-size: 12px;
    margin-right: 6px;
  }

  @media (max-width: 767px) {
    margin-right: 3.125rem;
    margin-left: 0;
    padding: 0 .9375rem;
  }

  @media (max-width: 480px) {
    font-weight: 400;
    margin-right: .9375rem;
  }
  
  ${({lightning}) => lightning && `
    &::before {
      content: '';
      width: .625rem;
      padding-right: .7125rem;
      height: 1rem;
      margin-right: .3125rem;
      background: url(${require('../../assets/icons/settings-btn-icon.svg')}) center/cover no-repeat;
    }
  `}
`;

export const Balance = styled.span`
  font-weight: 600;
  margin-left: auto;

  @media (max-width: 767px) {
    margin-top: 1.25rem;
    margin-bottom: .625rem;
    margin-left: 0;
    font-size: .875rem;
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  
  @media (max-width: 767px) {
    padding-top: .625rem;
    flex-flow: column;
    align-items: flex-start
  }
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #515151;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MobileWrap = styled.div`
  margin-bottom: 1.5625rem;
  padding: .625rem 1.5rem .875rem 1.25rem;
`;

export const PaymentsWrap = styled.div`
  max-width: 42.3125rem;
  padding: 0;
  position: relative;
  width: 100%;
  border-radius: 2px;
  color: #515151;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(184, 191, 211, .2);
  
  @media (max-width: 767px) {
    max-width: 100%;
    box-shadow: none;
  }
`;

export const HeaderWrap = styled.div`
  padding: .625rem 1.5rem .875rem 1.25rem;

  @media (max-width: 767px) {
    display: none;
    margin-bottom: 1.5625rem
  }
`;

export const Back = styled.div`
  display: inline-flex;
  margin-bottom: .625rem;
  color: #3b5a9a;
  align-items: center;
  cursor: pointer;

  i {
    font-size: 1.125rem;
    margin-right: .375rem;
  }

  @media (max-width: 767px) {
    i {
      font-size: 1.5625rem;
      margin-right: .625rem;
      color: #515151;
    }
  }
`;