import React from 'react';
import styled from 'styled-components';

import { Modal, ModalContent } from '../Modal';
import * as Styles from '../Subscription/styles';

const UnsubscribeMessage = ({ isOpen, onClose, subscription: { name, end_date } }) => {
  return (
    <Modal isOpen={isOpen}>
      <Content>
        <Styles.Close onClick={onClose}>
          <i className='fal fa-times'/>
        </Styles.Close>
        <div className="modal_body">
          <span className='title'>Your subscription was canceled.</span>
          <span className="text">
            You have canceled subscription renewal {name}.
            Funds will no longer be charged,
            but you can continue chatting until the end of the subscription {end_date}.
          </span>
          <div className="btn_wrapper">
            <button onClick={onClose}>OK!</button>
          </div>
        </div>
      </Content>
    </Modal>
  );
};

export default UnsubscribeMessage;

export const Content = styled(ModalContent)`
  width: 100%;
  max-width: 35rem;
  
  .modal_body {
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    
    .text {
      font-size: 1.125rem;
      line-height: 25px;
    }
    
    .title {
      color: #000;
      font-size: 15px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 14px;
    }
 
    .btn_wrapper {
      margin-top: 30px;
      display: flex;
      width: 100%;
      justify-content: center;
      
      button {
        cursor: pointer;
        background-color: #5072de;
        border-radius: 3px;
        width: 35%;
        outline: none;
        padding: .6125rem;
        font-size: 1rem;
        color: #fff;
      }
    }
  }
`;