import React from 'react';

import * as Styles from './styles';
import {TabsItem} from '../styles';

const types = ['Inbox', 'Unread', 'Sent', 'Favorites'];

const Filters = ({filter, onClick}) => {
  return (
    <Styles.Filters>
      {
        types.map(type => (
          <TabsItem
            onClick={onClick}
            key={type}
            id={type.toLowerCase()}
            active={filter === type.toLowerCase()}
          >
            {type}
          </TabsItem>
        ))
      }
    </Styles.Filters>
  );
};

export default Filters;