import React, {Component} from 'react';
import styled from 'styled-components';

import Contacts from './Contacts';
import {Modal, ModalContent} from '../../Modal';
import SendEmail from '../../SendEmail';

class NewEmail extends Component {

  state = {
    isOpenMailForm: false,
    isOpenContactsList: false,
    receiver: null
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.toggleEmailForm();
    }
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  toggleEmailForm = id => {
    if (id) {
      this.setState({
        isOpenMailForm: !this.state.isOpenMailForm,
        receiver: this.props.contacts.data.find(contact => contact.id === id)
      });
      this.toggleContactsList();
    } else {
      this.setState({isOpenMailForm: false, isOpenContactsList: false, receiver: null});
    }
  };

  toggleContactsList = () => this.setState({isOpenContactsList: !this.state.isOpenContactsList});

  render() {
    return (
      <div>
        <Button onClick={this.toggleContactsList}><i className="far fa-envelope" />NEW MAIL</Button>
        <Modal isOpen={this.state.isOpenContactsList}>
          <PopupContent ref={this.setWrapperRef}>
            <Close onClick={this.toggleContactsList}>
              <i className='fal fa-times'/>
            </Close>
            <Contacts
              onClose={this.toggleContactsList}
              toggleEmailForm={this.toggleEmailForm}
              contacts={this.props.contacts}
              onlineUsers={this.props.onlineUsers}
            />
          </PopupContent>
        </Modal>
        {
          this.state.isOpenMailForm && (
            <SendEmail
              isOpen={this.state.isOpenMailForm}
              profile={this.state.receiver}
              onClose={this.toggleEmailForm}
            />
          )
        }
      </div>
    );
  }
}

export default NewEmail;

export const PopupContent = styled(ModalContent)`
  min-width: 22rem;
  
  @media (max-width: 767px) {
    min-width: inherit;
  }
`;

export const Close = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background: 0;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  color: #b8bfd3;
  cursor: pointer;
  
  :hover {
    color: #4b4c59;
  }
  
  i {
    padding: .5rem;
  }
  
  @media (max-width: 767px) {
    display: none;
  }
`;

const Button = styled.button`
  border: 0;
  background-color: transparent;
  outline: none;
  color: #4a90e2;
  font-size: .75rem;
  letter-spacing: 0.8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  
  i {
    font-size: 1rem;
    padding-right: 6.5px;
  }
`;