import styled from 'styled-components';

export const NotificationContainer = styled.div`
	display: flex;
`;

export const HideNotifications = styled.button`
  width: 100%;
  outline: none;
  border: none;
  color: #aaa;
  text-align: center;
  font-size: 20px;
  position: relative;
  background-color: #EBEFF5;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  padding: 20px 8px 16px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Message = styled.div`
	display: flex;
	flex-direction: column;
	padding: 9px;
	
	b {
		font-size: 12px;
		margin-top: 14px;
	}
	
	span:last-child {
		font-size: 12px;
		margin-top: 14px;
	}
	
	span {
		font-size: 14px;
	}
`;

export const SideBar = styled.div`
  width: 100%;
  max-width: 16.5625rem;
  margin-right: 1.25rem;
  flex-shrink: 0;
  align-self: stretch;
  
  @media (max-width: 767px) {
    display: none;
  }
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 10px;
	span {
		color: #4b4c59;
		font-size: 18px;
	}
	
	span:first-child {
		font-weight: bold;
	}
`;

export const Loader = styled.img`
  width: 150px;
  display: inline-block;
  border-radius: 15px;
  right: 0;
  left: 0;
  margin: auto;
  top: 41%;
  position: absolute;
`;

export const Edit = styled.span`
	color: #3b5a9a;
  font-size: .875rem;
`;

export const Photo = styled.img`
	width: 70px;
	height: 70px;
	border-radius: 50%;
	cursor: pointer;
`;

export const Container = styled.div`
	width: 100%;
	padding: 5% 8% 7% 8%;
	display: flex;
	flex-direction: column;
	box-shadow: rgb(199,195,195) 0px 1px 2px -1px;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  margin-bottom: 10%;
`;

export const ButtonContainer = styled.div`
	display: flex;
	margin-top: 6%;
	button { 
		width: 100%;
	}
`;

export const Button = styled.button`
	font-size: .85rem;
	letter-spacing: .8px;
	font-weight: bold;
	color: #fff;
	outline: none;
	border: none;
	cursor: pointer;
	box-shadow: rgb(199, 195, 195) 0px 0px 15px 0px;
	margin: 0 5%;
	padding: 9px 15px;
	background-color: ${props => props.theme ? props.theme : '#555'}
`;
