import React, {useState} from 'react';
import styled from 'styled-components';

const Status = ({onChange}) => {
  const [checked, setChecked] = useState(false);

  const handleChange = e => {
    onChange(e.target.value, !checked);
    setChecked(checked => !checked);
  };

  return (
    <Checkbox>
      <CheckboxInput
        type="checkbox"
        onChange={handleChange}
        value='status'
        checked={checked}
      />
      <CheckboxCustom/>
      <CheckboxText>Online</CheckboxText>
    </Checkbox>
  );
};

export default Status;

const Checkbox = styled.label`
	position: relative;
	display: flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
`;

const CheckboxCustom = styled.span`
  position: relative;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: .375rem;
  border: .0625rem solid #b8bfd3;
  border-radius: .125rem;
  background-color: #fff;
  flex-shrink: 0;
`;

const CheckboxText = styled.span`
	font-size: .75rem;
	white-space: initial;
	color: #4b4c59;
	line-height: 1;
	
	@media (max-width: 767px) {
	  font-size: 1rem;
	}
`;

const CheckboxInput = styled.input`
	position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  
  :focus ~ ${CheckboxCustom} {
    border-color: #84c44b;
  }
  
  :checked ~ ${CheckboxCustom} {
    border-color: #84c44b;
		background-color: #84c44b;
  }
  
  :checked ~ ${CheckboxCustom}::before {
    content: '';
		position: absolute;
		top: .25rem;
		left: .1975rem;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
		width: 0.4375rem;
		height: 0.3125rem;  
		border: 2px solid #fff;
		border-top: none;
		border-right: none
  }
`;
