import {withSuccessStatus, withFailureStatus} from '../utils/withData';
import * as TYPES from '../constants/types';

const initialState = {
  data: {},
  error: null,
  loading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
  case TYPES.FETCH_ALBUM_REQUEST:
    return {...state, loading: true, error: null};

  case TYPES.FETCH_ALBUM_SUCCESS:
    return withSuccessStatus(state, {data: action.album});

  case TYPES.FETCH_ALBUM_FAILURE:
    return withFailureStatus(state, {}, action.error);

  case TYPES.UPDATE_PHOTOS_REQUEST:
    return {...state, error: null};

  case TYPES.UPDATE_PHOTOS_SUCCESS:
    return {...state, data: action.album};

  case TYPES.UPDATE_PHOTOS_FAILURE:
    return {...state, error: action.error};

  case TYPES.REMOVE_PHOTO: {
    const photos = state.data.photos.filter(photo => photo.id !== +action.photo);
    return {...state, data: {...state.data, photos}};
  }

  case TYPES.LIKE_PHOTO: {
    const index = state.data.photos.findIndex(photo => photo.id === action.photo.id);
    const likedPhoto = Object.assign({}, action.photo, {is_liked: true});
    return {
      ...state,
      data: {
        ...state.data,
        photos: [
          ...state.data.photos.slice(0, index),
          likedPhoto,
          ...state.data.photos.slice(index + 1)
        ]
      }
    };
  }

  case TYPES.UPDATE_ALBUM: {
    return {...state, data: action.album};
  }

  default:
    return state;
  }
};