import React from 'react';
import styled from 'styled-components';

import { Heart } from '../assets/svg/Heart';
import PinkArrow from '../assets/svg/pinkArrow';
import ErrorMessageActions from '../components/Chat/MessagesList/error';

const addZero = i => i < 10 ? (i = '0' + i, i) : i;

export function getMessageByType({ message, author, callback, isError, fetchMessage, sendLetter, removeErrorMessage, onTouchStart }) {
  let date = new Date(message.created_at);
  
  switch (message.type) {
  case 'PHOTO': {
    return (
      <PhotoWrap author={author}>
        {
          message.entity == null ? (
            <DeletedPhoto>
              <p>DELETED</p>
            </DeletedPhoto>
          ) : (<Image src={message.entity.src}/>)
        }
        <LikeWrapper>
          <span><Heart id={message.id}/>{author === 'me' ? 'You liked photo' : 'Liked your photo'}</span>
          <CreatedAt>{addZero(date.getHours()) + ':' + addZero(date.getMinutes())}</CreatedAt>
        </LikeWrapper>
      </PhotoWrap>
    );
  }

  case 'MESSAGE': {
    return (
      <MessageWrap author={author}>
        <ErrorMessageActions
          isError={isError}
          message={message}
          sendMessage={fetchMessage}
          removeMessage={removeErrorMessage}
          onTouchStart={onTouchStart}
        />
        <div className="message_wrapper">
          <p>{message.text}</p>
          <CreatedAt message>{addZero(date.getHours()) + ':' + addZero(date.getMinutes())}</CreatedAt>
        </div>
      </MessageWrap>
    );
  }

  case 'LETTER': {
    return (
      <LetterWrap author={author}>
        <ErrorMessageActions
          isError={isError}
          message={message}
          sendLetter={sendLetter}
          removeMessage={removeErrorMessage}
          onTouchStart={onTouchStart}
        />
        <Letter>
          <span>
            <LetterIcon>
              <i className="fas fa-envelope"/>
            </LetterIcon>
            {author === 'me' ? 'You send email. ' : `${author} send you email. `}
            <OpenLetter onClick={callback} onTouchStart={callback}>
              {message.entity.subject}
              <PinkArrow />
            </OpenLetter>
            <CreatedAt>{addZero(date.getHours()) + ':' + addZero(date.getMinutes())}</CreatedAt>
          </span>
        </Letter>
      </LetterWrap>
    );
  }

  default:
    break;
  }
}

const OpenLetter = styled.span`
  margin-left: 4px;
  color: #DE60AD !important;
  text-decoration: underline;
  padding-right: 40px;
  cursor: pointer;
  svg {
    margin-left: 5px;
    margin-top: 2px;
  }
`;

const LetterWrap = styled.div`
	width: 100%;
	display: flex;
	position: relative;
	
	${({author}) => author === 'me' && `
    justify-content: flex-end;
  `}
  
   @media (max-width: 480px) {
     position: relative;
   }
`;

const LetterIcon = styled.div`
	margin-right: 6px;
  font-size: 16px;
  color: #3b5a9a;
`;

const LikeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 8px;
  
  span {
  	display: flex;
  	align-items: center;
  }
  
  svg {
		margin-right: 5px;
		fill: #dd2c57;
		width: 12px;
		height: 12px;
	}
`;

const CreatedAt = styled.b`
  position: absolute;
  bottom: 3px;
  cursor: default;
  right: 10px;
  color: #b8bfd3;
  font-size: 12px;
  font-weight: 400;
`;

const MessageWrap = styled.div`
  display: flex;
  max-width: 85%;
  align-items: center;
  width: max-content;
  margin-top: 11px;
  margin-bottom: 11px;
  position: relative;
  
  .message_wrapper {
    border: 1px solid #edeff4;
    border-radius: 15px 15px 15px 0;
    padding: 8px 14px;
  }
  
  p {
    word-break: break-word;
    padding-right: 40px;
  }
  
  ${({author}) => author === 'me' && `
    margin-left: auto;
    .message_wrapper {
      background-color: #edeff4;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 0;
    }
  `}
`;

const Letter = styled.span`
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: .375rem .75rem;
  border: 1px solid #3b5a9a;
  border-radius: 15px;
  position: relative;

  b {
    bottom: 9px;
  }
  
  span {
    display: flex;
    align-items: center;
    font-size: .875rem;
    font-weight: 600;
    color: #4b4c59;
  }
`;

const DeletedPhoto = styled.div`
	width: 200px;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	p {
		font-size: 27px;
    font-weight: bold;
	}
`;

const PhotoWrap = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 15.1875rem;
  margin-top: .6875rem;
  margin-bottom: .6875rem;
  box-shadow: 0 0.125rem 0.25rem rgba(184, 191, 211, .2);
  
  ${({author}) => author === 'me' && `
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 0;
    margin-left: auto;
  `}
  
   @media (max-width: 767px) {
     width: 65%;
   }
`;

const Image = styled.img`
  border-radius: .125rem;
  object-fit: cover;
  font-family: "object-fit: cover;", serif;
  max-height: 15.625rem;
  box-sizing: content-box;
  max-width: 100%;
  height: auto;
`;