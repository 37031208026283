import React from 'react';
import styled from 'styled-components';

import {FieldContainer} from './styles';

const RenderInput = ({ label, type, input }) => {
  return (
    <FieldContainer>
      <Span>{label}</Span>
      <input type={type || 'text'} {...input}/>
    </FieldContainer>
  );
};

export default RenderInput;

const Span = styled.span`
  @media (max-width: 767px) {
    font-size: .75rem;
    font-weight: 600;
    width: auto;
    flex-shrink: 0;
    padding-right: 0;
  }
  
  flex-shrink: 0;
  padding-right: .9375rem;
  display: block;
  width: 5rem;
  color: #515151;
`;
