import React from 'react';
import styled from 'styled-components';

const BodyLetter = ({ text, photo, subject, openPhoto }) =>
  <Body>
    <Subject>{subject}</Subject>
    {photo && (<Photo onClick={openPhoto} src={photo.thumbnail_src}/>)}
    <Text>
      <p>{text.replace(/↵/gi, '\n')}</p>
    </Text>
  </Body>;

export default BodyLetter;

const Subject = styled.h1`
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: .625rem;
`;

const Body = styled.div`
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	padding: .9375rem 1.25rem;
	max-height: 29.45rem;
	
	::-webkit-scrollbar { 
    display: none; 
  } 
`;

const Text = styled.div`
	p {
    font-size: .875rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: justify;
    word-break: break-word;
    white-space: pre-line;
	}
`;

const Photo = styled.img`
  cursor: pointer;
	width: 8.4375rem;
  height: 6.25rem;
  object-fit: cover;
  font-family: "object-fit: cover;", serif;
  margin: .625rem 0;
`;