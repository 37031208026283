import * as TYPES from '../constants/types';

export const fetchProfile = () => ({
  type: TYPES.FETCH_PROFILE_REQUEST
});

export const fetchChatHistory = id => ({
  type: TYPES.FETCH_CHAT_HISTORY_REQUEST,
  userId: id,
});

export const loadMoreMessages = (id, page = 1) => ({
  type: TYPES.FETCH_MORE_MESSAGES_REQUEST,
  userId: id,
  page
});

export const loadMoreProfiles = (query, page = 1) => ({
  type: TYPES.FETCH_MORE_PROFILES_REQUEST,
  page,
  query
});

export const fetchMessage = (text, id) => ({
  type: TYPES.FETCH_MESSAGE,
  text,
  id
});

export const fetchCatalog = query => ({
  type: TYPES.FETCH_CATALOG_REQUEST,
  query
});

export const fetchUserProfile = endpoint => ({
  type: TYPES.FETCH_USER_PROFILE_REQUEST,
  endpoint
});

export const addMessage = message => ({
  type: TYPES.ADD_MESSAGE,
  text: message,
  scrollToBottom: true,
});

export const receiveMessage = message => ({
  type: TYPES.RECEIVE_MESSAGE,
  message
});

export const getChatUserProfile = profile => ({
  type: TYPES.INIT_CHAT_USER_PROFILE,
  profile
});

export const fetchWizardOptions = () => ({
  type: TYPES.FETCH_WIZARD_OPTIONS_REQUEST
});

export const fetchAlbumsList = endpoint => ({
  type: TYPES.FETCH_ALBUMS_REQUEST,
  endpoint
});

export const addAlbum = album => ({
  type: TYPES.ADD_ALBUM,
  album
});

export const deleteAlbum = album => ({
  type: TYPES.DELETE_ALBUM,
  album
});

export const fetchAlbum = (albumId, endpoint = '') => ({
  type: TYPES.FETCH_ALBUM_REQUEST,
  id: albumId,
  endpoint
});

export const updatePhotos = (albumId, photos) => ({
  type: TYPES.UPDATE_PHOTOS_REQUEST,
  id: albumId,
  photos
});

export const removePhoto = photo => ({
  type: TYPES.REMOVE_PHOTO,
  photo
});

export const loadMoreAlbums = (endpoint, page) => ({
  type: TYPES.FETCH_MORE_ALBUMS_REQUEST,
  page,
  endpoint
});

export const fetchMailsList = query => ({
  type: TYPES.FETCH_MAIL_HISTORY_REQUEST,
  query
});

export const loadMoreMails = (page, query) => ({
  type: TYPES.FETCH_MORE_MAILS_REQUEST,
  page,
  query
});

export const readLetter = letter => ({
  type: TYPES.READ_LETTER,
  letter
});

export const deleteLetter = letter => ({
  type: TYPES.REMOVE_LETTER,
  letter
});

export const incrementMailCount = () => ({
  type: TYPES.INCREMENT_INBOX_VALUE
});

export const decrementMailCount = () => ({
  type: TYPES.DECREMENT_INBOX_VALUE
});

export const getInitialMailCount = count => ({
  type: TYPES.GET_INITIAL_COUNT,
  count
});

export const getInitialMessagesCount = count => ({
  type: TYPES.GET_INITIAL_MESSAGES_COUNT,
  count
});

export const incrementMessagesCount = () => ({
  type: TYPES.INCREMENT_MESSAGES_VALUE
});

export const getContactsList = () => ({
  type: TYPES.FETCH_CONTACTS_REQUEST
});

export const setLikeToPhoto = photo => ({
  type: TYPES.LIKE_PHOTO,
  photo
});

export const markMessagesAsRead = contactId => ({
  type: TYPES.MARK_MESSAGES_AS_READ,
  contactId
});

export const addContactToFavoritesList = contact => ({
  type: TYPES.ADD_TO_FAVORITE_LIST,
  contact
});

export const deleteContactFromFavoritesList = contact => ({
  type: TYPES.DELETE_FROM_FAVORITE_LIST,
  contact
});

export const updateContact = (userId, message) => ({
  type: TYPES.UPDATE_LAST_MESSAGE,
  userId,
  message
});

export const createNewContact = contact => ({
  type: TYPES.CREATE_NEW_CONTACT,
  contact,
});

export const updateProfileInfo = values => ({
  type: TYPES.UPDATE_PROFILE_INFO,
  values
});

export const updateAlbum = album => ({
  type: TYPES.UPDATE_ALBUM,
  album
});

export const updateContactsInfo = (profile_id, data) => ({
  type: TYPES.UPDATE_CONTACTS_INFO,
  profile_id,
  data
});

export const initOnlineList = users => ({
  type: TYPES.INIT_ONLINE_LIST,
  users
});

export const removeUserFromOnlineList = user => ({
  type: TYPES.REMOVE_USER_FROM_ONLINE_LIST,
  user
});

export const addUserToOnlineList = user => ({
  type: TYPES.ADD_USER_TO_ONLINE_LIST,
  user
});

export const typing = uid => ({
  type: TYPES.TYPING_REQUEST,
  uid
});

export const getCardsList = cards => ({
  type: TYPES.GET_CARDS_LIST,
  cards
});

export const addNewCard = card => ({
  card,
  type: TYPES.ADD_NEW_CARD
});

export const deleteCard = card => ({
  card,
  type: TYPES.DELETE_CARD
});

export const resendVerificationEmail = () => ({
  type: TYPES.RESEND_VERIFICATION_EMAIL_REQUEST
});

export const closeVerifyEmailModal = () => ({
  type: TYPES.CLOSE_VERIFY_MESSAGE_MODAL,
});

export const getSimilarProfiles = id => ({
  type: TYPES.GET_SIMILAR_PROFILES_REQUEST,
  id
});

export const initErrorMessages = id => ({
  type: TYPES.INIT_ERROR_MESSAGES,
  id
});

export const removeErrorMessage = ({ id, profile_id }) => ({
  type: TYPES.REMOVE_ERROR_MESSAGE,
  id,
  profile_id
});

export const initErrorMails = () => ({
  type: TYPES.INIT_ERROR_MAILS,
});

export const removeErrorMail = letter => ({
  type: TYPES.REMOVE_ERROR_MAIL,
  payload: letter,
});

export const addErrorMail = letter => ({
  type: TYPES.ADD_ERROR_MAIL,
  payload: letter,
});

export const onSendMail = letter => ({
  type: TYPES.SEND_LETTER,
  payload: letter,
});